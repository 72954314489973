import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  const { palette, spacing } = theme;

  const radius = spacing(2.5);

  return {
    root: {
      width: '100%'
    },
    list: {
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: '350px',
      maxHeight: '328px',
      width: '100%',
      padding: '20px',
      paddingLeft: '20px!important',
      paddingRight: '20px!important',
      '&::-webkit-scrollbar': {
        width: '5px'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.mode === 'light' ? '#E5E3E8' : '#757575',
        borderRadius: '20px',
        border: '3px solid transparent'
      },
      [theme.breakpoints.down(1500)]: {
        height: '300px',
        maxHeight: '328px'
      }
    },
    chat: {
      marginTop: '30px',
      minHeight: '425px',
      border:
        theme.palette.mode === 'light'
          ? '1px solid #E7EBF0!important'
          : '1px solid #3a3541!important',
      padding: '30px',
      borderRadius: '15px!important'
    },
    chatbot: {
      minHeight: '340px!important',

      [theme.breakpoints.down(1500)]: {
        height: '300px',
        minHeight: '300px'
      },

      marginTop: '0px',
      height: '300px!important',
      backgroundColor:
        theme.palette.mode === 'light'
          ? '#f5f4f4!important'
          : '#46424c!important',
      border:
        theme.palette.mode === 'light'
          ? '1px solid #E7EBF0!important'
          : '1px solid #3a3541!important',
      padding: '5px',
      borderRadius: '0px!important'
    },
    title: {
      fontSize: '1.875rem',
      lineHeight: '2.25rem',
      fontWeight: '700'
    },
    submitContainer: {
      paddingTop: '4.2rem',
      // paddingBottom: "1.5rem",
      minHeight: '80px'
    },
    submitContainerChatbot: {
      marginTop: '2rem',
      padding: '10px',
      // paddingBottom: "1.5rem",
      minHeight: '80px',
      [theme.breakpoints.down(1500)]: {
        marginTop: '3.6rem'
      }
    },
    input: {
      fontFamily: ['Inter'].join(','),
      marginBottom: '10px!important'
    },
    count: {
      textAlign: 'right',
      marginTop: '-27px',
      marginBottom: '10px!important',
      opacity: '.7',
      fontSize: '.75rem',
      lineHeight: '1rem'
    },
    clarice: {
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex'
    },
    clariceTitle: {
      fontWeight: '700',
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      marginBottom: '10px'
    },
    clariceDescription: {
      fontSize: '1.05rem',
      lineHeight: '1.25rem',
      color: theme.palette.mode === 'light' ? 'rgb(100 116 139)' : '#ffffff'
    }
  };
});

export default useStyles;
