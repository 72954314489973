export const WORD_LIMIT = {
  FREE: {
    STRING: '1000',
    NUMBER: 1000
  },
  PAID: {
    TIPS: {
      MULTI: '6x',
      QUANTITY: '120'
    },
    MORE: '5x',
    STRING: '5 mil',
    NUMBER: 5000
  }
};
