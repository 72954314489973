import React, { useEffect, useState } from 'react';
import { GoogleButtonInterface } from './interface';
import useStyles from './style';
import { useMediaQuery, Button, CircularProgress, Box } from '@mui/material';
import GoogleLogin from 'react-google-login';
import GoogleIcon from '../../../../assets/images/auth/google-icon.svg';
import { useNavigate } from 'react-router-dom';
import { googleAuth } from '../../../../stories/actions/auth';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import { getBusinessModel } from '../../../../utils/function/getBusinessModel';

const GoogleButton = ({ children }: GoogleButtonInterface) => {
  const classes: any = useStyles();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const clientId =
    '389449520627-60p78dhde3e6dd54bu0na38i9n1u36f5.apps.googleusercontent.com';

  const dispatch = useAppDispatch();

  const { auth } = useReduxState();

  const onGoogleResponse = (res: any) => {
    setIsLoading(true);

    const cb = {
      success: (user?: any, firstAccess = false) => {
        const paymentCheckout = localStorage.getItem('PAYMENT_CHECKOUT');

        if (
          paymentCheckout &&
          paymentCheckout !== '' &&
          paymentCheckout !== 'inactive'
        ) {
          localStorage.getItem('');

          navigate(paymentCheckout);
        } else {
          const businessModel = getBusinessModel();

          if (!user?.isSubscriber && businessModel !== 'freemium') {
            const freeTrial = user?.detail?.options?.freeTrial;

            if (freeTrial) {
              return navigate('/plans');
            }
          }

          if (firstAccess) {
            navigate(`/home?product_tour_id=479884`);

            // navigate(`/home`);

            if (user) {
              // @ts-ignore
              window.intercomSettings = {
                api_base: 'https://api-iam.intercom.io',
                app_id: 'awj7l5s9',
                name: user.name, // Nome completo
                email: user.email // Endereço de e-mail
              };
            }

            // @ts-ignore
            window.Intercom('boot', {
              app_id: 'awj7l5s9'
            });
          } else {
            navigate(`/home`);
          }
        }
      }
    };

    // console.log(res);

    dispatch(googleAuth({ accessToken: res.accessToken }, cb));

    // navigate(`/premium`);

    setIsLoading(false);
  };

  const redirectUri = window.location.origin + window.location.pathname;
  return (
    <GoogleLogin
      clientId={clientId}
      buttonText="Login"
      // uxMode="redirect"
      redirectUri={redirectUri}
      // isSignedIn
      render={renderProps => (
        <Button
          onClick={renderProps.onClick}
          disabled={(renderProps.disabled && !isMobile) || isLoading}
          fullWidth
          variant={'contained'}
          className={classes.root}
          disableElevation
        >
          <img src={GoogleIcon} alt="Google" className={classes.icon} />
          {isLoading ? (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <span style={{ paddingRight: '20px' }}>Carregando...</span>{' '}
              <CircularProgress
                size={15}
                style={{
                  marginLeft: '10px'
                }}
              />
            </Box>
          ) : (
            children
          )}
        </Button>
      )}
      onSuccess={onGoogleResponse}
      // onFailure={onGoogleResponse}
      cookiePolicy={'single_host_origin'}
      autoLoad={false}
    />
  );
};

export default GoogleButton;
