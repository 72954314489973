import React, { useContext } from 'react';
import { MessageContainerInterface } from './interface';
import useStyles from './style';
import { Grid, Typography, LinearProgress } from '@mui/material';
import clsx from 'clsx';
import ChatActions from '../ChatActions';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';

const MessageContainer = ({
  talk,
  side = 'left',
  GridContainerProps,
  onSelectAction,
  chatbot
}: MessageContainerInterface) => {
  const classes: any = useStyles();
  const themeMode = useContext(ThemeContext);

  const { _id, messages } = talk;

  const attachClass = (index: any) => {
    if (index === 0) {
      return classes[`${side}First`];
    }
    if (index === messages.length - 1) {
      return classes[`${side}Last`];
    }
    return '';
  };

  return (
    <Grid
      className={classes.root}
      container
      spacing={2}
      justify={side === 'right' ? 'flex-end' : 'flex-start'}
      {...GridContainerProps}
    >
      {/* {side === "left" && (
        <Grid item {...GridItemProps}>
          <Avatar
            src={talk.avatar}
            {...AvatarProps}
                  className={cx(classes.avatar)} 
          />
        </Grid>
      ) }*/}
      <Grid item xs={12}>
        {messages.map((msg: any, i: number) => {
          return (
            <div key={msg.id + i} className={classes[`${side}Row`]}>
              <div
                id={'copy-talk' + _id}
                className={clsx(
                  classes.msgContainer,
                  classes[side],
                  attachClass(i),
                  {
                    [classes.msgContainerDark]: themeMode.mode === 'dark',
                    [classes.msgContainerChatBot]: chatbot
                  }
                )}
              >
                {talk.refresh && talk.loading && (
                  <LinearProgress
                    color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  />
                )}

                {!talk.refresh &&
                  msg.split('\n').map((el: any, index: number) => {
                    return (
                      <Typography
                        key={index}
                        align={'left'}
                        sx={{
                          fontFamily: 'Inter'
                        }}
                        className={clsx(
                          classes.msg,
                          classes[side],
                          attachClass(i),
                          {
                            [classes.hide]: talk.status && talk.status.hide
                          }
                        )}
                      >
                        {el}
                      </Typography>
                    );
                  })}

                {side === 'left' && (
                  <ChatActions talk={talk} onSelectAction={onSelectAction} />
                )}
              </div>
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default MessageContainer;
