import instance from '../../utils/axios/instance';
import { ACTIVE_COUPON } from '../../utils/coupon';

const PaymentApi = {
  getPlans: async (type?: string) => {
    const { data } = await instance().get(
      `${
        type && type === 'premium'
          ? '/payments/plans/'
          : '/payments/plansClariceWriter/'
      }${ACTIVE_COUPON === 'NO_DISCOUNT' ? 'all' : ACTIVE_COUPON}`
    );

    return data;
  }
};

export default PaymentApi;
