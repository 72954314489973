import React from 'react';
import useStyles from './styles';
import { PropsInterface } from './interface';
import SecurityIcon from '../../../../../../../../../assets/images/payment/security.svg';
import PrivacyIcon from '../../../../../../../../../assets/images/payment/privacy.svg';
import { Box, Typography, useMediaQuery } from '@mui/material';

const SecurityAndPrivacyItem = ({ title, subtitle, type }: PropsInterface) => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const src = type => {
    if (type === 'security') {
      return SecurityIcon;
    } else if (type === 'privacy') {
      return PrivacyIcon;
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      style={{ paddingRight: '10px', marginBottom: '20px' }}
    >
      <Box alignSelf="center">
        <div
          style={{
            marginTop: '10px',
            marginRight: isMobile ? '10px' : '20px',
            width: '40px',
            height: '40px',
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '8px'
          }}
        >
          <img src={src(type)} alt={type} />
        </div>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: isMobile ? '13px' : '16px',
            fontWeight: '600',
            fontFamily: 'Inter',
            color: '#FFFFFF',
            marginTop: '8px'
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontSize: isMobile ? '13px' : '16px',
            fontWeight: '400',
            fontFamily: 'Inter',
            color: '#FFFFFF'
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default SecurityAndPrivacyItem;
