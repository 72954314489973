import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    height: '100vh',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '2rem',
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#252525'
  }
}));

export default useStyles;
