import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    width: '100%'
  },
  title: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: '700'
  },
  options: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '2rem'
  },
  templates: {
    display: 'flex',
    justifyContent: 'center',
    padding: '20px',
    marginTop: '20px!important'
  },
  chromeExtentionTitle: {
    fontFamily: 'Inter',
    fontSize: '31px',
    fontWeight: '400',
    lineHeight: '38px',
    color: theme.palette.mode === 'light' ? '#000000' : '#fff',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    marginBottom: '0.5rem',
    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
      width: '100%',
      fontSize: '28px'
    }
  },
  chromeExtentionText: {
    width: '30rem',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '300',
    marginBlockStart: '0',
    marginBlockEnd: '0',
    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
      width: '100%'
    }
  }
}));

export default Styles;
