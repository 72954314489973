import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    // background:
    //   theme.palette.mode === 'light'
    //     ? 'linear-gradient(150.66deg, #D19DFB -16.97%, #7C24FB 51.52%)'
    //     : 'linear-gradient(150.66deg, #08f5b6 -16.97%, #09B386 51.52%)',
    background: 'linear-gradient(150.66deg, #D19DFB -16.97%, #7C24FB 51.52%)',
    mixBlendMode: 'normal',
    width: '100%',
    height: '100%',
    // padding: '114px',
    display: 'flex',
    paddingLeft: '30px',
    paddingRight: '30px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '60px',
      paddingBottom: '60px'
    }
  },
  dots: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '& img': {
      width: '100%'
    }
  },
  hr: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    height: '1.25px',
    border: 'none',
    marginTop: '76px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '26px'
    }
  }
}));

export default Styles;
