/* eslint-disable no-undef */
import { GET_PLANS } from '../actions/actionTypes';

const initialState: reducers.PaymentReducer = {
  billingInfo: {
    address: {
      country: '',
      postalCode: '',
      street: '',
      number: '',
      additionalInformation: '',
      district: '',
      city: {
        code: '',
        name: ''
      },
      state: ''
    },
    card_token: '',
    price_id: '',
    borrower: { name: '', cpf: '' },
    coupon_id: 'PLANOANUAL'
  },
  coupon: {
    id: 'PLANOANUAL',
    percent_off: 25,
    currency: 'brl',
    metadata: {
      recurring_interval: 'year'
    },
    default: true,
    amount_off: undefined,
    valid: true
  },
  step: 0,
  plans: [],
  // plans: {
  //   year: {
  //     id: '',
  //     object: '',
  //     active: false,
  //     aggregate_usage: undefined,
  //     amount: 0,
  //     amount_decimal: '',
  //     billing_scheme: '',
  //     created: 0,
  //     currency: '',
  //     interval: '',
  //     interval_count: 0,
  //     livemode: false,
  //     metadata: {},
  //     nickname: '',
  //     product: '',
  //     tiers_mode: undefined,
  //     transform_usage: undefined,
  //     trial_period_days: undefined,
  //     usage_type: '',
  //     amount_off: false
  //   },
  //   month: {
  //     id: '',
  //     object: '',
  //     active: false,
  //     aggregate_usage: undefined,
  //     amount: 0,
  //     amount_decimal: '',
  //     billing_scheme: '',
  //     created: 0,
  //     currency: '',
  //     interval: '',
  //     interval_count: 0,
  //     livemode: false,
  //     metadata: {},
  //     nickname: '',
  //     product: '',
  //     tiers_mode: undefined,
  //     transform_usage: undefined,
  //     trial_period_days: undefined,
  //     usage_type: ''
  //   }
  // },
  trialDays: 0
};

const paymentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PLANS: {
      return {
        ...state,
        plans: action.payload.plans,
        trialDays: action.payload.trialDays
      };
    }
    default: {
      return state;
    }
  }
};

export default paymentReducer;
