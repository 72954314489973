import React from 'react';
import useStyles from './style';
import clsx from 'clsx';
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import TooltipContainer from '../../../../../../../components/TooltipContainer';

type ScoreSubitemProps = {
  description?: string;
  loading?: any;
  total?: any;
  info?: any;
  space?: any;
  time?: any;
  type?: any;
  mt?: any;
};

const ScoreSubitem = ({
  description,
  loading,
  total,
  info,
  space,
  time,
  type,
  mt = 0
}: ScoreSubitemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      display={'flex'}
      flexDirection={isMobile && time ? 'column' : 'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      className={clsx(classes.container, { [classes.space]: space })}
      mt={mt}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Typography
          sx={{
            textAlign: 'left',
            color: theme.palette.text.primary,
            fontSize: isMobile ? '14px' : '15px',
            fontWeight: '400',
            lineHeight: '22px'
          }}
        >
          {description}
        </Typography>
        {info && (
          <TooltipContainer title={info || ''} placement="bottom">
            <InfoOutlined className={classes.infoIcon} />
          </TooltipContainer>
        )}
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {loading && !type && <Skeleton variant="text" width={50} height={22} />}

        {loading && type && type === 'legibility' && (
          <Skeleton variant="text" width={100} height={22} />
        )}

        {!loading && (
          <Typography
            className={classes.total}
            sx={{
              fontSize: isMobile ? '14px' : '16px'
            }}
          >
            {total}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ScoreSubitem;
