import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';

const Info = ({}: ActionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: isMobile ? '12px' : '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '140%',
          color:
            themeMode.mode === 'dark' ? theme.palette.text.primary : '#39274B'
        }}
      >
        Agora a Clarice oferece sugestões de IA que eliminam o bloqueio
        criativo, corrige automaticamente desvios, encontra sinônimos e faz
        ajustes precisos. <strong>Conheça as funcionalidades:</strong>
      </Typography>

      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: isMobile ? '12px' : '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '140%',
          color:
            themeMode.mode === 'dark' ? theme.palette.text.primary : '#39274B'
        }}
      >
        <ul
          style={{
            marginLeft: isMobile ? '23px' : '26px',
            marginTop: '20px',
            lineHeight: '140%'
          }}
        >
          <li>
            <strong>Selecione 1 palavra</strong> para buscar sinônimos;
          </li>
          <li>
            <strong>Selecione mais de uma palavra</strong> para abrir uma lista
            com diversas possibilidades de melhoria da sua seleção;
          </li>
          <li>
            <strong>Digite “/”</strong> em qualquer lugar para descobrir como a
            Clarice pode te ajudar com ideias criativas;
          </li>
          <li>
            <strong>
              Alguns desvios agora contam com a opção de gerar sugestões de
              correção com IA
            </strong>
            , experimente aplicar essas sugestões em seu texto!
          </li>
        </ul>
      </Typography>
    </Box>
  );
};

export default Info;
