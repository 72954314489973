import React, { useState } from 'react';
import { ForgotPasswordFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import Input from '../../../../components/Input';
import ChannelsContainer from '../../../../components/ChannelsContainer';
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPasswordForm = ({
  email,
  setEmail,
  captcha,
  setCaptcha,
  loading,
  onSubmit
}: ForgotPasswordFormInterface) => {
  const classes: any = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <HeadForm
          title="Primeiro acesso?"
          textButton="Crie uma conta"
          routeButton="/sign-up"
        />

        <TitleForm
          title="Recupere sua senha"
          description="Insira seu e-mail para receber instruções sobre como trocar sua senha por uma nova."
        />

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="email"
            label="E-mail"
            placeholder="Insira seu e-mail"
            type="text"
            value={email}
            setValue={setEmail}
          />

          <ReCAPTCHA
            sitekey="6Lck3ToaAAAAAA-EZIPMqAA3nQ-PtyKhoa0K3oU5"
            onChange={() => setCaptcha(true)}
            onErrored={() => setCaptcha(false)}
            onExpired={() => setCaptcha(false)}
            className={classes.captcha}
          />

          <ButtonSubmit disabled={loading || !captcha} loading={loading}>
            Enviar e-mail de recuperação
          </ButtonSubmit>

          <ChannelsContainer />
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
