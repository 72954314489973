import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    position: 'absolute',
    transition: 'transform ease-in 0.5s',
    zIndex: 1,
    right: -80,
    bottom: '25vh',
    transform: 'rotate(0deg)',
    transformOrigin: 'center',
    '&:hover,:target': {
      transform: 'rotate(90deg)'
    },
    [theme.breakpoints.down('sm')]: {
      bottom: -120,
      right: 'auto'
    }
  }
}));

export default Styles;
