import React, { useContext, useEffect, useRef, useState } from 'react';
import useStyles from './style';
import Chip from '@mui/material/Chip';
import { Box, Grid, useMediaQuery } from '@mui/material';
import TemplateCard from './components/TemplateCard';
import { useParams, useNavigate } from 'react-router-dom';
import TemplateContainer from './components/TemplateContainer';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import { STATE_TEMPLATE } from './const/STATE_TEMPLATE';
import { getCredits } from '../../../../stories/actions/credits';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { toggleFavoriteTemplate } from '../../../../stories/actions/templates';
import { useReduxState } from '../../../../hooks/useReduxState';
import { GlobalContext } from '../../../../utils/context/GlobalContext';

type Props = {};

const TemplatesPage = (props: Props) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);

  const params = useParams();

  const navigate = useNavigate();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const options = [
    'Todos',
    'Ads',
    'Blog',
    'Ecommerce',
    'E-mail',
    'Frameworks',
    'Google',
    'Marketing',
    'SEO',
    'Social Media',
    'Videos',
    'Website',
    'Ferramentas',
    'Outros'
  ];

  const { user } = useReduxState();

  const ctx = useContext(GlobalContext);

  const [option, setOption] = useState<any>('Todos');

  const [template, setTemplate] = useState<any>(undefined);

  const [favorites, setFavorites] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const cardsRef: any = useRef(null);

  const onToggleFavorite = (e, id: any) => {
    e.stopPropagation();

    if (!loading) {
      const cb = {
        success: () => {
          // DESMARCAR
          if (favorites.includes(id)) {
            setFavorites(favorites.filter(el => el !== id));
          } else {
            setFavorites([...favorites, id]);

            setTimeout(() => {
              const element: any = document.getElementById(id);

              if (element) {
                const scrollY = window.scrollY;

                if (scrollY) {
                  if (window.scrollY > element.offsetTop) {
                    window.scrollTo({
                      top: element.offsetTop - 77,
                      behavior: 'smooth'
                    });
                  }
                }
              }
            }, 500);
          }

          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(toggleFavoriteTemplate(id, cb));
    }
  };

  const templates = STATE_TEMPLATE(themeMode).templates;

  const onSelectOption = (el: any) => {
    setOption(el);
  };

  const onSelectTemplate = (template: any) => {
    if (template.id === 'image-prompt') {
      return null;
    }

    if (ctx.beta) {
      if (!template.notReady) {
        setTemplate(template);

        navigate(`/templates/${template.redirect}`);
      }
    } else {
      setTemplate(template);

      navigate(`/templates/${template.redirect}`);
    }
  };

  useEffect(() => {
    const template = templates.find(
      template => template.redirect === params.type
    );

    if (template) {
      setTemplate(template);
    }
  }, [params]);

  useEffect(() => {
    // console.log(user?.options?.templates?.favorites);

    if (favorites && favorites.length === 0) {
      if (user?.options?.templates?.favorites) {
        setFavorites(user?.options?.templates?.favorites || []);
      }
    }
  }, [user?.options?.templates?.favorites]);

  const colorButtonFilter = () => {
    if (themeMode.mode === 'light') return 'primary';
    return 'secondary';
  };

  return (
    <div className={classes.root}>
      {params.type && params.type !== '' && template && (
        <>
          <TemplateContainer
            id={template.id}
            title={template.title}
            description={template.description}
            type={template.type}
            redirect={template.redirect}
            fields={template.fields || {}}
          />
        </>
      )}

      {!params.type && (
        <>
          <p className={classes.title}>Templates</p>

          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className={classes.options}>
                {options.map(el => (
                  <Chip
                    key={el}
                    style={{
                      marginLeft: '10px',
                      marginBottom: '15px',
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                    color={el === option ? `${colorButtonFilter()}` : 'default'}
                    label={el}
                    variant={el === option ? 'filled' : 'outlined'}
                    onClick={() => onSelectOption(el)}
                  />
                ))}
              </div>

              <Box className={classes.templates}>
                <Grid
                  container
                  spacing={isMobile ? 0 : 3}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={10}
                  ref={cardsRef}
                >
                  {templates
                    .filter(template => {
                      if (option === 'Todos') {
                        return template;
                      }

                      // @ts-ignore
                      return template.type && template.type.includes(option);
                    })
                    .map(template => ({
                      ...template,
                      favorite: favorites.includes(template.id)
                    }))
                    .sort((a, b) => {
                      if (a.favorite && !b.favorite) {
                        return -1; // a comes first
                      }
                      if (!a.favorite && b.favorite) {
                        return 1; // b comes first
                      }
                      return 0; // no sorting necessary
                    })
                    .map((template, index) => (
                      <TemplateCard
                        key={template.id + index}
                        id={template.id}
                        title={template.title}
                        description={template.description}
                        type={template.type}
                        onSelect={() => onSelectTemplate(template)}
                        imageUrl={template.imageUrl}
                        notReady={template.notReady}
                        favorite={template.favorite || false}
                        onToggleFavorite={onToggleFavorite}
                      />
                    ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default TemplatesPage;
