import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  wrongContent: {
    font: 'normal normal 400 13.5px/15px Inter!important',
    textDecoration: 'line-through!important',
    textDecorationColor: 'red!important'
  },
  wrongContainer: {
    display: 'flex!important',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '10px'
  },
  btn: {
    textTransform: 'unset',
    font: 'normal normal 500 13.5px/15px Inter',
    letterSpacing: '0.12px',
    padding: '5px 12px',
    margin: '0 3px !important',
    borderRadius: '7px'
  },
  replaceWordContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5px',
    marginBottom: '7px'
  },
  replaceWordButton: {
    color: `${theme.palette.success.main}!important`,
    borderColor: `${theme.palette.success.main}!important`,
    padding: '2px',
    background: '#0FEDB208 0% 0% no-repeat padding-box',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  removeWordContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5px'
  },
  removeWordButton: {
    color: `${theme.palette.error.main}!important`,
    borderColor: `${theme.palette.error.main}!important`,
    background: '#fff5f773 0% 0% no-repeat padding-box',
    textDecorationLine: 'line-through!important',
    padding: '2px',
    '&:hover': {
      backgroundColor: 'white',
      textDecorationLine: 'line-through'
    }
  },
  arrow: {
    marginLeft: '8px',
    marginRight: '8px'
  },
  containerReplacement: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '5px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    borderRadius: '4px',
    backgroundColor:
      theme.palette.mode === 'light' ? '#FFF' : '#3a3541!important'
  },
  incorrectSentenceContainer: {
    width: '100%',
    paddingLeft: '1.0rem',
    paddingRight: '1.0rem',
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    textAlign: 'left',
    color: theme.palette.mode === 'light' ? '#ff0000ad!important' : 'white',
    backgroundColor: theme.palette.mode === 'light' ? '#fce4e4' : '#9b1b1b8a',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '5px',
    // fontWeight: '500',

    display: 'flex',
    padding: '6px',
    // alignItems: 'center!important',
    justifyContent: 'start',
    gap: '12px',
    marginTop: '10px'
  },
  correctSentenceContainer: {
    width: '100%',
    paddingLeft: '1.0rem',
    paddingRight: '1.0rem',
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    color: '#09B286!important',
    textAlign: 'left',
    backgroundColor: '#09b28629',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '5px',
    // fontWeight: '500',
    '& > p': {
      marginTop: '0px',
      marginBottom: '0px',
      marginBlockStart: '0em!important',
      marginBlockEnd: '0em!important'
    },
    display: 'flex',
    padding: '6px',
    // alignItems: 'center!important',
    justifyContent: 'start',
    gap: '12px',
    marginTop: '15px',
    cursor: 'pointer'
  },
  incorrectIcon: {
    // marginTop: '2.5px',
    color: theme.palette.mode === 'light' ? '#ff0000ad!important' : 'white'
  },
  correctIcon: {
    // marginTop: '2.5px',
    color: '#09B286!important'
  },
  descriptionResult: {
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    color: theme.palette.mode === 'light' ? '#09B286' : '#E7EBF0',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    // fontWeight: '500',
    '& > p': {
      marginTop: '0px',
      marginBottom: '0px',
      marginBlockStart: '0em',
      marginBlockEnd: '0em'
    }
  }
}));

export default Styles;
