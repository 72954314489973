import React, { useContext } from 'react';
import { LogoInterface } from './interface';
import { Box, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';

const Logo = ({}: LogoInterface) => {
  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx = useContext(GlobalContext);

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'dark'
  }/`;

  return (
    <div>
      <img
        src={baseURL + 'logo.svg'}
        alt="Google Docs Clarice"
        style={{ width: '3.4rem', marginRight: '0.8rem' }}
      ></img>
    </div>
  );
};

export default Logo;
