import React, { useContext } from 'react';
import useStyle from './style';
import { ThemeContext } from '../../../../../../../../../../utils/context/ThemeContext';
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const AutoCorrectionContainer = ({
  id,
  content,
  range,
  onGenerate,
  loading,
  outputs,
  newGenerate,
  onReplaceAutoCorrection,
  onSendFeedback,
  onSendFeedbackCorrections,
  onReset,
  correction,
  editor
}: any) => {
  const themeMode = useContext(ThemeContext);

  // const theme = useTheme();

  const classes = useStyle();

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  const descriptionLoading = {
    FRASE_LONGA: 'A Clarice está melhorando a sua frase...'
  };

  if (outputs.length === 0 && !loading && !newGenerate) {
    return null;
  }

  // console.log('AutoCorrectionContainer -> outputs', outputs);

  return (
    <div className={classes.container}>
      <div className={classes.incorrectSentenceContainer}>
        <CloseIcon
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
          className={classes.incorrectIcon}
          fontSize="small"
        />

        <div>{content}</div>
      </div>

      {loading && (
        <div className={classes.list} key="ia-writing">
          <img className={classes.icon} src={baseURL + 'logo.svg'} alt="" />

          <div className={classes.descriptionLoading}>
            {newGenerate
              ? 'Gerando novas sugestões...'
              : (id && descriptionLoading[id]) ||
                'A Clarice está escrevendo...'}
          </div>
        </div>
      )}

      {!loading &&
        outputs.length > 0 &&
        outputs.map((output: any) => (
          <div className={classes.output} key={output._id}>
            <div
              className={classes.resultsContainerWait}
              onClick={() => {
                if (loading) {
                  return;
                }

                onSendFeedbackCorrections({
                  incorrect: content,
                  suggestions: [
                    {
                      description: output.message
                    }
                  ],
                  type: correction.id,
                  textId: editor?.text?.textId
                });

                onReplaceAutoCorrection(content, range, output.message);

                onSendFeedback();

                onReset();
              }}
            >
              {/*<img*/}
              {/*  className={classes.icon}*/}
              {/*  style={{ marginLeft: '10px', marginTop: '15px' }}*/}
              {/*  src={baseURL + 'logo.svg'}*/}
              {/*  alt=""*/}
              {/*/>*/}
              <div className={classes.correctSentenceContainer}>
                <DoneAllIcon
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  className={classes.correctIcon}
                  fontSize="small"
                />

                {output.message.split('\n').map((el: any, index: number) => {
                  return (
                    <p key={index} className={classes.descriptionResult}>
                      {el}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default AutoCorrectionContainer;
