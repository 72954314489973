import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  title: {
    font: 'normal normal 400 18px Inter!important',
    // color: theme.palette.mode === 'light' ? '#FFFFFF' : '#000',
    color: '#FFFFFF',
    lineWeight: '125%',
    width: 'calc(100%)',
    textAlign: 'left',
    marginBottom: '24px!important',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginLeft: '0'
    },
    '& > strong': {
      fontWeight: 500
    }
  },
  warrantyContainerOld: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flexStart',
    padding: '5px 8px',
    width: '115px',
    height: '22px',
    borderRadius: '6px',
    textAlign: 'center',
    marginBottom: '18px!important',
    background: 'rgba(196, 148, 255, 0.5)!important'
    // background:
    //   theme.palette.mode === 'light'
    //     ? 'rgba(196, 148, 255, 0.5)!important'
    //     : '#3ac29e!important'
  },
  warrantyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px 8px',
    width: '145px',
    height: '26px',
    borderRadius: '6px',
    textAlign: 'center',
    marginBottom: '18px!important',
    background: 'rgba(196, 148, 255, 0.5)!important'

    // background:
    //   theme.palette.mode === 'light'
    //     ? 'rgba(196, 148, 255, 0.5)!important'
    //     : '#3ac29f!important'
  },
  warrantyOld: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    width: '100%',
    fontSize: '11px',
    lineHeight: '100%',
    textTransform: 'unset',
    color: '#ffffff',
    textAlign: 'center',
    letterSpacing: '-0.01em'
  },
  warranty: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: '600!important',
    width: '100%',
    fontSize: '9px!important',
    lineHeight: '100%',
    textTransform: 'uppercase',
    // color: theme.palette.mode === 'light' ? '#FFFFFF' : '#000',
    color: '#FFFFFF',
    textAlign: 'center',
    letterSpacing: '0.01em'
  },
  icon: {
    width: '25px',
    marginRight: '-5px',
    [theme.breakpoints.down(630)]: {
      marginRight: '3px'
    },
    transition: 'transform ease-in 0.5s',
    transform: 'rotate(0deg)',
    transformOrigin: 'center',
    '&:hover,:target': {
      transform: 'rotate(90deg)'
    }
  }
}));

export default Styles;
