import React from 'react';
import useStyles from './styles';
import PlanContainer from './component/PlanContainer';
import CreditCardInfo from './component/CreditCardInfo';
import Dots from '../../../../../../../assets/images/dots.png';
import { Box } from '@mui/material';

const PlanSelectPayment = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.dots}>
        <Box width={'100%'} height={'100%'} overflow={'hidden'} zIndex={'-1'}>
          <img src={Dots} alt="" />
          <img src={Dots} alt="" />
        </Box>
      </div>
      <Box maxWidth={456} pt={4} width={'100%'} zIndex={'1'}>
        <PlanContainer />
        <hr className={classes.hr} />
        <CreditCardInfo />
      </Box>
    </div>
  );
};

export default PlanSelectPayment;
