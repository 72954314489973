import React, { useContext } from 'react';
import { LinearProgressWithLabelInterface } from './interface';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';

const LinearProgressWithLabel = ({
  value
}: LinearProgressWithLabelInterface) => {
  const themeMode = useContext(ThemeContext);
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={value}
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color={themeMode.mode === 'light' ? 'primary' : 'text.white'}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
