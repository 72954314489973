import React from 'react';
import { HeadAuthInterface } from './interface';
import { Helmet } from 'react-helmet';

const HeadAuth = ({ title, description }: HeadAuthInterface) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
        />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <title>{title}</title>
        <link rel="stylesheet" href="https://use.typekit.net/pkn3acn.css" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <meta name="title" content={title} />
        <meta name="description" content={description} />
      </Helmet>
    </div>
  );
};

export default HeadAuth;
