/* eslint-disable no-undef */
import { CREDITS_DETAIL } from '../actions/actionTypes';

const initialState: reducers.CreditsReducer = {
  detail: null,
  bonus: {
    tokens: {
      total: 0,
      used: 0
    },
    words: {
      total: 0,
      used: 0
    }
  },
  plan: {
    tokens: {
      total: 0,
      used: 0
    },
    words: {
      total: 0,
      used: 0
    }
  }
};

const creditsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREDITS_DETAIL:
      state = {
        ...state,
        // detail: action.payload
        detail: {
          ...state.detail,
          active: true,
          daysRemaining: 30
        },
        bonus: action.payload.bonus,
        plan: action.payload.plan
      };
      break;

    default:
      return state;
  }

  return state;
};

export default creditsReducer;
