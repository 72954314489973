/* eslint-disable no-undef */
import {
  DESCREASE_LOADING,
  FINISH_PROGRESS,
  INCREASE_LOADING,
  SET_PROGRESS
} from '../actions/actionTypes';
import { LoadingReducer } from '../../models/reducers';

const initialState: reducers.LoadingReducer = {
  amount: 0,
  progress: 0
};

const loadingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case INCREASE_LOADING:
      state = {
        ...state,
        amount: state.amount + 1
      };
      break;
    case DESCREASE_LOADING:
      state = {
        ...state,
        amount: state.amount - 1
      };
      break;
    case SET_PROGRESS:
      state = {
        ...state,
        amount: state.amount,
        progress: action.payload
      };
      break;
    case FINISH_PROGRESS:
      state = {
        ...state,
        amount: state.amount,
        progress: 0
      };
      break;
    default:
      return state;
  }

  return state;
};

export const isLoading = (state: LoadingReducer) => state.amount > 0;

export default loadingReducer;
