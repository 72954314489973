import withAuth from '../../utils/axios/withAuth';
import instance from '../../utils/axios/instance';

const ProjectsApi = {
  createProject: async (title: string, description: string) => {
    const { data } = await withAuth().post(`/projects`, {
      title,
      description
    });

    return data;
  },
  update: async (form: any) => {
    const { data } = await withAuth().put(`/projects/${form._id}`, {
      ...form
    });

    return data;
  },
  getProjects: async () => {
    const { data } = await withAuth().get(`/projects`);

    return data;
  },
  getTemplateHistoric: async (typeTemplate: string, idUser: string) => {
    const { data } = await withAuth().get(
      `/templates/typeTemplate=${typeTemplate}&idUser=${idUser}`
    );

    return data;
  },
  delete: async (id: string) => {
    const { data } = await instance().put(`/projects/status/${id}`);

    return data;
  }
};

export default ProjectsApi;
