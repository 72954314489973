import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '2rem',
      textTransform: 'none',
      padding: '12px',
      // backgroundColor:
      //   theme.palette.mode === 'light' ? '#f3f1f1' : 'rgba(195,185,222,0.2)',
      // backgroundColor: '#f3f1f1',
      // borderRadius: '4px',
      // boxShadow:
      //   'inset 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)!important',
      textAlign: 'center',
      // maxWidth: '200px',
      // marginTop: '20px',
      // paddingBottom: '0px!important',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '1rem'
      }
    },
    description: {
      width: '100%',
      textAlign: 'center',
      font: 'normal normal 400 13.5px/15px Inter',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 8,
      paddingBottom: 8,
      color:
        theme.palette.mode === 'light' ? 'rgb(100 116 139)' : 'rgb(171,171,171)'
    }
  };
});

export default useStyles;
