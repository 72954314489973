/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';
import OutputIcon from '@mui/icons-material/Output';
import LoadingButton from '@mui/lab/LoadingButton';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { TemplateContainerInterface } from './interface';
import {
  completionByTemplate,
  completionClear,
  getAllCompletions,
  completionUpdateStatus,
  openInDocument
} from '../../../../../../stories/actions/templates';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import useStyles from './style';
import toast, { Toaster } from 'react-hot-toast';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import Tooltip from '@mui/material/Tooltip';
import TemplateActions from '../TemplateActions';
import notify from '../../../../../../utils/notify';
import { getProjects } from '../../../../../../stories/actions/projects';
import { getCredits } from '../../../../../../stories/actions/credits';
import clsx from 'clsx';
import { EditorState, ContentState } from 'draft-js';
import { serialiseEditorStateToRaw } from 'draftail';
import { useNavigate } from 'react-router-dom';
import HistoricNotFound from '../HistoricNotFound';
import { ReactComponent as TextingSVG } from '../../../../../../assets/images/template/light/texting.svg';
import { ReactComponent as MagicSVGLight } from '../../../../../../assets/images/template/light/magic.svg';
import { ReactComponent as MagicSVGDark } from '../../../../../../assets/images/template/dark/magic.svg';
import IMGIa from '../../../../../../assets/images/ilustra-ia.jpg';
import IMGTemplate from '../../../../../../assets/images/ilustra-template.jpg';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import BrandTag from '../BrandTag';
import { isBetaUser } from '../../../../../../utils/function/isBetaUser';

const TemplateContainer = ({
  id,
  title,
  description,
  type,
  fields
}: TemplateContainerInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const ctx = useContext(GlobalContext);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { templates: templatesRedux, user, brands } = useReduxState();

  const [loading, setLoading] = useState(false);

  const [outputs, setOutputs] = useState([]);

  const [activeHistoric, setActiveHistoric] = useState<any>(false);

  const [templates, setTemplates] = useState<any>({
    response: null,
    completions: []
  });

  const [loadGetAll, setLoadGetAll] = React.useState(false);

  const [brand, setBrand] = useState<any>();

  const [form, setForm] = useState<any>({
    type: type,
    template: id,
    title: '',
    content: '',
    audience: '',
    location: '',
    cta: '',
    theme: '',
    tone: '',
    // title: 'Tendências emergentes de marketing digital',
    // content: '',
    // audience: 'Profissionais de marketing',
    // tone: 'Casual e Criativo',
    name: '',
    description: '',
    keywords: '',
    count: fields?.count?.options ? fields.count.options[0].value : 1,
    pointsOfView: 'firstPerson',
    language: {
      input: 'portuguese',
      output: 'portuguese',
      formality: 'default'
    },
    quantity: 2
  });

  useEffect(() => {
    if (!loadGetAll) {
      clearAnswers();

      setLoadGetAll(true);

      // const cb = {
      //   success: () => {
      //     setLoadGetAll(true);
      //   }
      // };
    }

    setTemplates({
      ...templates,
      response: templatesRedux.response,
      completions: templatesRedux.completions
    });
  }, [templatesRedux]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (type === 'quantity') {
      const value: any = e.target.value;

      let quantity = value;

      if (value && value > 10) {
        quantity = 10;
      }

      if (value && value < 1) {
        quantity = 1;
      }

      return setForm({
        ...form,
        [type]: quantity
      });
    }

    setForm({ ...form, [type]: e.target.value });
  };

  const handleSelect = (event: SelectChangeEvent, type: string) => {
    if (type === 'pointsOfView') {
      setForm({
        ...form,
        pointsOfView: event.target.value
      });
    } else if (type === 'count') {
      setForm({
        ...form,
        count: event.target.value
      });
    } else {
      setForm({
        ...form,
        language: { ...form.language, [type]: event.target.value }
      });
    }
  };

  const handleSelectBrand = (event: SelectChangeEvent) => {
    const id = event.target.value;

    setBrand(id);

    const brand = brands.all.find(
      (brand: any) => String(brand._id) === String(id)
    );

    if (brand) {
      setForm({
        ...form,
        name: brand.name || '',
        description: brand.description || '',
        tone: brand.tone || '',
        audience: brand.audience || ''
      });
    }
  };

  const onClearForm = () => {
    setForm({
      type: type,
      template: id,
      title: '',
      content: '',
      audience: '',
      location: '',
      cta: '',
      theme: '',
      tone: '',
      name: '',
      description: '',
      keywords: '',
      count: fields?.count?.options ? fields.count.options[0].value : 1,
      pointsOfView: 'firstPerson',
      language: {
        input: 'portuguese',
        output: 'portuguese',
        formality: 'default'
      },
      quantity: 2
    });
  };

  const handleError = () => {
    return notify.error('Preencha todos os campos!');
  };

  const handleSubmit = () => {
    setLoading(true);

    const cb = {
      success: () => {
        dispatch(getCredits());

        setLoading(false);
      },
      error: () => {
        setLoading(false);
      }
    };

    return toast.promise(dispatch(completionByTemplate(form, cb)), {
      loading: 'Processando resposta...',
      success: data => {
        setLoading(false);

        return <b>Resposta gerada com sucesso!</b>;
      },
      error: err => {
        setLoading(false);

        if (err.message) {
          if (err.message === 'Você atingiu o limite de créditos!') {
            if (!ctx.modal.conversion.open) {
              ctx.modal.conversion.toggle();
            }

            return <b>{err.message}</b>;
          }

          return <b>{err.message}</b>;
        }

        return <b>Algum erro aconteceu, tente novamente</b>;
      }
    });
  };

  const onSubmit = () => {
    setActiveHistoric(false);

    const validate: any = [];

    Object.keys(fields).map(field => {
      if (form[field] === '' && field !== 'tone') {
        const response = fields[field];

        if (response?.required) {
          console.log(fields, response);

          validate.push(field);
        }
      }

      return field;
    });

    if (validate.length > 0) {
      return handleError();
    } else {
      return handleSubmit();
    }
  };

  const clearAnswers = () => {
    setTemplates({
      response: null,
      completions: []
    });

    setActiveHistoric(false);

    dispatch(completionClear(form.template));
  };

  const getHistoric = () => {
    const response =
      templates.completions.length > 0 &&
      templates.completions.find(el => el.template === id);

    if (!response) {
      setTemplates({
        response: null,
        completions: []
      });
    }

    const cb = {
      success: () => {
        setActiveHistoric(true);
      },
      error: () => {
        setActiveHistoric(false);
      }
    };

    dispatch(getAllCompletions(form.template, cb));
  };

  const onSelectAction = async (
    _id: any,
    type: string,
    action: string,
    output: any
  ) => {
    if (action === 'favorite') {
      dispatch(completionUpdateStatus(_id, type, action));
    }

    if (action === 'like') {
      dispatch(completionUpdateStatus(_id, type, action));
    }

    if (action === 'dislike') {
      dispatch(completionUpdateStatus(_id, type, action));
    }

    if (action === 'open-document') {
      const contentState = ContentState.createFromText(
        output.message.replace(/^\n+/g, '').replace(/<\/?b>|<br\/?>/g, '')
      );
      const editorState = EditorState.createWithContent(contentState);
      const rawEditorState = serialiseEditorStateToRaw(editorState);

      const text = editorState.getCurrentContent().getPlainText();

      dispatch(openInDocument(text, rawEditorState, navigate));
    }

    if (action === 'copy') {
      const text: any = document.getElementById('copy-completion' + _id);
      const range = document.createRange();
      const textToCopy = document.createElement('div');
      const selection: any = window.getSelection();
      textToCopy.innerHTML = text.innerHTML;
      textToCopy.id = 'copy-to-clipboard';
      document.body.append(textToCopy);
      range.selectNodeContents(textToCopy);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');

      textToCopy.remove();

      await notify.success('Texto copiado com sucesso!');
    }
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const heightContainerRight = () => {
    if (isMobile) {
      return '41.5rem';
    }

    const div: any = {
      left: document.getElementById('container-left') || 0,
      leftHeader: document.getElementById('container-left-header') || 0,
      leftFooter: document.getElementById('container-left-footer') || 0,
      rightHeader: document.getElementById('container-right-header') || 0
    };

    const height: any = {
      left: div.left && div.left.offsetHeight,
      leftHeader: div.leftHeader && div.leftHeader.offsetHeight,
      leftFooter: div.leftFooter && div.leftFooter.offsetHeight,
      rightHeader: div.rightHeader && div.rightHeader.offsetHeight
    };

    // console.log(div.offsetHeight);

    return `${
      height.left +
      height.leftHeader +
      height.leftFooter -
      (height.rightHeader + 1)
    }px`;
  };

  useEffect(() => {
    if (templates.completions && templates.completions.length > 0) {
      const newOutputs: any = [];

      templates.completions.map((completion: any) => {
        return completion.output.map((output: any) => {
          newOutputs.push({ ...output, template: completion.template });
        });
      });

      setOutputs(
        newOutputs.sort(
          (a: any, b: any) =>
            // @ts-ignore
            (b.status.favorite === true) - (a.status.favorite === true)
        )
      );
    } else {
      setOutputs([]);
    }
  }, [templates]);

  return (
    <>
      {/*<Toaster*/}
      {/*  position="top-center"*/}
      {/*  reverseOrder={false}*/}
      {/*  toastOptions={*/}
      {/*    themeMode.mode === 'light'*/}
      {/*      ? {}*/}
      {/*      : {*/}
      {/*          className: '',*/}
      {/*          style: {*/}
      {/*            border: '1.5px solid #3a3541!important',*/}
      {/*            backgroundColor: 'rgba(195,185,222,0.2)',*/}
      {/*            color: '#E7EBF0'*/}
      {/*          }*/}
      {/*        }*/}
      {/*  }*/}
      {/*/>*/}
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Grid item xs={12} id="container-left-header">
              <div className={classes.titleContainer}>
                <p className={classes.title}>{title}</p>

                <p className={classes.description}>{description}</p>
              </div>
            </Grid>

            <Grid item xs={12} id="container-left">
              <div className={classes.formContainer}>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { width: '100%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  {isBetaUser(user, 'brand') && (
                    <BrandTag form={form} setForm={setForm} />
                  )}

                  {/*{user.email &&*/}
                  {/*  [*/}
                  {/*    'eurafaelcamillo@gmail.com',*/}
                  {/*    'felipeiszlaji@gmail.com',*/}
                  {/*    'gmorais1994@gmail.com',*/}
                  {/*    'henrique.ps.carvalho@gmail.com'*/}
                  {/*  ].includes(user.email) && (*/}
                  {/*    <FormControl*/}
                  {/*      sx={{*/}
                  {/*        width: '100%',*/}
                  {/*        marginTop: '5px',*/}
                  {/*        marginBottom: '40px'*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      <InputLabel*/}
                  {/*        id="demo-select-small"*/}
                  {/*        color={*/}
                  {/*          themeMode.mode === 'light' ? 'primary' : 'secondary'*/}
                  {/*        }*/}
                  {/*        sx={{*/}
                  {/*          fontFamily: ['Inter'].join(',')*/}
                  {/*        }}*/}
                  {/*      >*/}
                  {/*        Selecione uma marca*/}
                  {/*      </InputLabel>*/}
                  {/*      <Select*/}
                  {/*        color={*/}
                  {/*          themeMode.mode === 'light' ? 'primary' : 'secondary'*/}
                  {/*        }*/}
                  {/*        labelId="demo-select-small"*/}
                  {/*        id="demo-select-small"*/}
                  {/*        value={brand}*/}
                  {/*        label="Selecione uma marca"*/}
                  {/*        onChange={e => handleSelectBrand(e)}*/}
                  {/*        sx={{*/}
                  {/*          fontFamily: ['Inter'].join(',')*/}
                  {/*        }}*/}
                  {/*      >*/}
                  {/*        {brands.all.map(brand => {*/}
                  {/*          return (*/}
                  {/*            <MenuItem*/}
                  {/*              sx={{*/}
                  {/*                fontFamily: ['Inter'].join(',')*/}
                  {/*              }}*/}
                  {/*              key={brand._id}*/}
                  {/*              value={brand._id}*/}
                  {/*            >*/}
                  {/*              {brand.name}*/}
                  {/*            </MenuItem>*/}
                  {/*          );*/}
                  {/*        })}*/}
                  {/*      </Select>*/}
                  {/*    </FormControl>*/}
                  {/*  )}*/}

                  {Object.keys(fields).map((field, index) => {
                    if (fields[field] && field === 'title') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.title.label}
                          placeholder={fields.title.placeholder}
                          multiline={fields.title.rows && fields.title.rows > 1}
                          rows={fields.title.rows || 1}
                          variant="outlined"
                          value={form.title}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'title')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'name') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.name.label}
                          placeholder={fields.name.placeholder}
                          multiline={fields.name.rows && fields.name.rows > 1}
                          rows={fields.name.rows || 1}
                          variant="outlined"
                          value={form.name}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'name')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'description') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.description.label}
                          placeholder={fields.description.placeholder}
                          multiline={
                            fields.description.rows &&
                            fields.description.rows > 1
                          }
                          rows={fields.description.rows || 1}
                          variant="outlined"
                          value={form.description}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'description')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'content') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-multiline-static"
                          label={fields.content.label}
                          placeholder={fields.content.placeholder}
                          multiline={
                            fields.content.rows && fields.content.rows > 1
                          }
                          rows={fields.content.rows || 4}
                          variant="outlined"
                          value={form.content}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'content')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'audience') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.audience.label}
                          placeholder={fields.audience.placeholder}
                          multiline={
                            fields.audience.rows && fields.audience.rows > 1
                          }
                          rows={fields.audience.rows || 1}
                          variant="outlined"
                          value={form.audience}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'audience')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'location') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.location.label}
                          placeholder={fields.location.placeholder}
                          multiline={
                            fields.location.rows && fields.location.rows > 1
                          }
                          rows={fields.location.rows || 1}
                          variant="outlined"
                          value={form.location}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'location')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'cta') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.cta.label}
                          placeholder={fields.cta.placeholder}
                          multiline={fields.cta.rows && fields.cta.rows > 1}
                          rows={fields.cta.rows || 1}
                          variant="outlined"
                          value={form.cta}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'cta')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'keywords') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.keywords.label}
                          placeholder={fields.keywords.placeholder}
                          multiline={
                            fields.keywords.rows && fields.keywords.rows > 1
                          }
                          rows={fields.keywords.rows || 1}
                          variant="outlined"
                          value={form.keywords}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'keywords')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'tone') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.tone.label}
                          placeholder={fields.tone.placeholder}
                          multiline={fields.tone.rows && fields.tone.rows > 1}
                          rows={fields.tone.rows || 1}
                          variant="outlined"
                          value={form.tone}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'tone')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'theme') {
                      return (
                        <TextField
                          key={field + index}
                          className={classes.input}
                          id="outlined-basic"
                          label={fields.theme.label}
                          placeholder={fields.theme.placeholder}
                          multiline={fields.theme.rows && fields.theme.rows > 1}
                          rows={fields.theme.rows || 1}
                          variant="outlined"
                          value={form.theme}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange(e, 'theme')}
                          color={
                            themeMode.mode === 'light' ? 'primary' : 'secondary'
                          }
                        />
                      );
                    }

                    if (fields[field] && field === 'pointsOfView') {
                      return (
                        <FormControl
                          key={field + index}
                          sx={{
                            width: '100%',
                            marginTop: '5px',
                            marginBottom: '40px'
                          }}
                        >
                          <InputLabel
                            id="demo-select-small"
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            Ponto de Vista
                          </InputLabel>
                          <Select
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={form.pointsOfView}
                            label="Ponto de Vista"
                            onChange={e => handleSelect(e, 'pointsOfView')}
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="firstPerson"
                            >
                              Primeira Pessoa
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="secondPerson"
                            >
                              Terceira Pessoa
                            </MenuItem>
                          </Select>
                        </FormControl>
                      );
                    }

                    if (fields[field] && field === 'count') {
                      return (
                        <FormControl
                          key={field + index}
                          sx={{
                            width: '100%',
                            marginTop: '5px',
                            marginBottom: '40px'
                          }}
                        >
                          <InputLabel
                            id="demo-select-small"
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            {fields.count.label}
                          </InputLabel>
                          <Select
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={form.count}
                            label={fields.count.label}
                            onChange={e => handleSelect(e, 'count')}
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            {fields.count.options &&
                              fields.count.options.map(el => (
                                <MenuItem
                                  key={el.value}
                                  sx={{
                                    fontFamily: ['Inter'].join(',')
                                  }}
                                  value={el.value}
                                >
                                  {el.description}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      );
                    }

                    return null;
                  })}

                  <div className={classes.languageContainer}>
                    <p className={classes.languageTitle}>Opções de idioma</p>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <FormControl
                          sx={{ width: '100%', marginBottom: '20px' }}
                          size="small"
                        >
                          <InputLabel
                            id="demo-select-small"
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            Idioma de Entrada
                          </InputLabel>
                          <Select
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={form.language?.input}
                            label="Idioma de Entrada"
                            onChange={e => handleSelect(e, 'input')}
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="portuguese"
                            >
                              Português
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="english"
                            >
                              Inglês
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="spanish"
                            >
                              Espanhol
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <FormControl
                          sx={{ width: '100%', marginBottom: '20px' }}
                          size="small"
                        >
                          <InputLabel
                            id="demo-select-small"
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            Idioma de Saída
                          </InputLabel>
                          <Select
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={form.language?.output}
                            label="Idioma de Saída"
                            onChange={e => handleSelect(e, 'output')}
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="portuguese"
                            >
                              Português
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="english"
                            >
                              Inglês
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="spanish"
                            >
                              Espanhol
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <FormControl
                          sx={{ width: '100%', marginBottom: '20px' }}
                          size="small"
                        >
                          <InputLabel
                            id="demo-select-small"
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            Formalidade
                          </InputLabel>
                          <Select
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={form.language?.formality}
                            label="Formalidade"
                            onChange={e => handleSelect(e, 'formality')}
                            sx={{
                              fontFamily: ['Inter'].join(',')
                            }}
                          >
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="default"
                            >
                              Padrão
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="lessFormal"
                            >
                              Menos formal
                            </MenuItem>
                            <MenuItem
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                              value="moreFormal"
                            >
                              Mais formal
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </div>
            </Grid>

            <Grid item xs={12} id="container-left-footer">
              <div className={classes.submitContainer}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Button
                      variant="outlined"
                      onClick={onClearForm}
                      style={{
                        marginLeft: '0px',
                        width: '100%',
                        marginBottom: '10px',
                        borderColor:
                          themeMode.mode === 'light'
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.secondary.main}`,
                        color:
                          themeMode.mode === 'light'
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.secondary.main}`
                      }}
                      sx={{
                        textTransform: 'none!important',
                        fontFamily: ['Inter'].join(',')
                      }}
                    >
                      Limpar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Box
                      component="form"
                      sx={{
                        '& > :not(style)': {
                          width: '100%',
                          marginBottom: '10px'
                        }
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <TextField
                        type="number"
                        id="outlined-basic"
                        label="Quantidade"
                        variant="outlined"
                        placeholder="Casual"
                        value={form.quantity}
                        onChange={(
                          e: React.ChangeEvent<HTMLInputElement>
                        ): void => handleChange(e, 'quantity')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          inputProps: {
                            fontFamily: ['Inter'].join(','),
                            min: 1,
                            max: 10
                          }
                        }}
                        size="small"
                        sx={{
                          fontFamily: ['Inter'].join(',')
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <div>
                      <LoadingButton
                        style={{
                          marginLeft: '0px',
                          width: '100%',
                          backgroundColor:
                            themeMode.mode === 'light'
                              ? `${theme.palette.primary.main}`
                              : `${theme.palette.secondary.main}`,
                          color: loading
                            ? themeMode.mode === 'light'
                              ? `${theme.palette.primary.main}`
                              : `${theme.palette.secondary.main}`
                            : themeMode.mode === 'light'
                            ? `${theme.palette.text.white}`
                            : `${theme.palette.text.black}`
                        }}
                        loading={loading}
                        variant="contained"
                        onClick={onSubmit}
                        sx={{
                          textTransform: 'none!important',
                          fontFamily: ['Inter'].join(',')
                        }}
                      >
                        Gerar
                      </LoadingButton>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Grid item xs={12} id="container-right-header">
              <div className={classes.actionsContainer}>
                <Tooltip title="Gerar Novas Saídas" key="Gerar Novas Saídas">
                  <IconButton
                    color="primary"
                    // aria-label="Novas Saídas"
                    // title="Novas Saídas"
                    onClick={onSubmit}
                  >
                    <OutputIcon
                      color={
                        themeMode.mode === 'light' ? 'primary' : 'secondary'
                      }
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip
                  title="Visualizar Histórico"
                  key="Visualizar Histórico"
                >
                  <IconButton
                    color="primary"
                    // aria-label="Histórico"
                    // title="Histórico"
                    onClick={getHistoric}
                  >
                    <HistoryIcon
                      aria-label="Histórico"
                      color={
                        themeMode.mode === 'light' ? 'primary' : 'secondary'
                      }
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Limpar saídas" key="Limpar saídas">
                  <IconButton
                    color="primary"
                    // aria-label="Limpar saídas"
                    // title="Limpar saídas"
                    onClick={clearAnswers}
                  >
                    <ClearIcon
                      color={
                        themeMode.mode === 'light' ? 'primary' : 'secondary'
                      }
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.resultsContainer}
              style={{ height: heightContainerRight() }}
              id="container-right"
            >
              <div>
                {!activeHistoric && !templates.response && (
                  <div className={classes.resultsContainerWait}>
                    <div
                      style={{
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        display: 'flex'
                      }}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        mt={'10.5px'}
                      >
                        {/*<TextingSVG*/}
                        {/*  width={'90%'}*/}
                        {/*  height={340}*/}
                        {/*  style={{ marginTop: '40px' }}*/}
                        {/*/>*/}

                        {themeMode.mode === 'light' && (
                          <>
                            <img
                              src={IMGTemplate}
                              alt="Person"
                              style={{
                                marginTop: '40px',
                                marginBottom: '20px',
                                width: '50%'
                                // height: '340px'
                              }}
                            />
                          </>
                          // <MagicSVGLight
                          //   width={'80%'}
                          //   height={340}
                          //   style={{ marginTop: '40px' }}
                          // />
                        )}

                        {themeMode.mode === 'dark' && (
                          <>
                            <img
                              src={IMGTemplate}
                              alt="Person"
                              style={{
                                marginTop: '40px',
                                marginBottom: '20px',
                                width: '50%'
                                // height: '340px'
                              }}
                            />
                          </>
                          // <MagicSVGDark
                          //   width={'80%'}
                          //   height={340}
                          //   style={{ marginTop: '40px' }}
                          // />
                        )}
                      </Box>

                      <Typography
                        style={{
                          fontSize: '1.2em',
                          fontWeight: '700',
                          marginTop: '-20px',
                          marginBottom: '15px',
                          textAlign: 'center'
                        }}
                      >
                        Preencha os campos e veja a mágica acontecer
                      </Typography>

                      <Typography
                        style={{
                          fontSize: '0.975em',
                          color: 'rgb(113, 128, 150)',
                          textAlign: 'center',
                          marginBottom: '5px'
                        }}
                      >
                        Para obter os melhores resultados <br /> tente
                        diferentes entradas, de diferentes tamanhos.
                      </Typography>
                    </div>

                    {/*<div className={classes.messageContainer}>*/}
                    {/*  <p className={classes.titleMessage}>*/}
                    {/*    Preencha os campos ao lado*/}
                    {/*  </p>*/}

                    {/*  <p className={classes.descriptionMessage}>*/}
                    {/*    Obtenha resultados melhores experimentando várias*/}
                    {/*    entradas de diferentes tamanhos.*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                  </div>
                )}

                {!activeHistoric &&
                  templates.response &&
                  templates.response.output.map((output: any) => (
                    <div
                      id={'copy-completion' + output._id}
                      key={output._id}
                      className={classes.resultsContainerWait}
                    >
                      <div className={classes.messageContainer}>
                        {output.message
                          .split('\n')
                          .map((el: any, index: number) => {
                            return (
                              <Typography
                                key={index}
                                className={classes.descriptionResult}
                                dangerouslySetInnerHTML={{ __html: el }}
                              />
                            );
                          })}

                        <TemplateActions
                          output={output}
                          onSelectAction={onSelectAction}
                        />
                      </div>
                    </div>
                  ))}

                {activeHistoric &&
                  templates.completions &&
                  templates.completions.length === 0 && (
                    <>
                      <HistoricNotFound />
                    </>
                  )}

                {activeHistoric &&
                  outputs.map((output: any) => (
                    <div
                      id={'copy-completion' + output._id}
                      key={output._id}
                      className={classes.resultsContainerWait}
                    >
                      <div className={classes.messageContainer}>
                        {[
                          'blog-post-outline',
                          'blog-post-topic-ideas',
                          'email-subject'
                        ].includes(output.template) ? (
                          output.message
                            .split('\n')
                            .map((el: any, index: number) => {
                              return (
                                <Typography
                                  key={index}
                                  className={classes.descriptionResult}
                                  dangerouslySetInnerHTML={{ __html: el }}
                                />
                              );
                            })
                        ) : (
                          <p className={classes.descriptionResult}>
                            {output.message}
                          </p>
                        )}

                        <TemplateActions
                          output={output}
                          onSelectAction={onSelectAction}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TemplateContainer;
