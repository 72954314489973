import { LoadingInterface } from './interface';
import useStyles from './style';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import LoadingIcon from '../../../../../../../assets/images/loading.svg';

const PaymentCheckoutLoading = ({ loading }: LoadingInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  if (loading) {
    return (
      <div className={classes.container}>
        <div className={classes.root}>
          <img src={LoadingIcon} alt="Clarice.ai" className={classes.img} />
        </div>
      </div>
    );
  }

  return null;
};

export default PaymentCheckoutLoading;
