/* eslint-disable no-undef */
import {
  PROJECTS_DETAIL,
  PROJECTS_GET_ALL,
  PROJECTS_ACTIVE,
  PROJECTS_CREATE,
  PROJECTS_DELETE,
  PROJECTS_UPDATE
} from '../actions/actionTypes';

const initialState: reducers.ProjectReducer = {
  active: null,
  allProjects: []
};

const projectsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PROJECTS_CREATE:
      state = {
        ...state,
        allProjects: [...state.allProjects, action.payload]
      };
      break;
    case PROJECTS_UPDATE:
      state = {
        ...state,
        allProjects: state.allProjects.map((project: any) => {
          if (String(project._id) === String(action.payload._id)) {
            project = {
              ...action.payload
            };
          }

          return project;
        })
      };
      break;
    case PROJECTS_DELETE:
      state = {
        ...state,
        allProjects: state.allProjects.map((project: any) => {
          if (String(project._id) === String(action.payload._id)) {
            project = {
              ...action.payload
            };
          }

          return project;
        })
      };
      break;
    case PROJECTS_GET_ALL:
      state = {
        ...state,
        allProjects: action.payload
      };
      break;
    case PROJECTS_ACTIVE:
      state = {
        ...state,
        active: action.payload
      };
      break;
    default:
      return state;
  }

  return state;
};

export default projectsReducer;
