import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * { 
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    //list-style: none;
    font-family: soleil, sans-serif;
  }

  html {
    font-size: 62.5%;
    @media(max-width: 1400px) {
      font-size: 60%;
    }
    @media(max-width: 1300px) {
      font-size: 55%;
    }
  }

  html,
  body,
  #root {
    min-height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
  }

  button {
    cursor: pointer;
  }

  input,
  button,
  textarea {
    appearance: none;
    resize: none;
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }
  
  .Toastify__toast-body {
    font-size: 1.3rem !important;
    font-family: Inter, sans-serif !important;
  }
`;

export default GlobalStyle;
