import { Dispatch } from 'redux';

import {
  DESCREASE_LOADING,
  FINISH_PROGRESS,
  INCREASE_LOADING,
  SET_PROGRESS
} from './actionTypes';

export const increaseLoading = () => ({
  type: INCREASE_LOADING
});

export const decreaseLoading = () => ({
  type: DESCREASE_LOADING
});

export const setProgress = (progress: number) => ({
  type: SET_PROGRESS,
  payload: progress
});

export const finishProgress = () => ({
  type: FINISH_PROGRESS
});

export const addLoading = () => (dispatch: Dispatch) =>
  dispatch(increaseLoading());

export const removeLoading = () => (dispatch: Dispatch) =>
  dispatch(decreaseLoading());
