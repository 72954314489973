// AUTH
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_GOOGLE_AUTH = 'AUTH_GOOGLE_AUTH';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_CHECK_TOKEN = 'AUTH_CHECK_TOKEN';
// AUTH

//LOADING
export const INCREASE_LOADING = 'INCREASE_LOADING';
export const DESCREASE_LOADING = 'DESCREASE_LOADING';
export const SET_PROGRESS = 'SET_PROGRESS';
export const FINISH_PROGRESS = 'FINISH_PROGRESS';
//LOADING

export const USER_DETAIL = 'USER_DETAIL';
export const USER_STATS = 'USER_STATS';
export const USER_UPDATE_THEME = 'USER_UPDATE_THEME';
export const USER_UPDATE_CHAT_HISTORIC = 'USER_UPDATE_CHAT_HISTORIC';
export const USER_UPDATE_PROJECT_ACTIVE = 'USER_UPDATE_PROJECT_ACTIVE';
export const USER_UPDATE_BRAND_ACTIVE = 'USER_UPDATE_BRAND_ACTIVE';
export const USER_ADD_WORD_FROM_DICTIONARY = 'USER_ADD_WORD_FROM_DICTIONARY';
export const USER_DELETE_WORD_FROM_DICTIONARY =
  'USER_DELETE_WORD_FROM_DICTIONARY';
export const USER_ENABLE_RULE = 'USER_ENABLE_RULE';
export const USER_DISABLE_RULE = 'USER_DISABLE_RULE';
export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';
export const USER_UPDATE_SUBSCRIPTION = 'USER_UPDATE_SUBSCRIPTION';

export const TEMPLATES_DETAIL = 'TEMPLATES_DETAIL';
export const TEMPLATE_COMPLETION = 'TEMPLATE_COMPLETION';
export const TEMPLATES_COMPLETION_CLEAR = 'TEMPLATES_COMPLETION_CLEAR';
export const TEMPLATES_GET_ALL_COMPLETIONS = 'TEMPLATES_GET_ALL_COMPLETIONS';
export const TEMPLATES_COMPLETION_UPDATE_STATUS =
  'TEMPLATES_COMPLETION_UPDATE_STATUS';
export const TEMPLATE_TOGGLE_FAVORITE = 'TEMPLATE_TOGGLE_FAVORITE';

export const CREDITS_DETAIL = 'CREDITS_DETAIL';

export const PROJECTS_CREATE = 'PROJECTS_CREATE';
export const PROJECTS_DETAIL = 'PROJECTS_DETAIL';
export const PROJECTS_GET_ALL = 'PROJECTS_GET_ALL';
export const PROJECTS_ACTIVE = 'PROJECTS_ACTIVE';
export const PROJECTS_UPDATE = 'PROJECTS_UPDATE';
export const PROJECTS_DELETE = 'PROJECTS_DELETE';

export const DOCUMENTS_LIST = 'DOCUMENTS_LIST';
export const DOCUMENTS_DETAIL = 'DOCUMENTS_DETAIL';
export const DOCUMENTS_UPDATE_CORRECTION = 'DOCUMENTS_UPDATE_CORRECTION';
export const DOCUMENTS_REPLACE_WORD = 'DOCUMENTS_REPLACE_WORD';
export const DOCUMENTS_UPDATE_LIST = 'DOCUMENTS_UPDATE_LIST';
export const DOCUMENTS_CLEAR_DETAIL = 'DOCUMENTS_CLEAR_DETAIL';

/* EDITOR */
export const TEXT_EDIT = 'TEXT_EDIT';
export const TEXT_CREATE = 'TEXT_CREATE';

export const TEXT_PROCESS_READABILITY = 'TEXT_PROCESS_READABILITY';
export const TEXT_UPDATE_GENRE = 'TEXT_UPDATE_GENRE';
export const TEXT_ACTIVE_CATEGORY = 'TEXT_ACTIVE_CATEGORY';
export const TEXT_ACTIVE_CATEGORY_TYPE = 'TEXT_ACTIVE_CATEGORY_TYPE';
export const DOCUMENTS_CREATE = 'DOCUMENTS_CREATE';
export const DOCUMENTS_DELETE = 'DOCUMENTS_DELETE';

export const EDITOR_ADD_WORD_FROM_DICTIONARY =
  'EDITOR_ADD_WORD_FROM_DICTIONARY';
export const FEEDBACK = 'FEEDBACK';
export const SET_ACTIVE_CORRECTION_ITEM = 'SET_ACTIVE_CORRECTION_ITEM';
export const SET_TITLE = 'SET_TITLE';
/* EDITOR */

/* CHAT */
export const CHAT_CONVERSATION = 'CHAT_CONVERSATION';
export const CHAT_GET_ALL = 'CHAT_GET_ALL';
export const CHAT_UPDATE_STATUS = 'CHAT_UPDATE_STATUS';
export const CHAT_CLEAR = 'CHAT_CLEAR';
/* CHAT */

/* PLANS */
export const GET_PLANS = 'GET_PLANS';
/* PLANS */

/* BRANDS */
export const BRANDS_CREATE = 'BRANDS_CREATE';
export const BRANDS_UPDATE = 'BRANDS_UPDATE';
export const BRANDS_GET_ALL = 'BRANDS_GET_ALL';
export const BRANDS_DELETE = 'BRANDS_DELETE';

/* BRANDS */
