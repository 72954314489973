import { ModalCustomInterface } from './interface';
import useStyles from './style';
import { IconButton, Modal } from '@mui/material';
import clsx from 'clsx';
import { Close } from '@mui/icons-material';
import { useEffect } from 'react';

const ModalCustom = ({
  open,
  toggle,
  children,
  containerClass,
  hideClose,
  didMountHook,
  ...props
}: ModalCustomInterface) => {
  const classes: any = useStyles();

  useEffect(() => {
    if (didMountHook && typeof didMountHook === 'function') {
      setTimeout(didMountHook, 100);
    }
  }, []);

  return (
    <Modal open={open} className={classes.root} onClose={toggle} {...props}>
      <div
        className={clsx(classes.container, {
          [containerClass]: containerClass
        })}
      >
        {/*{!hideClose && (*/}
        {/*  <IconButton className={classes.close} size={'small'} onClick={toggle}>*/}
        {/*    <Close fontSize={'small'} />*/}
        {/*  </IconButton>*/}
        {/*)}*/}
        {children}
      </div>
    </Modal>
  );
};

export default ModalCustom;
