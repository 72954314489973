import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChatContainerInterface } from './interface';
import useStyles from './style';
import { Grid, useTheme, TextField, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import toast from 'react-hot-toast';
import {
  chatClear,
  chatConversation,
  chatUpdateStatus,
  getChatConversationAll
} from '../../../../../../stories/actions/chat';
import Box from '@mui/material/Box';
import FullLogo from '../../../../../../assets/images/jsx-icons/full-logo';
import MessageContainer from '../MessageContainer';
import LoadingButton from '@mui/lab/LoadingButton';
import PromptsContainer from '../PromptsContainer';
import clsx from 'clsx';
import { getCredits } from '../../../../../../stories/actions/credits';
import notify from '../../../../../../utils/notify';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';

const ChatContainer = ({ chatbot }: ChatContainerInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const ctx = useContext(GlobalContext);

  const listRef: any = useRef(null);

  const { user, chat, credits } = useReduxState();

  const [talks, setTalks] = useState<any>([]);
  const [form, setForm] = useState<any>({ message: '' });
  const [loading, setLoading] = React.useState(false);
  const [loadGetAll, setLoadGetAll] = React.useState(false);

  const dispatch = useAppDispatch();

  const custom = {
    totalCredits: {
      quantity: credits.bonus.words.total + (credits?.plan?.words?.total || 0),
      percentage:
        ((credits.bonus.words.used + (credits?.plan?.words?.used || 0)) * 100) /
        (credits.bonus.words.total + (credits?.plan?.words?.total || 0))
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    if (form.message === '') {
      return notify.error('Digite uma mensagem!');
    }

    if (form.message.length > 2000) {
      return notify.error('Mensagem muito grande, no máximo 2000 caracteres!');
    }

    setForm({ message: '' });

    if (custom.totalCredits.percentage < 100) {
      setLoading(true);

      setTalks((prevState: any) => [
        ...prevState,
        {
          _id: form.message,
          name: user.detail?.name,
          avatar: '',
          messages: [`${form.message}`],
          side: 'right',
          type: 'input',
          status: {
            hide: false
          },
          temp: true
        }
      ]);

      const cb = {
        success: () => {
          setLoading(false);

          setTalks((prevState: any) => {
            return prevState.map(el => {
              delete el.temp;

              return el;
            });
          });

          dispatch(getCredits());
        },
        error: (err: any) => {
          setLoading(false);

          setTalks((prevState: any) => {
            return prevState.filter(el => !el.temp);
          });

          dispatch(getCredits());
        }
      };

      dispatch(chatConversation(form.message, 'PT-BR', cb));
    } else {
      // MENSAGEM DE ERRO
      if (!ctx.modal.conversion.open) {
        ctx.modal.conversion.toggle();
      }

      notify.error('Você atingiu o limite de créditos!');
    }
  };

  const onClearForm = () => {
    setForm({ message: '' });

    setTalks([]);

    dispatch(chatClear());
  };

  const onSelectPrompt = (prompt: string) => {
    setForm({ message: prompt });
  };

  const onSelectAction = async (_id: any, type: string, action: string) => {
    const newTalks = talks;

    const index = newTalks.findIndex(
      (talk: any) => String(talk._id) === String(_id)
    );

    if (index !== -1) {
      const selected = newTalks[index];

      if (action === 'like') {
        newTalks[index] = {
          ...selected,
          status: { ...selected.status, like: true, dislike: false }
        };

        dispatch(chatUpdateStatus(_id, type, action));
      }

      if (action === 'dislike') {
        newTalks[index] = {
          ...selected,
          status: { ...selected.status, like: false, dislike: true }
        };

        dispatch(chatUpdateStatus(_id, type, action));
      }

      if (action === 'hide') {
        newTalks[index] = {
          ...selected,
          status: { ...selected.status, hide: !!selected.status.hide }
        };

        dispatch(chatUpdateStatus(_id, type, action));
      }

      if (action === 'copy') {
        const text: any = document.getElementById('copy-talk' + _id);
        const range = document.createRange();
        const textToCopy = document.createElement('div');
        const selection: any = window.getSelection();
        textToCopy.innerHTML = text.innerHTML;
        textToCopy.id = 'copy-to-clipboard';
        document.body.append(textToCopy);
        range.selectNodeContents(textToCopy);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');

        textToCopy.remove();
      }

      if (action === 'refresh') {
        if (index - 1 >= 0) {
          newTalks[index] = { ...selected, refresh: true, loading: true };

          const refresh = newTalks[index - 1];

          setLoading(true);

          const cb = {
            success: () => {
              newTalks[index] = {
                ...selected,
                refresh: false,
                loading: false
              };

              setTalks([...newTalks]);

              setLoading(false);
            }
          };

          dispatch(chatUpdateStatus(_id, type, action, cb));
        }
      }

      setTalks([...newTalks]);
    }
  };

  useEffect(() => {
    listRef.current.scrollTop =
      listRef.current.scrollHeight - listRef.current.clientHeight;
  }, [talks]);

  useEffect(() => {
    if (!loadGetAll) {
      const cb = {
        success: () => {
          setLoadGetAll(true);
        }
      };

      dispatch(getChatConversationAll(cb));
    }

    const conversation: any = [];

    if (chat.conversation) {
      chat.conversation.map((el: any) => {
        // INPUT
        // if (el.input.status.active) {
        conversation.push({
          _id: el.input._id,
          name: user.name,
          avatar: '',
          messages: [`${el.input.message}`],
          side: 'right',
          type: 'input',
          status: el.input.status
        });
        // }

        // OUTPUT
        el.output.map(output => {
          // if (output.status.active) {
          conversation.push({
            _id: output._id,
            name: 'Clarice.ai',
            avatar: 'https://clarice.ai/popup/emoji.png',
            messages: [`${output.message}`],
            type: 'output',
            status: output.status
          });
          // }

          return output;
        });

        setTalks(conversation);

        return el;
      });
    }
  }, [chat]);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <div
      className={clsx(classes.chat, {
        [classes.chatbot]: chatbot
      })}
    >
      <div className={chatbot ? classes.list : undefined} ref={listRef}>
        <div
          style={{ minHeight: '250px' }}
          className={talks.length === 0 ? classes.clarice : undefined}
        >
          {talks.length === 0 && (
            <div>
              <Box display={'flex'} justifyContent={'center'} mt={'10.5px'}>
                <FullLogo
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  style={{
                    width: isMobile ? '130px' : '180px',
                    marginBottom: isMobile ? '10px' : '20px'
                  }}
                />
              </Box>
              {!chatbot ? (
                <p className={classes.clariceDescription}>
                  Escolha um prompt abaixo ou escreva o seu próprio para começar
                  a conversar com a <strong>Clarice.ai</strong>.
                </p>
              ) : (
                <p className={classes.clariceDescription}>
                  Use esse chat para conversar com a <strong>Clarice.ai</strong>{' '}
                  e pedir dicas de escrita. Para falar com o suporte, use o
                  outro chat.
                </p>
              )}
            </div>
          )}

          {talks.length > 0 &&
            talks.map((talk: any, index: number) => {
              return (
                <MessageContainer
                  chatbot={chatbot}
                  key={index}
                  talk={talk}
                  side={talk.side && talk.side === 'right' ? 'right' : 'left'}
                  onSelectAction={onSelectAction}
                />
              );
            })}
        </div>
      </div>

      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <div
          className={clsx(classes.submitContainer, {
            [classes.submitContainerChatbot]: chatbot
          })}
        >
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={chatbot ? 12 : 10}>
              <Box
                component="div"
                sx={{
                  '& > :not(style)': { width: '100%' }
                }}
              >
                <p className={classes.count}>({form.message.length}/2000)</p>

                <TextField
                  className={classes.input}
                  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                  id="outlined-basic"
                  label="Digite uma mensagem"
                  variant="outlined"
                  multiline={false}
                  rows={1}
                  placeholder="Digite uma mensagem..."
                  value={form.message}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleChange(e, 'message')
                  }
                  size="small"
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      return onSubmit(undefined);
                    }
                  }}
                  sx={{
                    fontFamily: ['Inter'].join(',')
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={chatbot ? 6 : 1}>
              <LoadingButton
                variant="outlined"
                onClick={onClearForm}
                style={{
                  textTransform: 'none',
                  fontFamily: 'Inter',
                  marginLeft: '0px',
                  width: '100%',
                  borderColor:
                    themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`,
                  color:
                    themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.main}`
                }}
              >
                Limpar
              </LoadingButton>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={chatbot ? 6 : 1}>
              <div>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  onClick={onSubmit}
                  style={{
                    textTransform: 'none',
                    fontFamily: 'Inter',
                    marginRight: '0px',
                    width: '100%',
                    backgroundColor:
                      themeMode.mode === 'light'
                        ? `${theme.palette.primary.main}`
                        : `${theme.palette.secondary.main}`,
                    color: loading
                      ? themeMode.mode === 'light'
                        ? `${theme.palette.primary.main}`
                        : `${theme.palette.secondary.main}`
                      : themeMode.mode === 'light'
                      ? `${theme.palette.text.white}`
                      : `${theme.palette.text.black}`
                  }}
                >
                  Enviar
                </LoadingButton>
              </div>
            </Grid>
          </Grid>

          {!chatbot && <PromptsContainer onSelectPrompt={onSelectPrompt} />}
        </div>
      </form>
    </div>
  );
};

export default ChatContainer;
