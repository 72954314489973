import ApplePayIcon from '../../../../../../assets/images/payment/applePay.svg';
import VisaIcon from '../../../../../../assets/images/payment/visa.svg';
import GooglePayIcon from '../../../../../../assets/images/payment/googlePay.svg';
import MastercardIcon from '../../../../../../assets/images/payment/mastercard.svg';
import Mastercard2Icon from '../../../../../../assets/images/payment/mastercard2.svg';

const creditCardsAccepted = [
  {
    flag: 'Apple Pay',
    icon: ApplePayIcon
  },
  {
    flag: 'Visa',
    icon: VisaIcon
  },
  {
    flag: 'Google Pay',
    icon: GooglePayIcon
  },
  {
    flag: 'MasterCard',
    icon: MastercardIcon
  },
  {
    flag: 'MasterCard 2',
    icon: Mastercard2Icon
  }
];

export default creditCardsAccepted;
