import React, { createContext, useState, useMemo, useEffect } from 'react';
import {
  ThemeContextInterface,
  ThemeContextProviderInterface
} from './interface';
import ThemeContextInitial from './state';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';
import getThemeByMode from './theme';
import GlobalStyle from './theme/GlobalStyle';
import { useReduxState } from '../../../hooks/useReduxState';
import { getChatConversationAll } from '../../../stories/actions/chat';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { updateTheme } from '../../../stories/actions/user';
import GlobalStyleDark from './theme/GlobalStyleDark';
import { Toaster, ToastBar } from 'react-hot-toast';

export const ThemeContext =
  createContext<ThemeContextInterface>(ThemeContextInitial);

export const ThemeContextProvider = (props: ThemeContextProviderInterface) => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  const { user } = useReduxState();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const { options }: any = user;

    if (options && options.theme) {
      setMode(options.theme);
    }
  }, [user.options]);

  const valueProvider = useMemo(
    () => ({
      mode,
      toggle: () => {
        dispatch(updateTheme(mode === 'light' ? 'dark' : 'light'));

        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    [mode]
  );

  const theme = useMemo(() => getThemeByMode(mode), [mode]);

  return (
    <ThemeContext.Provider value={valueProvider}>
      {theme.palette.mode === 'light' ? <GlobalStyle /> : <GlobalStyleDark />}
      <ThemeProvider theme={theme}>
        <ThemeProviderLegacy theme={theme}>
          <Toaster
            position="top-center"
            reverseOrder={false}
            // gutter={8}
            toastOptions={
              theme.palette.mode === 'light'
                ? {
                    style: {
                      fontSize: '14px'
                    }
                  }
                : {
                    className: '',
                    style: {
                      fontSize: '14px',
                      border: '1.5px solid #3a3541!important',
                      backgroundColor: '#333',
                      color: '#E7EBF0'
                    }
                  }
            }
          />

          {props.children}
        </ThemeProviderLegacy>
      </ThemeProvider>

      {/*<ThemeProvider theme={theme}>{props.children}</ThemeProvider>*/}
    </ThemeContext.Provider>
  );
};
