import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  container: {
    backgroundColor: theme.palette.mode === 'light' ? 'white' : '#303030',
    borderRadius: '8px',
    border:
      theme.palette.mode === 'light'
        ? `1px solid #bbbbbb`
        : `0px solid ${theme.palette.strokes.grey}`,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '5px'
  }
}));

export default Styles;
