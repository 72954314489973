import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ThemeContextProvider } from './utils/context/ThemeContext';
import { CssBaseline } from '@mui/material';
import React from 'react';
import Router from './router';

function App() {
  return (
    <ThemeContextProvider>
      <ToastContainer />
      <CssBaseline />
      <BrowserRouter basename="/">
        <Router />
      </BrowserRouter>
    </ThemeContextProvider>
  );
}

export default App;
