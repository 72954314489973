import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    width: '100%'
  },
  title: {
    fontFamily: 'Inter',
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: '700'
  },
  options: {
    marginTop: '30px'
  },
  templates: {
    padding: '20px',
    marginTop: '20px!important',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  }
}));

export default Styles;
