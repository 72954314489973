import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import useStyles from './style';

type EditorContainerProps = {
  children?: any;
};

const EditorContainer = ({ children }: EditorContainerProps) => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  return (
    <Box
      width={'100vw'}
      display={'flex'}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      flexDirection={isMobile ? 'column' : 'row'}
      mt={isMobile ? '90px' : '120px'}
      ml={isMobile ? '0' : '30px'}
      paddingRight={isMobile ? '0' : '10rem'}
    >
      {children}
    </Box>
  );
};

export default EditorContainer;
