import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  container: {
    marginTop: '25px',
    paddingTop: '25px',
    paddingBottom: '25px'
  }
}));

export default Styles;
