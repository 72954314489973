import React from 'react';
import useStyles from './styles';
import TitlePlanDescription from './component/Title';
import BenefitContainer from './component/BenefitContainer';
import TestimonialContainer from './component/TestimonialContainer';
import { Box } from '@mui/material';

const PlanDescriptionPayment = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} px={'30px'}>
      <TitlePlanDescription
        title="Assine o PRO"
        subtitle="Tenha acesso a benefícios exclusivos"
      />

      {/* <Box> */}
      <BenefitContainer />
      <Box bgcolor={'rgba(164, 163, 255, 0.3)'} height={'1px'} width={'100%'} />
      <TestimonialContainer />
      {/* </Box> */}
    </Box>
  );
};

export default PlanDescriptionPayment;
