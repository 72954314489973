import axios from 'axios';
import { STATE } from '../const/STATE';

export default () => {
  return axios.create({
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    baseURL: STATE.app.baseURL(process.env.REACT_APP_ENV || 'production')
  });
};
