import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  scoreText: {
    color:
      theme.palette.mode === 'light' ? '#410A85' : theme.palette.text.white,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontWeight: '700',
    letterSpacing: '0.13px'
  },
  moreScore: {
    fontSize: '16px',
    backgroundColor: '#09B286',
    color: 'white',
    letterSpacing: '0.1px',
    cursor: 'pointer'
  }
}));

export default Styles;
