import React, { useContext } from 'react';
import useStyles from './style';
import { useTheme } from '@mui/material';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import ChatContainer from './components/ChatContainer';

const ChatPage = () => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  return (
    <>
      <div className={classes.root}>
        <p className={classes.title}>Chat</p>

        <ChatContainer />
      </div>
    </>
  );
};

export default ChatPage;
