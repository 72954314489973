import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  container: {
    marginBottom: '25px',
    paddingBottom: '25px'
  },
  loadingText: {
    font: 'normal normal 500 18px Inter!important',
    color: '#FFFFFF',
    lineWeight: '125%',
    marginBottom: '30px'
  }
}));

export default Styles;
