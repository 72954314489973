import React from 'react';
import useStyles from './styles';
import CreditCardAcceptedItem from '../CreditCardAcceptedItem';
import creditCardsAccepted from '../../../../const/creditCardsAccepted';
import { Box, Typography, useMediaQuery } from '@mui/material';

const CreditCardAccepted = () => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        paddingBottom: '30px',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>Aceitamos:</Typography>
      </Box>

      <div
        className={classes.cardsContainer}
        style={{
          marginLeft: isMobile ? '0' : '15px'
        }}
      >
        {creditCardsAccepted.map(
          ({ flag, icon }: { flag: string; icon: string }) => (
            <CreditCardAcceptedItem key={flag} flag={flag} icon={icon} />
          )
        )}
      </div>
    </Box>
  );
};

export default CreditCardAccepted;
