const filteredCorrections = (items, category, type) => {
  const ranges: any[] = [];

  if (!items) {
    return [];
  }

  if (category && ['deviations', 'doubts'].includes(category)) {
    const overlaps: any = getOverlaps(
      type === 'all' ? items : items.filter(item => item.score[type]),
      ranges
    );

    if (type === 'all') {
      return overlaps.filter(item => !item.hide);
    }

    return overlaps.filter(item => item.score[type] && !item.hide);
  }

  if (category && category === 'spellingAndGrammar') {
    if (type === 'all') {
      return items;
    }

    return items.filter(item => {
      if (item.id && !item.type) {
        return item.score && item.score[type];
      }

      return item.type === type;
    });
  }

  return items;
};

export default filteredCorrections;

const getOverlaps = (items, ranges) => {
  return items.map(item => {
    const tempRanges: any[] = [];
    const deviation = { ...item };

    deviation.spans.find(span => {
      const range: any = {
        start: span.offset,
        end: span.offset + span.length
      };
      const overlaps = rangeOverlaps(ranges, range);
      if (!overlaps) {
        tempRanges.push(range);
      }
      return overlaps;
    });

    let shouldHide;

    if (tempRanges.length !== deviation.spans.length) {
      shouldHide = true;
    } else {
      shouldHide = false;

      if (tempRanges.length > 0) {
        ranges.push(...tempRanges);
      }
    }

    deviation.hide = shouldHide;

    return deviation;
  });
};

const rangeOverlaps = (ranges, range) => {
  if (!ranges) return false;

  if (ranges.length === 0) return false;

  // eslint-disable-next-line no-unused-vars
  for (const rg of ranges) {
    if (range.start >= rg.start && range.start <= rg.end) return true;
    if (range.start >= rg.start && range.end <= rg.end) return true;
    if (range.end >= rg.start && range.end <= rg.end) return true;
    if (range.start <= rg.start && range.end >= rg.start) return true;
    if (
      range.start >= rg.start &&
      range.start <= rg.end &&
      range.end >= rg.start
    ) {
      return true;
    }
  }
  return false;
};
