import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    position: 'relative',
    backgroundColor:
      theme.palette.mode === 'light'
        ? `${theme.palette.background.paper}`
        : `${theme.palette.background.default}`,
    borderRadius: '13px',
    maxWidth: '590px',
    outline: 'none',
    padding: '0px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      // padding: '20px 29px'
      overflow: 'auto',
      paddingBottom: '30px'
    }
  },
  box: {
    '&::-webkit-scrollbar': {
      width: '0.4em',
      backgroundColor: '#ffffff'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
      backgroundColor: '#ffffff'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px',
      backgroundColor: '#410a85bd'
    }
  }
}));

export default useStyles;
