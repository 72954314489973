import React from 'react';
import { PropsInterface } from './interface';
import useStyles from './styles';
import GuaranteeIcon from '../../../../../../../../../assets/images/checked-guarantee.svg';
import { Box, Typography, useMediaQuery } from '@mui/material';

const TitlePlanSelect = ({ title }: PropsInterface) => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Typography
          className={classes.title}
          sx={{
            marginLeft: isMobile ? '0px!important' : '40px!important'
          }}
        >
          <strong>{title}</strong>
        </Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <div className={classes.warrantyContainer}>
          <img
            src={GuaranteeIcon}
            alt="GuaranteeIcon"
            className={classes.icon}
          />

          <Typography className={classes.warranty}>
            14 dias de garantia
          </Typography>
        </div>
      </Box>
    </Box>
  );
};

export default TitlePlanSelect;
