const SuggestionStrategy = (contentBlock, callback, suggestions) => {
  const text = contentBlock.getText();
  suggestions.forEach(suggestion => {
    const start = text.indexOf(suggestion);
    if (start !== -1) {
      callback(start, start + suggestion.length);
    }
  });
};

export default SuggestionStrategy;
