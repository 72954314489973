import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      position: 'relative',
      width: '100%',
      marginBottom: '3.2rem'
    },
    label: {
      display: 'block',
      fontWeight: '500',
      fontSize: '1.4rem',
      lineHeight: '115%',
      fontFamily: 'Inter, sans-serif',
      color: '#39274b',
      marginBottom: '1.2rem',
      transition: 'all 0.4s'
    },
    labelFocus: {
      color: '#410A85'
    },
    password: {
      position: 'relative',
      '& > button': {
        backgroundColor: 'transparent',
        border: 'none',
        position: 'absolute',
        top: '1.4rem',
        right: '1.4rem',
        '& > img': {
          display: 'block'
        }
      }
    },
    input: {
      background: '#ffffff',
      border: '1px solid #e5d5fb',
      borderRadius: '6px',
      width: '100%',
      height: '4.8rem',
      fontSize: '1.4rem',
      fontFamily: 'Inter, sans-serif',
      letterSpacing: '0.04em',
      color: '#410a85',
      padding: '0px 1.6rem',
      transition: 'all 0.4s',
      '&:placeholder': {
        color: '#655679'
      }
    },
    inputFocus: {
      border: '1px solid #7C24FB'
    }
  };
});

export default useStyles;
