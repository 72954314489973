import React from 'react';
import { InfoInterface } from './interface';
import useStyles from './style';
import { ACTIVE_COUPON } from '../../../../../../utils/coupon';

const Info = ({}: InfoInterface) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {ACTIVE_COUPON === 'ANUAL40' && (
        <>
          <h1 className={classes.title}>40% de desconto! 🎉</h1>
          <p className={classes.description}>
            Utilize o cupom <b>ANUAL40</b> e ganhe 40% de desconto na assinatura
            anual. Aproveite!
          </p>
        </>
      )}

      {ACTIVE_COUPON === 'LINGUAPT50' && (
        <>
          <div
            className={classes.title}
            style={{ fontSize: '19px', fontWeight: '400' }}
          >
            Dia 05 de maio celebramos o{' '}
            <b>
              <br />
              Dia Mundial da Língua Portuguesa!
            </b>{' '}
            🌍🎉
          </div>
          <p className={classes.description}>
            Utilize o cupom <b>LINGUAPT50</b> e ganhe 50% de desconto na
            assinatura anual. Aproveite!
          </p>
        </>
      )}
    </div>
  );
};

export default Info;
