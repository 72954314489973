import React, { useContext } from 'react';
import { MotionInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';

const Motion = ({ image }: MotionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const baseURL = `/videos/`;

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        width: '100%',
        // marginTop: isMobile ? '24px' : '32px',
        // height: isMobile ? 'auto' : '500px',
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {isMobile && (
        <img
          src={image}
          alt="Clarice Editora"
          // height={isMobile ? '320px' : '500px'}
          width={'100%'}
        ></img>
      )}

      {!isMobile && (
        <img
          src={image}
          // height={'500px'}
          width={'100%'}
          alt="Clarice Editora"
        ></img>
      )}
    </Box>
  );
};

export default Motion;
