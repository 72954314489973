/* eslint-disable no-undef */
import {
  BRANDS_CREATE,
  BRANDS_DELETE,
  BRANDS_GET_ALL,
  BRANDS_UPDATE
} from '../actions/actionTypes';

const initialState: any = {
  loaded: false,
  all: []
};

const brandsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case BRANDS_CREATE:
      state = {
        ...state,
        all: [...state.all, action.payload]
      };
      break;
    case BRANDS_UPDATE:
      state = {
        ...state,
        all: state.all.map((brand: any) => {
          if (String(brand._id) === String(action.payload._id)) {
            brand = {
              ...action.payload
            };
          }

          return brand;
        })
      };
      break;
    case BRANDS_GET_ALL:
      state = {
        ...state,
        loaded: true,
        all: action.payload
      };
      break;
    case BRANDS_DELETE:
      state = {
        ...state,
        all: state.all.filter(brand => brand._id !== action.payload._id)
      };
      break;
    default:
      return state;
  }

  return state;
};

export default brandsReducer;
