import React from 'react';
import { LeftContainerFlowInterface } from './interface';
import useStyles from './style';
import FeaturedImage from '../../../../assets/images/auth/featured-image-flow.png';
import IconData from '../../../../assets/images/auth/icon-data.svg';

const LeftContainerFlow = ({}: LeftContainerFlowInterface) => {
  const classes: any = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.image}>
          <img src={FeaturedImage} alt="" />
        </div>
        <div className={classes.text}>
          <img src={IconData} alt="" />
          <p>
            Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse
            os termos de uso e as políticas de privacidade.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeftContainerFlow;
