import React, { useContext, useState } from 'react';
import useStyle from './style';
import { ThemeContext } from '../../../../../../../../../../utils/context/ThemeContext';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme
} from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { GlobalContext } from '../../../../../../../../../../utils/context/GlobalContext';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TooltipContainer from '../../../../../../../../components/TooltipContainer';
import { isBetaUser } from '../../../../../../../../../../utils/function/isBetaUser';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: '0.5px solid rgba(9,178,134,0.55)',
    color: '#ffffff!important',
    textAlign: 'left',
    backgroundColor: '#09B286',
    minWidth: '25px',
    height: '25px',
    borderRadius: '14px',
    cursor: 'pointer'
  }
}));

const FixAllContainer = ({
  correction,
  corrections,
  onFixAll,
  textId,
  category
}: any) => {
  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const classes = useStyle();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);

  console.log('FIX ALL CONTAINER');

  const handleMouseEnter = () => {
    setOpen(false);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const response = corrections.filter(el => {
    if (
      [
        'ESPACO_DUPLO',
        'WHITESPACE_RULE',
        'COMMA_PARENTHESIS_WHITESPACE',
        'SPACE_BEFORE_PUNCTUATION2'
      ].includes(correction?.rule?.id) &&
      !el.hide &&
      el?.suggestions?.length <= 1
    ) {
      return true;
    }

    return (
      el?.rule?.id === correction?.rule?.id &&
      !el.hide &&
      correction?.spans[0]?.content === el?.spans[0]?.content
      // el?.suggestions?.length <= 1
    );
  });

  const quantity: number = response.length;

  if (quantity <= 1) {
    return null;
  }

  if (correction?.suggestions?.length > 1) {
    return (
      <div className={classes.container}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          flexDirection={'row'}
          textAlign={'center'}
          mt={'25px'}
          mb={'10px'}
          style={{ width: '100%' }}
        >
          <StyledBadge
            // badgeContent={quantity}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            badgeContent={
              <>
                <TooltipContainer
                  title={'Corrigir todos os itens do mesmo tipo'}
                  placement="right"
                >
                  <span
                    style={{
                      cursor: 'auto'
                    }}
                  >
                    {quantity}
                  </span>
                </TooltipContainer>
              </>
            }
          >
            <FormControl
              sx={{ padding: '0px!important', cursor: 'pointer' }}
              size="small"
            >
              <Select
                variant="outlined"
                labelId="demo-select-small"
                id="demo-select-small"
                IconComponent={() => null}
                onChange={() => {}}
                value={correction?.suggestions[0]}
                renderValue={() => (
                  <div
                    style={{
                      textTransform: 'unset',
                      fontSize: '13.5px',
                      letterSpacing: '0.12px',
                      display: 'flex',
                      // alignItems: 'center!important',
                      justifyContent: 'start',
                      gap: '8px'
                    }}
                  >
                    <DoneAllIcon
                      color={
                        themeMode.mode === 'light' ? 'primary' : 'secondary'
                      }
                      className={classes.icon}
                      fontSize="small"
                    />
                    Corrigir todos
                  </div>
                )}
                // color={'success'}
                className={classes.button}
                style={{
                  textTransform: 'unset',
                  fontSize: '13.5px',
                  letterSpacing: '0.12px'
                }}
                sx={{
                  borderSize: '0px!important',
                  width: 'max-content',
                  fontFamily: ['Inter'].join(','),
                  fontSize: '15px',
                  maxWidth: '180px',
                  '&.MuiOutlinedInput-root': {
                    border: '0.5px solid transparent',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    '& fieldset': {
                      border: 'none',
                      padding: '0px'
                    },
                    '&:hover fieldset': {
                      border: '0.5px solid rgba(9,178,134,0.55)'
                    },
                    '&.Mui-focused fieldset': {
                      border: '0.5px solid rgba(9,178,134,0.55)'
                    }
                  },
                  '& .MuiSelect-select': {
                    padding: '0px!important'
                  }
                }}
              >
                {correction?.suggestions.map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={item}
                      selected={i === 0 ? true : false}
                      onClick={(e: any) =>
                        onFixAll(textId, 'agree', category, response, item)
                      }
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </StyledBadge>
        </Box>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        flexDirection={'row'}
        textAlign={'center'}
        mt={'25px'}
        mb={'10px'}
        style={{ width: '100%' }}
      >
        <StyledBadge
          // badgeContent={quantity}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          badgeContent={
            <>
              <TooltipContainer
                title={'Corrigir todos os itens do mesmo tipo'}
                placement="right"
              >
                <span
                  style={{
                    cursor: 'auto'
                  }}
                >
                  {quantity}
                </span>
              </TooltipContainer>
            </>
          }
        >
          <div
            className={classes.button}
            style={{
              textTransform: 'unset',
              fontSize: '13.5px',
              letterSpacing: '0.12px'
            }}
            onClick={(e: any) => onFixAll(textId, 'agree', category, response)}
          >
            <DoneAllIcon
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              className={classes.icon}
              fontSize="small"
            />
            Corrigir todos
          </div>
        </StyledBadge>
      </Box>
    </div>
  );
};

export default FixAllContainer;
