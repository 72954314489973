import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    // backgroundColor: 'rgba(164, 163, 255, 0.15)!important',
    // backgroundColor: 'rgb(254, 197, 210)!important',
    // backgroundColor: 'rgba(196, 148, 255, 0.18) !important',
    border:
      theme.palette.mode === 'light' ? '2px solid #EEE' : `1px solid #404040`,

    borderRadius: '8px',
    /* Elevation 1 */
    boxShadow: '0px 18px 56px -12px rgba(227, 227, 238, 0.8)',
    // boxShadow: '0px 2px 12px -8px rgba(0, 0, 0, 0.16)!important',

    '&:before': {
      display: 'none'
    },
    margin: '0 !important',
    marginBottom: '8px !important'
    // '&:hover': {
    //   backgroundColor: 'rgba(196, 148, 255, 0.38) !important'
    // }
  },
  quantity: {
    // margin: '5px',
    background: 'rgb(124, 36, 250)!important',
    borderRadius: '20px',
    padding: '5px 3px',
    minWidth: '17px',
    textAlign: 'center',
    // height: '16px',
    // width: '16px',
    font: 'normal normal 700 10px/7px Inter',
    color: theme.palette.mode === 'light' ? 'white' : theme.palette.text.primary
  },
  iconButton: {
    width: '18px',
    height: '18px'
  }
}));

export default useStyles;
