import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    description: {
      width: '100%',
      textAlign: 'center',
      font: 'normal normal 400 15.5px/15px Inter',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 8,
      paddingBottom: 8
    }
  };
});

export default useStyles;
