import React from 'react';
import { CheckboxAcceptTermsInterface } from './interface';
import useStyles from './style';
import { Checkbox } from '@mui/material';

const CheckboxAcceptTerms = ({
  accept,
  handleChange
}: CheckboxAcceptTermsInterface) => {
  const classes: any = useStyles();

  return (
    <label className={classes.root}>
      <Checkbox
        className={classes.checkmark}
        checked={accept}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
      />
      Li e aceito os{' '}
      <a href="https://clarice.ai/termos" target="_blank" rel="noreferrer">
        termos de uso
      </a>
      <span style={{ marginRight: '2px' }}>,</span>
      <a href="https://clarice.ai/privacidade" target="_blank" rel="noreferrer">
        política de privacidade
      </a>
      <span style={{ marginLeft: '5px', marginRight: '2px' }}>e</span>
      <a href="https://clarice.ai/reembolsos" target="_blank" rel="noreferrer">
        política de pagamento
      </a>
    </label>
  );
};

export default CheckboxAcceptTerms;
