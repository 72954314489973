import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../utils/context/ThemeContext';
import { Box, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import EditTextPage from './pages/editText';
import {
  GlobalContext,
  GlobalContextProvider
} from '../../utils/context/GlobalContext';
import ModalCreateProjects from './components/Modal/ModalCreateProjects';
import ModalSuggestion from './components/Modal/ModalSuggestion';
import ModalDisabledRules from './components/Modal/ModalDisabledRules';
import ModalDictionary from './components/Modal/ModalDictionary';
import Chatbot from './components/Chatbot';
import PaymentContainer from './pages/payment/old';
import clsx from 'clsx';
import Plans from './pages/plans';
import PaymentCheckout from './pages/payment/checkout';
import IntercomButton from './components/IntercomButton';
import ModalNews from './components/Modal/ModalNews';
import ModalOnboarding from './components/Modal/ModalOnboarding';
import ModalConversion from './components/Modal/ModalConversion';

const AppLayout = () => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const location: any = useLocation();
  const pathLocation = location.pathname.split('/');

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const [sidebarExpanded, setSidebarExpanded] = React.useState<any>(!isMobile);

  const toggleSidebar = () => {
    setSidebarExpanded((prevState: any) => !prevState);
  };

  useEffect(() => {
    if (location && location.pathname) {
      const title = {
        '/home': 'Clarice.ai | Página Inicial',
        '/brands': 'Clarice.ai | Central da Marca',
        '/templates': 'Clarice.ai | Templates',
        '/chat': 'Clarice.ai | Chat',
        '/documents': 'Clarice.ai | Documentos',
        '/projects': 'Clarice.ai | Projetos',
        '/account/profile': 'Clarice.ai | Perfil',
        '/account/edit-account': 'Clarice.ai | Editar Perfil',
        '/account/credits-billings': 'Clarice.ai | Créditos'
      };

      document.title = title[location.pathname] || 'Clarice.ai';

      if (location.pathname.includes('/edit-text')) {
        document.title = 'Clarice.ai | Edição de Texto';
      }
    }
  }, [location]);

  return (
    <GlobalContextProvider>
      <barepay />

      {pathLocation[1] === 'edit-text' ? (
        <EditTextPage />
      ) : pathLocation[1] === 'premium' ? (
        <PaymentContainer />
      ) : pathLocation[1] === 'plans' ? (
        <Plans />
      ) : pathLocation[1] === 'payment' && pathLocation[2] === 'checkout' ? (
        <PaymentCheckout />
      ) : (
        <Box
          sx={{ display: 'flex' }}
          className={clsx(
            themeMode.mode === 'dark' ? 'darkTheme' : '',
            isMobile ? 'isMobile' : '',
            isMobile && themeMode.mode === 'dark' ? 'isMobileDarkTheme' : ''
          )}
        >
          <Navbar
            toggleSidebar={toggleSidebar}
            sidebarExpanded={sidebarExpanded}
          />
          <Box
            component="nav"
            sx={
              isMobile
                ? {
                    width: sidebarExpanded ? '100%' : '0rem',
                    flexShrink: 0,
                    position: isMobile ? 'absolute!important' : 'unset',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    height: '100%',
                    zIndex: '9999'
                  }
                : {
                    width: sidebarExpanded ? '18rem' : '0rem',
                    flexShrink: 0,
                    position: isMobile ? 'absolute!important' : 'unset',
                    padding: '0px'
                  }
            }
            /* onClick={() => (isMobile ? toggleSidebar() : null)} */
          >
            {sidebarExpanded && (
              <Sidebar
                sidebarExpanded={sidebarExpanded}
                temporary={isMobile}
                toggle={setSidebarExpanded}
              />
            )}
          </Box>
          <Box
            component="main"
            sx={
              isMobile
                ? {
                    flexGrow: 1,
                    p: 3,
                    width: sidebarExpanded
                      ? `calc(100% - 0rem)`
                      : `calc(100% - 0rem)`,
                    minHeight: '100vh',
                    backgroundColor:
                      themeMode.mode === 'light'
                        ? `#ffffff`
                        : '#252525!important'
                  }
                : {
                    flexGrow: 1,
                    p: 3,
                    width: sidebarExpanded
                      ? `calc(100% - 18rem)`
                      : `calc(100% - 0rem)`,
                    minHeight: '100vh',
                    backgroundColor:
                      themeMode.mode === 'light'
                        ? `#ffffff`
                        : '#252525!important'
                  }
            }
          >
            <Toolbar />
            <Outlet />
          </Box>
        </Box>
      )}

      <ModalCreateProjects />

      <ModalSuggestion />

      <ModalNews />

      <ModalOnboarding />

      <ModalDictionary />

      <ModalDisabledRules />

      <ModalConversion />

      {/*{location &&*/}
      {/*  !['/chat', '/premium', '/plans'].includes(location?.pathname) &&*/}
      {/*  !location?.pathname.includes('payment/checkout') && <Chatbot />}*/}
    </GlobalContextProvider>
  );
};

export default AppLayout;
