/* eslint-disable no-undef */
import {
  DOCUMENTS_CLEAR_DETAIL,
  DOCUMENTS_DETAIL,
  DOCUMENTS_LIST,
  DOCUMENTS_UPDATE_CORRECTION,
  DOCUMENTS_UPDATE_LIST,
  SET_ACTIVE_CORRECTION_ITEM,
  SET_TITLE,
  USER_DISABLE_RULE,
  DOCUMENTS_REPLACE_WORD,
  DOCUMENTS_CREATE,
  DOCUMENTS_DELETE
} from '../actions/actionTypes';

const initialState: reducers.DocumentReducer = {
  all: [],
  detail: null
};

const documentsReducer = (state = initialState, action: any) => {
  let newState: any;
  let newDetail: any;

  switch (action.type) {
    case DOCUMENTS_DETAIL:
      state = {
        ...state,
        detail: JSON.parse(JSON.stringify(action.payload))
      };
      break;
    case DOCUMENTS_CLEAR_DETAIL:
      state = {
        ...state,
        detail: null
      };
      break;
    case DOCUMENTS_LIST:
      state = {
        ...state,
        all: JSON.parse(JSON.stringify(action.payload))
      };
      break;
    case DOCUMENTS_UPDATE_LIST:
      state = {
        ...state,
        all: [...state.all, JSON.parse(JSON.stringify(action.payload))]
      };
      break;
    case DOCUMENTS_CREATE:
      state = {
        ...state,
        all: [...state.all, action.payload]
      };
      break;
    case DOCUMENTS_DELETE:
      state = {
        ...state,
        all: state.all.filter(document => document._id !== action.payload._id)
      };
      break;
    case DOCUMENTS_UPDATE_CORRECTION:
      newState = state;

      newDetail = {
        ...newState.detail,
        corrections: {
          ...newState.detail.corrections,
          [action.payload.correctionType]: newState.detail.corrections[
            action.payload.correctionType
          ].filter(correction => correction._id !== action.payload.correctionId)
        }
      };

      state = {
        ...state,
        detail: newDetail
      };
      break;
    case DOCUMENTS_REPLACE_WORD:
      newState = state;

      newDetail = {
        ...newState.detail,
        corrections: {
          ...newState.detail.corrections,
          [action.payload.feedback.correctionType]: newState.detail.corrections[
            action.payload.feedback.correctionType
          ].filter(
            correction =>
              correction._id !== action.payload.feedback.correctionId
          )
        },
        contentState: action.payload.editorState
      };

      state = {
        ...state,
        detail: newDetail
      };

      break;
    case SET_TITLE:
      // console.log(action.payload.title);

      state = {
        ...state,
        detail: state.detail
          ? { ...state.detail, title: action.payload.title }
          : null,
        all: state.all.map(text => {
          if (String(text._id) === String(action.payload._id)) {
            text.title = action.payload.title;
          }

          return text;
        })
      };
      break;
    case USER_DISABLE_RULE:
      newDetail = state.detail;

      // console.log(newDetail.corrections.deviations, action.payload);

      newDetail.corrections.deviations =
        newDetail.corrections.deviations.filter(
          deviation => deviation.id !== action.payload
        );

      state = {
        ...state,
        detail: state.detail
          ? {
              ...state.detail,
              corrections: {
                ...state.detail.corrections,
                deviations: newDetail.corrections.deviations
              }
            }
          : null
      };

      break;

    default:
      return state;
  }

  return state;
};

export default documentsReducer;
