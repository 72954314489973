import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    // marginRight: '20px',
    marginBottom: '40px'
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: '700'
    // marginBottom: '20px'
  },
  description: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    color: theme.palette.text.secondary,
    marginTop: '5px'
  },
  titleContainer: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important',
    borderBottom: 'none!important',
    minHeight: '80px',
    borderRadius: '15px 15px 0 0'
  },
  actionsContainer: {
    display: 'flex',
    alingItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
    padding: '0.5rem 0',
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important',
    borderBottom: 'none!important',
    minHeight: '5px',
    borderRadius: '15px 15px 0 0'
  },
  formContainer: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important',
    borderBottom: 'none!important'
    // marginBottom: '10px',
    // minHeight: '600px'
  },
  languageContainer: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important',
    borderRadius: '15px',
    minHeight: '80px'
  },
  languageTitle: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    marginBottom: '30px',
    fontWeight: '700'
  },
  input: {
    fontFamily: ['Inter'].join(','),
    marginBottom: '40px!important'
  },
  submitContainer: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important',
    minHeight: '80px',
    borderRadius: '0 0 15px 15px '
  },
  resultsContainer: {
    height: '41.5rem',
    paddingTop: '1.5rem',
    paddingBottom: theme.breakpoints.up('sm') ? '1.5rem' : '1.0rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important',
    borderRadius: '0 0 15px 15px',
    backgroundColor:
      theme.palette.mode === 'light' ? 'rgb(255 255 255 / 69%)' : '#252525',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette.background.darkGrey}`,
      borderRadius: '20px',
      border: '3px solid transparent'
    }
  },
  resultsContainerWait: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  messageContainer: {
    width: '100%',
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgb(255 255 255 / 69%)'
        : 'rgba(195,185,222,0.2)',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1.5px solid #E7EBF0!important'
        : '1.5px solid #3a3541!important',
    borderRadius: '.375rem',
    minHeight: '80px',
    marginBottom: '20px'
  },
  titleMessage: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    fontWeight: '500',
    marginBottom: '5px'
  },
  descriptionMessage: {
    fontSize: '0.775rem',
    lineHeight: '1.25rem',
    color:
      theme.palette.mode === 'light' ? 'rgb(100 116 139)' : 'rgb(171,171,171)'
  },
  descriptionResult: {
    fontSize: '0.925rem',
    lineHeight: '1.55rem',
    textAlign: 'justify',
    color: theme.palette.mode === 'light' ? 'rgb(28 28 28) ' : '#E7EBF0'
  },
  fileContainer: {
    width: '100%',
    margin: '0 auto',
    padding: '20px',
    height: 'auto',
    overflowHidden: 'hidden',
    border: '0.5px solid #b8b0b0',
    borderRadius: '5px'
  },
  fileLabel: {
    margin: '0',
    padding: '10px',
    width: 'auto',
    maxWidth: '300px',
    height: 'auto',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: '5px',
    '&:hover': {
      opacity: '0.8'
    },
    backgroundColor:
      theme.palette.mode === 'light'
        ? `${theme.palette.primary.main}`
        : `${theme.palette.secondary.main}`,
    color:
      theme.palette.mode === 'light'
        ? `${theme.palette.text.white}`
        : `${theme.palette.text.black}`,
    fontSize: '14px'
  },
  file: {
    '[type=file]': {
      display: 'none'
    }
  }
}));

export default useStyles;
