import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  small: {
    backgroundColor: theme.palette.common.tooltip
  },
  tooltip: {
    backgroundColor: `${theme.palette.common.tooltip}!important`,
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  arrow: {
    color: theme.palette.common.tooltip + ' !important'
  },
  info: {
    textAlign: 'center',
    font: 'normal normal 400 12px/14px Inter !important',
    lineHeight: '22px'
  }
}));

export default Styles;
