import React, { useContext } from 'react';
import { InfoInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';

const Info = ({ children }: InfoInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box style={{ width: '100%', marginTop: '24px', marginBottom: '24px' }}>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: isMobile ? '12px' : '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '160%',
          color:
            themeMode.mode === 'dark' ? theme.palette.text.primary : '#39274B'
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default Info;
