import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      width: '100%',
      height: '100vh',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      [theme.breakpoints.down(991)]: {
        gridTemplateColumns: '1fr'
      },
      [theme.breakpoints.down(480)]: {
        height: 'auto'
      }
    }
  };
});

export default useStyles;
