import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  iconContainer: {
    // marginRight: '24px',
    marginTop: '2px',
    marginRight: '16px',
    flexShrink: 0
  },
  icon: {
    // width: '48px',
    width: '57px',
    borderRadius: '50%'
  },
  item: {
    minHeight: '265px',
    paddingBottom: '20px'
  },
  person: {
    font: ' normal normal 500 14px Inter!important',
    lineHeight: '135%!important',
    color: '#410A85',
    // color:
    //   theme.palette.mode === 'light' ? '#410A85' : theme.palette.secondary.main,
    marginTop: '12px!important',
    mixBlendMode: 'normal'
  },
  role: {
    font: ' normal normal 400 14px Inter!important',
    lineHeight: '185% !important',
    color: '#410A85',
    // color:
    //   theme.palette.mode === 'light' ? '#410A85' : theme.palette.secondary.dark,
    marginTop: '8px',
    mixBlendMode: 'normal'
  },
  description: {
    // textAlign: 'justify',
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: 'normal!important',
    fontSize: '14px!important',
    lineHeight: '150%!important',
    color: '#655679'
    // color: theme.palette.mode === 'light' ? '#655679' : '#838594'
  }
}));

export default Styles;
