import { createGlobalStyle } from 'styled-components';

const GlobalStyleDark = createGlobalStyle`  
  * {  
    margin: 0;
    padding: 0; 
    outline: 0;
    box-sizing: border-box;
    font-family: 'Inter'!important;
  }

  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  } 

  //html {
  //  font-size: 62.5%;
  //  @media(max-width: 1400px) {
  //    font-size: 60%;
  //  }
  //  @media(max-width: 1300px) {
  //    font-size: 55%;
  //  }
  //}
  html,
  body,
  #root {
    min-height: 100%;
    background-color: #252525;
  }

   body {
    min-height: 100%;
    background-color: #252525;
    -webkit-font-smoothing: antialiased !important;
  }

  body::-webkit-scrollbar {
    width: 5px;               
  }

  body::-webkit-scrollbar-track {
    background: 'transparent'      
  }

  body::-webkit-scrollbar-thumb {
    background-color: #757575;
    border-radius: 20px;
    border: 3px solid transparent;
  }

  button {
    cursor: pointer;
  }

  input,
  button,
  textarea {
    appearance: none;
    resize: none;
  }

  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }
`;

export default GlobalStyleDark;
