import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    padding: '40px',
    paddingTop: '0!important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  message: {
    marginTop: '30px',
    textAlign: 'center'
  }
}));

export default Styles;
