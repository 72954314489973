import React from 'react';

const Logo = () => {
  return (
    <svg viewBox="0 0 142.02 177.42" width={25} height={25}>
      <g fill="#0fedb2">
        <path d="M142,70A71,71,0,1,0,71,142v35.4A115.2,115.2,0,0,0,142,72c0-.34,0-.68,0-1s0-.69,0-1M71,107.8a27.1,27.1,0,0,1-27.1-27.1h54.2A27.1,27.1,0,0,1,71,107.8" />
      </g>
    </svg>
  );
};

export default Logo;
