import { WORD_LIMIT } from '../../../../../../utils/wordLimit';

const benefits = [
  {
    title: 'Mais dicas',
    description:
      'Vá além da Gramática! Garanta uma escrita clara, concisa, forte e original com sugestões avançadas da Clarice.ai PRO.',
    // description: `Receba ${WORD_LIMIT.PAID.TIPS.MULTI} mais dicas de estilo em relação aos usuários free. São mais de ${WORD_LIMIT.PAID.TIPS.QUANTITY} Dicas de Estilo na versão PRO`,
    icon: 'more-tips'
  },
  {
    title: 'Mais Palavras',
    description: `Usuários PRO podem revisar ${WORD_LIMIT.PAID.MORE} mais palavras ao mesmo tempo. São ${WORD_LIMIT.PAID.STRING} palavras por texto.`,
    icon: 'more-words'
  },
  {
    title: 'Mais textos',
    description:
      'Guarde ilimitados textos simultâneos e volte para revisá-los quando quiser.',
    // // 'Guarde 100 textos simultâneos e volte para revisá-los quando quiser.',
    icon: 'more-texts'
  }
];

export default benefits;
