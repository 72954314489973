import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      marginBottom: '3.2rem',
      maxWidth: '45.6rem'
    },
    title: {
      fontWeight: 'bold',
      fontSize: '2.4rem',
      lineHeight: '125%',
      color: '#39274b',
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center'
      }
    },
    description: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '1.6rem',
      lineHeight: '150%',
      color: '#655679',
      marginTop: '0.8rem',
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center'
      }
    }
  };
});

export default useStyles;
