import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      textAlign: 'right',
      marginTop: '8px'
    },
    button: {
      marginLeft: '8px!important'
    }
  };
});

export default useStyles;
