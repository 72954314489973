import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
    // height: '100%',
    backdropFilter: 'blur(3px)',
    paddingLeft: '20px',
    paddingRight: '20px',
    maxHeight: '95%',
    overflowY: 'auto'
  }
}));

export default useStyles;
