import React, { useEffect } from 'react';
import { IntercomButtonInterface } from './interface';
import useStyles from './style';
import { useMediaQuery } from '@mui/material';
import { useReduxState } from '../../../../hooks/useReduxState';

const IntercomButton = ({}: IntercomButtonInterface) => {
  const classes = useStyles();

  const { user } = useReduxState();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .intercom-lightweight-app-launcher {
        bottom: ${isMobile ? '55px!important' : '20px!important'};
      }
    `;

    if (
      // intercomButton &&
      // intercomButton.style &&
      isMobile &&
      location?.pathname.includes('/edit-text')
    ) {
      if (document.head.querySelector('.intercom-lightweight-app-launcher')) {
        document.head.removeChild(style);
      }

      if (document.head) {
        document.head.appendChild(style);
      }

      return () => {
        if (document.head) {
          document.head.removeChild(style);
        }
      };
    } else {
      const div = document.head.querySelector(
        '.intercom-lightweight-app-launcher'
      );

      if (document.head && div) {
        document.head.removeChild(div);
      }
    }
  }, [location, isMobile, window]);

  // useEffect(
  //   () => {
  //     // @ts-ignore
  //     if (window.intercomSettings && user.email) {
  //       // @ts-ignore
  //       window.intercomSettings = {
  //         api_base: 'https://api-iam.intercom.io',
  //         app_id: 'awj7l5s9',
  //         name: user.name, // Nome completo
  //         email: user.email // Endereço de e-mail
  //       };
  //
  //       setTimeout(() => {
  //         if (user?.firstAccess) {
  //           // @ts-ignore
  //           window.Intercom('boot', {
  //             app_id: 'awj7l5s9'
  //           });
  //         }
  //       }, 2000);
  //     }
  //   },
  //   // @ts-ignore
  //   [user, window.intercomSettings]
  // );

  return (
    <div id="intercom-container">
      <script async src="https://widget.intercom.io/widget/awj7l5s9"></script>
    </div>
  );
};

export default IntercomButton;
