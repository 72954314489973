import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import ModalAccount from './components/modalAccount';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { updateProjectActive } from '../../../../../../stories/actions/user';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';

const Account = ({ toggle }: any) => {
  const themeMode = useContext(ThemeContext);
  const classes = useStyles(themeMode);
  const theme = useTheme();

  const { user } = useReduxState();

  const [modalVisible, setModalVisible] = useState(false);

  const nameFormatted =
    (user.name &&
      user.name.split(' ')[0] +
        ' ' +
        ((user.name && user.name.split(' ')[1]) || '')) ||
    '';

  return (
    <div style={{ position: 'relative' }}>
      <Box
        sx={{
          // marginLeft: '10px',
          width: '210px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingRight: '5px',
          paddingLeft: '7px',
          '&:hover': {
            background:
              themeMode.mode === 'light' ? 'rgb(241 241 241)' : 'rgb(72 80 78)',
            borderRadius: '9px'
          }
        }}
        onClick={() => setModalVisible(!modalVisible)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.4rem'
          }}
        >
          <Avatar
            src={user.detail?.picture}
            sx={{
              backgroundColor:
                themeMode.mode === 'light'
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main,
              height: '2.0rem',
              width: '2.0rem'
            }}
          ></Avatar>
          <Typography
            style={{
              fontSize: '13.9px',
              marginBottom: '2px!important',
              fontWeight: '500',
              color: themeMode.mode === 'light' ? '#303030' : '#fff',
              justifyContent: 'center',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {nameFormatted.length <= 14
              ? nameFormatted
              : user.name && user.name.split(' ')[0]}
            <span
              style={
                user.isSubscriber
                  ? {
                      background: 'rgb(255, 172, 75)',
                      color: '#39274B',
                      font: 'normal normal 600 10px/12px Inter',
                      letterSpacing: '1px',
                      padding: '4px 4px',
                      marginLeft: 5,
                      borderRadius: 4
                    }
                  : {
                      background: '#e3d0fb',
                      color: theme.palette.primary.main,
                      font: 'normal normal 600 10px/12px Inter',
                      letterSpacing: '1px',
                      padding: '4px 4px',
                      marginLeft: 5,
                      borderRadius: 4
                    }
              }
            >
              {user.isSubscriber ? 'PRO' : 'FREE'}
            </span>
          </Typography>
        </Box>
        {modalVisible ? <ExpandLessRounded /> : <ExpandMoreRounded />}
      </Box>
      {modalVisible && (
        <ModalAccount
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          toggle={toggle}
        ></ModalAccount>
      )}
    </div>
  );
};

export default Account;
