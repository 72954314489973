import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      paddingTop: '3.2rem',
      paddingBottom: '12.1rem',
      fontSize: '62.5%!important',
      '& > div': {
        margin: '0 auto',
        width: '100%',
        maxWidth: '52rem'
      },
      [theme.breakpoints.down(480)]: {
        padding: '4rem 1.5rem'
      },
      [theme.breakpoints.down(1400)]: {
        fontSize: '60%!important'
      },
      [theme.breakpoints.down(1300)]: {
        fontSize: '50%!important'
      }
    },
    form: {
      paddingTop: '3.2rem'
    },
    link: {
      display: 'block',
      fontWeight: '500',
      fontSize: '1.3rem',
      lineHeight: '120%',
      color: '#410a85',
      textDecoration: 'none',
      fontFamily: 'Inter',
      transition: 'color 0.3s',
      borderBottom: '1px dotted #43b1b0',
      '&:hover': {
        color: '#09b386'
      }
    },
    captcha: {
      marginTop: 15,
      marginBottom: 25,
      '& div>div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100% !important'
      }
    }
  };
});

export default useStyles;
