import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  icon: {
    color: '#FFFFFF',
    padding: '8px',
    width: '40px',
    height: '40px'
  }
}));

export default Styles;
