import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    // marginRight: '20px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      // marginRight: '0px',
    }
  },
  title: {
    fontSize: '28px',
    fontWeight: '700'
  },
  creditCards: {
    // width: '250px',
    height: '160px',
    padding: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1.5px solid #E7EBF0!important'
        : '1.5px solid #3a3541!important',
    backgroundColor: theme.palette.mode === 'light' ? '#f7f7f7' : '#303030',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  p: {
    fontSize: '14px',
    fontWeight: '700',
    marginTop: '0'
  },
  numberCredits: {
    margin: '5px 0',
    fontSize: '28px',
    fontWeight: '700'
  },
  span: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '15px',
    color: theme.palette.mode === 'light' ? '#4A4A4A' : '#d7d7d7'
  },
  plan: {
    // maxWidth: '900px',
    // height: '365px',
    padding: '3rem',
    border:
      theme.palette.mode === 'light'
        ? '1.5px solid #E7EBF0!important'
        : '1.5px solid #3a3541!important',
    backgroundColor: theme.palette.mode === 'light' ? '#f7f7f7' : '#303030',
    borderRadius: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem',
      width: '100%',
      height: 'max-content'
    }
  },
  planTitle: {
    fontSize: '28px',
    fontWeight: '700'
  },
  planSubtitle: {
    fontSize: '22px',
    fontWeight: '700'
  },
  planButtonsDiv: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    gap: '1rem',
    marginTop: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: '0.5rem'
    }
  },
  cancel: {
    maxWidth: '910px',
    height: '200px',
    margin: '1rem 0'
  },
  cancelTitle: {
    fontSize: '22px',
    fontWeight: '700'
  },
  cancelSubtitle: {
    fontSize: '14px',
    margin: '0 0 1rem 0'
  },
  linear: {
    width: '100%'
  },
  progressText: {
    font: 'normal normal 400 18px/21px Inter',
    marginBottom: '15px!important'
  }
}));

export default Styles;
