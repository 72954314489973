import React from 'react';
import useStyles from './styles';
import TestimonialPersonIcon from '../../../../../../../../../assets/images/payment/testimonial-person.jpeg';
import TestimonialPersonTainaIcon from '../../../../../../../../../assets/images/payment/testimonial-person-taina.jpeg';
import TestimonialPersonJoaquimIcon from '../../../../../../../../../assets/images/payment/testimonial-person-joaquim.jpeg';
import TestimonialSlider from '../TestimonialSlider';
import { Box } from '@mui/material';

const TestimonialContainer = () => {
  const classes = useStyles();

  const testimonials = [
    {
      id: '0001',
      icon: TestimonialPersonIcon,
      name: 'Thiago Sarinho',
      role: 'Educador e Empresário',
      description: (
        <>
          Por muito tempo, converter ideias em texto sempre foi um desafio, a
          desleixa impera nessas horas.
          <br />
          <br />
          Após fazer o upgrade para a Clarice.ai PRO, sinto a segurança e a
          tranquilidade que os deslizes textuais não irão comprometer a
          qualidade do meu trabalho. Não vivo mais sem!
        </>
      )
    },
    {
      id: '0002',
      icon: TestimonialPersonTainaIcon,
      name: 'Tayná Carvalho',
      role: 'Copywriter',
      description: (
        <>
          Com a Clarice.ai PRO consigo revisar meus textos mais rápido, recebo
          sugestões para construir frases melhores, e o recurso de palavras
          repetidas tem deixado a minha escrita mais concisa. Amo!
        </>
      )
    },
    {
      id: '0003',
      icon: TestimonialPersonJoaquimIcon,
      name: 'Joaquim Oliveira',
      role: 'Copywriter',
      description: (
        <>
          Um novo recorde: foram 3 páginas de vendas entregues em uma semana.
          <br />
          <br />
          Agradeço a Clarice.ai PRO que me ajudou a revisar o material em menos
          tempo e acelerar a entrega dos trabalhos. Essa é uma ferramenta
          obrigatória para qualquer redator ou copywriter que deseja acelerar a
          entrega dos seus projetos com qualidade, evitando erros que destroem a
          credibilidade dos seus textos.
        </>
      )
    }
    // {
    //   id: '0004',
    //   icon: TestimonialPersonTainaIcon,
    //   name: 'Nilmara Bernardes',
    //   role: 'Copywriter',
    //   description: (
    //     <>
    //       A Clarice.ai PRO proporciona dicas de escrita, correções e revisões
    //       que antes passavam despercebidas no dia a dia. A assinatura vale muito
    //       a pena pelo tempo que economizo e a segurança que me proporciona na
    //       hora de escrever.
    //     </>
    //   )
    // }
  ];

  return (
    <Box className={classes.container}>
      <TestimonialSlider testimonials={testimonials} />

      {/* <TestimonialItem */}
      {/*  testimonialPersonIcon={TestimonialPersonIconAnaBittencourt} */}
      {/*  person="Ana Bittencourt" */}
      {/*  role={'Jornalista e Redatora freelancer'} */}
      {/*  profileImage="Profile Image" */}
      {/*  description="Hoje sinto uma segurança muito maior de entregar meus textos com qualidade e livre de erros. Foi a melhor escolha que fiz em 2021." */}
      {/* /> */}
    </Box>
  );
};

export default TestimonialContainer;
