import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './style';
import { ThemeContext } from '../../../../../../../../../../utils/context/ThemeContext';

type TextMetricsItemProps = {
  title?: any;
  type?: any;
  total?: any;
  open?: any;
  updateStatusCollapse?: any;
  last?: any;
};

const TextMetricsItem = ({
  title,
  type,
  total,
  open,
  updateStatusCollapse,
  last = false
}: TextMetricsItemProps) => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      className={last ? classes.lastBox : classes.box}
    >
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontWeight: '600',
          fontSize: '12px',
          textAlign: 'left',
          marginRight: '5px'
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontWeight: '600',
          fontSize: '12px',
          color: themeMode.mode === 'light' ? '#410a85bd' : '#09B286!important'
        }}
      >
        {total}
      </Typography>
      {type === 'word' && (
        <span className={classes.tag} onClick={() => updateStatusCollapse()}>
          {open ? '-' : '+'}
        </span>
      )}
    </Box>
  );
};

export default TextMetricsItem;
