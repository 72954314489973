import React, { useContext, useState } from 'react';
import useStyles from './style';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import ModalDeleteProject from '../../../../../components/Modal/ModalDeleteProject';

const ProjectsCard = ({ id, title, description, onEdit, date }: any) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const [modal, setModal] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <>
      <Box className={classes.root}>
        <Box
          style={{
            maxWidth: '100%',
            marginTop: '1rem',
            cursor: 'default',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            width: '300px',
            height: '123px',
            background: themeMode.mode === 'light' ? '#FFFFFF' : '#303030',
            border: themeMode.mode === 'light' ? '1px solid #E5E3E8' : 'none',
            borderRadius: '13px',
            flex: 'none',
            order: '0',
            flexGrow: '1'
          }}
          // onClick={onSelect}
        >
          <Box className={classes.content} style={{ maxWidth: '100%' }}>
            <Typography
              gutterBottom
              style={{
                color: themeMode.mode === 'light' ? '#39274B' : '#fff',
                fontFamily: 'Inter',
                lineHeight: '125%',
                fontSize: '20px',
                fontWeight: '700',
                flex: 'none',
                order: 0,
                flexGrow: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                marginBottom: '10px'
              }}
            >
              {title === '' ? 'Sem título' : title}
            </Typography>
          </Box>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              borderTop:
                themeMode.mode === 'light'
                  ? '1px solid #E5E3E8'
                  : '1px solid #757575',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main
            }}
          >
            <Box
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                width: '100%',
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                borderRight:
                  themeMode.mode === 'light'
                    ? '1px solid #E5E3E8'
                    : '1px solid #757575',
                alignItems: 'center',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    themeMode.mode === 'light' ? '#E5E3E8' : '#414141ba',
                  borderRadius: '0px 0px 0px 15px'
                }
              }}
              onClick={() => onEdit(id)}
            >
              Editar
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    themeMode.mode === 'light' ? '#E5E3E8' : '#414141ba',
                  borderRadius: '0px 0px 15px 0px'
                }
              }}
              onClick={() => setModal(true)}
            >
              Excluir
            </Box>
          </div>
        </Box>
      </Box>
      <ModalDeleteProject
        idProject={id}
        open={modal}
        onClose={() => setModal(false)}
      ></ModalDeleteProject>
    </>
  );
};

export default ProjectsCard;
