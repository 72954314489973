import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';

const Action = ({}: ActionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box style={{ width: '100%', marginBottom: isMobile ? '24px' : '0px' }}>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          cursor: 'pointer!important',
          fontFamily: 'Inter',
          backgroundColor: '#09B286',
          textTransform: 'none',
          padding: '8px 24px 8px 24px',
          gap: '10px',
          borderRadius: '6px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.dark}`
          },
          [theme.breakpoints.down('sm')]: {
            // width: '100%'
          },
          fontWeight: '600',
          color: '#fff'
        }}
        onClick={() => {
          ctx.modal.news.toggle();
        }}
      >
        Fechar
      </Button>
    </Box>
  );
};

export default Action;
