import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Modal,
  Button,
  Paper,
  useTheme,
  InputBase
} from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import {
  disabledRulesFormatted,
  enableRule
} from '../../../../../stories/actions/user';
import { useReduxState } from '../../../../../hooks/useReduxState';
import useStyles from './style';
import DisabledRuleItem from './components/DisabledRuleItem';
import ModalCustom from '../ModalCustom';
import DisabledRuleNotFound from './components/DisabledRuleNotFound';

type ModalDisabledRulesProps = {};

const ModalDisabledRules = ({}: ModalDisabledRulesProps) => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const dispatch = useAppDispatch();

  const { user } = useReduxState();

  const disabledRules: any = user.disabledRules;

  const [form, setForm] = useState<any>({ word: '' });

  const [loading, setLoading] = useState(false);

  const [loaded, setLoaded] = useState<any>(undefined);

  const [rules, setRules] = useState([false]);

  const onClose = async () => {
    setForm({ word: '' });

    setLoading(false);

    ctx.modal.disabledRules.toggle();
  };

  const onEnable = async (id: string) => {
    const cb = {
      success: () => {
        setLoading(false);

        setRules(rules.filter((rule: any) => rule && rule.id !== id));
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(enableRule(id, cb));
  };

  useEffect(() => {
    if (ctx.modal.disabledRules.open) {
      if (!loaded) {
        const cb = {
          success: formatted => {
            setRules(formatted);

            setTimeout(() => {
              setLoaded(true);
            }, 1000);
          },
          error: () => {
            setLoaded(true);
          }
        };

        dispatch(disabledRulesFormatted(disabledRules, cb));
      }
    } else {
      setLoaded(false);
    }
  }, [ctx.modal.disabledRules.open, loaded]);

  return (
    <>
      <ModalCustom
        open={ctx.modal.disabledRules.open}
        toggle={onClose}
        containerClass={classes.root}
      >
        <h2 className={classes.title}>Regras Desativadas</h2>

        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexDirection={'column'}
          mt={'8px'}
          width={'100%'}
        >
          {!loaded && <DisabledRuleNotFound />}

          {loaded && rules.length === 0 && (
            <DisabledRuleItem alternative={true} />
          )}

          {loaded &&
            rules.length > 0 &&
            rules.map(
              (el: any, idx) =>
                el && (
                  <DisabledRuleItem
                    key={idx}
                    el={el}
                    title={el.title}
                    loading={loading}
                    onEnable={onEnable}
                    divider={rules.length !== idx + 1}
                  />
                )
            )}
        </Box>
      </ModalCustom>
    </>
  );
};

export default ModalDisabledRules;
