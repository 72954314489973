import React, { useContext, useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import SearchBar from './components/SearchBar';
import { useReduxState } from '../../../../../hooks/useReduxState';
import ProjectNotFound from './components/ProjectNotFound';
import ProjectsCard from './components/ProjectsCard';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import ModalUpdateProject from './components/ModalUpdateProject';

const ListProjects = () => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const params = useParams();
  const navigate = useNavigate();

  const ctx = useContext(GlobalContext);

  const { projects } = useReduxState();

  const { allProjects } = projects;

  const [search, setSearch] = useState('');

  const allProjectsActive = useMemo(() => {
    return allProjects.filter(project => !project?.status?.inactive);
  }, [projects]);

  const projectsFilter = useMemo(() => {
    const lowerSearch = search.toLowerCase();

    return allProjectsActive.filter(project => {
      return project.title.toLowerCase().includes(lowerSearch);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, projects]);

  const [modalUpdateProject, setModalUpdateProject] = useState({
    open: false,
    id: ''
  });

  const toggleModalUpdateProject = (id: string) => {
    setModalUpdateProject({ open: !modalUpdateProject.open, id });
  };

  return (
    <div className={classes.root}>
      {!params.type && (
        <>
          <Box>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'transparent',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'center',
                  width: '949px',
                  // height: '148px',
                  // padding: '10px 20px',
                  gap: '2rem',
                  [theme.breakpoints.down(1250)]: {
                    width: '700px',
                    gap: '0.5rem'
                  },
                  [theme.breakpoints.down(1000)]: {
                    height: 'max-content',
                    width: '100%',
                    flexDirection: 'column',
                    gap: '0.5rem'
                  }
                }}
              >
                <p className={classes.title}>Projetos</p>
              </Box>
              <SearchBar value={search} setValue={setSearch}></SearchBar>
              <Box className={classes.templates}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={10}>
                  <Grid
                    container
                    spacing={{ xs: 0, sm: 0, md: 2, lg: 2, xl: 2 }}
                  >
                    {search !== '' && projectsFilter.length === 0 && (
                      <>
                        <ProjectNotFound>
                          Nenhum projeto encontrado...
                        </ProjectNotFound>
                      </>
                    )}

                    {search === '' && allProjectsActive.length === 0 && (
                      <>
                        <ProjectNotFound>
                          Nenhum projeto adicionado até o momento...
                        </ProjectNotFound>
                      </>
                    )}
                  </Grid>
                </Grid>

                <Box
                  sx={{
                    display: 'grid',
                    gap: '1.5rem',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    [theme.breakpoints.down(1250)]: {
                      gridTemplateColumns: '1fr 1fr'
                    },
                    [theme.breakpoints.down(900)]: {
                      gridTemplateColumns: '1fr',
                      gap: '0'
                    }
                  }}
                >
                  {search !== '' &&
                    projectsFilter.length > 0 &&
                    projectsFilter?.map(project => (
                      <ProjectsCard
                        key={project._id}
                        id={project._id}
                        title={project.title}
                        description={project.description}
                        onEdit={toggleModalUpdateProject}
                      />
                    ))}

                  {search === '' &&
                    allProjectsActive?.length > 0 &&
                    allProjectsActive?.map(project => (
                      <ProjectsCard
                        key={project._id}
                        id={project._id}
                        title={project.title}
                        description={project.description}
                        onEdit={toggleModalUpdateProject}
                      />
                    ))}
                </Box>
              </Box>
            </div>
          </Box>

          <ModalUpdateProject
            id={modalUpdateProject.id}
            open={modalUpdateProject.open}
            toggle={toggleModalUpdateProject}
          />
        </>
      )}
    </div>
  );
};

export default ListProjects;
