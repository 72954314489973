import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
      backdropFilter: 'blur(3px)'
    },
    container: {
      position: 'relative',
      backgroundColor:
        theme.palette.mode === 'light'
          ? `${theme.palette.background.paper}`
          : `${theme.palette.background.default}`,
      borderRadius: '13px',
      maxWidth: '590px',
      outline: 'none',
      padding: '40px 58px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '20px 29px'
      }
    },
    close: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      bottom: 'auto',
      left: 'auto'
    }
  };
});

export default useStyles;
