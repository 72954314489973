import React, { useState } from 'react';
import useStyles from './style';
import TextMetricsItem from './component/TextMetricsItem';
import { Box, Collapse } from '@mui/material';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';

const TextMetrics = () => {
  const classes = useStyles();

  const { editor, documents } = useReduxState();

  const text = documents.detail?.fullText || '';

  const [openCollapse, setOpenCollapse] = useState(false);

  const score = {
    characters: documents.detail?.fullText.replace(/\n+/g, '').length,
    words: documents.detail?.fullText
      .split(/\s+/)
      .filter(el => el.length > 0 && el !== '—').length,
    sentences: documents.detail?.fullText
      .split(/[!?.]\s/)
      .filter(el => el.length > 0).length,
    paragraphs: documents.detail?.fullText
      .split(/\n+/)
      .filter(el => el.length > 0).length
  };

  const updateStatusCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <>
      <Box mt={'12px'} mb={'8px'}>
        <Box className={classes.container}>
          <TextMetricsItem
            title="Palavras"
            type="word"
            total={score.words}
            open={openCollapse}
            updateStatusCollapse={updateStatusCollapse}
            last={!openCollapse}
          />

          <Collapse in={openCollapse}>
            <TextMetricsItem
              title="Caracteres"
              type="character"
              total={score.characters}
            />
            <TextMetricsItem
              title="Frases"
              type="sentence"
              total={score.sentences}
            />
            <TextMetricsItem
              title="Parágrafos"
              type="paragraph"
              total={score.paragraphs}
              last
            />
          </Collapse>
        </Box>
      </Box>
    </>
  );
};

export default TextMetrics;
