import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, LinearProgress, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useReduxState } from '../../../../../hooks/useReduxState';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { getCredits } from '../../../../../stories/actions/credits';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import {
  checkoutPaymentSuccess,
  openPortal
} from '../../../../../stories/actions/payment';
import moment from 'moment';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { getChurnKeyHash, signOut } from '../../../../../stories/actions/auth';

const CreditsBilling: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const themeMode = useContext(ThemeContext);

  const [isCheckoutSuccessLoading, setIsCheckoutSuccessLoading] =
    useState(false);

  const dispatch = useAppDispatch();

  const { credits, user } = useReduxState();

  const ctx = useContext(GlobalContext);

  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const [detailPlan, setDetailPlan] = useState<any>(undefined);

  const onOpenPortal = (cb: any) => {
    dispatch(openPortal(cb));
  };
  const handleOpenPortal = () => {
    setIsLoadingPortal(true);
    onOpenPortal(() => setIsLoadingPortal(false));
  };

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCredits());
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user.isSubscriber && user.subscription) {
      const isTrial = user.subscription.status === 'trialing';
      const isCanceled = !!user.subscription.canceled_at;

      const today = moment();

      const daysUntilNextBilling = moment(
        user.subscription.current_period_end
      ).diff(today, 'days');

      const hoursUntilNextBilling =
        moment(user.subscription.current_period_end).diff(today, 'hours') -
        daysUntilNextBilling * 24;

      setDetailPlan({
        isTrial,
        isCanceled,
        daysUntilNextBilling,
        hoursUntilNextBilling
      });
    }
  }, [user]);

  useEffect(() => {
    if (user.email) {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');

      if (sessionId) {
        setIsCheckoutSuccessLoading(true);

        const cb = {
          success: () => {
            setIsCheckoutSuccessLoading(false);

            navigate('/account/credits-billings');
          },
          error: () => {
            setIsCheckoutSuccessLoading(false);

            navigate('/account/credits-billings');
          }
        };

        dispatch(checkoutPaymentSuccess(sessionId, user.email, cb));
      }
    }
  }, [window.location.search, user.email]);

  const custom = {
    totalCredits: {
      quantity: credits.bonus.words.total + (credits?.plan?.words?.total || 0),
      percentage:
        ((credits.bonus.words.used + (credits?.plan?.words?.used || 0)) * 100) /
        (credits.bonus.words.total + (credits?.plan?.words?.total || 0))
    },
    planCredits: {
      quantity: credits?.plan?.words?.total || 0,
      percentage:
        (credits?.plan?.words?.used * 100) / credits?.plan?.words?.total
    },
    bonusCredits: {
      quantity: credits.bonus.words.total,
      percentage: (credits.bonus.words.used * 100) / credits.bonus.words.total
    }
  };

  const formattedPercentage = percentage => {
    if (percentage) {
      return percentage.toFixed(2).replace('.', ',');
    }

    return '0,00';
  };

  return (
    <Box className={classes.root}>
      <h2 className={classes.title}>Créditos e Cobrança</h2>
      <p style={{ fontSize: '16px' }}>Créditos disponíveis</p>
      <Grid
        container
        xs={12}
        sm={12}
        md={12}
        lg={8}
        xl={8}
        // gap={theme.breakpoints.down('sm') ? 2 : 10}
        // style={{
        //   margin: '1rem 0'
        // }}
        rowSpacing={1}
        columnSpacing={{ xs: 0, sm: 0, md: 3, lg: 3, xl: 3 }}
      >
        {user?.options?.plan?.type !== 'Assinatura Mensal | Profissional' &&
          user?.options?.plan?.type !== 'Assinatura Anual | Profissional' && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <Box className={classes.creditCards}>
                  <p
                    className={classes.p}
                    style={{
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.text.secondary}`
                          : `${theme.palette.text.white}`
                    }}
                    aria-label="teste"
                  >
                    Total de créditos
                  </p>
                  <h2
                    className={classes.numberCredits}
                    style={{
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`
                    }}
                  >
                    {custom.totalCredits.quantity}
                  </h2>
                  <p className={classes.span}>
                    {formattedPercentage(custom.totalCredits.percentage)}% de
                    créditos utilizados
                  </p>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                <Box className={classes.creditCards}>
                  <p
                    className={classes.p}
                    style={{
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.text.secondary}`
                          : `${theme.palette.text.white}`
                    }}
                    aria-label="teste"
                  >
                    Créditos do plano
                  </p>
                  <h2
                    className={classes.numberCredits}
                    style={{
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`
                    }}
                  >
                    {custom.planCredits.quantity}
                  </h2>
                  <p className={classes.span}>
                    {formattedPercentage(custom.planCredits.percentage)}% de
                    créditos utilizados do plano
                  </p>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={4}
                xl={4}
                style={{ marginTop: '16px' }}
              >
                {' '}
                <Box className={classes.creditCards}>
                  <p
                    className={classes.p}
                    style={{
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.text.secondary}`
                          : `${theme.palette.text.white}`
                    }}
                    aria-label="teste"
                  >
                    Créditos de bônus
                  </p>
                  <h2
                    className={classes.numberCredits}
                    style={{
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`
                    }}
                  >
                    {custom.bonusCredits.quantity}
                  </h2>
                  <p className={classes.span}>
                    {user?.options?.plan?.active
                      ? ` ${formattedPercentage(
                          custom.bonusCredits.percentage
                        )}% de créditos bônus utilizados`
                      : ` ${formattedPercentage(
                          custom.bonusCredits.percentage
                        )}% de créditos bônus utilizados`}
                  </p>
                </Box>
              </Grid>
            </>
          )}

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box className={classes.plan} style={{ marginTop: '16px' }}>
            <h2 className={classes.planTitle} aria-label="teste">
              Plano
            </h2>
            <div
              style={{
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                margin: '1rem 0 0 0'
              }}
            >
              <p
                className={classes.planSubtitle}
                style={{
                  margin: '0',
                  marginBottom: '20px',
                  color:
                    themeMode.mode === 'light'
                      ? `${theme.palette.text.secondary}`
                      : `${theme.palette.text.white}`,
                  justifyContent: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {user.isSubscriber
                  ? user?.options?.plan?.type || 'PRO'
                  : 'FREE'}

                <span
                  style={{
                    height: '30px',
                    width: 'max-content',
                    padding: '0 0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: credits.detail?.active
                      ? `${theme.palette.success.main}`
                      : `${theme.palette.error.main}`,
                    borderRadius: '5px',
                    color: 'white',
                    font: 'normal normal 600 16px/12px Inter',
                    marginLeft: 10
                  }}
                >
                  {credits.detail?.active ? 'Ativo' : 'Expirado'}
                </span>

                {/*{credits.detail?.plan || 'Free'}*/}
              </p>
            </div>
            {/*<p*/}
            {/*  style={{*/}
            {/*    margin: '0',*/}
            {/*    marginTop: '5px',*/}
            {/*    marginBottom: '5px',*/}
            {/*    fontSize: '16px',*/}
            {/*    fontWeight: '700',*/}
            {/*    color:*/}
            {/*      themeMode.mode === 'light'*/}
            {/*        ? `${theme.palette.text.secondary}`*/}
            {/*        : `${theme.palette.text.white}`*/}
            {/*  }}*/}
            {/*>*/}
            {/*  /!*{`${custom.totalCredits.quantity} palavras de crédito`}*!/*/}
            {/*  {user.options.plan.type}*/}
            {/*</p>*/}
            {/*<p*/}
            {/*  style={{*/}
            {/*    margin: '0',*/}
            {/*    fontSize: '14px',*/}
            {/*    fontWeight: '700',*/}
            {/*    color:*/}
            {/*      themeMode.mode === 'light'*/}
            {/*        ? `${theme.palette.text.hint}`*/}
            {/*        : `${theme.palette.text.secondary}`*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {(credits.detail?.daysRemaining || (0 as number)) <= 1*/}
            {/*    ? `Plano expira em ${credits.detail?.daysRemaining || 0} dia`*/}
            {/*    : `Plano expira em ${credits.detail?.daysRemaining || 0} dias`}*/}
            {/*</p>*/}

            {!user?.options?.plan?.active && (
              <Box
                // mt="30px"
                display={'flex'}
                alignItems={'flex-start'}
                width={'100%'}
                flexDirection={'column'}
              >
                <Typography className={classes.progressText}>
                  <span>
                    Melhore sua conta para ter acesso a mais créditos bônus.
                  </span>
                </Typography>
              </Box>
            )}

            {detailPlan && (
              <Box
                // mt="30px"
                display={'flex'}
                alignItems={'flex-start'}
                width={'100%'}
                flexDirection={'column'}
              >
                <Typography className={classes.progressText}>
                  {detailPlan.isTrial
                    ? `Seu período de teste termina em ${detailPlan.daysUntilNextBilling} dias e ${detailPlan.hoursUntilNextBilling} horas`
                    : `A próxima renovação é em ${detailPlan.daysUntilNextBilling} dias`}
                </Typography>
                <LinearProgress
                  variant={'determinate'}
                  value={
                    100 -
                    (detailPlan.daysUntilNextBilling /
                      moment(user.subscription.current_period_end).diff(
                        moment(user.subscription.current_period_start),
                        'days'
                      )) *
                      100
                  }
                  className={classes.linear}
                />
                <br />

                <Typography className={classes.progressText}>
                  {detailPlan.isCanceled ? (
                    <span>
                      Após esse período você não terá mais acesso aos benefícios
                      PRO. <br />
                    </span>
                  ) : (
                    <span>
                      {detailPlan.isTrial ? 'Primeira' : 'Próxima'} cobrança{' '}
                      {new Date(
                        user.subscription.current_period_end
                      ).toLocaleDateString('pt-BR')}
                    </span>
                  )}
                </Typography>
              </Box>
            )}

            <div className={classes.planButtonsDiv}>
              {user.isSubscriber ? (
                <>
                  <Button
                    variant="outlined"
                    disabled={isLoadingPortal || ctx.beta}
                    style={{
                      backgroundColor:
                        themeMode.mode === 'light'
                          ? `transparent`
                          : `${theme.palette.secondary.main}`,
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.text.black}`
                    }}
                    sx={{
                      textTransform: 'none!important',
                      fontFamily: 'Inter',
                      [theme.breakpoints.down('sm')]: {
                        width: '100%'
                      }
                    }}
                    onClick={() => {
                      const cb = {
                        success: (payload: any) => {
                          // @ts-ignore
                          window.churnkey.init('show', {
                            customerId: payload.stripe_id,
                            authHash: payload.user_hash,
                            appId: 'ewln3rith',
                            mode: 'live',
                            provider: 'stripe',
                            i18n: {
                              lang: 'pt',
                              messages: {
                                pt: {
                                  next: 'Próximo',
                                  back: 'Voltar',
                                  nevermind: 'Voltar',
                                  goToAccount: 'Ir para a Conta',
                                  getHelp: 'Algo errado? Contate-nos...',
                                  declineOffer: 'Recusar Oferta',
                                  confirmAndCancel: 'Confirmar e Cancelar',
                                  pauseSubscription: 'Pausar Assinatura',
                                  cancelSubscription: 'Cancelar',
                                  discountSubscription: 'Aceitar Esta Oferta', // botão para aceitar oferta com desconto
                                  claimOffer:
                                    'Reivindique sua oferta por tempo limitado', // mostrado acima das ofertas com desconto
                                  discountOff: 'de desconto', // ex. "10% de desconto"
                                  discountFor: 'por', // ex. "20% de desconto por 2 meses"
                                  discountForever: 'para sempre', // ex. "10% de desconto para sempre"
                                  discountOneTime: 'no seu próximo renovação', // ex. "10% de desconto na sua próxima renovação"
                                  day: 'Dia | Dias', // ex. "1 dia" "2 dias"
                                  month: 'mês | meses', // ex. "1 mês" "2 meses"
                                  year: 'ano | anos', // ex. "1 ano" "2 anos"
                                  error: 'Desculpe, algo deu errado', // mensagem de erro genérica
                                  genericErrorDescription:
                                    'Por favor, contate-nos.', // mensagem de erro genérica
                                  cancelNow: 'Cancelar Assinatura →', // botão para cancelar assinatura imediatamente
                                  applyingDiscount: 'Aplicando desconto...', // mostrado enquanto aplica o desconto
                                  applyingCancel: 'Cancelando assinatura...', // mostrado enquanto cancela a assinatura
                                  applyingResume: 'Retomando assinatura...', // mostrado enquanto retoma uma assinatura pausada
                                  applyingPause: 'Pausando assinatura...', // mostrado enquanto pausa a assinatura
                                  discountApplied: 'Desconto aplicado.', // mostrado quando o desconto é aplicado
                                  discountAppliedMessage:
                                    'Estamos tão felizes que você ainda está aqui', // mostrado quando o desconto é aplicado
                                  pauseApplied: 'Assinatura pausada.', // mostrado quando a assinatura é pausada
                                  pauseAppliedMessage:
                                    'Você não será cobrado até que sua assinatura seja retomada', // mostrado quando a assinatura é pausada
                                  pauseAppliedResumeMessage:
                                    'Sua assinatura será retomada em', // mostrado quando a assinatura é pausada
                                  pauseScheduledMessage:
                                    'Sua assinatura será pausada a partir de', // mostrado quando a assinatura está agendada para o futuro
                                  until: 'até',
                                  cancelApplied: 'Assinatura cancelada.', // mostrado quando a assinatura é cancelada
                                  cancelAppliedMessage:
                                    'Você não será cobrado novamente', // mostrado quando a assinatura é cancelada
                                  cancelAppliedDateMessage:
                                    'Sua assinatura terminará em', // mostrado quando a assinatura é cancelada
                                  howLongToPausePrompt:
                                    'Escolha por quanto tempo você quer pausar...', // mostrado acima do prompt para pausar a assinatura
                                  whatCouldWeHaveDone:
                                    'O que poderíamos ter feito melhor?', // mostrado acima do prompt de feedback
                                  weReadEveryAnswer:
                                    'Nós lemos cada resposta...', // mostrado como placeholder no prompt de feedback
                                  applyingCustomerAction:
                                    'Isso vai levar apenas um segundo.', // mostrado enquanto aplica a ação do cliente
                                  loading: 'Carregando...', // mostrado enquanto carrega
                                  pauseWallCardPunch: 'Quer acesso?',
                                  pauseWallCta: 'Retomar Assinatura Agora',
                                  pauseWallCardHeading: 'Retome sua assinatura',
                                  scheduledToReactivate:
                                    'Está agendado para reativar em',
                                  resumeApplied: 'Assinatura retomada', // mostrado quando a assinatura é retomada
                                  resumeAppliedMessage:
                                    'Você será cobrado no seu próximo período de renovação da assinatura.', // mostrado quando a assinatura é retomada
                                  resumeNextChargeMessage:
                                    'Após a reativação, você será cobrado na sua taxa original de ',
                                  resumeNextChargeMessageWithoutAmount:
                                    'Após a reativação, você será cobrado na sua taxa usual.',
                                  resumeAccountDataInfo:
                                    'Seus dados de conta estão sendo mantidos em segurança para quando sua assinatura for retomada.',
                                  subscriptionPauseNotice:
                                    'Parece que sua assinatura ainda está pausada',
                                  failedPaymentNotice:
                                    'O acesso à sua conta está limitado no momento',
                                  chargedMultipleTimeNotice:
                                    'Tentamos várias vezes cobrar o cartão que temos em arquivo, mas não deu certo. Não queremos perder você, então 👇',
                                  failedPaymentCardPunch:
                                    'Atualize seu cartão para restaurar o acesso.',
                                  resumeHey: 'Oi',
                                  invoicePaidTitle: 'Fatura paga com sucesso',
                                  logout: 'Sair →', // usado no pagamento falhado e no muro de pausa
                                  note: 'Nota:', // mostrado antes da nota de desconto
                                  discount: 'desconto',
                                  discountNoticeHeadline:
                                    'Nota: você também perderá um desconto ativo.',
                                  discountNoticePrepend:
                                    'Se você cancelar agora, perderá seu atual',
                                  discountOverride:
                                    'se você aceitar esta oferta, perderá seu atual',
                                  discountValidUntil: 'É válido até', // mostrado no desconto ativo
                                  updateBilling: 'Atualizar Cartão',
                                  // Extensão de Teste
                                  extendTrialCTA: 'Estender Teste Por ',
                                  extendTrialPunch: 'Seu teste termina em ',
                                  extendTrialOfferTitle: 'Extensão de Teste',
                                  trialExtended: 'Teste estendido.',
                                  trialExtendedMessage:
                                    'Seu teste foi estendido com sucesso',
                                  applyingTrialExtension:
                                    'Estendendo seu teste',
                                  // Mudança de Plano
                                  switchPlanCTA: 'Mudar Plano',
                                  changePlanHighlights: 'Destaques',
                                  changePlanOfferPunch:
                                    'Planos Secretos com Desconto',
                                  planChanged: 'Plano alterado.',
                                  planChangedMessage:
                                    'Seu novo plano agora está em vigor',
                                  applyingPlanChange: 'Alterando seu plano...',
                                  // Etiquetas de Etapas do Tema Esquerdo
                                  surveyStepTag: 'Seu Feedback',
                                  freeFormStepTag: 'Seu Feedback',
                                  finalConfirmation: 'Confirmação Final',
                                  offerDiscountTag: 'Oferta Especial',
                                  offerChangePlanTag: 'Considere Outros Planos',
                                  offerExtendTrialTag: 'Estenda Seu Teste',
                                  offerRedirectTag: 'Deixe-nos Ajudar',
                                  offerContactTag: 'Deixe-nos Ajudar',
                                  offerPauseTag: 'Pausa de Assinatura',
                                  completeTag: 'Assinatura Cancelada',
                                  errorTag: 'Ocorreu um Erro',
                                  offerAccepted: 'Oferta Aceita'
                                }
                              }
                            }
                          });
                        },
                        error: () => {}
                      };

                      dispatch(getChurnKeyHash(cb));
                    }}
                  >
                    Cancelar Assinatura
                  </Button>

                  <Button
                    variant="outlined"
                    disabled={isLoadingPortal || ctx.beta}
                    style={{
                      backgroundColor:
                        themeMode.mode === 'light'
                          ? `transparent`
                          : `${theme.palette.secondary.main}`,
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.text.black}`
                    }}
                    sx={{
                      textTransform: 'none!important',
                      fontFamily: 'Inter',
                      [theme.breakpoints.down('sm')]: {
                        width: '100%'
                      }
                    }}
                    onClick={() => (!ctx.beta ? handleOpenPortal() : null)}
                  >
                    Gerenciar Assinatura
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  disabled={ctx.beta}
                  style={{
                    backgroundColor:
                      themeMode.mode === 'light'
                        ? `${theme.palette.primary.main}`
                        : `${theme.palette.secondary.main}`,
                    color:
                      themeMode.mode === 'light'
                        ? `${theme.palette.text.white}`
                        : `${theme.palette.text.black}`
                  }}
                  sx={{
                    textTransform: 'none!important',
                    fontFamily: 'Inter',
                    [theme.breakpoints.down('sm')]: {
                      width: '100%'
                    }
                  }}
                  onClick={() => (!ctx.beta ? navigate('/plans') : null)}
                  // onClick={() => (!ctx.beta ? navigate('/premium') : null)}
                >
                  Obter Plano
                </Button>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>

      {/*{!ctx.beta && (*/}
      {/*  <Box className={classes.cancel}>*/}
      {/*    <h2 className={classes.cancelTitle} aria-label="teste">*/}
      {/*      Cancelamento da conta*/}
      {/*    </h2>*/}
      {/*    <p*/}
      {/*      className={classes.cancelSubtitle}*/}
      {/*      style={{*/}
      {/*        color: `${theme.palette.text.secondary}`*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Esteja ciente de que cancelar sua conta fará com que você perca todo*/}
      {/*      o conteúdo salvo e os créditos ganhos em sua conta.*/}
      {/*    </p>*/}

      {/*    <Button*/}
      {/*      variant="outlined"*/}
      {/*      style={{*/}
      {/*        color: `${theme.palette.text.secondary}`,*/}
      {/*        borderColor: `${theme.palette.text.secondary}`*/}
      {/*      }}*/}
      {/*      sx={{*/}
      {/*        textTransform: 'none!important',*/}
      {/*        fontFamily: 'Inter'*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Cancelar*/}
      {/*    </Button>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Box>
  );
};

export default CreditsBilling;
