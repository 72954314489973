import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '5px',
    // position: 'absolute',
    // padding: '11px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    // zIndex: '1',
    borderRadius: '4px',
    backgroundColor:
      theme.palette.mode === 'light' ? '#FFF' : '#3a3541!important'
    // boxShadow:
    //   'rgb(255 251 251 / 5%) 0px 0px 0px 1px, rgb(255 251 251 / 5%) 0px 1px 0px, rgb(15 15 15 / 16%) 0px 1px 14px'
    // maxWidth: 'calc(100vw - 24px)'
  },
  list: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    padding: '6px',
    marginBottom: '3px',
    alignItems: 'center!important',
    justifyContent: 'start',
    gap: '12px',
    color: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.85)' : 'white',
    fill: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.45)' : 'white',
    fontSize: '13.4px',
    fontWeight: '500',
    lineHeight: '120%',
    userSelect: 'none',
    transition: 'color 200ms, background-color 200ms, border-color 200ms',
    backgroundColor:
      theme.palette.mode === 'light' ? '#ebebebbf' : '#3a3541cc!important',
    // '&:hover': {
    //   backgroundColor: `${theme.palette.background.lighterGrey}`
    // },
    // cursor: 'pointer',
    paddingLeft: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
    borderRadius: '5px'
  },
  description: {
    color: theme.palette.mode === 'light' ? '#636363' : 'white',
    fontFamily: 'Inter',
    fontSize: '13.5px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    paddingRight: '2px'
  },
  icon: {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0'
  },
  boxInput: {
    display: 'flex',
    // paddingLeft: '10px',
    // paddingRight: '10px',
    // marginTop: '2px',
    // marginBottom: '2px',
    color: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.85)' : 'white',
    fill: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.45)' : 'white',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '120%',
    userSelect: 'none',
    transition: 'color 200ms, background-color 200ms, border-color 200ms',
    padding: '0.8em 1em 0.8em 1em',
    cursor: 'pointer'
  },
  iconInput: {
    color: 'rgb(167, 130, 195)'
  },
  descriptionLoading: {
    color: theme.palette.mode === 'light' ? '#636363' : 'white',
    fontFamily: 'Inter',
    fontSize: '13.5px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%'
  },
  resultsContainerWait: {
    // width: '100%',
    // height: '100%',
    // display: 'flex',
    // justifyContent: 'center',
    // backgroundColor:
    //   theme.palette.mode === 'light'
    //     ? 'rgb(255 255 255 / 69%)'
    //     : 'rgba(195,185,222,0.2)',
    // border:
    //   theme.palette.mode === 'light'
    //     ? '1.5px solid #E7EBF0!important'
    //     : '1.5px solid #3a3541!important',
    // borderRadius: '.375rem',
    // minHeight: '80px',
    // paddingTop: '0.2rem',
    cursor: 'pointer',
    width: '100%',
    '&:hover': {
      backgroundColor: `${theme.palette.background.lighterGrey}`
    }
  },
  messageContainer: {
    width: '100%',
    // '&:hover': {
    //   backgroundColor: `${theme.palette.background.lighterGrey}`
    // },
    // paddingTop: '1rem',
    // paddingBottom: '1rem',
    paddingLeft: '1.0rem',
    paddingRight: '1.0rem'
    // marginBottom: '20px'
  },
  titleMessage: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    fontWeight: '500',
    marginBottom: '5px'
  },
  descriptionMessage: {
    fontSize: '0.775rem',
    lineHeight: '1.25rem',
    color:
      theme.palette.mode === 'light' ? 'rgb(100 116 139)' : 'rgb(171,171,171)'
  },
  descriptionResult: {
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    color: theme.palette.mode === 'light' ? '#09B286' : '#E7EBF0',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    // fontWeight: '500',
    '& > p': {
      marginTop: '0px',
      marginBottom: '0px',
      marginBlockStart: '0em',
      marginBlockEnd: '0em'
    }
  },
  output: {
    display: 'flex',
    // paddingLeft: '10px',
    // paddingRight: '10px',
    marginTop: '12px',
    marginBottom: '1px',
    maxWidth: '450px!important',
    color: theme.palette.mode === 'light' ? '#09B286' : 'white',
    fill: theme.palette.mode === 'light' ? '#09B286' : 'white',
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    userSelect: 'none',
    transition: 'color 200ms, background-color 200ms, border-color 200ms'
    // padding: '0.8em 1em 0.8em 1em'
    // cursor: 'pointer'
  },

  incorrectSentenceContainer: {
    width: '100%',
    paddingLeft: '1.0rem',
    paddingRight: '1.0rem',
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    textAlign: 'left',
    color: theme.palette.mode === 'light' ? '#ff0000ad!important' : 'white',
    backgroundColor: theme.palette.mode === 'light' ? '#fce4e4' : '#9b1b1b8a',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '5px',
    // fontWeight: '500',

    display: 'flex',
    padding: '6px',
    // alignItems: 'center!important',
    justifyContent: 'start',
    gap: '12px'
  },
  correctSentenceContainer: {
    width: '100%',
    paddingLeft: '1.0rem',
    paddingRight: '1.0rem',
    fontSize: '13.4px',
    letterSpacing: 'calc(0px * var(--rem))',
    lineHeight: 'calc(1.3125px * var(--rem))',
    color: '#09B286!important',
    textAlign: 'left',
    backgroundColor: '#09b28629',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '5px',
    // fontWeight: '500',
    '& > p': {
      marginTop: '0px',
      marginBottom: '0px',
      marginBlockStart: '0em!important',
      marginBlockEnd: '0em!important'
    },
    display: 'flex',
    padding: '6px',
    // alignItems: 'center!important',
    justifyContent: 'start',
    gap: '12px'
  },
  incorrectIcon: {
    marginTop: '2.5px',
    color: theme.palette.mode === 'light' ? '#ff0000ad!important' : 'white'
  },
  correctIcon: {
    marginTop: '2.5px',
    color: '#09B286!important'
  }
}));

export default useStyles;
