import React, { useContext, useEffect } from 'react';
import { IconButton, Modal, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';

type CustomModalProps = {
  open?: any;
  toggle?: any;
  children?: any;
  containerClass?: any;
  hideClose?: any;
  didMountHook?: any;
};

const CustomModal = ({
  open,
  toggle,
  children,
  hideClose,
  didMountHook,
  ...props
}: CustomModalProps) => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (didMountHook && typeof didMountHook === 'function') {
      setTimeout(didMountHook, 100);
    }
  }, []);

  return (
    <Modal
      open={open}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(3px)'
      }}
      onClose={toggle}
      {...props}
    >
      <div
        style={{
          height: 'max-content',
          position: 'relative',
          backgroundColor:
            themeMode.mode === 'light'
              ? `${theme.palette.background.paper}`
              : `${theme.palette.background.default}`,
          borderRadius: '13px',
          maxWidth: '590px',
          outline: 'none',
          padding: isMobile ? '10px 20px 10px 20px' : '40px 15px',
          paddingLeft: isMobile ? '20px' : '40px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: isMobile ? '0px 10px 0px 10px' : '0px'
        }}
      >
        {!hideClose && (
          <IconButton
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              bottom: 'auto',
              left: 'auto'
            }}
            size={'small'}
            onClick={toggle}
          >
            <Close fontSize={'small'} />
          </IconButton>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
