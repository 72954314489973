import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    margin: '0rem'
  },
  card: {
    boxShadow:
      'inset 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)!important',
    borderRadius: '15px!important',
    transition: '500ms easy-in',
    '&:hover': {
      cursor: 'pointer',
      boxShadow:
        theme.palette.mode === 'light'
          ? 'inset 0px 0px 1px 1px #410A85, 0px 1px 1px 0px #410A85, 0px 1px 3px 0px #410A85!important'
          : 'inset 0px 0px 1px 1px #0fedb2, 0px 1px 1px 0px #0fedb2, 0px 1px 3px 0px #0fedb2!important'
    }
  },
  content: {
    minHeight: '80px!important'
  }
}));

export default useStyles;
