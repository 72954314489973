import React, { useContext, useState } from 'react';
import { DocumentCardInterface } from './interface';
import useStyles from './style';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import { useNavigate } from 'react-router-dom';
import ModalDeleteBrand from '../../../../../components/Modal/ModalDeleteBrand';

const BrandsCard = ({ id, name, description, image, onSelect, date }: any) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const [modal, setModal] = useState(false);
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleEditBrand = (id: string) => {
    return navigate(`/brands/update/${id}`);
  };

  const baseURL = `/icons/template/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  return (
    <>
      <Box className={classes.root}>
        <Box
          style={{
            maxWidth: '100%',
            marginTop: '1rem',
            cursor: 'default',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            padding: '0px',
            width: '300px',
            height: '260px',
            background: themeMode.mode === 'light' ? '#FFFFFF' : '#303030',
            border: themeMode.mode === 'light' ? '1px solid #E5E3E8' : 'none',
            borderRadius: '13px',
            flex: 'none',
            order: '0',
            flexGrow: '1'
          }}
          onClick={onSelect}
        >
          <Box className={classes.content} style={{ maxWidth: '100%' }}>
            <Typography
              gutterBottom
              style={{
                color: themeMode.mode === 'light' ? '#39274B' : '#fff',
                fontFamily: 'Inter',
                lineHeight: '125%',
                fontSize: '20px',
                fontWeight: '700',
                flex: 'none',
                order: 0,
                flexGrow: 1,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: '100%',
                marginBottom: '10px'
              }}
            >
              {name === '' ? 'Sem título' : name}
            </Typography>
            {/*<Typography*/}
            {/*  style={{*/}
            {/*    color:*/}
            {/*      themeMode.mode === 'light'*/}
            {/*        ? '#4F3D66'*/}
            {/*        : theme.palette.text.secondary,*/}
            {/*    fontFamily: 'Inter',*/}
            {/*    fontSize: '14px',*/}
            {/*    fontWeight: '400',*/}
            {/*    lineHeight: '150%',*/}
            {/*    textAlign: 'left',*/}
            {/*    marginTop: '5px',*/}
            {/*    marginBottom: '10px',*/}
            {/*    minHeight: '100px',*/}
            {/*    display: '-webkit-box',*/}
            {/*    WebkitLineClamp: 5,*/}
            {/*    WebkitBoxOrient: 'vertical',*/}
            {/*    overflow: 'hidden'*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {description ||*/}
            {/*    'Você ainda não adicionou nenhuma descrição aqui. Que tal começar agora?'}*/}
            {/*</Typography>*/}

            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              style={{
                width: '100%',
                height: isMobile ? 'auto' : '130px',
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor:
                  themeMode.mode === 'light'
                    ? 'rgba(236 221 255 / 65%)'
                    : 'rgb(67 67 67)',
                borderRadius: '5px'
              }}
            >
              <img
                src={image && image !== '' ? image : baseURL + 'file.svg'}
                alt="File Clarice"
                style={{ maxHeight: '65%', maxWidth: '60%' }}
              ></img>
            </Box>

            {/*<Typography*/}
            {/*  style={{*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'flex-start',*/}
            {/*    gap: '0.5rem',*/}
            {/*    color:*/}
            {/*      themeMode.mode === 'light'*/}
            {/*        ? '#4F3D66'*/}
            {/*        : theme.palette.text.secondary,*/}
            {/*    fontSize: '12px',*/}
            {/*    fontFamily: 'Inter',*/}
            {/*    fontWeight: '400'*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <AccessTimeRoundedIcon*/}
            {/*    color={themeMode.mode === 'light' ? 'primary' : 'secondary'}*/}
            {/*  />*/}
            {/*  {`Atualizado ${new Date(date).toLocaleString()}`}*/}
            {/*</Typography>*/}
          </Box>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              borderTop:
                themeMode.mode === 'light'
                  ? '1px solid #E5E3E8'
                  : '1px solid #757575',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.primary.main
                  : theme.palette.secondary.main
            }}
          >
            <Box
              sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                width: '100%',
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                borderRight:
                  themeMode.mode === 'light'
                    ? '1px solid #E5E3E8'
                    : '1px solid #757575',
                alignItems: 'center',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    themeMode.mode === 'light' ? '#E5E3E8' : '#414141ba',
                  borderRadius: '0px 0px 0px 15px'
                }
              }}
              onClick={() => handleEditBrand(id)}
            >
              Editar
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    themeMode.mode === 'light' ? '#E5E3E8' : '#414141ba',
                  borderRadius: '0px 0px 15px 0px'
                }
              }}
              onClick={() => setModal(true)}
            >
              Excluir
            </Box>
          </div>
        </Box>
      </Box>
      <ModalDeleteBrand
        idBrand={id}
        open={modal}
        onClose={() => setModal(false)}
      ></ModalDeleteBrand>
    </>
  );
};

export default BrandsCard;
