/* eslint-disable no-undef */
import instance from '../../utils/axios/instance';
import withAuth from '../../utils/axios/withAuth';
import { getChurnKeyHash, googleAuth } from '../actions/auth';

const AuthRequests = {
  signIn: async (data: any) => {
    const res = await instance().post(`/auth/sign-in`, {
      ...data,
      isClariceWriter: true
    });

    return res.data;
  },
  signUp: async (data: any) => {
    const res = await instance().post(`/auth/sign-up`, {
      ...data,
      isClariceWriter: true
    });

    return res.data;
  },
  googleAuth: async (data: any) => {
    const res = await instance().post(`/auth/google`, {
      ...data,
      isClariceWriter: true
    });

    return res.data;
  },
  mailConfirmation: async (code: string) => {
    const res = await instance().post(`/auth/mail-confirmation`, {
      confirmationCode: code
    });

    return res.data;
  },
  forgotPassword: async (email: string) => {
    const res = await instance().post(`/auth/forgot-password`, {
      email,
      isClariceWriter: true
    });

    return res.data;
  },
  passwordRecovery: async (password: string, recoveryCode: string) => {
    const res = await instance().post(`/auth/password-recovery`, {
      password,
      recoveryCode,
      isClariceWriter: true
    });

    return res.data;
  },
  checkToken: async () => {
    const res = await withAuth().post(`/auth/check`, { isClariceWriter: true });

    return res.data;
  },
  getChurnKeyHash: async () => {
    const res = await withAuth().post(`/auth/churn-key-hash`, {
      isClariceWriter: true
    });

    return res.data;
  }
};

export default AuthRequests;
