import React, { useContext } from 'react';
import useStyle from './style';
import { Box, Typography } from '@mui/material';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';
import { ReactComponent as RewardSVGLight } from '../../../../../../../../assets/images/template/light/reward.svg';
import { ReactComponent as RewardSVGDark } from '../../../../../../../../assets/images/template/dark/reward.svg';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import IMGIA from '../../../../../../../../assets/images/ilustra-ia.jpg';

type CorrectionNotFoundProps = {
  corrections?: any;
  activeType?: any;
  activeCorrectionCategory?: any;
};

const CorrectionNotFound = ({}: CorrectionNotFoundProps) => {
  const unique = true;

  const { editor, documents, user } = useReduxState();

  const themeMode: any = useContext(ThemeContext);

  const corrections = documents.detail?.corrections || {
    deviations: [],
    spellingAndGrammar: [],
    doubts: []
  };

  const activeCorrectionCategory = editor.activeCategory;

  const activeType = editor.activeCategoryType;

  const classes = useStyle();

  let feedback;

  const messages = {
    deviations: {
      all: '',
      clareza: 'clareza',
      concisao: 'concisão ',
      forca: 'força',
      originalidade: 'originalidade',
      formatacao: 'formatação',
      correcao: 'correção'
    },
    spellingAndGrammar: {
      all: '',
      gramatica: 'gramática',
      ortografia: 'ortografia',
      pontuacao: 'pontuação'
    },
    doubts: {
      all: '',
      duvida: 'dúvida'
    }
  };

  const quantity: any =
    (documents && documents?.detail?.premiumDeviations?.quantity) || 0;

  if (unique) {
    return null;
  }

  if (
    quantity > 0 &&
    !user.isSubscriber &&
    activeCorrectionCategory === 'deviations'
  ) {
    return null;
  }

  if (
    corrections.deviations.length === 0 &&
    corrections.spellingAndGrammar.length === 0 &&
    corrections.doubts &&
    corrections.doubts.length === 0
  ) {
    feedback = (
      <>
        <Typography
          style={{
            fontSize: '1.4em',
            fontWeight: '700',
            marginTop: '14px',
            marginBottom: '5px',
            textAlign: 'center'
          }}
        >
          Bom trabalho!
        </Typography>

        <Typography
          style={{
            fontSize: '0.975em',
            color: 'rgb(113, 128, 150)',
            textAlign: 'center',
            marginBottom: '5px'
          }}
        >
          Não identifiquei nenhum desvio
          <br /> em seu texto.
        </Typography>
      </>
    );
  } else {
    const messageFormatted = activeType => {
      const description = {
        deviations: <>Não encontrei nenhum desvio de Estilo.</>,
        spellingAndGrammar: (
          <>Não encontrei nenhum desvio de Ortografia e Gramática.</>
        ),
        doubts: (
          <>Não encontrei nenhum desvio relacionado a categoria de dúvidas.</>
        )
      };

      return (
        <>
          <Typography
            style={{
              fontSize: '1.4em',
              fontWeight: '700',
              marginTop: '14px',
              marginBottom: '5px',
              textAlign: 'center'
            }}
          >
            Muito bem!
          </Typography>

          <Typography
            style={{
              fontSize: '0.975em',
              color: 'rgb(113, 128, 150)',
              textAlign: 'center',
              marginBottom: '5px'
            }}
          >
            {description[activeType]}
          </Typography>
        </>
      );
    };

    const verifyFormatted = activeType => {
      const sizeDeviations = corrections.deviations.length || 0;

      const sizeSpellingAndGrammar = corrections.spellingAndGrammar.length || 0;

      const sizeDoubts: any =
        (corrections.doubts && corrections?.doubts?.length) || 0;

      if (activeType === 'deviations') {
        return (
          <>
            <Typography
              style={{
                marginTop: '15px',
                fontSize: '0.975em',
                color: 'rgb(113, 128, 150)',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              {sizeSpellingAndGrammar > 0 ? (
                <>
                  Verifique {sizeSpellingAndGrammar}{' '}
                  {sizeSpellingAndGrammar === 1 ? 'desvio(s)' : 'desvios'} de{' '}
                  <b>Ortografia e Gramática</b> na aba à direita.
                </>
              ) : sizeDoubts > 0 ? (
                <>
                  Verifique {sizeDoubts}
                  {sizeDoubts === 1 ? 'desvio(s)' : 'desvios'} de <b>Dúvidas</b>{' '}
                  na aba à direita.
                </>
              ) : null}
            </Typography>
          </>
        );
      }

      if (activeType === 'spellingAndGrammar') {
        return (
          <>
            <Typography
              style={{
                marginTop: '15px',
                fontSize: '0.975em',
                color: 'rgb(113, 128, 150)',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              {sizeDeviations > 0 ? (
                <>
                  Verifique {sizeDeviations}{' '}
                  {sizeDeviations === 1 ? 'desvio(s)' : 'desvios'} de{' '}
                  <b>Estilo</b> <br />
                  na aba à direita.
                </>
              ) : sizeDoubts > 0 ? (
                <>
                  Verifique {sizeDoubts}{' '}
                  {sizeDoubts === 1 ? 'desvio(s)' : 'desvios'} de <b>Dúvidas</b>{' '}
                  na aba à direita.
                </>
              ) : null}
            </Typography>
          </>
        );
      }

      if (activeType === 'doubts') {
        return (
          <>
            <Typography
              style={{
                marginTop: '15px',
                fontSize: '0.975em',
                color: 'rgb(113, 128, 150)',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              {sizeDeviations > 0 ? (
                <>
                  Verifique {sizeDeviations}{' '}
                  {sizeDeviations === 1 ? 'desvio(s)' : 'desvios'} de{' '}
                  <b>Estilo</b> <br />
                  na aba à direita.
                </>
              ) : sizeSpellingAndGrammar > 0 ? (
                <>
                  Verifique {sizeSpellingAndGrammar}{' '}
                  {sizeSpellingAndGrammar === 1 ? 'desvio(s)' : 'desvios'} de{' '}
                  <b>Ortografia e Gramática</b> na aba à direita.
                </>
              ) : null}
            </Typography>
          </>
        );
      }
    };

    feedback = (
      <Typography
        className={classes.message}
        style={{
          fontSize: '0.975em',
          color: 'rgb(113, 128, 150)',
          textAlign: 'center',
          marginBottom: '5px'
        }}
      >
        {messageFormatted(activeCorrectionCategory)}

        {verifyFormatted(activeCorrectionCategory)}
      </Typography>
    );
  }

  return (
    <Box
      style={{
        padding: '40px',
        paddingTop: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.5rem',
        marginLeft: '1.5rem'
      }}
      width={'100%'}
    >
      <Box className={classes.root} width={'100%'}>
        {themeMode.mode === 'light' && (
          <>
            <img
              src={IMGIA}
              alt="Person"
              style={{
                // marginTop: '40px',
                marginBottom: '20px',
                width: '90%'
                // height: '340px'
              }}
            />
          </>
          // <RewardSVGLight width={'90%'} height={300} />
        )}

        {themeMode.mode === 'dark' && (
          <>
            <img
              src={IMGIA}
              alt="Person"
              style={{
                // marginTop: '40px',
                marginBottom: '20px',
                width: '5' + '90%'
                // height: '340px'
              }}
            />
          </>
          // <RewardSVGDark width={'90%'} height={300} />
        )}

        {feedback}
      </Box>
    </Box>
  );
};

export default CorrectionNotFound;
