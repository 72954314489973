import React, { useState } from 'react';
import useStyles from './style';
import ContainerAuth from '../../components/ContainerAuth';
import LeftContainerFlow from '../../components/LeftContainerFlow';
import HeadAuth from '../../components/HeadAuth';
import SignUpForm from './components/SignUpForm';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import { signIn, signUp } from '../../../../stories/actions/auth';
import { useNavigate } from 'react-router-dom';
import notify from '../../../../utils/notify';

type Props = {};

const SignUp = (props: Props) => {
  const classes = useStyles();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accept, setAccept] = useState(true);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(false);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { auth } = useReduxState();

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (name === '') {
      return notify.error('O campo de nome é obrigatório.');
    }

    if (email === '') {
      return notify.error('O campo de e-mail é obrigatório.');
    }

    if (password === '') {
      return notify.error('O campo de senha é obrigatório.');
    }

    if (!loading && accept && captcha) {
      setLoading(true);

      const cb = {
        success: () => {
          setCaptcha(false);

          setLoading(false);

          navigate(`/sign-in`);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(signUp({ name, email, password, accept }, cb));
    }
  };

  const handleChange = ({ target }: any) => {
    setAccept(target.checked);
  };

  return (
    <>
      <div className={classes.root}>
        <HeadAuth
          title="Clarice.ai - Crie sua conta"
          description="Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade."
        />
        <ContainerAuth>
          <LeftContainerFlow />

          <SignUpForm
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            accept={accept}
            loading={loading}
            handleChange={handleChange}
            onSubmit={onSubmit}
            captcha={captcha}
            setCaptcha={setCaptcha}
          />
        </ContainerAuth>
      </div>
    </>
  );
};

export default SignUp;
