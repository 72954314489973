import { useContext, useState } from 'react';
import { completionByAutoCorrection } from '../stories/actions/templates';
import { useAppDispatch } from './useAppDispatch';
import { getCredits } from '../stories/actions/credits';
import { GlobalContext } from '../utils/context/GlobalContext';

const useAutoCorrection = (
  correction: any,
  getContentForAutoCorrection: any
) => {
  // console.log(correction);

  const dispatch = useAppDispatch();

  const ctx = useContext(GlobalContext);

  const { id } = correction;

  const [text, setText] = useState({
    content: '',
    range: undefined
  });

  const options = {
    USO_PALAVRAS_REPETIDAS: 'repeated-words',
    VAGUEZA: 'vagueza',
    SUBSTANTIVOS_RASTEJANTES: 'substantivos-rastejantes',
    USO_FRASE_CLICHE: 'frase-cliche',
    GLUE_WORDS: 'glue-words',
    ADVERBIOS_MENTE: 'adverbs-mente',
    FRASE_LONGA: 'long-sentence',
    USO_CADA_VEZ_MAIS: 'cada-vez-mais',
    USO_PLEONASMO: 'pleonasmo',
    USO_FACE_VAR: 'face-var',
    // NEWS
    USO_AO_PASSO_QUE: 'ao-passo-que',
    USO_POSTO_QUE: 'posto-que',
    USO_COMO_SENDO: 'como-sendo',
    USO_ISSO_PORQUE: 'isso-porque',
    USO_PARA_PODER: 'para-poder',
    USO_GROSSO_MODO: 'grosso-modo',
    USO_UM_DOS_MAIS: 'um-dos-mais',

    USO_PALAVRAS_SENSIVEIS: 'palavras-sensiveis',
    USO_FRENTE_VAR: 'frente-var',
    USO_ALEM_VAR: 'alem-var',
    USO_MEDIANTE_VAR: 'mediante-var',
    USO_PREZAR_VAR: 'prezar-var',
    USO_AFIM_VAR: 'afim-var',

    DOIS_SLOTS: 'dois-slots',
    USO_EXPRESSAO_PROLIXA: 'expressao-prolixa'
  };

  const [outputs, setOutputs] = useState<any>([]);

  const [loading, setLoading] = useState(false);

  const [newGenerate, setNewGenerate] = useState(false);

  const getRangeText = (id, spans) => {
    let range: any = {
      start: 0,
      end: 0
    };

    let content = '';

    // console.log(id);

    const option = options[id];

    // if (id === 'FRASE_LONGA') {
    //   const span = correction?.spans[0];
    //
    //   const start = span.offset;
    //   const end = span.offset + span.length;
    //
    //   const response = getContentForAutoCorrection(start, end);
    //
    //   content = response.content;
    //   range = response.range;
    // }

    if (['USO__PALAVRAS__REPETIDAS_OLD'].includes(id)) {
      const start = correction?.spans[0].offset;
      const end =
        correction?.spans[correction?.spans.length - 1].offset +
        correction?.spans[correction?.spans.length - 1].length;

      const response = getContentForAutoCorrection(start, end);

      content = response.content;
      range = response.range;
    }

    if (['USO_PALAVRAS_REPETIDAS'].includes(id)) {
      let spans = correction?.spans || [];

      if (spans.length <= 5) {
        if (correction?.spans.length === 3) {
          spans = spans.slice(1, -1);
        }

        if (correction?.spans.length === 4) {
          spans = spans.slice(1, -1);
        }

        if (correction?.spans.length === 5) {
          spans = spans.slice(1, -1);
        }

        const start = spans[0].offset;
        const end =
          spans[spans.length - 1].offset + spans[spans.length - 1].length;

        const response = getContentForAutoCorrection(start, end, 'sentence');

        // console.log('RESPOSTA: ', response);

        content = response?.sentence?.content || '';
        range = response?.sentence?.range;
      }

      if (spans.length > 5) {
        const start = correction?.spans[0].offset;
        const end =
          correction?.spans[correction?.spans.length - 1].offset +
          correction?.spans[correction?.spans.length - 1].length;

        const response = getContentForAutoCorrection(start, end);

        content = response.content;
        range = response.range;
      }

      return {
        option,
        content,
        range,
        expression: {
          content: spans[0].content
        }
      };
    }

    if (
      [
        'SUBSTANTIVOS_RASTEJANTES',
        'USO_FRASE_CLICHE',
        'GLUE_WORDS',
        'ADVERBIOS_MENTE',
        'FRASE_LONGA',
        'VAGUEZA',
        'USO_CADA_VEZ_MAIS',
        'USO_PLEONASMO',
        'USO_FACE_VAR',
        // NEWS
        'USO_FRENTE_VAR',
        'USO_ALEM_VAR',
        'USO_AO_PASSO_QUE',
        'USO_POSTO_QUE',
        'USO_COMO_SENDO',
        'USO_AFIM_VAR',
        'USO_MEDIANTE_VAR',
        'USO_PREZAR_VAR',
        'USO_ISSO_PORQUE',
        'USO_PALAVRAS_SENSIVEIS',
        'USO_PARA_PODER',
        'USO_GROSSO_MODO',
        'USO_EXPRESSAO_PROLIXA',
        'USO_UM_DOS_MAIS',
        'DOIS_SLOTS'
      ].includes(id)
    ) {
      const start = correction?.spans[0].offset;
      const end =
        correction?.spans[correction?.spans.length - 1].offset +
        correction?.spans[correction?.spans.length - 1].length;

      const response = getContentForAutoCorrection(start, end, 'sentence');

      content = response?.sentence?.content || '';
      range = response?.sentence?.range;

      if (
        [
          'USO_FRASE_CLICHE',
          'USO_PLEONASMO',
          'USO_CADA_VEZ_MAIS',
          'USO_FACE_VAR',
          // NEWS
          'USO_AO_PASSO_QUE',
          'USO_POSTO_QUE',
          'USO_COMO_SENDO',
          'USO_ISSO_PORQUE',
          'USO_PALAVRAS_SENSIVEIS',
          'USO_PARA_PODER',
          'USO_GROSSO_MODO',
          'USO_UM_DOS_MAIS',
          'USO_FRENTE_VAR',
          'USO_ALEM_VAR',
          'USO_AFIM_VAR',
          'USO_PREZAR_VAR',
          'USO_MEDIANTE_VAR',
          'DOIS_SLOTS',
          'USO_EXPRESSAO_PROLIXA'
        ].includes(id)
      ) {
        return {
          option,
          content,
          range,
          expression: {
            content: correction?.spans[0].content
          }
        };
      }

      return {
        option,
        content,
        range,
        paragraph: {
          content: response.content,
          range: response.range
        }
      };
    }

    return {
      option,
      content,
      range
    };
  };

  const onGenerate = async () => {
    if (outputs.length > 0) {
      setNewGenerate(true);

      setOutputs([]);
    }

    setLoading(true);

    const cb = {
      success: (outputs: any) => {
        setOutputs(outputs);

        setLoading(false);

        setNewGenerate(false);

        dispatch(getCredits());
      },
      error: (err: any) => {
        if (
          err?.response?.data?.message === 'Você atingiu o limite de créditos!'
        ) {
          if (!ctx.modal.conversion.open) {
            ctx.modal.conversion.toggle();
          }
        }

        setLoading(false);

        setNewGenerate(false);
      }
    };

    const { option, range, content, paragraph, expression } = getRangeText(
      id,
      correction?.spans
    );

    setText({
      content: content,
      range
    });

    let description: any = '';

    if (
      [
        'USO_FRASE_CLICHE',
        'USO_PLEONASMO',
        'USO_CADA_VEZ_MAIS',
        'USO_FACE_VAR',
        'USO_PALAVRAS_REPETIDAS',
        // NEWS
        'USO_AO_PASSO_QUE',
        'USO_POSTO_QUE',
        'USO_COMO_SENDO',
        'USO_ISSO_PORQUE',
        'USO_PALAVRAS_SENSIVEIS',
        'USO_PARA_PODER',
        'USO_GROSSO_MODO',
        'USO_UM_DOS_MAIS',
        'USO_FRENTE_VAR',
        'USO_ALEM_VAR',
        'USO_AFIM_VAR',
        'USO_PREZAR_VAR',
        'USO_MEDIANTE_VAR',
        'DOIS_SLOTS',
        'USO_EXPRESSAO_PROLIXA'
      ].includes(id)
    ) {
      description = expression?.content;
    }

    if (['SUBSTANTIVOS_RASTEJANTES', 'GLUE_WORDS'].includes(id)) {
      description = paragraph?.content;
    }

    // console.log('HELLO: ', {
    //   description,
    //   snippet: content,
    //   range
    // });

    await dispatch(
      completionByAutoCorrection(
        {
          template: 'command-' + option,
          description,
          snippet: content,
          quantity: content.length > 200 ? 1 : 1 // 2
        },
        cb
      )
    );
  };

  const onReset = () => {
    setText({
      content: '',
      range: undefined
    });

    setOutputs([]);

    setLoading(false);

    setNewGenerate(false);
  };

  return {
    id,
    content: text.content || '',
    range: text.range || undefined,
    onGenerate,
    newGenerate,
    setNewGenerate,
    loading,
    setLoading,
    outputs,
    setOutputs,
    onReset
  };
};

export default useAutoCorrection;
