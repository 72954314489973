/* eslint-disable no-undef */
import {
  CHAT_CLEAR,
  CHAT_CONVERSATION,
  CHAT_GET_ALL,
  CHAT_UPDATE_STATUS
} from '../actions/actionTypes';

const initialState: reducers.ChatReducer = {
  conversation: []
};

const chatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHAT_CONVERSATION:
      state = {
        ...state,
        conversation: [...state.conversation, action.payload]
      };
      break;
    case CHAT_GET_ALL:
      state = {
        ...state,
        conversation: action.payload
      };
      break;
    case CHAT_UPDATE_STATUS:
      // eslint-disable-next-line no-case-declarations
      const index = state.conversation.findIndex(
        el => el._id === action.payload._id
      );

      // eslint-disable-next-line no-case-declarations
      const { conversation } = state;

      if (index !== -1) {
        conversation[index] = action.payload;
      }

      state = {
        ...state,
        conversation: conversation
      };
      break;
    case CHAT_CLEAR:
      state = {
        ...state,
        conversation: []
      };
      break;

    default:
      return state;
  }

  return state;
};

export default chatReducer;
