import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: `#ffffff`,
    boxShadow: `0 2px 0.75rem ${theme.palette.background.lightGrey}`
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 30,
    padding: 0,
    marginTop: 28,
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      marginLeft: 8,
      marginRight: 15
    }
  },
  space: {
    flexGrow: 1
  },
  icon: {
    stroke: '2px'
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '250px',
    paddingLeft: '25px',
    paddingRight: '20px',
    flex: '0 0 250px',
    height: '48px',
    textTransform: 'none',
    marginLeft: '19px',
    borderRadius: '30px',
    '&:disabled': {
      background: theme.palette.primary.main,
      opacity: 0.7,
      color: 'white'
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      flex: 'auto'
    }
  },
  buttonOnboarding: {
    marginRight: '24px!important',

    fontFamily: 'Inter',
    display: 'inline-flex',
    padding: '12px!important',
    justifyContent: 'center',
    gap: '8px',

    // display: 'flex',
    alignItems: 'center',
    minWidth: '48px!important',
    height: '48px',
    textTransform: 'none',
    borderRadius: '100px!important',
    '&:disabled': {
      background: theme.palette.primary.main,
      opacity: 0.7,
      color: 'white'
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      flex: 'auto'
    }
  },
  menuButton: {
    borderRadius: '30px!important',
    height: '48px'
  }
}));

export default Styles;
