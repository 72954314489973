import { ChatActionsInterface } from './interface';
import useStyles from './style';
import IconButton from '@mui/material/IconButton';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CachedIcon from '@mui/icons-material/Cached';
import Tooltip from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';

const ChatActions = ({ talk, onSelectAction }: ChatActionsInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  const actions = [
    {
      type: 'hide',
      icon:
        talk.status && talk.status.hide ? (
          <VisibilityOffIcon fontSize="inherit" />
        ) : (
          <VisibilityIcon fontSize="inherit" />
        ),
      tooltip:
        talk.status && talk.status.hide
          ? 'Mostrar em Clarice.ai'
          : 'Ocultar em Clarice.ai',
      // active: false
      loading: false
    },
    {
      type: 'like',
      icon: <ThumbUpOffAltIcon fontSize="inherit" />,
      tooltip: 'Boa resposta!',
      active: talk.status && talk.status.like,
      loading: false
    },
    {
      type: 'dislike',
      icon: <ThumbDownOffAltIcon fontSize="inherit" />,
      tooltip: 'Mensagem ruim!',
      active: talk.status && talk.status.dislike,
      loading: false
    },
    {
      type: 'refresh',
      icon: <CachedIcon fontSize="inherit" />,
      tooltip: 'Tentar novamente',
      // active: false,
      loading: false
    },
    {
      type: 'copy',
      icon: <ContentCopyIcon fontSize="inherit" />,
      tooltip: 'Copiar mensagem',
      // active: false,
      loading: false
    }
  ];

  const color = themeMode.mode === 'light' ? 'primary' : 'secondary';

  return (
    <div className={classes.root}>
      {actions.map((action: any, index: number) => {
        return (
          <Tooltip title={action.tooltip} key={index}>
            <IconButton
              size="small"
              color={action.active ? color : 'default'}
              className={classes.button}
              onClick={() =>
                !action.loading && !action.active
                  ? onSelectAction(talk._id, talk.type, action.type)
                  : null
              }
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ChatActions;
