import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  input: {
    fontFamily: ['Inter'].join(','),
    marginBottom: '40px!important',
    marginTop: '20px!important',
    width: '90%'
  },
  generateWithAIContainer: {
    paddingTop: '1rem',
    paddingBottom: '2rem',
    paddingLeft: '1.5rem',
    paddingRight: '1.5rem',
    border:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important',
    borderBottom: 'none!important'
    // marginBottom: '10px',
    // minHeight: '600px'
  },
  generateWithAIHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default useStyles;
