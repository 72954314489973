import React from 'react';
import { ChannelsContainerInterface } from './interface';
import useStyles from './style';
import IconHelp from '../../../../assets/images/auth/icon-help.svg';

const ChannelsContainer = ({}: ChannelsContainerInterface) => {
  const classes: any = useStyles();

  return (
    <div className={classes.root}>
      <img src={IconHelp} alt="" />
      <p className={classes.description}>
        Precisa de ajuda?{' '}
        <a
          href="https://clarice.ai/?fale-conosco"
          target="_blank"
          rel="noreferrer"
        >
          Visite nossos canais
        </a>
      </p>
    </div>
  );
};

export default ChannelsContainer;
