/* eslint-disable no-undef */
import {
  AUTH_SIGN_IN,
  AUTH_SIGN_UP,
  AUTH_SIGN_OUT,
  AUTH_CHECK_TOKEN,
  AUTH_GOOGLE_AUTH
} from '../actions/actionTypes';

const initialState: reducers.AuthReducer = {
  loaded: false,
  authenticated: false,
  token: ''
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AUTH_SIGN_IN:
      state = {
        ...state,
        loaded: false,
        authenticated: true,
        token: action.payload.token
      };
      break;
    case AUTH_GOOGLE_AUTH:
      state = {
        ...state,
        loaded: false,
        authenticated: true,
        token: action.payload.token
      };
      break;
    case AUTH_SIGN_UP:
      break;
    case AUTH_SIGN_OUT:
      state = {
        ...state,
        loaded: false,
        authenticated: false,
        token: ''
      };
      break;
    case AUTH_CHECK_TOKEN:
      state = {
        ...state,
        loaded: action.payload.token !== '',
        authenticated: action.payload.token !== '',
        token: action.payload.token
      };
      break;

    default:
      return state;
  }

  return state;
};

export default authReducer;
