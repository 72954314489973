import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down(600)]: {
      marginBottom: '24px'
    }
  },
  title: {
    color: theme.palette.mode === 'light' ? '#000000' : '#fff',
    fontFamily: 'Inter',
    fontSize: '28px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '125%',

    flex: 'none',
    order: 0,
    flexGrow: 0,
    marginBottom: '0.5rem',
    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
      width: '100%',
      fontSize: '19px'
    }
  },
  description: {
    color:
      theme.palette.mode === 'light' ? '#000000' : 'rgba(255, 255, 255, 0.80)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '140%',
    width: '30rem',
    marginBlockStart: '0',
    marginBlockEnd: '0',
    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
      width: '100%',
      fontSize: '14px'
    }
  }
}));

export default useStyles;
