const formatReadability = data => {
  if (data) {
    const dataNumber = data.toFixed(2);
    const dataString = String(dataNumber).replace('.', ',');

    return {
      number: dataNumber,
      string: dataNumber < 0 ? '0,00' : dataString
    };
  }

  return {
    number: 0,
    string: '0,00'
  };
};

export default formatReadability;
