import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  iconContainer: {
    marginRight: '30px!important',
    width: '40px !important',
    flexBasis: '40px !important',
    flexShrink: 0,
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    paddingBottom: '30px!important'
  },
  title: {
    font: ' normal normal 400 16px Inter!important',
    lineHeight: '125%!important',
    color: '#39274B'
    // color:
    //   theme.palette.mode === 'light' ? '#39274B' : theme.palette.secondary.main
  },
  description: {
    marginTop: '4px!important',
    font: 'normal normal normal 14px Inter!important',
    lineHeight: '150%!important',
    color: '#655679'
    // color: theme.palette.mode === 'light' ? '#655679' : '#838594'
  }
}));

export default Styles;
