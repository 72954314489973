import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import { Box, Button, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import analytics from '../../../../../../utils/function/analytics';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import mapping from '../../../../../../utils/function/mapping';
import { useNavigate } from 'react-router-dom';

const Action = ({}: ActionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          cursor: 'pointer!important',
          fontFamily: 'Inter',
          backgroundColor: themeMode.mode === 'dark' ? `#09B286` : `#09B286`,
          textTransform: 'none',
          padding: '8px 24px 8px 24px',
          gap: '10px',
          borderRadius: '6px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor:
              themeMode.mode === 'dark'
                ? `rgba(9, 178, 134, 0.85)`
                : 'rgba(9, 178, 134, 0.85)'
          },
          [theme.breakpoints.down('sm')]: {
            // width: '100%'
          },
          fontWeight: '600',
          color: '#fff'
        }}
        onClick={() => {
          navigate(`/plans`);

          analytics.logEvent('CTA', 'redirect-plans-page-with-coupon');

          mapping.track('CTA | redirect-plans-page-with-coupon');
        }}
      >
        Assinar agora
      </Button>
    </>
  );
};

export default Action;
