import React, { useContext } from 'react';
import { SuggestionComponentInterface } from './interface';
import useStyles from './style';
import { useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../../../../utils/context/ThemeContext';

const SuggestionComponent = (props: SuggestionComponentInterface) => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles(themeMode);

  return (
    <span
      style={{
        backgroundColor: 'lightblue',
        cursor: 'pointer'
      }}
      {...props}
    />
  );
};

export default SuggestionComponent;
