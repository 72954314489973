import mixpanel from 'mixpanel-browser';
import { hotjar } from 'react-hotjar';

const init = async (id: any, config: any): Promise<any> => {
  await mixpanel.init(id, config);

  const hjid = 2127829;
  const hjsv = 6;

  await hotjar.initialize(hjid, hjsv);
};

const identify = async (id: any): Promise<any> => {
  await mixpanel.identify(id);

  if (hotjar.initialized()) {
    await hotjar.identify(id, {});
    // await hotjar.identify(id, { userProperty: 'value' });
  }
};

const setPeople = async (data: any): Promise<any> => {
  await mixpanel.people.set({
    $name: data.name,
    $email: data.email
  });

  if (hotjar.initialized()) {
    await hotjar.identify(data.id, { name: data.name, email: data.email });
  }
};

const track = async (data: any): Promise<any> => {
  await mixpanel.track(data);

  await hotjar.event(data);
};

const mapping: any = {
  init,
  identify,
  people: {
    set: setPeople
  },
  track
};

export default mapping;
