import React, { useContext, useState } from 'react';
import { Box, useTheme, useMediaQuery, Typography, Grid } from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import ModalCustom from '../ModalCustom';
import useStyles from './style';
import Root from './components/Root';
import Title from './components/Title';
import Motion from './components/Motion';
import Info from './components/Info';
import Action from './components/Action';

type ModalNewsProps = {};

const ModalOnboarding = ({}: ModalNewsProps) => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState<any>(0);

  const baseURL = `/onboarding/${
    themeMode.mode === 'dark' ? 'dark' : 'light'
  }/`;

  const texts = [
    {
      image: baseURL + (isMobile ? 'mobile/1.jpg' : '1.jpg'),
      title: <>Selecione uma palavra para buscar sinônimos</>,
      description: (
        <>
          A funcionalidade de <strong>Buscar Sinônimos</strong> enriquece seus
          textos, fornecendo sugestões de palavras similares em significado.
        </>
      )
    },
    {
      image: baseURL + (isMobile ? 'mobile/2.jpg' : '2.jpg'),
      title: (
        <>Destaque um trecho do texto e receba sugestões de edição e melhoria</>
      ),
      description: (
        <>
          Use os <strong>Ajustes com IA</strong> para simplificar, encurtar,
          melhorar e mudar o tom e voz do seu texto num piscar de olhos.
        </>
      )
    },
    {
      image: baseURL + (isMobile ? 'mobile/3.jpg' : '3.jpg'),
      title: <>Corrija desvios automaticamente com ajuda da IA</>,
      description: (
        <>
          Com a <strong>Correção Automática</strong> você recebe sugestões de
          como corrjir cada desvio do texto com um clique.
        </>
      )
    },
    {
      image: baseURL + (isMobile ? 'mobile/4.jpg' : '4.jpg'),
      title: <>Digite “/” e veja como a Clarice.ai sugere ideias criativas.</>,
      description: (
        <>
          Deu branco? Use a <strong>Escrita com IA</strong> e supere o bloqueio
          criativo. Pausa agora só para o café ☕
        </>
      )
    }
  ];

  const onReset = () => {
    setActiveStep(0);
  };

  const onNext = () => {
    if (activeStep === texts.length - 1) {
      ctx.modal.onboarding.toggle();

      setActiveStep(0);

      return;
    }

    setActiveStep(activeStep + 1);
  };

  return (
    <Root onReset={onReset}>
      <Grid container sx={{ padding: '0px' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={5}
          xl={5}
          sx={
            useMediaQuery((theme: any) => theme.breakpoints.down(1200))
              ? {
                  gap: {
                    xs: '24px',
                    sm: '32px',
                    md: '32px',
                    lg: '48px',
                    xl: '48px'
                  },
                  padding: isMobile ? '16px' : '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  alignItems: 'center',
                  order: 2
                }
              : {
                  gap: {
                    xs: '24px',
                    sm: '32px',
                    md: '32px',
                    lg: '48px',
                    xl: '48px'
                  },
                  padding: isMobile ? '16px' : '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  justifyItems: 'center',
                  alignItems: 'center'
                }
          }
        >
          <Box
            // display="flex"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              // paddingLeft: '0px',
              padding: isMobile ? '12px' : '24px'
            }}
          >
            <Box
              sx={{
                minHeight: {
                  xs: '180px',
                  sm: '180px',
                  md: '180px',
                  lg: '220px',
                  xl: '220px'
                }
              }}
            >
              <Title>{texts[activeStep].title}</Title>

              <Info>{texts[activeStep].description}</Info>
            </Box>

            <Action
              onNext={onNext}
              active={activeStep}
              last={activeStep === texts.length - 1}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
          <Motion image={texts[activeStep].image} />
        </Grid>
      </Grid>
    </Root>
  );
};

export default ModalOnboarding;
