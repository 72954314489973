import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  };
});

export default useStyles;
