import React from 'react';
import useStyles from './styles';
import SecurityAndPrivacy from '../SecurityAndPrivacy';
import CreditCardAccepted from '../CreditCardAccepted';
import { Box } from '@mui/material';

const CreditCardInfo = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <SecurityAndPrivacy />
      <CreditCardAccepted />
    </Box>
  );
};

export default CreditCardInfo;
