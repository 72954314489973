import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  titleContainer: {
    // paddingLeft: '10px',
    // paddingRight: '10px',
    marginBottom: '25px'
  },
  title: {
    font: ' normal normal 600 14px Inter',
    lineHeight: '21px',
    color: '#FFFFFF',
    marginTop: '8px'
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

export default Styles;
