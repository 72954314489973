import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      width: '100%',
      height: '4.8rem',
      backgroundColor: '#09b386',
      border: 'none',
      borderRadius: '6px',
      fontWeight: '600',
      fontSize: '1.6rem',
      lineHeight: ' 100%',
      color: '#ffffff',
      marginTop: '4rem',
      transition: 'background-color 0.3s',
      '&:hover': {
        backgroundColor: '#0ba179'
      },
      '&:disabled': {
        opacity: '0.7',
        cursor: 'default',
        backgroundColor: '#09b386 !important'
      }
    }
  };
});

export default useStyles;
