import { makeStyles } from '@mui/styles';
import BG from '../../../../../assets/images/auth/gradient-left-container.svg';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      width: '!important',
      height: '100%!important',
      background: `url(${BG}) no-repeat center center`,
      backgroundSize: 'cover!important',
      paddingTop: '8.5rem!important',
      '& > div': {
        flex: '1!important',
        maxWidth: '46.9rem!important',
        margin: '0 auto!important'
      },
      [theme.breakpoints.down(991)]: {
        display: 'none'
      }
    },
    image: {
      marginBottom: '6.3rem!important'
    },
    text: {
      maxWidth: '38.4rem!important',
      marginLeft: '2.6rem!important',
      '& > img': {
        marginBottom: '2.4rem!important'
      },
      '& > p': {
        fontSize: '1.6rem!important',
        lineHeight: '150%!important',
        color: '#ffffff!important'
      }
    }
  };
});

export default useStyles;
