import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(3px)',
      '& > div,img': {
        outline: 'none'
      }
      // minHeight: '100vh',
      // width: '100%'
    },
    img: {
      width: '90px',
      height: '150px'
    },
    container: {
      zIndex: 9999,
      backgroundColor: 'rgb(65, 10, 133)',
      maxWidth: '100%',
      height: '100vh',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
});

export default useStyles;
