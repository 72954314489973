/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import { ContainerInterface } from './interface';
import { completionByTemplate } from '../../../../../../../stories/actions/templates';
import { useAppDispatch } from '../../../../../../../hooks/useAppDispatch';
import useStyles from './style';
import toast, { Toaster } from 'react-hot-toast';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import notify from '../../../../../../../utils/notify';
import { useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { createBrand } from '../../../../../../../stories/actions/brands';
import TooltipContainer from '../../../../../components/TooltipContainer';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloseIcon from '@mui/icons-material/Close';
import IMGTemplate from '../../../../../../../assets/images/ilustra-template.jpg';
import { ReactComponent as MagicSVGLight } from '../../../../../../../assets/images/template/light/magic.svg';
import { ReactComponent as MagicSVGDark } from '../../../../../../../assets/images/template/dark/magic.svg';
import GenerateWithAI from '../GenerateWithAI';

const Container = ({ fields }: ContainerInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<any>({
    name: '',
    description: '',
    audience: '',
    tone: '',
    personality: '',
    missionVisionAndValue: '',
    keywords: '',
    preferredWordsAndPhrases: '',
    wordsAndPhrasesToAvoid: ''
  });

  const [file, setFile] = useState<any>(null);
  const [base64, setBase64] = useState('');

  const onClearForm = () => {
    setForm({
      name: '',
      description: '',
      audience: '',
      tone: '',
      personality: '',
      missionVisionAndValue: '',
      keywords: '',
      preferredWordsAndPhrases: '',
      wordsAndPhrasesToAvoid: ''
    });

    setFile('');
    setBase64('');

    navigate('/brands');
  };

  const handleError = () => {
    return notify.error('Preencha todos os campos!');
  };

  const handleSubmit = () => {
    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);

        navigate('/brands');
      },
      error: () => {
        setLoading(false);
      }
    };

    return toast.promise(
      dispatch(createBrand({ ...form, image: base64 }, cb)),
      {
        loading: 'Criando a marca...',
        success: data => {
          setLoading(false);

          return <b>Marca criada com sucesso!</b>;
        },
        error: err => {
          setLoading(false);

          if (err.message) {
            return <b>{err.message}</b>;
          }

          return <b>Algum erro aconteceu, tente novamente</b>;
        }
      }
    );
  };

  const onSubmit = () => {
    const validate: any = [];

    Object.keys(fields).map(field => {
      if (form[field] === '') {
        const response = fields[field];

        if (response?.required) {
          validate.push(field);
        }
      }

      return field;
    });

    if (validate.length > 0) {
      return handleError();
    } else {
      return handleSubmit();
    }
  };

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const handleSelect = (event: SelectChangeEvent, type: string) => {
    if (type === 'tone') {
      setForm({
        ...form,
        tone: event.target.value
      });
    }
  };

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    convertToBase64(selectedFile);
  };

  const convertToBase64 = file => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // @ts-ignore
      setBase64(reader.result); // Armazena o Data URL completo para exibir a imagem
    };
  };

  const [generateWithAI, setGenerateWithAI] = useState(false);

  return (
    <>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} id="container-left-header">
              <div className={classes.titleContainer}>
                <p className={classes.title}>Criar nova marca</p>

                <p
                  className={classes.description}
                  // style={{ maxWidth: '600px' }}
                >
                  Bem-vindo à jornada de criação da sua marca! Aqui, você
                  compartilhará as definições de comunicação do seu negócio.{' '}
                  <br />
                  <br />
                  Com essas informações valiosas em mãos, a Clarice.ai estará
                  pronta para criar um guia único e memorável, alinhado com os
                  objetivos e valores da sua marca.
                </p>

                <TooltipContainer
                  title={
                    'Utilize nossa Inteligência Artificial e descreva sua marca em segundos!'
                  }
                  placement="right"
                >
                  <Button
                    variant="outlined"
                    onClick={(e: any): void => {
                      setGenerateWithAI(true);
                    }}
                    style={{
                      marginLeft: '0px',
                      marginTop: '5px',
                      marginBottom: '5px',
                      marginRight: '10px',
                      borderColor:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`,
                      color:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`
                    }}
                    sx={{
                      textTransform: 'none!important',
                      fontFamily: ['Inter'].join(',')
                    }}
                    startIcon={<AutoFixHighIcon />}
                  >
                    Gerar com IA
                  </Button>
                </TooltipContainer>
              </div>
            </Grid>

            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={generateWithAI ? 6 : 12}
                lg={generateWithAI ? 6 : 12}
                xl={generateWithAI ? 6 : 12}
              >
                <div className={classes.formContainer}>
                  <Box
                    component="form"
                    sx={{
                      '& > :not(style)': { width: '100%' }
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    {Object.keys(fields).map((field, index) => {
                      if (fields[field].type === 'input') {
                        return (
                          <TextField
                            key={field + index}
                            InputLabelProps={{
                              shrink: true
                            }}
                            className={classes.input}
                            id="outlined-basic"
                            label={fields[field].label}
                            placeholder={fields[field].placeholder}
                            multiline={
                              fields[field].rows && fields[field].rows > 1
                            }
                            rows={fields[field].rows || 1}
                            variant="outlined"
                            value={form[field]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void => handleChange(e, field)}
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                          />
                        );
                      }

                      if (fields[field].type === 'textarea') {
                        return (
                          <TextField
                            key={field + index}
                            InputLabelProps={{
                              shrink: true
                            }}
                            className={classes.input}
                            id="outlined-basic"
                            label={fields[field].label}
                            placeholder={fields[field].placeholder}
                            multiline={
                              fields[field].rows && fields[field].rows > 1
                            }
                            rows={fields[field].rows || 1}
                            variant="outlined"
                            value={form[field]}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void => handleChange(e, field)}
                            color={
                              themeMode.mode === 'light'
                                ? 'primary'
                                : 'secondary'
                            }
                          />
                        );
                      }

                      if (fields[field].type === 'select') {
                        return (
                          <FormControl
                            key={field + index}
                            sx={{
                              width: '100%',
                              marginTop: '5px',
                              marginBottom: '40px'
                            }}
                          >
                            <InputLabel
                              id="demo-select-small"
                              color={
                                themeMode.mode === 'light'
                                  ? 'primary'
                                  : 'secondary'
                              }
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                            >
                              {fields[field].label}
                            </InputLabel>
                            <Select
                              color={
                                themeMode.mode === 'light'
                                  ? 'primary'
                                  : 'secondary'
                              }
                              labelId="demo-select-small"
                              id="demo-select-small"
                              value={form[field]}
                              label={fields[field].label}
                              onChange={e => handleSelect(e, field)}
                              sx={{
                                fontFamily: ['Inter'].join(',')
                              }}
                            >
                              {fields[field].options &&
                                fields[field].options.map(el => (
                                  <MenuItem
                                    key={el.value}
                                    sx={{
                                      fontFamily: ['Inter'].join(',')
                                    }}
                                    value={el.value}
                                  >
                                    {el.description}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        );
                      }

                      if (fields[field] && field === 'image') {
                        return (
                          <div
                            key={field + index}
                            style={{
                              width: '100%',
                              marginTop: '5px',
                              marginBottom: '40px'
                            }}
                          >
                            <InputLabel
                              id="demo-select-small"
                              color={
                                themeMode.mode === 'light'
                                  ? 'primary'
                                  : 'secondary'
                              }
                              sx={{
                                fontFamily: ['Inter'].join(','),
                                marginLeft: '5px',
                                marginBottom: '5px'
                              }}
                            >
                              {fields.image.label}
                            </InputLabel>

                            <div className={classes.fileContainer}>
                              <label
                                htmlFor="file"
                                className={classes.fileLabel}
                              >
                                {file && file?.name
                                  ? 'Escolhido: ' + file.name
                                  : 'Escolher imagem'}
                              </label>
                              <input
                                name="file"
                                accept="image/*"
                                key={field + index}
                                className={classes.file}
                                type="file"
                                id="file"
                                placeholder={fields.image.placeholder}
                                value={form.image}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ): void => handleFileChange(e)}
                                color={
                                  themeMode.mode === 'light'
                                    ? 'primary'
                                    : 'secondary'
                                }
                              />
                            </div>
                          </div>
                        );
                      }

                      return null;
                    })}

                    {/*{base64 && (*/}
                    {/*  <div>*/}
                    {/*    <h3>Preview:</h3>*/}
                    {/*    <img*/}
                    {/*      src={base64}*/}
                    {/*      alt="File Preview"*/}
                    {/*      style={{ maxHeight: '200px' }}*/}
                    {/*    />*/}
                    {/*  </div>*/}
                    {/*)}*/}
                  </Box>
                </div>
              </Grid>

              {generateWithAI && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={generateWithAI ? 6 : 12}
                  lg={generateWithAI ? 6 : 12}
                  xl={generateWithAI ? 6 : 12}
                  id="container-right"
                >
                  <GenerateWithAI
                    form={form}
                    setForm={setForm}
                    setGenerateWithAI={setGenerateWithAI}
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} id="container-left-footer">
              <div className={classes.submitContainer}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      textAlign: 'right',
                      justifyContent: 'right'
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={onClearForm}
                      style={{
                        marginLeft: '0px',
                        // width: '100%',
                        // marginBottom: '10px',
                        marginRight: '10px',
                        borderColor:
                          themeMode.mode === 'light'
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.secondary.main}`,
                        color:
                          themeMode.mode === 'light'
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.secondary.main}`
                      }}
                      sx={{
                        textTransform: 'none!important',
                        fontFamily: ['Inter'].join(',')
                      }}
                    >
                      Voltar
                    </Button>

                    <LoadingButton
                      style={{
                        marginLeft: '0px',
                        // width: '100%',
                        backgroundColor:
                          themeMode.mode === 'light'
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.secondary.main}`,
                        color: loading
                          ? themeMode.mode === 'light'
                            ? `${theme.palette.primary.main}`
                            : `${theme.palette.secondary.main}`
                          : themeMode.mode === 'light'
                          ? `${theme.palette.text.white}`
                          : `${theme.palette.text.black}`
                      }}
                      loading={loading}
                      variant="contained"
                      onClick={onSubmit}
                      sx={{
                        textTransform: 'none!important',
                        fontFamily: ['Inter'].join(',')
                      }}
                    >
                      Salvar
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}></Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Container;
