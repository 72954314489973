import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
/* import NavBarPayment from './component/NavBar';
import PlanDescriptionPayment from './component/PlanDescription';
import PlanSelectPayment from './component/PlanSelect';
import GuaranteeBadge from './component/GuaranteeBadge'; */
import { useReduxState } from '../../../../../hooks/useReduxState';
import GuaranteeBadge from './components/GuaranteeBadge';
import NavBarPayment from './components/NavBar';
import PlanDescriptionPayment from './components/PlanDescription';
import PlanSelectPayment from './components/PlanSelect';
import useStyles from './styles';

const PaymentContainer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useReduxState();

  useEffect(() => {
    if (user.isSubscriber) {
      return navigate('/');
    }

    const urlParams = new URLSearchParams(window.location.search);
    const coupon = urlParams.get('c');
    if (coupon) {
      localStorage.setItem('coupon', coupon);
    }
  }, []);

  useEffect(() => {
    if (user.isSubscriber) {
      return navigate('/');
    }
  }, [user.isSubscriber]);

  if (user.isSubscriber) {
    return null;
  }

  const coupon = localStorage.getItem('CLARICE_BLACK_FRIDAY');

  return (
    <>
      <div style={{ overflowX: 'hidden', backgroundColor: '#fcfcfe' }}>
        <NavBarPayment />
        <Box className={classes.itemsContainer}>
          <Box className={classes.container} display="flex">
            <PlanDescriptionPayment />
            <GuaranteeBadge />
          </Box>
          <Box className={classes.container} display="flex">
            <PlanSelectPayment />
          </Box>
        </Box>
      </div>
    </>
  );
};

export default PaymentContainer;
