import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormatQuote } from '@mui/icons-material';
import useStyle from './style';

type CitationProps = {
  text?: any;
  author?: any;
};

const Citation = ({ text, author }: CitationProps) => {
  const classes = useStyle();

  return (
    <Box className={classes.citation} width={'100%'}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <FormatQuote className={classes.icon} />
        <Typography
          className={classes.text}
          dangerouslySetInnerHTML={{ __html: text }}
        />

        {/* <Typography component={'span'} className={classes.text}> */}
        {/*  {text} */}
        {/* </Typography> */}
      </Box>

      <Box
        display={'flex'}
        flexDirection={'row'}
        width={'100%'}
        justifyContent="flex-end"
      >
        <Typography
          className={classes.author}
          dangerouslySetInnerHTML={{ __html: author }}
        />

        {/* <Typography component={'span'} className={classes.author}> */}
        {/*  {author} */}
        {/* </Typography> */}
      </Box>
    </Box>
  );
};

export default Citation;
