import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  first: {},
  last: {
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px'
  },
  name: {
    font: 'normal normal 400 14px Inter!important',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    // color:
    //   theme.palette.mode === 'light' ? '#410A85' : theme.palette.secondary.main,
    color: '#410A85',
    opacity: 0.6,
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '8px 0px'
  },
  nameBlackFriday: {
    font: 'normal normal 400 14px Inter!important',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: '#000000',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '8px 0px'
  },
  price: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: 'bold!important',
    fontSize: '24px!important',
    lineHeight: '125%',
    textTransform: 'uppercase',
    // color:
    //   theme.palette.mode === 'light' ? '#39274B' : theme.palette.secondary.main,
    color: '#39274B',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '0px 0px'
  },
  type: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: 'normal!important',
    fontSize: '14px!important',
    lineHeight: '150%',
    textTransform: 'lowercase',
    // color:
    //   theme.palette.mode === 'light' ? '#655679' : theme.palette.text.white,
    color: '#655679',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '0px 0px'
  },
  automaticRenovation: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: 500,
    fontSize: '14px!important',
    lineHeight: '150%',
    textTransform: 'unset',
    color: '#9389A1',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '0px 0px'
  },
  descriptionCoupon: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: 500,
    fontSize: '13px!important',
    lineHeight: '150%',
    textTransform: 'unset',
    color: '#9389A1',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '0px 0px',
    marginTop: '2px'
  },
  button: {
    borderRadius: '6px',
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: 600,
    fontSize: '14px!important',
    lineHeight: '100%',
    textAlign: 'center',
    color: '#FFFFFF',
    height: '40px',
    width: theme.breakpoints.down('xs') ? '100%' : '115px',
    '&:hover': {
      background: '#09B386',
      opacity: 0.7
    }
  },
  buttonBlackFriday: {
    background: '#000000',
    borderRadius: '6px',
    textTransform: 'unset',
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: '600!important',
    fontSize: '14px!important',
    lineHeight: '100%',
    textAlign: 'center',
    color: '#FFFFFF',
    height: '40px',
    width: theme.breakpoints.down('xs') ? '100%' : '115px',
    '&:hover': {
      background: '#000000',
      opacity: 0.7
    }
  },
  yearPriceDescription: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: '500!important',
    fontSize: '14px!important',
    lineHeight: '150%',
    textTransform: 'unset',
    color: '#9389A1',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '0px 0px'
  },
  yearPrice: {
    fontWeight: 'bold'
  },
  discountContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flexStart',
    padding: '5px 8px',
    width: '115px',
    height: '22px',
    background: 'rgba(196, 148, 255, 0.15)',
    borderRadius: '6px',
    textAlign: 'center',
    marginBottom: '10px'
  },
  discount: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: '600!important',
    width: '100%!important',
    fontSize: '11px!important',
    lineHeight: '100%',
    textTransform: 'unset',
    color: '#7C24FB',
    textAlign: 'center',
    letterSpacing: '-0.01em'
  },
  discountContainerYear50: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flexStart',
    padding: '7px 8px',
    width: '115px',
    height: '23px',
    background: 'rgba(196, 148, 255, 0.37)',
    borderRadius: '4px!important',
    textAlign: 'center',
    marginBottom: '10px'
  },
  discountYear50: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: '600!important',
    width: '100%',
    fontSize: '11px!important',
    lineHeight: '100%',
    textTransform: 'unset',
    color: '#7C24FB!important',
    textAlign: 'center',
    letterSpacing: '-0.01em'
  },
  off: {
    font: 'normal normal 600 14px Inter!important',
    width: '100%',
    fontSize: '11px!important',
    lineHeight: '100%',
    textTransform: 'unset',
    color: '#7C24FB',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    background: 'rgba(196, 148, 255, 0.37)',
    borderRadius: '4px',
    marginLeft: '6px',
    padding: '2px 4px 2px 4px'
  },
  offBlackFriday: {
    font: 'normal normal 600 14px Inter!important',
    width: '100%',
    fontSize: '11px!important',
    lineHeight: '100%',
    textTransform: 'unset',
    color: '#ffffff',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    background: 'rgba(0,0,0,1)',
    borderRadius: '4px',
    marginLeft: '6px',
    padding: '4px 4px 2px 4px'
  },
  discountContainerBlackFriday: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flexStart',
    padding: '5px 8px',
    width: '115px',
    height: '22px',
    background: 'rgba(152,152,152,0.15)',
    borderRadius: '6px',
    textAlign: 'center',
    marginBottom: '10px'
  },
  discountBlackFriday: {
    fontFamily: 'Inter!important',
    fontStyle: 'normal!important',
    fontWeight: '600!important',
    width: '100%',
    fontSize: '11px!important',
    lineHeight: '100%',
    textTransform: 'unset',
    color: '#000000',
    textAlign: 'center',
    letterSpacing: '-0.01em'
  },
  activeBestPlan: {
    background: 'rgba(196, 148, 255, 0.5)',
    borderBottomLeftRadius: theme.breakpoints.down('xs')
      ? '0px!important'
      : '8px',
    borderTopLeftRadius: theme.breakpoints.down('xs')
      ? '12px!important'
      : '8px',
    borderTopRightRadius: theme.breakpoints.down('xs') && '12px!important',
    marginTop: theme.breakpoints.down('xs') && '10px!important'
  },
  borderBestPlan: {
    borderTopLeftRadius: '0px!important',
    borderTopRightRadius: theme.breakpoints.down('xs') && '0px !important',
    borderBottomLeftRadius: theme.breakpoints.down('xs')
      ? '12px!important'
      : '0px!important'
  },
  ctaContainer: {
    width: theme.breakpoints.down('xs') && '100%!important',
    marginTop: theme.breakpoints.down('xs') && '10px!important',
    alignSelf: 'flex-end'
  }
}));

export default Styles;
