import React from 'react';
import { TitleFormInterface } from './interface';
import useStyles from './style';

const TitleForm = ({ title, description }: TitleFormInterface) => {
  const classes: any = useStyles();

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>{title}</h2>
      {description && <p className={classes.description}>{description}</p>}
    </div>
  );
};

export default TitleForm;
