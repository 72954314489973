const descriptionReadability = score => {
  const readabilityRanges = {
    '90.01-100':
      'Seu texto é muito fácil de ler. Ele pode ser entendido por pessoas com pelo menos a 5a. série do fundamental. Facilmente compreendido por um aluno médio de 11 anos.',
    '80.01-90':
      'Seu texto é fácil de ler. Ele pode ser entendido por pessoas com pelo menos a 6a. série do fundamental. Português conversacional para consumidores.',
    '70.01-80':
      'Seu texto é bastante fácil de ler. Ele pode ser entendido por pessoas com pelo menos a 7a. série do fundamental.',
    '60.01-70':
      'Seu texto é simples. Ele pode ser entendido por pessoas com pelo menos a 8a. série do fundamental. Facilmente compreendido por um aluno com idade entre 13 a 15 anos.',
    '50.01-60':
      'Seu texto é difícil de ler. Ele pode ser entendido por pessoas com pelo menos o 1º ano do ensino médio.',
    '30.01-50':
      'Seu texto é bastante difícil de ler. Ele pode ser entendido por pessoas com pelo menos o ensino médio completo.',
    '10.01-30':
      'Seu texto é muito difícil de ler. Ele pode ser melhor compreendido por pessoas que estejam cursando uma graduação.',
    '0-10':
      'Seu texto é extremamente difícil de ler. Ele pode ser melhor compreendido por pessoas com ao menos uma graduação.'
  };

  for (const range in readabilityRanges) {
    const [min, max] = range.split('-').map(Number);
    if (score >= min && score <= max) {
      return readabilityRanges[range];
    }
  }
};

export default descriptionReadability;
