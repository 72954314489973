import React from 'react';
import useStyles from './styles';
import { PropsInterface } from './interface';
import { Box } from '@mui/material';

const CreditCardAcceptedItem = ({ flag, icon }: PropsInterface) => {
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems="center"
      className={classes.item}
    >
      <img src={icon} alt={flag} />
    </Box>
  );
};

export default CreditCardAcceptedItem;
