import React from 'react';
import useStyles from './styles';
import { ReactComponent as Badge } from '../../../../../../../assets/images/14-days-guarantee.svg';
const GuaranteeBadge = () => {
  const classes = useStyles();

  return <Badge className={classes.root} />;
};

export default GuaranteeBadge;
