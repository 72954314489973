import React from 'react';
import useStyles from './styles';
import benefits from '../../../../const/benefits';
import BenefitItem from '../BenefitItem';
import { Box } from '@mui/material';

const BenefitContainer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {benefits.map(
        (benefit: { title: string; description: string; icon: string }) => (
          <BenefitItem
            key={benefit.title}
            title={benefit.title}
            description={benefit.description}
            icon={benefit.icon}
          />
        )
      )}
    </Box>
  );
};

export default BenefitContainer;
