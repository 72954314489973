/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import CorrectionsRequests from '../repositories/corrections';
import { decreaseLoading, increaseLoading } from './loading';

export const sendFeedbackCorrections =
  (fields: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<any>>) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await CorrectionsRequests.sendFeedbackCorrections(
        fields
      );

      // console.log('payload', payload);

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };
