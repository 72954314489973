const GlobalContextInitial = {
  beta: true,
  language: 'PT-BR',
  chatHistoric: {
    active: false,
    toggle: active => active
  },
  modal: {
    project: {
      create: {
        open: false,
        toggle: () => null
      }
    },
    suggestion: {
      open: false,
      toggle: () => null
    },
    news: {
      open: false,
      toggle: () => null
    },
    conversion: {
      open: false,
      toggle: () => null
    },
    onboarding: {
      open: false,
      toggle: () => null
    },
    disabledRules: {
      open: false,
      toggle: () => null
    },
    dictionary: {
      open: false,
      toggle: () => null
    },
    corrections: {
      open: false,
      toggle: () => null
    }
  }
};

export default GlobalContextInitial;
