export const getScore = (data: any, user: any) => {
  if (!data || !user) return { score: { text: 0 } };

  const { corrections }: any = data;

  const multiplier = {
    deviations: 1,
    spellingAndGrammar: 0.5
  };

  if (corrections.deviations.length > 10) {
    multiplier.deviations = 0.5;
  }

  if (corrections.spellingAndGrammar.length > 10) {
    multiplier.spellingAndGrammar = 0.2;
  }

  const state = {
    corrections: {
      deviations: [],
      spellingAndGrammar: [],
      doubts: []
    },
    score: {
      deviations: {
        clareza: { score: 0, quantity: 0 },
        concisao: { score: 0, quantity: 0 },
        forca: { score: 0, quantity: 0 },
        originalidade: { score: 0, quantity: 0 },
        formatacao: { score: 0, quantity: 0 },
        correcao: { score: 0, quantity: 0 },
        premium: { score: 0, quantity: 0 }
      },
      spellingAndGrammar: {
        gramatica: { score: 0, quantity: 0 },
        ortografia: { score: 0, quantity: 0 },
        pontuacao: { score: 0, quantity: 0 }
      },
      doubts: {
        duvida: { score: 0, quantity: 0 },
        premium: { score: 0, quantity: 0 }
      },
      text: 100
    }
  };

  state.corrections.deviations = corrections.deviations.map(
    (deviation: any) => {
      const mainScore = Object.keys(deviation.score)[0];
      state.score.deviations[mainScore].score += deviation.score[mainScore];
      state.score.deviations[mainScore].quantity += 1;
      state.score.text -= multiplier.deviations;
      // state.score.text -= 0.5;
      // state.score.text -= deviation.score[mainScore] * 0.5;
      // console.log(deviation.score[mainScore], deviation.score[mainScore] * 0.5);
      return { ...deviation, mainScore };
    }
  );

  // state.corrections.doubts = (corrections.doubts || []).map((doubt): any => {
  //   const mainScore = Object.keys(doubt.score)[0];
  //   state.score.doubts[mainScore].score += doubt.score[mainScore];
  //   state.score.doubts[mainScore].quantity += 1;
  //   return { ...doubt, mainScore };
  // });

  state.corrections.spellingAndGrammar = (
    corrections.spellingAndGrammar || []
  ).map((sagItem): any => {
    if (sagItem?.id && !sagItem.type) {
      const mainScore = Object.keys(sagItem.score)[0];

      state.score.spellingAndGrammar[mainScore].quantity += 1;
      state.score.text -=
        sagItem.score[mainScore] * multiplier.spellingAndGrammar;

      return { ...sagItem, mainScore };
    } else {
      state.score.spellingAndGrammar[sagItem.type].score += 1;
      state.score.spellingAndGrammar[sagItem.type].quantity += 1;
      state.score.text -= multiplier.spellingAndGrammar;

      return sagItem;
    }
  });

  state.score.text = state.score.text > 0 ? state.score.text : 0;

  // if (!user.isSubscriber) {
  //   if (state.score.text > 0) {
  //     const quantity = data?.premiumDeviations?.quantity || 0;
  //
  //     if (state.score.text - quantity >= 0) {
  //       state.score.text = state.score.text - quantity;
  //     }
  //   }
  // }

  return { corrections: state.corrections, score: state.score };
};
