import { DisabledRuleItemInterface } from './interface';
import useStyles from './style';
import { Box, Button, Divider } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';

const DisabledRuleItem = ({
  el,
  title,
  alternative,
  divider,
  loading,
  onEnable
}: DisabledRuleItemInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  if (alternative) {
    return (
      <p className={classes.descriptionAlternative}>
        Nenhuma regra desativada até o momento...
      </p>
    );
  }

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
        width={'100%'}
      >
        <p className={classes.description}>{title}</p>
        <Button
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
          size={'small'}
          variant="outlined"
          classes={{
            root: classes.enBtnRoot
          }}
          className={classes.enBtn}
          disabled={loading}
          onClick={() => onEnable(el.id)}
          sx={{
            textTransform: 'none!important',
            fontFamily: 'Inter'
          }}
        >
          Ativar
        </Button>
      </Box>
      {divider && <Divider className={classes.divider} />}
    </>
  );
};

export default DisabledRuleItem;
