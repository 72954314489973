import { Box, Typography } from '@mui/material';
import React from 'react';
import { PropsInterface } from './interface';
import useStyles from './styles';
// @ts-ignore

const TestimonialItem = ({
  testimonialPersonIcon,
  person,
  profileImage,
  description,
  role
}: PropsInterface) => {
  const classes = useStyles();

  return (
    <Box display={'flex'} flexDirection={'row'} className={classes.item}>
      <Box className={classes.iconContainer}>
        <img
          src={testimonialPersonIcon}
          alt="Testimonial Person"
          className={classes.icon}
        />
      </Box>
      <Box>
        <Typography className={classes.description}>{description}</Typography>
        <Typography className={classes.person}>
          <strong>{person}</strong>
          <br />
          <Typography variant={'inherit'} className={classes.role}>
            {role}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default TestimonialItem;
