import React, { useEffect, useState } from 'react';
import useStyles from './style';
import clsx from 'clsx';
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from '@mui/icons-material/Close';
import TooltipContainer from '../../../../../../../components/TooltipContainer';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  getProcessReadability,
  updateTextualGenre
} from '../../../../../../../../../stories/actions/editor';
import { useAppDispatch } from '../../../../../../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../../../../../../hooks/useReduxState';

type ScoreSubitemGenreProps = {
  description?: string;
  loading?: any;
  total?: any;
  info?: any;
  space?: any;
  time?: any;
  type?: any;
  mt?: any;
};

const ScoreSubitemGenre = ({
  description,
  loading,
  total,
  info,
  space,
  time,
  type,
  mt = 0
}: ScoreSubitemGenreProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const options = [
    {
      id: 'news',
      value: 'Notícias'
    },
    {
      id: 'blog',
      value: 'Blog'
    },
    {
      id: 'conversation',
      value: 'Conversação'
    },
    {
      id: 'fiction',
      value: 'Ficção'
    },
    {
      id: 'scientificResearch',
      value: 'Pesquisa Científica'
    }
  ];

  const [genre, setGenre] = useState('');

  const [editing, setEditing] = useState(false);

  const { editor, user, documents } = useReduxState();

  const cb = {
    success: () => {
      setEditing(false);
    },
    error: () => {
      setEditing(false);
    }
  };

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;

    setGenre(value);

    dispatch(updateTextualGenre(editor.text, value, cb));
  };

  useEffect(() => {
    setGenre(total);
  }, [total]);

  const selected =
    options.find(option => option.id === genre)?.value || 'Não Identificado';

  return (
    <Box
      display={'flex'}
      flexDirection={isMobile && time ? 'column' : 'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      className={clsx(classes.container, { [classes.space]: space })}
      mt={mt}
    >
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <Typography
          sx={{
            textAlign: 'left',
            color: theme.palette.text.primary,
            fontSize: isMobile ? '14px' : '15px',
            fontWeight: '400',
            lineHeight: '22px'
          }}
        >
          {description}
        </Typography>

        {!loading && !editing && (
          <EditIcon className={classes.infoIcon} onClick={handleEdit} />
        )}

        {!loading && editing && (
          <CloseIcon className={classes.infoIcon} onClick={handleCancel} />
        )}

        {info && (
          <TooltipContainer title={info || ''} placement="bottom">
            <InfoOutlined className={classes.infoIcon} />
          </TooltipContainer>
        )}
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        {loading && !type && <Skeleton variant="text" width={50} height={22} />}

        {loading && type && type === 'legibility' && (
          <Skeleton variant="text" width={100} height={22} />
        )}

        {!loading && !editing && (
          <Typography
            className={classes.total}
            sx={{
              fontSize: isMobile ? '14px' : '16px'
            }}
          >
            {selected}
          </Typography>
        )}

        {!loading && editing && (
          <Typography
            className={classes.total}
            sx={{
              fontSize: isMobile ? '14px' : '16px'
            }}
          >
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                marginRight: '-2px'
              }}
              variant="standard"
              size="small"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={genre}
                onChange={handleChange}
              >
                {/*<MenuItem value="">Não Identificado</MenuItem>*/}
                {options.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ScoreSubitemGenre;
