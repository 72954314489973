import React from 'react';
import { LinkContainerInterface } from './interface';
import useStyles from './style';

const LinkContainer = ({ children }: LinkContainerInterface) => {
  const classes: any = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default LinkContainer;
