/* eslint-disable no-undef */
import {
  TEMPLATES_GET_ALL_COMPLETIONS,
  TEMPLATE_COMPLETION,
  TEMPLATES_COMPLETION_CLEAR,
  CHAT_UPDATE_STATUS,
  TEMPLATES_COMPLETION_UPDATE_STATUS,
  USER_UPDATE_PROJECT_ACTIVE
} from '../actions/actionTypes';

const initialState: reducers.TemplatesReducer = {
  filds: null,
  response: null,
  completions: []
};

const templatesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TEMPLATE_COMPLETION:
      state = {
        ...state,
        response: action.payload
      };
      break;
    case TEMPLATES_GET_ALL_COMPLETIONS:
      state = {
        ...state,
        completions: action.payload
      };
      break;
    case TEMPLATES_COMPLETION_CLEAR:
      state = {
        ...state,
        response: null
      };
      break;
    case TEMPLATES_COMPLETION_UPDATE_STATUS:
      // eslint-disable-next-line no-case-declarations
      const index = state.completions.findIndex(
        el => el._id === action.payload._id
      );

      // eslint-disable-next-line no-case-declarations
      const { completions } = state;

      if (index !== -1) {
        completions[index] = action.payload;
      }

      state = {
        ...state,
        response: state.response !== null ? action.payload : null,
        completions: completions
      };
      break;
    case USER_UPDATE_PROJECT_ACTIVE:
      state = {
        ...state,
        response: null,
        completions: []
      };
      break;

    default:
      return state;
  }

  return state;
};

export default templatesReducer;
