import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import { TextField, Grid, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useReduxState } from '../../../../../hooks/useReduxState';
import { updateUser } from '../../../../../stories/actions/user';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import InputMask from 'react-input-mask';

const EditProfile: React.FC = () => {
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const { user } = useReduxState();

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-undef
  const [form, setForm] = useState<models.User>({
    _id: user.detail?._id as string,
    name: '',
    email: '',
    phone: ''
  });

  useEffect(() => {
    if (user && user.detail) {
      setForm({
        _id: user.detail?._id as string,
        name: user.detail?.name as string,
        email: user.detail?.email as string,
        phone: user.detail?.phone as string
      });
    }

    // dispatch(getUserById(user?._id as string));
  }, [user]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const onSubmit = async () => {
    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(updateUser(form, cb));
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
          <Grid item xs={12}>
            <div className={classes.contentContainer}>
              <div className={classes.formContainer}>
                <p className={classes.title}>Editar perfil</p>
                <p
                  className={classes.description}
                  style={{
                    color:
                      themeMode.mode === 'light'
                        ? `${theme.palette.text.black}`
                        : `${theme.palette.text.white}`
                  }}
                >
                  Configurações do perfil do usuário
                </p>
                <Box
                  component="form"
                  sx={{
                    '& > :not(style)': { width: '100%' }
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    className={classes.input}
                    id="outlined-basic"
                    label="Nome"
                    variant="outlined"
                    placeholder="Digite seu nome..."
                    multiline
                    value={form.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleChange(e, 'name')
                    }
                    color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                    sx={{
                      fontFamily: 'Inter'
                    }}
                  />

                  <TextField
                    className={classes.input}
                    id="outlined-basic"
                    label="E-mail"
                    variant="outlined"
                    placeholder="Digite seu e-mail..."
                    value={form.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleChange(e, 'email')
                    }
                    color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                    sx={{
                      fontFamily: 'Inter'
                    }}
                  />

                  <InputMask
                    mask="(99) 99999-9999"
                    value={form.phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                      handleChange(e, 'phone')
                    }
                  >
                    {() => (
                      <TextField
                        className={classes.input}
                        id="outlined-basic"
                        label="Telefone"
                        variant="outlined"
                        placeholder="Digite seu telefone..."
                        color={
                          themeMode.mode === 'light' ? 'primary' : 'secondary'
                        }
                        sx={{
                          fontFamily: 'Inter'
                        }}
                      />
                    )}
                  </InputMask>
                </Box>
                <Grid item xs={12} className={classes.submitContainer}>
                  <LoadingButton
                    style={{
                      fontFamily: 'Inter',
                      marginLeft: '0px',
                      backgroundColor:
                        themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`,
                      color: loading
                        ? themeMode.mode === 'light'
                          ? `${theme.palette.primary.main}`
                          : `${theme.palette.secondary.main}`
                        : themeMode.mode === 'light'
                        ? `${theme.palette.text.white}`
                        : `${theme.palette.text.black}`
                    }}
                    sx={{
                      textTransform: 'none!important'
                    }}
                    loading={loading}
                    variant="contained"
                    onClick={onSubmit}
                  >
                    Salvar
                  </LoadingButton>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditProfile;
