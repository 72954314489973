import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  container: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
      width: '100%',
      marginLeft: 0
    }
  },
  itemsContainer: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 80px)',
    marginTop: '80px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  }
}));

export default Styles;
