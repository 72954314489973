import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  title: {
    color: theme.palette.mode === 'light' ? `#303030` : '#fff',
    fontSize: '14px!important',
    fontWeight: 500,
    fontStyle: 'normal',
    paddingBottom: '2px'
  },
  icon: {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0'
  },
  iconButton: {
    width: '18px',
    height: '18px'
  }
}));

export default useStyles;
