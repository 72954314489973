import { convertToRaw, EditorState, SelectionState, Modifier } from 'draft-js';

const removeCorrectionEntities = editorState => {
  let contentStateWithoutEntities = editorState.getCurrentContent();
  const { blocks, entityMap } = convertToRaw(contentStateWithoutEntities);
  blocks.forEach(block => {
    if (block.entityRanges) {
      block.entityRanges.forEach(entityRange => {
        if (entityMap[entityRange.key].type !== 'CORRECTION') {
          return;
        }

        const selection = SelectionState.createEmpty(block.key)
          .set('anchorOffset', entityRange.offset)
          .set('focusOffset', entityRange.offset + entityRange.length);

        contentStateWithoutEntities = Modifier.applyEntity(
          contentStateWithoutEntities,
          selection,
          null
        );
      });
    }
  });
  // console.timeEnd('removeCorrectionEntities');

  return EditorState.set(editorState, {
    currentContent: contentStateWithoutEntities
  });
};

export default removeCorrectionEntities;
