import { ENTITY_TYPE, INLINE_STYLE, BLOCK_TYPE } from 'draftail';
import CustomIconToolbar from '../components/CustomIconToolbar';

const ALIGN_TEXT_BLOCK: any = {
  LEFT: {
    type: 'text-align-left',
    icon: <CustomIconToolbar type="left" />
  },
  CENTER: {
    type: 'text-align-center',
    icon: <CustomIconToolbar type="center" />
  },
  RIGHT: {
    type: 'text-align-right',
    icon: <CustomIconToolbar type="right" />
  },
  JUSTIFY: {
    type: 'text-align-justify',
    icon: <CustomIconToolbar type="justify" />
  }
};

export const HTMLExporterConfig = {
  // eslint-disable-next-line react/display-name
  blockToHTML: block => {
    if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
      return <blockquote />;
    }

    // // Discard atomic blocks, as they get converted based on their entity.
    if (block.type === BLOCK_TYPE.ATOMIC) {
      return {
        start: '',
        end: ''
      };
    }

    if (block.type === ALIGN_TEXT_BLOCK.LEFT.type) {
      return <div style={{ textAlign: 'left' }}>{block.text}</div>;
    }

    if (block.type === ALIGN_TEXT_BLOCK.CENTER.type) {
      return <div style={{ textAlign: 'center' }}>{block.text}</div>;
    }

    if (block.type === ALIGN_TEXT_BLOCK.RIGHT.type) {
      return <div style={{ textAlign: 'right' }}>{block.text}</div>;
    }

    if (block.type === ALIGN_TEXT_BLOCK.JUSTIFY.type) {
      return <div style={{ textAlign: 'justify' }}>{block.text}</div>;
    }

    return null;
  },

  entityToHTML: (entity, originalText) => {
    if (entity.type === ENTITY_TYPE.LINK) {
      return <a href={entity.data.url}>{originalText}</a>;
    }

    if (entity.type === ENTITY_TYPE.IMAGE) {
      return <img src={entity.data.src} alt={entity.data.alt} />;
    }

    if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
      return <hr />;
    }

    return originalText;
  }
};

export const state = {
  config: {
    id: 'index',
    ariaDescribedBy: 'demo-editor-index',
    placeholder: 'Cole seu texto aqui ou pressione "/" para escrever com IA',
    enableHorizontalRule: true,
    spellCheck: false,
    stripPastedStyles: false,
    entityTypes: [ENTITY_TYPE.LINK, ENTITY_TYPE.IMAGE],
    blockTypes: [
      {
        type: BLOCK_TYPE.HEADER_ONE,
        icon: <CustomIconToolbar type="HEADER_ONE" />
      },
      {
        type: BLOCK_TYPE.HEADER_TWO,
        icon: <CustomIconToolbar type="HEADER_TWO" />
      },
      {
        type: BLOCK_TYPE.HEADER_THREE,
        icon: <CustomIconToolbar type="HEADER_THREE" />
      },
      {
        type: BLOCK_TYPE.HEADER_FOUR,
        icon: <CustomIconToolbar type="HEADER_FOUR" />
      },
      {
        type: BLOCK_TYPE.BLOCKQUOTE,
        icon: <CustomIconToolbar type="blockquote" />
      },
      {
        type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
        icon: <CustomIconToolbar type="unordered" />
      },
      {
        type: BLOCK_TYPE.ORDERED_LIST_ITEM,
        icon: <CustomIconToolbar type="ordered" />
      },
      ALIGN_TEXT_BLOCK.LEFT,
      ALIGN_TEXT_BLOCK.CENTER,
      ALIGN_TEXT_BLOCK.RIGHT,
      ALIGN_TEXT_BLOCK.JUSTIFY
    ],
    blockTypesMobile: [
      ALIGN_TEXT_BLOCK.LEFT,
      ALIGN_TEXT_BLOCK.CENTER,
      ALIGN_TEXT_BLOCK.RIGHT,
      ALIGN_TEXT_BLOCK.JUSTIFY,
      {
        type: BLOCK_TYPE.UNORDERED_LIST_ITEM,
        icon: <CustomIconToolbar type="unordered" />
      },
      {
        type: BLOCK_TYPE.ORDERED_LIST_ITEM,
        icon: <CustomIconToolbar type="ordered" />
      }
    ],
    inlineStyles: [
      { type: INLINE_STYLE.BOLD, icon: <CustomIconToolbar type="bold" /> },
      { type: INLINE_STYLE.ITALIC, icon: <CustomIconToolbar type="italic" /> },
      {
        type: INLINE_STYLE.STRIKETHROUGH,
        icon: <CustomIconToolbar type="strikethrough" />
      },
      {
        type: INLINE_STYLE.UNDERLINE,
        icon: <CustomIconToolbar type="underline" />
      }
    ],
    enableLineBreak: false
  },
  suggestion: {
    INSERT_ACTION_LABEL: 'insert-suggestion',
    PREFIX: '/',
    OPTIONS: [
      { id: 'ia-writer', description: 'Peça para a IA escrever...' },
      { id: 'insert-text', description: 'Completar texto' },
      // { id: 'blog-post', description: 'Post para Blog' },
      { id: 'create-paragraph', description: 'Criar parágrafo' },
      { id: 'brainstorm-ideas', description: 'Gerar Ideias' }
    ]
  }
};
