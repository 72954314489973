import React from 'react';
import { ContainerAuthInterface } from './interface';
import useStyles from './style';

const ContainerAuth = ({ children }: ContainerAuthInterface) => {
  const classes: any = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default ContainerAuth;
