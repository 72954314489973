import React from 'react';
import { LoadingContainerInterface } from './interface';
import useStyles from './style';
import { Modal } from '@mui/material';
import LoadingIcon from '../../../../assets/images/loading.svg';
// import Logo from '../../../../assets/images/auth/logo.svg';

const LoadingContainer = ({ loading = false }: LoadingContainerInterface) => {
  const classes = useStyles();

  return (
    <Modal open={loading} className={classes.root}>
      <img src={LoadingIcon} alt="Clarice.ai" className={classes.img} />
    </Modal>
  );
};

export default LoadingContainer;
