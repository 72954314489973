import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'row',
    alignItems: 'center',
    height: '50px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      height: 'auto'
    }
  },
  container: {
    marginTop: '2rem',
    width: '949px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    textTransform: 'none',
    paddingBottom: '0px!important',
    [theme.breakpoints.down(1250)]: {
      width: '700px'
    },
    [theme.breakpoints.down(600)]: {
      width: '300px',
      flexDirection: 'column',
      gap: '1rem',
      padding: '0px!important'
    }
  },
  searchBar: {
    background:
      theme.palette.mode === 'light'
        ? '#F2F2F2!important'
        : '#323232!important',
    height: '53px',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '10px',
    '& > img': {
      margin: '0px 19px'
    }
  },
  button: {
    marginLeft: '16px',
    whiteSpace: 'nowrap',
    // minWidth: '140px',
    width: '100%',
    height: '50px',
    textTransform: 'unset',
    maxWidth: 'unset',
    font: ' normal normal 400 15px/18px Inter',
    letterSpacing: 0,
    [theme.breakpoints.down(600)]: {
      width: '100%',
      margin: 0,
      marginBottom: '16px'
    }
  }
}));

export default useStyles;
