import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    position: 'absolute',
    top: '-155px',
    left: 0,
    padding: '0.5rem',
    width: '210px',
    height: '145px',
    borderRadius: '10px!important',
    transition: '500ms easy-in',
    zIndex: '1000',
    overflow: 'auto',
    backdropFilter: 'blur(3px)',
    border:
      theme.palette.mode === 'light' ? ' 1.5px solid #E7EBF0!important' : 'none'
  },
  title: {
    color: theme.palette.mode === 'light' ? '#303030' : '#fff',
    fontSize: '15px!important',
    marginBottom: '2px!important'
  },
  projectName: {
    fontSize: '20px!important',
    marginBottom: '5px!important',
    fontWeight: '700'
  },
  list: {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
    position: 'relative',
    overflow: 'auto',
    fontFamily: 'Inter',
    maxHeight: 200,
    '& ul': { padding: 0 },
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `${theme.palette.background.darkGrey}`,
      borderRadius: '20px',
      border: '3px solid transparent'
    }
  },
  listItem: {
    fontFamily: 'Inter',
    transition: 'backgroundColor 500ms easy-in-out',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? `${theme.palette.background.lighterGrey}`
          : `${theme.palette.background.lighterGrey}`
    },
    '&::-webkit-scrollbar': {
      width: '12px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'orange'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'blue',
      borderRadius: '20px',
      border: '3px solid orange'
    }
  }
}));

export default useStyles;
