import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    backgroundColor: '#ffffff',
    padding: ' 2rem 5rem',
    [theme.breakpoints.down(1150)]: {
      padding: '0rem 1rem'
    }
  },
  logo: {
    [theme.breakpoints.down(1150)]: {
      justifyContent: 'center'
    }
  },
  planHeader: {
    justifyContent: 'space-between',
    [theme.breakpoints.down(1150)]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column'
    }
  },
  planSelect: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(1150)]: {
      marginTop: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    }
  },
  planContainer: {
    [theme.breakpoints.down(1150)]: {
      flexDirection: 'column'
    }
  },
  iconContainer: {
    marginRight: '20px',
    minWidth: '80px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px'
    }
  },
  title: {
    font: ' normal normal 400 24px Inter!important',
    color: '#410A85',
    // color:
    //   theme.palette.mode === 'light' ? '#410A85' : theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px !important'
    }
  },
  subtitle: {
    marginTop: '8px!important',
    font: ' normal normal 400 18px Inter!important',
    color: '#4F3D66'
    // color:
    //   theme.palette.mode === 'light' ? '#4F3D66' : theme.palette.secondary.dark
  }
}));

export default Styles;
