import React, { useContext } from 'react';
import { MotionInterface } from './interface';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';

const Motion = ({}: MotionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const baseURL = `/videos/`;

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      style={{
        width: '100%',
        marginTop: isMobile ? '24px' : '32px',
        height: isMobile ? 'auto' : '242px',
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {isMobile && (
        <img src={baseURL + 'editora.gif'} alt="Clarice Editora"></img>
      )}

      {!isMobile && (
        <img
          src={baseURL + 'editora.gif'}
          height={'242px'}
          alt="Clarice Editora"
        ></img>
      )}

      {/*eslint-disable-next-line jsx-a11y/media-has-caption*/}
      {/*<video width="640" height="360" controls>*/}
      {/*  <source src={baseURL + 'editora.mp4'} type="video/mp4" />*/}
      {/*  Seu navegador não suporta o elemento de vídeo.*/}
      {/*</video>*/}
    </Box>
  );
};

export default Motion;
