import React, { useContext } from 'react';
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ScoreSubitem from '../ScoreSubitem';
import CircularScore from '../../../../CircularScore';
import { useReduxState } from '../../../../../../../../../hooks/useReduxState';
import { getScore } from '../../../../../function/getScore';
import ScoreSubitemGenre from '../ScoreSubitemGenre';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';

type ScoreItemProps = {
  title?: any;
  subtitle?: any;
  description?: any;
  type?: any;
  loading?: any;
  characters?: any;
  words?: any;
  paragraphs?: any;
  readingTime?: any;
  speakingTime?: any;
  sentences?: any;
  uniqueWords?: any;
  rareWords?: any;
  wordLength?: any;
  sentenceLength?: any;
  readabilityScore?: any;
  legibility?: any;
  sentiment?: any;
  genre?: any;
  style?: any;
  complexWord?: any;
  summary?: any;
};

const ScoreItem = ({
  title,
  subtitle,
  description,
  type,
  loading,
  characters,
  words,
  paragraphs,
  readingTime,
  speakingTime,
  sentences,
  uniqueWords,
  rareWords,
  wordLength,
  sentenceLength,
  readabilityScore,
  legibility,
  sentiment,
  genre,
  style,
  complexWord,
  summary
}: ScoreItemProps) => {
  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const { documents, user } = useReduxState();

  const { score } = getScore(documents.detail, user);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const types = {
    performance() {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box display={'flex'}>
            <Typography
              style={{
                textAlign: 'justify',
                color: theme.palette.text.primary,
                fontSize: isMobile ? '14px' : '16px',
                paddingTop: isMobile ? '5px' : '20px',
                lineHeight: '22px'
              }}
            >
              {description}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            style={{
              paddingTop: isMobile ? '0px' : '20px',
              paddingLeft: '20px'
            }}
          >
            <CircularScore
              size={55}
              score={score.text || 0}
              // score={editor.process?.readability.score.flesh || 0}
            />
          </Box>
        </Box>
      );
    },
    summary() {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <Box
            display={'flex'}
            style={{
              width: '100%'
            }}
          >
            {loading && (
              <Skeleton
                variant="text"
                width="100%!important"
                height={100}
                style={{
                  width: '100%',
                  minWidth: isMobile ? '280px' : '497px',
                  paddingTop: '15px',
                  lineHeight: isMobile ? '16px' : '23.68px'
                }}
              />
            )}

            {!loading && (
              <Typography
                style={{
                  // textAlign: 'justify',
                  color:
                    themeMode.mode === 'dark'
                      ? theme.palette.text.primary
                      : '#39274B',
                  fontSize: isMobile ? '12px' : '14px',
                  // fontStyle: 'italic',
                  paddingTop: '15px',
                  lineHeight: isMobile ? '16px' : '23.68px'
                }}
              >
                {summary}
              </Typography>
            )}
          </Box>
        </Box>
      );
    },
    wordCount() {
      return (
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
        >
          <Box width={'100%'} sx={{ marginRight: '15px' }}>
            {isMobile && (
              <ScoreSubitem description="Parágrafos" total={paragraphs} />
            )}
            <ScoreSubitem description="Caracteres" total={characters} />
            <ScoreSubitem description="Palavras" total={words} />
            <ScoreSubitem description="Frases" total={sentences} />
          </Box>
          <Box width={'100%'} sx={{ marginLeft: '15px' }}>
            {!isMobile && (
              <ScoreSubitem description="Parágrafos" total={paragraphs} />
            )}
            <ScoreSubitem
              description="Tempo de leitura"
              total={readingTime}
              time={isMobile}
            />
            <ScoreSubitem
              description="Tempo de fala"
              total={speakingTime}
              time={isMobile}
            />
          </Box>
        </Box>
      );
    },
    readability() {
      return (
        <Box alignItems={'center'} width={'100%'}>
          <ScoreSubitem
            description="Média de Tamanho das Palavras"
            loading={loading}
            total={wordLength}
          />

          <ScoreSubitem
            description="Média de Palavras por Sentença"
            loading={loading}
            total={sentenceLength}
          />

          <ScoreSubitem
            description="Pontuação"
            loading={loading}
            total={readabilityScore}
            // info="In the Flesch reading-ease test, higher scores indicate material that is easier to read"
            info="A pontuação vai de 0 a 100. Pontuações altas indicam que o texto é fácil de ler."
            // info="No teste de facilidade de leitura Flesch, pontuações mais altas indicam material que é mais fácil de ler"
          />

          <Typography
            style={{
              color:
                themeMode.mode === 'dark'
                  ? theme.palette.text.primary
                  : '#39274B',
              fontSize: isMobile ? '12px' : '14px',
              // fontStyle: 'italic',
              paddingTop: '15px',
              lineHeight: isMobile ? '16px' : '23.68px'
            }}
          >
            {description}
          </Typography>
        </Box>
      );
    },
    legibility() {
      return (
        <Box width={'100%'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            // alignItems={'center'}
            width={'100%'}
          >
            <Box width={'100%'} sx={{ marginRight: '15px' }}>
              <ScoreSubitem
                description="Tamanho das Palavras"
                loading={loading}
                total={wordLength}
              />

              <ScoreSubitem
                description="Palavras por Sentença"
                loading={loading}
                total={sentenceLength}
              />

              <ScoreSubitem
                description="Palavras Complexas"
                loading={loading}
                total={String(complexWord) + '%'}
                info="Medimos a profundidade do vocabulário identificando palavras que não estão entre as 5.000  mais usadas em Português."
              />

              <ScoreSubitem
                description="Pontuação de Leitura"
                loading={loading}
                total={legibility.score.string}
                info="A pontuação vai de 0 a 100. Pontuações altas indicam que o texto é fácil de ler."
                mt={2}
              />
            </Box>
            <Box width={'100%'} sx={{ marginLeft: '15px' }}>
              <ScoreSubitem
                type="legibility"
                description="Formalidade"
                loading={loading}
                total={style}
              />

              <ScoreSubitemGenre
                type="legibility"
                description="Gênero"
                loading={loading}
                total={genre}
              />

              <ScoreSubitem
                type="legibility"
                description="Sentimento"
                loading={loading}
                total={sentiment}
              />
            </Box>
          </Box>

          <Box alignItems={'center'} width={'100%'}>
            <Typography
              style={{
                color:
                  themeMode.mode === 'dark'
                    ? theme.palette.text.primary
                    : '#39274B',
                fontSize: isMobile ? '12px' : '15px',
                // fontStyle: 'italic',
                paddingTop: '15px',
                lineHeight: isMobile ? '16px' : '23.68px'
              }}
            >
              {legibility.label}
            </Typography>
          </Box>
        </Box>
      );
    },
    vocabulary() {
      return (
        <Box alignItems={'center'} width={'100%'}>
          <ScoreSubitem
            description="Palavras únicas"
            total={uniqueWords}
            info="Measures vocabulary diversity by calculating the number of unique words"
          />
          <ScoreSubitem
            description="Palavras raras"
            total={rareWords}
            info="Measures depth of vocabulary by identifying words that are not among the 5,000 most commom English words."
          />
        </Box>
      );
    }
  };

  return (
    <Box style={{ width: '100%', paddingTop: '10px', paddingBottom: '15px' }}>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        style={{
          width: '100%',
          borderBottom: '1px solid #e4e6f2',
          paddingTop: '5px',
          paddingBottom: '5px'
        }}
      >
        <Typography
          style={{
            textAlign: 'left',
            color: `${theme.palette.text.primary}`,
            fontSize: isMobile ? '18px' : '22px',
            fontWeight: '700',
            paddingBottom: '5px'
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            style={{
              textAlign: 'left',
              color: `${theme.palette.text.primary}`,
              fontSize: '12px',
              fontWeight: '300',
              paddingBottom: '5px',
              lineHeight: '16px'
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      {types[type] && types[type]()}
    </Box>
  );
};

export default ScoreItem;
