/* eslint-disable no-undef */
import CreditsRequest from '../repositories/credits';

import { CREDITS_DETAIL } from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import analytics from '../../utils/function/analytics';

export const getCredits = () => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('credit', 'credits-all');

    const payload: models.Credits = await CreditsRequest.getCredits();
    dispatch({
      payload,
      type: CREDITS_DETAIL
    });
  } catch (err) {
    if (err instanceof Error) {
      // console.log(err.message);
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const clearCreditsDetails = () => (dispatch: any) => {
  dispatch({
    payable: null,
    type: CREDITS_DETAIL
  });
};
