import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  container: {
    paddingTop: '50px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '20px'
    }
  }
}));

export default Styles;
