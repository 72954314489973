import withAuth from '../../utils/axios/withAuth';

const ChatRequests = {
  chatConversation: async (message: string, language: string) => {
    const { data } = await withAuth().post(`/chat`, {
      message,
      language
    });

    return data;
  },
  chatUpdateStatus: async (_id: string, type: string, action: string) => {
    const { data } = await withAuth().put(
      `/chat/status/${type}/${action}/${_id}`
    );

    return data;
  },
  chatClear: async () => {
    const { data } = await withAuth().put(`/chat/clear`);

    return data;
  },
  getChatConversationAll: async () => {
    const { data } = await withAuth().get(`/chat`);

    return data;
  }
};

export default ChatRequests;
