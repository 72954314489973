import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  container: {
    width: '100%',
    paddingTop: '10px'
  },
  total: {
    textAlign: 'left',
    color:
      theme.palette.mode === 'light'
        ? `${theme.palette.primary.main}`
        : `${theme.palette.secondary.main}`,
    fontWeight: '600!important',
    lineHeight: '22px'
  },
  space: {
    paddingRight: '10px'
    // paddingLeft: '20px'
  },
  infoIcon: {
    width: '0.8em',
    height: '0.8em',
    marginLeft: '5px',
    color: '#707070',
    fontSize: '20px!important',
    // width: '0.5px',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      color:
        theme.palette.mode === 'light'
          ? `${theme.palette.primary.main}`
          : `${theme.palette.secondary.main}`
    }
  }
}));

export default Styles;
