import { DisabledRuleNotFoundInterface } from './interface';
import useStyles from './style';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';

const DisabledRuleNotFound = ({}: DisabledRuleNotFoundInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  return <p className={classes.description}>Carregando...</p>;
};

export default DisabledRuleNotFound;
