import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';

import {
  Badge,
  Box,
  Button,
  Collapse,
  LinearProgress,
  Typography,
  useTheme
} from '@mui/material';
import { ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material';
import { useAppDispatch } from '../../../../../../../../hooks/useAppDispatch';
import {
  getActiveCategory,
  getActiveCategoryType
} from '../../../../../../../../stories/actions/editor';
import useStyles from './style';
import CategoryItem from '../CategoryItem';
import { useReduxState } from '../../../../../../../../hooks/useReduxState';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import TooltipContainer from '../../../../../../components/TooltipContainer';

const namesMap = {
  clareza: 'Clareza',
  concisao: 'Concisão',
  forca: 'Força',
  originalidade: 'Originalidade',
  ortografia: 'Ortografia',
  gramatica: 'Gramática',
  pontuacao: 'Pontuação',
  formatacao: 'Formatação',
  correcao: 'Correção',
  duvida: 'Dúvida'
};

type CategoryCollapseProp = {
  title?: any;
  items?: any;
  type?: any;
  category?: any;
  itemsCount?: any;
  handleRemoveActiveCard?: any;
};

const CategoryCollapse = ({
  title,
  items,
  category,
  type,
  itemsCount
}: CategoryCollapseProp) => {
  const classes = useStyles();
  const theme = useTheme();
  const themeMode = useContext(ThemeContext);
  const dispatch = useAppDispatch();

  const [innerHeight, setInnerHeight] = useState(1000);
  const [open, setOpen] = useState(category === 'deviations');
  const [activeCategory, setActiveCategory] = useState('deviations');
  const [activeType, setActiveType] = useState('all');

  const { user } = useReduxState();

  useEffect(() => {
    dispatch(getActiveCategory(activeCategory));
    dispatch(getActiveCategoryType(activeType));
  }, [activeCategory, activeType]);

  const setCollapse = (category, type) => {
    setOpen(!open);
    setActiveCategory(category);
    dispatch(getActiveCategory(category));

    setActiveType(type);
    dispatch(getActiveCategoryType(type));
  };

  const onSetActiveCorrectionCategory = type => {
    dispatch(getActiveCategoryType(type));
  };

  useEffect(() => {
    setInnerHeight(window.innerHeight);
  }, [window.innerHeight]);

  const { editor, documents } = useReduxState();

  const deviationsTypes = [
    {
      key: 6,
      name: 'Correção',
      type: 'correcao',
      tooltip: 'Melhora a ortografia, gramática e pontuação.',
      number:
        documents.detail?.corrections.deviations.filter(
          el => el.score['correcao']
        ).length || 0
    },
    {
      key: 1,
      name: 'Clareza',
      type: 'clareza',
      tooltip: 'Ajuda a tornar a sua escrita mais fácil de entender.',
      number:
        documents.detail?.corrections.deviations.filter(
          el => el.score['clareza']
        ).length || 0
    },
    {
      key: 2,
      name: 'Concisão',
      type: 'concisao',
      tooltip: 'Transmite a mensagem de forma direta e clara.',
      number:
        documents.detail?.corrections.deviations.filter(
          el => el.score['concisao']
        ).length || 0
    },
    {
      key: 3,
      name: 'Força',
      type: 'forca',
      tooltip: 'Potencializa o impacto e a persuasão do seu texto.',
      number:
        documents.detail?.corrections.deviations.filter(el => el.score['forca'])
          .length || 0
    },
    {
      key: 4,
      name: 'Originalidade',
      type: 'originalidade',
      tooltip:
        'Auxilia na criação de um texto único e autêntico, oferecendo ideias frescas e criativas.',
      number:
        documents.detail?.corrections.deviations.filter(
          el => el.score['originalidade']
        ).length || 0
    }
    // {
    //   key: 5,
    //   name: 'Formatação',
    //   type: 'formatacao',
    //   number:
    //     documents.detail?.corrections.deviations.filter(
    //       el => el.score['formatacao']
    //     ).length || 0
    // },
  ];

  if (!user.isSubscriber) {
    deviationsTypes.push({
      key: 11,
      name: 'PRO',
      type: 'premium',
      tooltip:
        'Eleva sua escrita a um nível superior com uma correção impecável, clareza excepcional e resultados impactantes.',
      number:
        documents.detail?.corrections.deviations.filter(
          el => el.score['premium']
        ).length || 0
    });
  }

  const spellingAndGrammarTypes = [
    {
      key: 7,
      name: 'Gramática',
      type: 'gramatica',
      number:
        documents.detail?.corrections.spellingAndGrammar.filter((el: any) => {
          if (el.id && !el.type) {
            return el.score && el.score['gramatica'];
          }

          return el.type === 'gramatica';
        }).length || 0
    },
    {
      key: 8,
      name: 'Ortografia',
      type: 'ortografia',
      number:
        documents.detail?.corrections.spellingAndGrammar.filter((el: any) => {
          if (el.id && !el.type) {
            return el.score && el.score['ortografia'];
          }

          return el.type === 'ortografia';
        }).length || 0
    },
    {
      key: 9,
      name: 'Pontuação',
      type: 'pontuacao',
      number:
        documents.detail?.corrections.spellingAndGrammar.filter((el: any) => {
          if (el.id && !el.type) {
            return el.score && el.score['pontuacao'];
          }

          return el.type === 'pontuacao';
        }).length || 0
    }
  ];

  const doubtsTypes = [
    {
      key: 10,
      name: 'Dúvidas',
      type: 'duvida',
      number:
        documents.detail?.corrections.doubts.filter(
          (el: any) => el.score['duvida']
        ).length || 0
    }
  ];

  return (
    <>
      <Box
        style={{
          zIndex: 999
        }}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        px={'0px'}
        py={'3px'}
        className={classes.root}
        bgcolor={themeMode.mode === 'light' ? '#E8E8E8' : `#303030`}
        // onClick={() => setCollapse(category, 'all')}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          // style={{ height: '100%' }}
        >
          {/*<Typography*/}
          {/*  style={{*/}
          {/*    color:*/}
          {/*      themeMode.mode === 'light'*/}
          {/*        ? `${theme.palette.primary.main}`*/}
          {/*        : `${theme.palette.secondary.main}`,*/}
          {/*    letterSpacing: '0',*/}
          {/*    transition: 'margin-left 0.2s ease-in-out',*/}
          {/*    fontFamily: 'Inter',*/}
          {/*    fontSize: '12px',*/}
          {/*    fontWeight: '700',*/}
          {/*    marginLeft: '15px'*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {title}*/}
          {/*</Typography>*/}

          {/*{category === 'doubts' && (*/}
          {/*  <TooltipContainer*/}
          {/*    title={*/}
          {/*      'Esta categoria esclarece as dúvidas mais frequentes em língua portuguesa. Ela não aponta Desvios no texto.'*/}
          {/*    }*/}
          {/*    placement="bottom"*/}
          {/*  >*/}
          {/*    <InfoOutlined className={classes.infoIcon} />*/}
          {/*  </TooltipContainer>*/}
          {/*)}*/}
        </Box>

        {/*{editor.activeCategory === category ? (*/}
        {/*  <ExpandMore />*/}
        {/*) : (*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      display: 'flex',*/}
        {/*      justifyContent: 'center',*/}
        {/*      alignItems: 'center',*/}
        {/*      minWidth: '20px',*/}
        {/*      height: innerHeight < 750 ? '18px' : '20px',*/}
        {/*      color: 'white',*/}
        {/*      letterSpacing: '0',*/}
        {/*      transition: 'margin-left 0.2s ease-in-out',*/}
        {/*      marginRight: '5px',*/}
        {/*      fontSize: '13px',*/}
        {/*      backgroundColor:*/}
        {/*        category !== 'doubts' ? `#E44C5D` : 'rgba(7, 141, 255, 1)',*/}
        {/*      borderRadius: '50%'*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <p>{itemsCount}</p>*/}
        {/*  </div>*/}
        {/*)}*/}
      </Box>
      <Collapse
        in={editor.activeCategory === category}
        style={{
          // marginTop: '2px',
          borderBottom: '2px solid #ffffff'
        }}
      >
        <Box
          pt="12px"
          px={'8px'}
          pb={innerHeight < 750 ? '3px' : '5px'}
          // style={{ overflowX: 'scroll', maxHeight: '100%' }}
        >
          {['deviations', 'spellingAndGrammar'].includes(category) && (
            <Button
              className={clsx(classes.allSuggestions, {
                [classes.allSuggestionsActive]:
                  editor.activeCategoryType === 'all'
              })}
              sx={{
                width: '100%',
                letterSpacing: '0.1px',
                cursor: 'pointer',
                textTransform: 'unset',
                marginBottom: innerHeight < 750 ? '7px' : '10px',
                marginTop: innerHeight < 750 ? '7px' : '10px',
                fontFamily: 'Inter',
                fontSize: '13px',
                fontWeight: '500',
                borderRadius: '8px',
                gap: '8px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                padding: '8px 8px'
              }}
              onClick={() => onSetActiveCorrectionCategory('all')}
            >
              Todas as sugestões
            </Button>
          )}

          {category === 'deviations' &&
            deviationsTypes.map((item: any) => {
              return (
                <CategoryItem
                  key={item.name + '_deviations'}
                  name={item.name}
                  type={item.type}
                  number={item.number}
                  color={item.type}
                  tooltip={item.tooltip}
                  active={item.type === editor.activeCategoryType}
                  onSetActiveCorrectionCategory={() => {
                    if (item.type !== editor.activeCategoryType) {
                      onSetActiveCorrectionCategory(item.type);
                    }
                  }}
                  innerHeight={innerHeight}
                ></CategoryItem>
              );
            })}

          {category === 'spellingAndGrammar' &&
            spellingAndGrammarTypes.map((item: any) => {
              return (
                <CategoryItem
                  key={item.name + '_spellingAndGrammar'}
                  name={item.name}
                  type={item.type}
                  number={item.number}
                  color={item.type}
                  onSetActiveCorrectionCategory={() => {
                    if (item.type !== editor.activeCategoryType) {
                      onSetActiveCorrectionCategory(item.type);
                    }
                  }}
                  innerHeight={innerHeight}
                ></CategoryItem>
              );
            })}

          {category === 'doubts' &&
            doubtsTypes.map((item: any) => {
              return (
                <CategoryItem
                  key={item.name + '_doubts'}
                  name={item.name}
                  type={item.type}
                  number={item.number}
                  color={item.type}
                  onSetActiveCorrectionCategory={() => {
                    if (item.type !== editor.activeCategoryType) {
                      onSetActiveCorrectionCategory(item.type);
                    }
                  }}
                ></CategoryItem>
              );
            })}

          {/*{category === 'spellingAndGrammar' && (*/}
          {/*  <Typography*/}
          {/*    style={{*/}
          {/*      textAlign: 'right',*/}
          {/*      marginTop: '10px',*/}
          {/*      color:*/}
          {/*        themeMode.mode === 'light'*/}
          {/*          ? theme.palette.text.secondary*/}
          {/*          : theme.palette.text.white,*/}
          {/*      fontSize: '12px'*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    por LanguageTool*/}
          {/*  </Typography>*/}
          {/*)}*/}
        </Box>
      </Collapse>
    </>
  );
};

export default CategoryCollapse;
