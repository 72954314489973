import React, { useContext } from 'react';
import useStyles from './style';
import clsx from 'clsx';
import { Box, CircularProgress, Typography } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';

const CircularScore = props => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  return (
    <Box position="relative" display="inline-flex" width={props.size}>
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={props.size}
          thickness={3}
          value={100}
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
        />
        <CircularProgress
          variant="determinate"
          className={classes.top}
          classes={{
            circle: classes.circle
          }}
          size={props.size}
          thickness={3}
          value={props.score}
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
        />
      </div>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={clsx({
          [classes.optionalClick]: props.optionalClick
        })}
        style={{
          height: props.size
        }}
        onClick={() => (props.optionalClick ? props.optionalClick() : {})}
      >
        <Typography
          variant="h6"
          component="div"
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
          style={{ fontSize: props.size / 2.4 }}
        >{`${Math.round(props.score)}`}</Typography>
      </Box>
    </Box>
  );
};

export default CircularScore;
