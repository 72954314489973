import React from 'react';
import { ButtonSubmitInterface } from './interface';
import useStyles from './style';
import LoadingButton from '@mui/lab/LoadingButton';

const ButtonSubmit = ({
  children,
  disabled = false,
  loading = false,
  ...props
}: ButtonSubmitInterface) => {
  const classes: any = useStyles();

  return (
    <LoadingButton
      // className={classes.root}
      variant="contained"
      loading={loading}
      disabled={disabled}
      sx={{
        boxShadow: 'none',
        width: '100%',
        height: '4.8rem',
        backgroundColor: '#09b386',
        border: 'none',
        borderRadius: '6px',
        fontWeight: '600',
        fontSize: '1.6rem',
        lineHeight: ' 100%',
        color: '#ffffff',
        marginTop: '4rem',
        transition: 'background-color 0.3s',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'rgba(11,161,121,0.94)',
          boxShadow: 'none'
        },
        '&:disabled': {
          opacity: '0.8',
          cursor: 'default',
          color: '#ffffff',
          backgroundColor: '#09b386 !important'
        }
      }}
      type="submit"
      {...props}
    >
      {loading ? '' : children}
    </LoadingButton>
  );
};

export default ButtonSubmit;
