/* eslint-disable no-undef */
import instance from '../../utils/axios/instance';
import withAuth from '../../utils/axios/withAuth';

const TemplatesApi = {
  completionByTemplate: async (fields: models.Templates) => {
    const { data } = await withAuth().post(`/completions`, {
      template: fields.template,
      title: fields.title,
      audience: fields.audience,
      content: fields.content,
      tone: fields.tone,
      name: fields.name,
      description: fields.description,
      keywords: fields.keywords,
      pointsOfView: fields.pointsOfView,
      theme: fields.theme,
      location: fields.location,
      cta: fields.cta,
      count: fields.count,
      language: {
        input: fields.language?.input,
        output: fields.language?.output,
        formality: fields.language?.formality
      },
      quantity: fields.quantity
    });

    return data;
  },
  completionByEditor: async (fields: any) => {
    const { data } = await withAuth().post(`/completions`, {
      template: fields.template,
      command: fields.command,
      snippet: fields.snippet,
      description: fields.description,
      quantity: fields.quantity
    });

    return data;
  },
  getAllCompletions: async (template: string) => {
    const { data } = await withAuth().get(`/completions/template/${template}`);

    return data;
  },
  completionClear: async (template: string) => {
    const { data } = await withAuth().put(`/completions/clear/${template}`);

    return data;
  },
  completionUpdateStatus: async (_id: string, type: string, action: string) => {
    const { data } = await withAuth().put(
      `/completions/status/${type}/${action}/${_id}`
    );

    return data;
  },
  openInDocument: async (text: any, rawEditorState: any) => {
    const { data } = await withAuth().post(`/texts/open-in-document`, {
      text,
      rawEditorState
    });

    return data;
  },

  getTemplateResponse: async (filds: models.Templates) => {
    const { data } = await withAuth().post(`/generator/prompt/`, {
      template: filds.template,
      title: filds.title,
      audience: filds.audience,
      tone: filds.tone,
      language: {
        input: filds.language?.input,
        output: filds.language?.output,
        formality: filds.language?.formality
      },
      quantity: filds.quantity
    });
    return data;
  },
  getTemplateHistoric: async (typeTemplate: string, idUser: string) => {
    const { data } = await withAuth().get(
      `/templates/typeTemplate=${typeTemplate}&idUser=${idUser}`
    );
    return data;
  },
  toggleFavoriteTemplate: async (id: any) => {
    // return { options: { templates: { favorites: [id] } } };

    const { data } = await withAuth().put(`/users/template/toggle`, {
      id: id
    });

    return data;
  }
};

export default TemplatesApi;
