import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    // cursor: 'pointer'
  },
  collapseText: {
    color: theme.palette.primary.main,
    letterSpacing: '0',
    transition: 'margin-left 0.2s ease-in-out'
  },
  expandIcon: {
    stroke: '0',
    width: '18.5px'
  },
  widthBadge: {
    marginLeft: '15px'
  },
  badgeRed: {
    backgroundColor: 'rgba(228, 76, 94, 1)'
  },
  badgeBlue: {
    backgroundColor: 'rgba(68, 137, 255, 1)'
  },
  badgeDoubts: {
    backgroundColor: 'rgba(7, 141, 255, 1)'
  },
  hiddenBadge: {
    opacity: '0!important'
  },
  infoIcon: {
    marginLeft: '5px',
    color: '#707070',
    width: '0.80em!important',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
      color: '#410a85bd'
    }
  },
  collapseScroll: {
    maxHeight: '290px',
    // overflowX: 'hidden',
    // overflowY: 'scroll',
    whiteSpace: 'nowrap'
  },
  badge: {},
  allSuggestions: {
    backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#09B286',
    color:
      theme.palette.mode === 'light' ? '#251831!important' : 'white!important',
    '&:hover': {
      backgroundColor:
        theme.palette.mode === 'light'
          ? 'rgba(65, 10, 133, 0.10)!important'
          : '#09B286!important'
    }
  },
  allSuggestionsActive: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgba(65, 10, 133, 0.10)!important'
        : '#09B286!important'
  }
}));

export default useStyles;
