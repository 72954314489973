import withAuth from '../../utils/axios/withAuth';

const CreditsApi = {
  getCredits: async () => {
    const { data } = await withAuth().get(`/credits`);

    return data;
  }
};

export default CreditsApi;
