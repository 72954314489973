import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  moreButton: {
    marginLeft: '8px!important',
    minWidth: '15px!important',
    fontFamily: 'Inter',
    textTransform: 'unset',
    fontSize: '13.5px',
    fontWeight: '500',
    letterSpacing: '0.12px',
    padding: '5px 12px!important',
    margin: '0 3px !important',
    borderRadius: '7px!important',
    border: `1px solid #655679!important`,

    font: 'normal normal 500 13.5px/15px Inter',
    color: '#655679!important',
    // borderRadius: '7px',
    // borderColor: '#00000!important',
    // borderWidth: '1px!important',
    // border: 'solid',
    // background: '#410A8505 0% 0% no-repeat padding-box',
    '&:hover': {
      background: 'white'
    }
  },
  moreIcon: {
    width: '0.93em',
    height: '0.93em'
  },
  itemIcon: {
    width: '0.80em',
    height: '0.80em',
    marginRight: '5px'
  }
}));

export default Styles;
