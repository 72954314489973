import React, { useState } from 'react';
import { TooltipContainerInterface } from './interface';
import useStyles from './style';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const TooltipContainer = ({
  open = false,
  title,
  placement = 'bottom',
  small = false,
  children
}: TooltipContainerInterface) => {
  const classes = useStyles();

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClose = () => {
    setOpenTooltip(false);
  };

  const handleOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <Tooltip
      classes={{
        tooltip: small ? classes.small : classes.tooltip,
        arrow: classes.arrow
      }}
      open={title !== '' ? open || openTooltip : false}
      title={<Typography className={classes.info}>{title}</Typography>}
      placement={placement}
      arrow
      onClose={handleClose}
      onOpen={handleOpen}
    >
      {children}
    </Tooltip>
  );
};

export default TooltipContainer;
