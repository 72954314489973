import React from 'react';
import { AppBar, Toolbar, useMediaQuery } from '@mui/material';
import SecurityDescriptionNavBar from './component/SecurityDescription';
import PoweredByStripeImg from '../../../../../../../assets/images/powered-by-stripe.png';
import LogoNavBar from './component/Logo';
import useStyles from './styles';
import { ACTIVE_COUPON } from '../../../../../../../utils/coupon';

const NavBarPayment = () => {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <LogoNavBar />

          {ACTIVE_COUPON === 'NO_DISCOUNT' && (
            <div className={classes.space}></div>
          )}

          {ACTIVE_COUPON === 'ANUAL40' && (
            <div className={classes.space}>
              {!isMobile && (
                <p
                  style={{
                    color: '#000000',
                    textAlign: 'center',
                    marginLeft: '142px'
                  }}
                >
                  Utilize o cupom{' '}
                  <b
                    style={{
                      color: '#410A85'
                    }}
                  >
                    ANUAL40
                  </b>{' '}
                  e ganhe 40% de desconto na assinatura anual. Aproveite! 🎉
                </p>
              )}
            </div>
          )}

          {ACTIVE_COUPON === 'LINGUAPT50' && (
            <div className={classes.space}>
              {!isMobile && (
                <p
                  style={{
                    color: '#000000',
                    textAlign: 'center',
                    marginLeft: '142px'
                  }}
                >
                  Utilize o cupom{' '}
                  <b
                    style={{
                      color: '#410A85'
                    }}
                  >
                    LINGUAPT50
                  </b>{' '}
                  e ganhe 50% de desconto na assinatura anual. Aproveite! 🎉
                </p>
              )}
            </div>
          )}

          <SecurityDescriptionNavBar />

          <img src={PoweredByStripeImg} alt="" height={'24px'} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default NavBarPayment;
