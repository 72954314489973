import React, { useContext } from 'react';
import { RootInterface } from './interface';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../../utils/context/GlobalContext';
import useStyles from './style';
import ModalCustom from '../../../ModalCustom';

const Root = ({ children, onReset }: RootInterface) => {
  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <ModalCustom
      open={ctx.modal.onboarding.open}
      toggle={() => {
        ctx.modal.onboarding.toggle();

        onReset();
      }}
      containerClass={classes.container}
    >
      <Box
        className={classes.box}
        sx={{
          // height: isMobile ? '600px' : '100%',
          maxHeight: isMobile ? '90vh' : '100%',
          width: {
            xs: '320px',
            sm: '320px',
            md: '500px',
            lg: '872px',
            xl: '872px'
          },
          padding: isMobile ? '0px' : '0px',
          gap: '0px',
          touchAction: 'auto'
        }}
      >
        {children}
      </Box>
    </ModalCustom>
  );
};

export default Root;
