import React, { useContext } from 'react';
import { NavbarInterface } from './interface';
import useStyles from './style';
import { AppBar, Box, useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import clsx from 'clsx';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import { signIn, signOut } from '../../../../stories/actions/auth';
import { useNavigate } from 'react-router-dom';
import { GoogleLogout } from 'react-google-login';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { GlobalContext } from '../../../../utils/context/GlobalContext';
import BrandTag from '../../pages/chat/components/BrandTag';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}));

const Navbar = ({ style, toggleSidebar, sidebarExpanded }: NavbarInterface) => {
  const classes = useStyles();

  const settings = ['Perfil', 'Sair'];

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const themeMode = useContext(ThemeContext);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { user } = useReduxState();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (type: string) => {
    if (type === 'Perfil') {
      navigate(`/account/profile`);
    }

    if (type === 'Sair') {
      // console.log('onLogoutSuccess');
      // dispatch(signOut(user));
    }

    setAnchorElUser(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const ctx = useContext(GlobalContext);

  return (
    <Box className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarAlternative]: !sidebarExpanded || isMobile
        })}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          {/*<Typography*/}
          {/*    variant="h6"*/}
          {/*    noWrap*/}
          {/*    component="div"*/}
          {/*    sx={{ display: { xs: 'none', sm: 'block' } }}*/}
          {/*>*/}
          {/*  MUI*/}
          {/*</Typography>*/}
          {/*<Search>*/}
          {/*  <SearchIconWrapper>*/}
          {/*    <SearchIcon />*/}
          {/*  </SearchIconWrapper>*/}
          {/*  <StyledInputBase*/}
          {/*      placeholder="Pesquise…"*/}
          {/*      inputProps={{ 'aria-label': 'search' }}*/}
          {/*  />*/}
          {/*</Search>*/}

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: { xs: 'flex', md: 'flex' },
              justifyContent: 'center'
            }}
          >
            {/*<IconButton size="large" aria-label="show 4 new mails" color="inherit">*/}
            {/*  <Badge badgeContent={4} color="error">*/}
            {/*    <MailIcon />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}
            {/*<IconButton*/}
            {/*    size="large"*/}
            {/*    aria-label="show 17 new notifications"*/}
            {/*    color="inherit"*/}
            {/*>*/}
            {/*  <Badge badgeContent={17} color="error">*/}
            {/*    <NotificationsIcon />*/}
            {/*  </Badge>*/}
            {/*</IconButton>*/}

            {location.pathname.includes('/chat') && <BrandTag />}

            {location.pathname.includes('/chat') && (
              <FormControlLabel
                control={
                  <Switch
                    checked={ctx.chatHistoric.active}
                    size="small"
                    sx={{ marginRight: '2px' }}
                  />
                }
                label={isMobile ? 'Histórico' : 'Utilizar histórico'}
                // labelPlacement="start"
                onChange={(e: any) => ctx.chatHistoric.toggle(e.target.value)}
                sx={{ marginRight: '10px' }}
              />
            )}

            <IconButton size="large" color="inherit" onClick={themeMode.toggle}>
              {themeMode.mode === 'dark' ? (
                <LightModeIcon sx={{ fill: '#0fedb2' }} />
              ) : (
                <DarkModeIcon sx={{ fill: '#410A85' }} />
              )}
            </IconButton>
            {/*<IconButton*/}
            {/*    size="large"*/}
            {/*    edge="end"*/}
            {/*    aria-label="account of current user"*/}
            {/*    aria-controls={menuId}*/}
            {/*    aria-haspopup="true"*/}
            {/*    onClick={handleProfileMenuOpen}*/}
            {/*    color="inherit"*/}
            {/*>*/}
            {/*    <AccountCircle />*/}
            {/*</IconButton>*/}
          </Box>

          <Box sx={{ flexGrow: 0, marginLeft: '15px', display: 'none' }}>
            {/*<Tooltip title="">*/}
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={user.detail?.name} src={user.detail?.picture} />
            </IconButton>
            {/*</Tooltip>*/}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              // anchorOrigin={{
              //   vertical: 'top',
              //   horizontal: 'right'
              // }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(setting => {
                if (setting === 'Sair') {
                  return (
                    <GoogleLogout
                      key={1}
                      onLogoutSuccess={() => {
                        dispatch(signOut(user));
                        navigate(`/sign-in`);
                      }}
                      onFailure={() => {
                        // dispatch(signOut(user));
                        // navigate(`/sign-in`);
                      }}
                      clientId={
                        '389449520627-60p78dhde3e6dd54bu0na38i9n1u36f5.apps.googleusercontent.com'
                      }
                      render={renderProps => (
                        <MenuItem
                          key={setting}
                          disabled={false}
                          // onClick={() => handleCloseUserMenu(setting)}
                          {...renderProps}
                        >
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      )}
                    />
                  );
                }

                return (
                  <MenuItem
                    key={setting}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>

          {/*<Box sx={{ display: { xs: 'flex', md: 'none' } }}>*/}
          {/*  <IconButton*/}
          {/*    size="large"*/}
          {/*    aria-label="show more"*/}
          {/*    aria-controls={mobileMenuId}*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={handleMobileMenuOpen}*/}
          {/*    color="inherit"*/}
          {/*  >*/}
          {/*    <MoreIcon />*/}
          {/*  </IconButton>*/}
          {/*</Box>*/}
        </Toolbar>
      </AppBar>

      {/*{renderMobileMenu}*/}
      {renderMenu}
    </Box>
  );
};

export default Navbar;
