import { BrandTagInterface } from './interface';
import { Grid, Box } from '@mui/material';
import useStyles from './style';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TooltipContainer from '../../../../components/TooltipContainer';

const BrandTag = ({ form, setForm }: BrandTagInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  const { user, brands } = useReduxState();

  const [activeBrand, setActiveBrand] = useState<any>('');

  useEffect(() => {
    const active = brands?.all.find(
      el => String(el._id) === String(user?.options?.brand?._id)
    );

    if (active) {
      setActiveBrand(active || '');

      if (!['email-marketing', 'push-notification'].includes(form.template)) {
        setForm(prev => ({
          ...prev,
          name: active.name || '',
          // description: active.description || '',
          tone: active.tone || '',
          audience: active.audience || ''
        }));
      }
    } else {
      setActiveBrand('');

      setForm(prev => ({
        ...prev,
        name: '',
        tone: '',
        audience: ''
      }));
    }
  }, [brands, user]);

  if (activeBrand?.name) {
    return (
      <Box className={classes.container}>
        <TooltipContainer
          title={'Marca selecionada para a criação de conteúdo.'}
          placement="right"
        >
          <div className={classes.description}>
            <TurnedInIcon />
            <span style={{ marginLeft: '2px', paddingBottom: '1px' }}>
              {activeBrand?.name || 'Selecione uma marca'}
            </span>
          </div>
        </TooltipContainer>
      </Box>
    );
  }

  return null;
};

export default BrandTag;
