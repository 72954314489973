import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    height: '100%',
    // padding: '114px',
    paddingBottom: '0px',
    maxWidth: 548,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: theme.palette.mode === 'light' ? '#fcfcfe' : 'transparent'
    backgroundColor: '#fcfcfe'
  }
}));

export default Styles;
