import React, { useContext, useState } from 'react';
import {
  Box,
  TextField,
  Modal,
  Button,
  useTheme,
  CircularProgress
} from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import { useAppDispatch } from '../../../../../hooks/useAppDispatch';
import LoadingButton from '@mui/lab/LoadingButton';
import { createProject } from '../../../../../stories/actions/projects';
import ModalCustom from '../ModalCustom';
import useStyles from './style';

type ModalCreateProjectsProps = {};

const ModalCreateProjects = ({}: ModalCreateProjectsProps) => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const [form, setForm] = useState<any>({ title: '', description: '' });

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setForm({ ...form, [type]: e.target.value });
  };

  const onClose = async () => {
    setForm({ title: '', description: '' });

    setLoading(false);

    ctx.modal.project.create.toggle();
  };

  const onSubmit = async (e: any) => {
    if (e) e.preventDefault();

    setLoading(true);

    const cb = {
      success: () => {
        setLoading(false);

        setForm({ title: '', description: '' });

        ctx.modal.project.create.toggle();
      },
      error: () => {
        setLoading(false);
      }
    };

    dispatch(createProject(form.title, form.description, cb));
  };

  return (
    <ModalCustom
      open={ctx.modal.project.create.open}
      toggle={onClose}
      containerClass={classes.root}
    >
      <form onSubmit={onSubmit} noValidate autoComplete="off">
        <Box
          sx={{
            // width: 400,
            backgroundColor:
              themeMode.mode === 'light'
                ? `${theme.palette.background.paper}`
                : `${theme.palette.background.default}`,
            borderRadius: '15px',
            padding: '0rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem'
          }}
        >
          <div>
            <h2 id="parent-modal-title">Novo Projeto</h2>
            <p id="parent-modal-description">
              Crie novos projetos para organizar seu trabalho.
            </p>
          </div>
          <TextField
            id="outlined-basic"
            label="Nome"
            variant="outlined"
            color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
            value={form.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              handleChange(e, 'title')
            }
          />
          <TextField
            id="outlined-multiline-static"
            label="Descrição"
            multiline
            rows={4}
            color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
            value={form.description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              handleChange(e, 'description')
            }
          />
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
              sx={{
                textTransform: 'none!important',
                fontFamily: 'Inter'
              }}
            >
              Cancelar
            </Button>
            <LoadingButton
              loading={loading}
              onClick={onSubmit}
              variant="contained"
              style={{
                backgroundColor:
                  themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`,
                color: loading
                  ? themeMode.mode === 'light'
                    ? `${theme.palette.primary.main}`
                    : `${theme.palette.secondary.main}`
                  : themeMode.mode === 'light'
                  ? `${theme.palette.text.white}`
                  : `${theme.palette.text.black}`
              }}
              sx={{
                textTransform: 'none!important',
                fontFamily: 'Inter'
              }}
            >
              Criar
            </LoadingButton>
          </div>
        </Box>
      </form>
    </ModalCustom>
  );
};

export default ModalCreateProjects;
