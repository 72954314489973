import React, { useContext, useState } from 'react';

import clsx from 'clsx';
import {
  Box,
  Typography,
  useTheme,
  LinearProgress as MuiLinearProgress
} from '@mui/material';
import useStyles from './style';
import './styles.css';
import { ThemeContext } from '../../../../../../../../utils/context/ThemeContext';
import { withStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TooltipContainer from '../../../../../../components/TooltipContainer';
import Tooltip from '@mui/material/Tooltip';

type CategoryItemProps = {
  name?: any;
  type?: any;
  color?: any;
  number?: any;
  active?: any;
  onSetActiveCorrectionCategory?: any;
  innerHeight?: any;
  tooltip?: string;
};

const BorderLinearProgress = color =>
  withStyles(theme => ({
    root: {
      borderRadius: '5px !important',
      marginTop: '7px'
    },
    colorPrimary: {
      backgroundColor: `${theme.palette.highlights[color](0.3)}!important`
    },
    bar: {
      borderRadius: '5px',
      backgroundColor: `${theme.palette.highlights[color](1)}!important`
    }
  }))(MuiLinearProgress);

const CategoryItem = ({
  name,
  type,
  color,
  number,
  active,
  tooltip,
  onSetActiveCorrectionCategory,
  innerHeight
}: CategoryItemProps) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const LinearProgress = BorderLinearProgress(color);

  const response = 100 - number * 5 * 0.35;
  const value = response > 3 ? response : 3;

  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClose = () => {
    setOpenTooltip(false);
  };

  const handleOpen = () => {
    setOpenTooltip(true);
  };

  return (
    <Box
      pt={innerHeight < 750 ? '4px' : '10px'}
      pb={innerHeight < 750 ? '4px' : '11px'}
      className={clsx(classes.container, {
        [classes.active]: active
      })}
      sx={(theme: any) => {
        if (active) {
          return {
            backgroundColor: `${theme.palette.highlights[color](0.1)}!important`
          };
        }

        return {
          // backgroundColor: `${theme.palette.highlights[color](0.1)}!important`
        };
      }}
      onClick={() => onSetActiveCorrectionCategory()}
    >
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        mb={number > 0 ? 1.5 : 0.3}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width="60%"
        >
          <Typography
            style={{
              font: 'normal normal 500 12px Inter',
              color:
                themeMode.mode === 'light'
                  ? '#251831'
                  : theme.palette.text.white
            }}
          >
            {type !== 'doubts' ? name : ''}
          </Typography>

          {tooltip && (
            <Tooltip
              style={{
                marginRight: '86px'
              }}
              classes={{
                tooltip: clsx(classes.tooltip, {
                  [classes.tooltipCorrecao]: type === 'correcao',
                  [classes.tooltipClareza]: type === 'clareza',
                  [classes.tooltipConcisao]: type === 'concisao',
                  [classes.tooltipForca]: type === 'forca',
                  [classes.tooltipOriginalidade]: type === 'originalidade',
                  [classes.tooltipPremium]: type === 'premium'
                }),
                arrow: classes.arrow
              }}
              open={openTooltip}
              title={
                <Typography className={classes.info}>{tooltip}</Typography>
              }
              placement="left"
              arrow
              onClose={handleClose}
              onOpen={handleOpen}
            >
              <InfoOutlinedIcon
                sx={(theme: any) => {
                  return {
                    marginLeft: '4px',
                    width: '14px',
                    height: '14px',
                    color:
                      themeMode.mode === 'light'
                        ? '#707070'
                        : theme.palette.text.white
                  };
                }}
                fontSize="small"
              />
            </Tooltip>
          )}
        </Box>

        {number === 0 && (
          <CheckCircleIcon
            sx={(theme: any) => {
              return {
                width: '18px',
                color: `${theme.palette.highlights[color](1)}!important`
              };
            }}
            fontSize="small"
          />
        )}

        {number > 0 && (
          <Typography
            style={{
              font: 'normal normal 500 11px Inter',
              color:
                themeMode.mode === 'light'
                  ? theme.palette.text.secondary
                  : theme.palette.text.white
            }}
          >
            {number}{' '}
            {type !== 'doubts'
              ? `desvio${number !== 1 ? 's' : ''}`
              : `dúvida${number !== 1 ? 's' : ''}`}
          </Typography>
        )}
      </Box>

      {/*{number > 0 && (*/}
      {/*  <Box*/}
      {/*    display={'flex'}*/}
      {/*    flexDirection={'row'}*/}
      {/*    alignItems={'center'}*/}
      {/*    justifyContent={'space-between'}*/}
      {/*    mb={1}*/}
      {/*  >*/}
      {/*    <Typography*/}
      {/*      style={{*/}
      {/*        font: 'normal normal 500 11px Inter',*/}
      {/*        color:*/}
      {/*          themeMode.mode === 'light'*/}
      {/*            ? theme.palette.text.secondary*/}
      {/*            : theme.palette.text.white*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {number}{' '}*/}
      {/*      {type !== 'doubts'*/}
      {/*        ? `desvio${number !== 1 ? 's' : ''}`*/}
      {/*        : `dúvida${number !== 1 ? 's' : ''}`}*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*)}*/}

      {/*<Box*/}
      {/*  style={{*/}
      {/*    width: '100%',*/}
      {/*    height: '4px',*/}
      {/*    borderRadius: '50px',*/}
      {/*    marginTop: '5px',*/}
      {/*    backgroundColor: theme.palette.highlights[type](0.7)*/}
      {/*  }}*/}
      {/*/>*/}

      <LinearProgress variant="determinate" value={value > 0 ? value : 0} />
    </Box>
  );
};

export default CategoryItem;
