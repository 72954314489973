import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    description: {
      width: '100%',
      textAlign: 'left',
      font: 'normal normal 400 15.5px/15px Inter',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 8,
      paddingBottom: 18
    },
    descriptionAlternative: {
      width: '100%',
      textAlign: 'center',
      font: 'normal normal 400 15.5px/15px Inter',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 8,
      paddingBottom: 8
    },
    button: {
      marginTop: '20px',
      whiteSpace: 'nowrap',
      minWidth: '140px',
      height: '50px',
      textTransform: 'unset',
      maxWidth: 'unset',
      borderRadius: '6px',
      font: ' normal normal 400 15px/18px Inter',
      letterSpacing: 0,
      '&:disabled': {
        background: theme.palette.primary.main,
        opacity: 0.7,
        color: 'white'
      }
    },
    enBtnRoot: {
      // color: '#4A4A4A',
      // border: '1px solid #4A4A4A !important'
    },
    enBtn: {
      textTransform: 'unset',
      font: 'normal normal 400 13.5px/15px Inter',
      letterSpacing: '0.12px',
      padding: '5px 12px',
      height: '27px',
      margin: '0 3px !important',
      borderRadius: '7px',
      '&:hover':
        theme.palette.mode === 'light'
          ? {
              color: theme.palette.common.tPurple + ' !important',
              border:
                '1px solid ' + theme.palette.common.tPurple + ' !important'
            }
          : {}
    },
    divider: {
      width: '100%',
      color: theme.palette.grey + '!important',
      height: '1px',
      margin: '8px 0px 8px'
    }
  };
});

export default useStyles;
