import { ProjectNotFoundInterface } from './interface';
import { Grid, Box } from '@mui/material';
import useStyles from './style';
import { useContext } from 'react';
import { ThemeContext } from '../../../../../../../utils/context/ThemeContext';

const ProjectNotFound = ({ children }: ProjectNotFoundInterface) => {
  const classes: any = useStyles();

  const themeMode = useContext(ThemeContext);

  return (
    // <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Box className={classes.container}>
        <p className={classes.description}>{children}</p>
      </Box>
    </Grid>
    // </Grid>
  );
};

export default ProjectNotFound;
