import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    padding: 0,
    display: 'flex',
    flexDirection: 'row'
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor: '#fff!important',
    // backgroundColor:
    //   theme.palette.mode === 'light' ? '#fff!important' : '#252525!important',
    // boxShadow: `0 2px 0.75rem ${theme.palette.background.lightGrey}`,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(164, 163, 255, 0.3)'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: '20px',
    marginRight: '20px',
    padding: 30,
    height: '80px',
    minHeight: '60px !important'
  },
  space: {
    flexGrow: 1
  }
}));

export default Styles;
