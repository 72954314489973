import React from 'react';
import Container from './components/Container';

const CreateBrand = () => {
  const fields = {
    name: {
      label: 'Nome',
      placeholder: 'Qual é o nome da sua marca? \nExemplo: Nike',
      required: true,
      type: 'input'
    },
    description: {
      label: 'Descrição',
      placeholder:
        'Conte um pouco sobre sua marca...\n\nExemplo: A Nike é uma marca global de roupas e calçados esportivos conhecida por sua inovação e qualidade.',
      required: true,
      rows: 5,
      type: 'textarea'
    },
    audience: {
      label: 'Audiência',
      placeholder:
        'Qual é o seu público-alvo? \n\nExemplo: Atletas, entusiastas do esporte, jovens adultos interessados em fitness.',
      rows: 5,
      required: true,
      type: 'textarea'
    },
    tone: {
      label: 'Tonalidade',
      placeholder:
        'Defina o tom de voz da sua marca. \n\nExemplo: Motivacional e inspirador.',
      rows: 5,
      required: true,
      type: 'textarea'
    },
    personality: {
      label: 'Personalidade',
      placeholder:
        'Escreva a personalidade da sua marca. \n\nExemplo: Uma marca ousada, corajosa e inspiradora, focada em atletas e pessoas que buscam superar seus limites.',
      rows: 5,
      required: true,
      type: 'textarea'
    },
    missionVisionAndValue: {
      label: 'Missão, Visão e Valores',
      placeholder:
        'Insira a missão, visão e valores da sua marca. \n\nMissão: Inspirar e inovar para todos os atletas no mundo. \nVisão: Trazer inspiração e inovação para todos os atletas. \nValores: Determinação, inovação, trabalho em equipe.',
      rows: 6,
      required: true,
      type: 'textarea'
    },
    keywords: {
      label: 'Palavras-chave',
      placeholder:
        'Insira palavras-chave relacionadas à sua marca. \n\nExemplo: Inovação, desempenho, superação.',
      required: true,
      type: 'textarea'
    },
    preferredWordsAndPhrases: {
      label: 'Palavras e Frases Preferidas',
      placeholder:
        'Liste as palavras e frases que você prefere usar em sua comunicação. \n\nExemplo: Just Do It, excelência, campeão.',
      rows: 5,
      required: true,
      type: 'textarea'
    },
    wordsAndPhrasesToAvoid: {
      label: 'Palavras e Frases Evitáveis',
      placeholder:
        'Liste as palavras e frases que devem ser evitadas. \n\nExemplo: Falha, desistir, impossível.',
      rows: 5,
      required: true,
      type: 'textarea'
    },
    image: {
      label: 'Insira uma imagem do logo da sua marca',
      placeholder: 'Insira uma imagem do logo da sua marca',
      required: false,
      type: 'image'
    }
  };

  return (
    <>
      <Container fields={fields} />
    </>
  );
};

export default CreateBrand;
