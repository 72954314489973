import React, { useContext, useState } from 'react';
import { Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';
import ModalCustom from '../ModalCustom';
import useStyles from './style';
import Root from './components/Root';
import Title from './components/Title';
import Motion from './components/Motion';
import Info from './components/Info';
import Action from './components/Action';

type ModalNewsProps = {};

const ModalNews = ({}: ModalNewsProps) => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Root>
      <Title />

      <Motion />

      <Info />

      <Action />
    </Root>
  );
};

export default ModalNews;
