import React, { useContext, useState } from 'react';
import { Box, Button, MenuItem, Typography, Menu } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import useStyle from './style';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeContext } from '../../../../../../../../../../utils/context/ThemeContext';
import DoneAllIcon from '@mui/icons-material/DoneAll';

type ReplaceWordContainerProps = {
  textId?: any;
  correction?: any;
  replaceWord?: any;
  category?: any;
};

const ReplaceWordContainer = ({
  correction,
  replaceWord,
  category,
  textId
}: ReplaceWordContainerProps) => {
  const classes = useStyle();

  const themeMode = useContext(ThemeContext);

  const replaceFor = correction => {
    if (correction.replace) {
      const response = correction.replacement;

      // if (typeof response === 'string' && response === '') {
      //   console.log('REMOVER', correction);
      // }

      if (correction.id === 'CAPITALIZACAO') {
        if (response && response !== '') {
          return [response.toUpperCase()];
        }
      } else {
        if (
          response &&
          typeof correction.replacement === 'string' &&
          response !== ''
        ) {
          const expression = correction.spans[0].content;

          if (typeof expression === 'string') {
            const firstLetter = correction.spans[0].content.substring(0, 1);

            // MAIÚSCULO
            if (expression === expression.toUpperCase()) {
              return [response.toUpperCase()];
            }

            // PRIMEIRA LETRA MAIÚSCULA
            if (firstLetter === firstLetter.toUpperCase()) {
              return [
                response.substring(0, 1).toUpperCase() +
                  response.substring(1, response.length)
              ];
            }
          }

          return [response];
        }

        if (response && typeof correction.replacement === 'object') {
          return response.map(replacement => {
            const expression = correction.spans[0].content;

            const firstLetter = correction.spans[0].content.substring(0, 1);

            // MAIÚSCULO
            if (expression === expression.toUpperCase()) {
              return replacement.toUpperCase();
            }

            // PRIMEIRA LETRA MAIÚSCULA
            if (firstLetter === firstLetter.toUpperCase()) {
              return (
                replacement.substring(0, 1).toUpperCase() +
                replacement.substring(1, replacement.length)
              );
            }

            return replacement;
          });
        }
      }
    }

    return [''];
  };

  const response = replaceFor(correction);

  const hasPopUP = response.length > 1 && !response.includes('');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (
    hasPopUP &&
    ['deviations', 'doubts', 'spellingAndGrammar'].includes(category) &&
    correction.id &&
    correction.replace
  ) {
    return (
      <Box>
        <div
          className={classes.wrongContainer}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography key={'typo'} className={classes.wrongContent}>
            {correction.spans[0].content}
          </Typography>
          <ArrowForward
            key={'icon'}
            fontSize={'small'}
            className={classes.arrow}
          />

          <Button
            variant={'outlined'}
            className={clsx(classes.btn, classes.replaceWordButton)}
            aria-controls="simple-menu"
            aria-haspopup={hasPopUP}
            onClick={handleOpenMenu}
            sx={{ textTransform: 'unset!important' }}
          >
            {response[0]}
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            elevation={0}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {response.map(replacement => (
              <MenuItem
                key={replacement}
                onClick={() => {
                  handleClose();

                  replaceWord(
                    textId,
                    correction._id,
                    'other',
                    '',
                    category,
                    replacement
                  );
                }}
              >
                {replacement}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Box>
    );
  }

  return (
    <>
      {['deviations', 'doubts', 'spellingAndGrammar'].includes(category) &&
        correction.id &&
        correction.replace &&
        response.map((replacement, index) => {
          if (hasPopUP) {
            return (
              <Box
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <div className={classes.wrongContainer}>
                  <Typography key={'typo'} className={classes.wrongContent}>
                    {correction.spans[0].content}
                  </Typography>
                  <ArrowForward
                    key={'icon'}
                    fontSize={'small'}
                    className={classes.arrow}
                  />

                  <Button
                    variant={'outlined'}
                    className={clsx(classes.btn, classes.replaceWordButton)}
                    aria-controls="simple-menu"
                    aria-haspopup={hasPopUP}
                    onClick={handleOpenMenu}
                    sx={{ textTransform: 'unset!important' }}
                  >
                    {replacement}
                  </Button>

                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    elevation={0}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {correction.suggestions.map(suggestion => (
                      <MenuItem
                        key={suggestion}
                        onClick={() => {
                          handleClose();

                          replaceWord(
                            textId,
                            correction._id,
                            'other',
                            '',
                            category,
                            replacement
                          );
                        }}
                      >
                        {suggestion}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Box>
            );
          }

          return (
            <Box key={index}>
              {replacement !== '' && (
                <div className={classes.containerReplacement}>
                  <div className={classes.incorrectSentenceContainer}>
                    <CloseIcon
                      color={
                        themeMode.mode === 'light' ? 'primary' : 'secondary'
                      }
                      className={classes.incorrectIcon}
                      fontSize="small"
                    />

                    <div>{correction.spans[0].content}</div>
                  </div>

                  <div
                    className={classes.correctSentenceContainer}
                    onClick={() =>
                      replaceWord(
                        textId,
                        correction._id,
                        'other',
                        '',
                        category,
                        replacement
                      )
                    }
                  >
                    <DoneAllIcon
                      color={
                        themeMode.mode === 'light' ? 'primary' : 'secondary'
                      }
                      className={classes.correctIcon}
                      fontSize="small"
                    />

                    {replacement.split('\n').map((el: any, index: number) => {
                      return (
                        <p key={index} className={classes.descriptionResult}>
                          {el}
                        </p>
                      );
                    })}
                  </div>
                </div>
              )}

              {replacement === '' && (
                <>
                  <div className={classes.containerReplacement}>
                    <Box
                      className={classes.incorrectSentenceContainer}
                      sx={{
                        cursor: 'pointer',
                        textDecorationLine: 'line-through',
                        '&:hover': {
                          opacity: 0.9
                        }
                      }}
                      onClick={() =>
                        replaceWord(
                          textId,
                          correction._id,
                          'other',
                          '',
                          category,
                          ''
                        )
                      }
                    >
                      {correction.spans[0].content}
                    </Box>
                  </div>
                </>
              )}
            </Box>
          );
        })}
    </>
  );
};

export default ReplaceWordContainer;
