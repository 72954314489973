import React from 'react';
import { CheckboxKeepConnectedInterface } from './interface';
import useStyles from './style';
import { Checkbox } from '@mui/material';

const CheckboxKeepConnected = ({
  connected,
  handleChange
}: CheckboxKeepConnectedInterface) => {
  const classes: any = useStyles();

  return (
    <label className={classes.root}>
      <Checkbox
        className={classes.checkmark}
        checked={connected}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
      />
      Manter conectado
    </label>
  );
};

export default CheckboxKeepConnected;
