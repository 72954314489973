import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import {
  useTheme,
  Avatar,
  Typography,
  Divider,
  Button,
  useMediaQuery,
  Grid
} from '@mui/material';
import Box from '@mui/material/Box';
import { useReduxState } from '../../../../../hooks/useReduxState';
import { ThemeContext } from '../../../../../utils/context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import {
  ArchitectureRounded,
  BoltRounded,
  TungstenOutlined,
  WorkspacePremiumRounded
} from '@mui/icons-material';
import { GlobalContext } from '../../../../../utils/context/GlobalContext';

const ProfilePage: React.FC = () => {
  const classes = useStyles();

  const themeMode = useContext(ThemeContext);

  const [words, setWords] = useState(0);

  const theme = useTheme();

  const ctx = useContext(GlobalContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const { user, documents } = useReduxState();

  const preventDefault = (event: React.SyntheticEvent) => {
    event.preventDefault();

    navigate(`/account/edit-account`);
  };

  const linkPremium = () => {
    navigate('/plans');
    // navigate('/premium');
  };

  useEffect(() => {
    setWords(getWords());
  }, [documents.all]);

  const getWords = () => {
    let quantity: any = 0;

    if (documents.all.length > 0) {
      documents.all.map((document: any) => {
        const text = document.fullText || '';

        const response =
          text?.split(/\s+/).filter(el => el.length > 0 && el !== '—') || [];

        quantity = quantity + response.length;
      });

      return quantity;
    }

    return '0';
  };

  return (
    <Box display={'flex'} gap={'2rem'} className={classes.root}>
      <div className={classes.container}>
        <div className={classes.content}>
          <h2 className={classes.title}>Seus Dados</h2>
          <Avatar
            alt={user.detail?.name}
            className={classes.avatar}
            src={user.detail?.picture}
          />
        </div>
        <Divider orientation={'horizontal'} className={classes.divider} />
        <div className={classes.info}>
          <Typography
            className={classes.infoLabel}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            Como podemos te chamar?
          </Typography>
          <Typography
            className={classes.infoValue}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {user.detail?.name}
          </Typography>
          <Typography
            className={classes.infoLabel}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            E-mail
          </Typography>
          <Typography
            className={classes.infoValue}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {user.detail?.email}
          </Typography>
          <Typography
            className={classes.infoLabel}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            Telefone
          </Typography>
          <Typography
            className={classes.infoValue}
            sx={{
              fontFamily: 'Inter'
            }}
          >
            {user.detail?.phone || 'Não informado'}
          </Typography>
        </div>
        <Button
          className={classes.button}
          variant={'outlined'}
          color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
          sx={{
            fontFamily: 'Inter',
            textTransform: 'none!important'
          }}
          disableElevation
          onClick={(e: any) => {
            preventDefault(e);
          }}
        >
          Editar dados
        </Button>
      </div>

      <div className={classes.statsContainer}>
        <Grid
          container
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          rowSpacing={1}
          columnSpacing={{ xs: 0, sm: 0, md: 1, lg: 1, xl: 1 }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: '16px' }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center'
                // marginBottom: '1rem'
              }}
            >
              <h2
                className={classes.statsTitle}
                style={{
                  justifyContent: 'center',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                Minha conta
                {!user.isSubscriber ? (
                  <span className={classes.freeAccount}>FREE</span>
                ) : (
                  <div className={classes.premiumAccount}>PRO</div>
                )}
              </h2>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div>
              <Typography
                className={classes.infoLabel}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                Primeiro Acesso
              </Typography>
              <Typography
                className={classes.infoValue}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                {user.since
                  ? new Date(user.since).toLocaleDateString('pt-BR')
                  : 'Não informado'}
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div>
              <Typography
                className={classes.infoLabel}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                Textos
              </Typography>
              <Typography
                className={classes.infoValue}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                {documents.all.length || 0}
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div>
              <Typography
                className={classes.infoLabel}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                Palavras
              </Typography>
              <Typography
                className={classes.infoValue}
                sx={{
                  fontFamily: 'Inter'
                }}
              >
                {words}
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ marginTop: '16px', marginBottom: '8px' }}
          >
            <h2 className={classes.statsSubtitle}>Desvios de Estilo</h2>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div className={classes.statsCardsItem}>
              <div
                style={{
                  width: isMobile ? '50px' : '65px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#7C24FB',
                  borderRadius: '8.72727px'
                  //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                }}
              >
                <TungstenOutlined
                  sx={{ width: '30px', height: '30px', color: '#fff' }}
                ></TungstenOutlined>
              </div>
              <div>
                <p className={classes.statsLabel}>Clareza</p>
                <p className={classes.statsValue}>0 desvios corrigidos</p>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div className={classes.statsCardsItem}>
              <div
                style={{
                  width: isMobile ? '50px' : '65px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#09B386',
                  borderRadius: '8.72727px'
                  //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                }}
              >
                <ArchitectureRounded
                  sx={{ width: '30px', height: '30px', color: '#fff' }}
                ></ArchitectureRounded>
              </div>
              <div>
                <p className={classes.statsLabel}>Concisão</p>
                <p className={classes.statsValue}>0 desvios corrigidos</p>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div className={classes.statsCardsItem}>
              <div
                style={{
                  width: isMobile ? '50px' : '65px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#A4A3FF',
                  borderRadius: '8.72727px'
                  //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                }}
              >
                <BoltRounded
                  sx={{ width: '30px', height: '30px', color: '#fff' }}
                ></BoltRounded>
              </div>
              <div>
                <p className={classes.statsLabel}>Força</p>
                <p className={classes.statsValue}>0 desvios corrigidos</p>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div className={classes.statsCardsItem}>
              <div
                style={{
                  width: isMobile ? '50px' : '65px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#FFC560',
                  borderRadius: '8.72727px'
                  //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                }}
              >
                <WorkspacePremiumRounded
                  sx={{ width: '30px', height: '30px', color: '#fff' }}
                ></WorkspacePremiumRounded>
              </div>
              <div>
                <p className={classes.statsLabel}>Originalidade</p>
                <p className={classes.statsValue}>0 desvios corrigidos</p>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div className={classes.statsCardsItem}>
              <div
                style={{
                  width: isMobile ? '50px' : '65px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#4CD7E4',
                  borderRadius: '8.72727px'
                  //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                }}
              >
                <WorkspacePremiumRounded
                  sx={{ width: '30px', height: '30px', color: '#fff' }}
                ></WorkspacePremiumRounded>
              </div>
              <div>
                <p className={classes.statsLabel}>Formatação</p>
                <p className={classes.statsValue}>0 desvios corrigidos</p>
              </div>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            style={{ marginTop: '16px' }}
          >
            <div className={classes.statsCardsItem}>
              <div
                style={{
                  width: isMobile ? '50px' : '65px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#E44C5D',
                  borderRadius: '8.72727px'
                  //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'
                }}
              >
                <WorkspacePremiumRounded
                  sx={{ width: '30px', height: '30px', color: '#fff' }}
                ></WorkspacePremiumRounded>
              </div>
              <div>
                <p className={classes.statsLabel}>Correção</p>
                <p className={classes.statsValue}>0 desvios corrigidos</p>
              </div>
            </div>
          </Grid>

          {/*<Grid*/}
          {/*  item*/}
          {/*  xs={12}*/}
          {/*  sm={12}*/}
          {/*  md={12}*/}
          {/*  lg={12}*/}
          {/*  xl={12}*/}
          {/*  style={{ marginTop: '16px' }}*/}
          {/*>*/}
          {/*  <h2 className={classes.statsSubtitle}>Ortografia e Gramática</h2>*/}
          {/*</Grid>*/}

          {/*<Grid*/}
          {/*  item*/}
          {/*  xs={12}*/}
          {/*  sm={12}*/}
          {/*  md={12}*/}
          {/*  lg={4}*/}
          {/*  xl={4}*/}
          {/*  style={{ marginTop: '16px' }}*/}
          {/*>*/}
          {/*  <div className={classes.statsCardsItem}>*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        width: isMobile ? '50px' : '65px',*/}
          {/*        height: '50px',*/}
          {/*        display: 'flex',*/}
          {/*        justifyContent: 'center',*/}
          {/*        alignItems: 'center',*/}
          {/*        backgroundColor: '#FFC560',*/}
          {/*        borderRadius: '8.72727px'*/}
          {/*        //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <WorkspacePremiumRounded*/}
          {/*        sx={{ width: '30px', height: '30px', color: '#fff' }}*/}
          {/*      ></WorkspacePremiumRounded>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <p className={classes.statsLabel}>Gramática</p>*/}
          {/*      <p className={classes.statsValue}>0 desvios corrigidos</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Grid>*/}

          {/*<Grid*/}
          {/*  item*/}
          {/*  xs={12}*/}
          {/*  sm={12}*/}
          {/*  md={12}*/}
          {/*  lg={4}*/}
          {/*  xl={4}*/}
          {/*  style={{ marginTop: '16px' }}*/}
          {/*>*/}
          {/*  <div className={classes.statsCardsItem}>*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        width: isMobile ? '50px' : '65px',*/}
          {/*        height: '50px',*/}
          {/*        display: 'flex',*/}
          {/*        justifyContent: 'center',*/}
          {/*        alignItems: 'center',*/}
          {/*        backgroundColor: '#E44C5D',*/}
          {/*        borderRadius: '8.72727px'*/}
          {/*        //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <WorkspacePremiumRounded*/}
          {/*        sx={{ width: '30px', height: '30px', color: '#fff' }}*/}
          {/*      ></WorkspacePremiumRounded>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <p className={classes.statsLabel}>Ortografia</p>*/}
          {/*      <p className={classes.statsValue}>0 desvios corrigidos</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Grid>*/}

          {/*<Grid*/}
          {/*  item*/}
          {/*  xs={12}*/}
          {/*  sm={12}*/}
          {/*  md={12}*/}
          {/*  lg={4}*/}
          {/*  xl={4}*/}
          {/*  style={{ marginTop: '16px' }}*/}
          {/*>*/}
          {/*  <div className={classes.statsCardsItem}>*/}
          {/*    <div*/}
          {/*      style={{*/}
          {/*        width: isMobile ? '50px' : '65px',*/}
          {/*        height: '50px',*/}
          {/*        display: 'flex',*/}
          {/*        justifyContent: 'center',*/}
          {/*        alignItems: 'center',*/}
          {/*        backgroundColor: '#4588FF',*/}
          {/*        borderRadius: '8.72727px'*/}
          {/*        //boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.08)'*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <WorkspacePremiumRounded*/}
          {/*        sx={{ width: '30px', height: '30px', color: '#fff' }}*/}
          {/*      ></WorkspacePremiumRounded>*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <p className={classes.statsLabel}>Pontuação</p>*/}
          {/*      <p className={classes.statsValue}>0 desvios corrigidos</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Grid>*/}
        </Grid>
      </div>
    </Box>
  );
};

export default ProfilePage;
