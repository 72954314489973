export const isBlackWeek = (): boolean => {
  const START = new Date(2023, 10, 23, 0, 0, 0);
  const END = new Date(2023, 10, 27, 23, 59, 0);

  const now = new Date();

  const start = START;
  const end = END;

  // IS BLACK WEEK
  return now.getTime() >= start.getTime() && now.getTime() <= end.getTime();
};
