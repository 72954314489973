import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '9.6rem',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
          marginLeft: '1.4rem',
          '& > p': {
            fontFamily: 'Inter, sans-serif',
            fontWeight: '500',
            fontSize: '1.4rem',
            lineHeight: '1',
            color: '#4f3d66',
            display: 'flex',
            alignItems: 'center',
            '& > a': {
              fontWeight: 'bold',
              fontSize: '1.4rem',
              color: '#410a85',
              marginLeft: '3px',
              textDecoration: 'none',
              transition: 'color 0.3s',
              '&:hover': {
                color: '#09b386'
              }
            }
          }
        }
      },
      '& > a': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        lineHeight: '120%',
        color: '#410a85',
        textDecoration: 'none',
        transition: 'color 0.3s',
        '& > img': {
          marginRight: '1.2rem'
        },
        '&:hover': {
          color: '#09b386'
        }
      },
      [theme.breakpoints.down(480)]: {
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '4rem',
        '& > div': {
          marginTop: '30px'
        }
      }
    },
    buttonBack: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'bold',
      fontSize: '1.4rem',
      lineHeight: '120%',
      color: '#410a85',
      textDecoration: 'none',
      transition: 'color 0.3s',
      '& > img': {
        marginRight: '1.2rem'
      },
      '&:hover': {
        color: '#09b386'
      }
    }
  };
});

export default useStyles;
