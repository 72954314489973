import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      display: 'none!important'
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '20px!important'
    },
    [theme.breakpoints.down(630)]: {
      marginRight: '15px!important'
    }
  },
  icon: {
    marginRight: '10px',
    [theme.breakpoints.down(630)]: {
      marginRight: '3px!important'
    }
  },
  text: {
    textTransform: 'unset',
    whiteSpace: 'nowrap',
    font: ' normal normal 400 14px Inter!important',
    letterSpacing: 0,
    color: '#4F3D66'
  }
}));

export default Styles;
