import { makeStyles } from '@mui/styles';

const Styles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  chat: {
    marginTop: '30px',
    minHeight: '425px',
    border: '1px solid #d7d7d7!important',
    padding: '30px',
    borderRadius: '15px!important'
  },
  title: {
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: '700'
  },
  submitContainer: {
    paddingTop: '1.5rem',
    // paddingBottom: "1.5rem",
    minHeight: '80px'
  },
  input: {
    marginBottom: '10px!important'
  },
  count: {
    textAlign: 'right',
    marginTop: '-27px',
    marginBottom: '10px!important',
    opacity: '.7',
    fontSize: '.75rem',
    lineHeight: '1rem'
  },
  clarice: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    display: 'flex'
  },
  clariceTitle: {
    fontWeight: '700',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    marginBottom: '10px'
  },
  clariceDescription: {
    fontSize: '1.05rem',
    lineHeight: '1.25rem',
    // fontWeight: '700',
    color: 'rgb(100 116 139)'
  }
}));

export default Styles;
