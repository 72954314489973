/* eslint-disable no-undef */
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import CorrectionsOverview from './components/CorrectionsOverview';
import ActionsBar from './components/ActionsBar';
import Sidebar from '../../components/Sidebar';
import Corrections from './components/Corrections';
import Editor from './components/Editor';
import {
  getActiveCategory,
  getActiveCategoryType,
  processText,
  saveTextPreCorrection,
  sendFeedback,
  sendFeedbackFixAll,
  setTitle
} from '../../../../stories/actions/editor';
import { useReduxState } from '../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  clearTemplatesDetails,
  getDocumentById
} from '../../../../stories/actions/documents';
import createCorrectionEntities from './components/Editor/decorators/function/createCorrectionEntities';
import removeCorrectionEntities from './components/Editor/decorators/function/removeCorrectionEntities';
import {
  ContentState,
  convertToRaw,
  EditorState,
  SelectionState,
  Modifier,
  CompositeDecorator,
  ContentBlock
} from 'draft-js';
import { throttle, debounce } from 'underscore';
import { serialiseEditorStateToRaw, createEditorStateFromRaw } from 'draftail';
import { ThemeContext } from '../../../../utils/context/ThemeContext';
import useStyles from './style';
import EditorContainer from './components/EditorContainer';
import notify from '../../../../utils/notify';
import { convertToHTML } from 'draft-convert';
import { HTMLExporterConfig, state } from './components/Editor/const';
import LoadingContainer from '../../components/LoadingContainer';
import withAuth from '../../../../utils/axios/withAuth';
import {
  completionByEditor,
  completionByTemplate
} from '../../../../stories/actions/templates';
import { getCredits } from '../../../../stories/actions/credits';
import filteredCorrections from '../../../../utils/function/filteredCorrections';
import decorators from './components/Editor/decorators';
import { styled } from '@mui/material/styles';
import CircularScore from './components/CircularScore';
import { getScore } from './function/getScore';
import ModalScore from './components/CorrectionsOverview/components/ModalScore';
import IntercomButton from '../../components/IntercomButton';
import CorrectionsOverviewMobile from './components/CorrectionsOverviewMobile';
import { GlobalContext } from '../../../../utils/context/GlobalContext';
// import EditorTest from './components/EditorTest';

/* ----------*
* Utils
------------ */

function getSelectionRange() {
  const selection: any = window.getSelection();

  if (selection.rangeCount === 0) return null;

  return selection.getRangeAt(0);
}

function getTriggerRange(term) {
  const range: any = getSelectionRange();

  const text =
    range && range.startContainer.textContent.substring(0, range.startOffset);

  if (!text || /\s+$/.test(text)) {
    return null;
  }

  const start = text.lastIndexOf(term);

  if (start === -1) {
    return null;
  }

  const end = range.startOffset;

  return {
    end,
    start,
    text: text.substring(start)
  };
}

function getInsertRange(activeSuggestion, editorState) {
  const selection = editorState.getSelection();
  const content = editorState.getCurrentContent();
  const anchorKey = selection.getAnchorKey();
  const end: any = selection.getAnchorOffset();
  const block = content.getBlockForKey(anchorKey);
  const text = block.getText();
  const start: any = text
    .substring(0, end)
    .lastIndexOf(state.suggestion.PREFIX);

  return {
    snippet: text.substring(0, end - 1 > 0 ? end - 1 : end),
    start,
    end
  };
}

const CaretCoordinates = {
  x: 0,
  y: 0
};

function getCaretCoordinates() {
  const range = getSelectionRange();
  if (range) {
    const { left: x, top: y } = range.getBoundingClientRect();
    Object.assign(CaretCoordinates, { x, y: y + window.scrollY });
  }
  return CaretCoordinates;
}

const EditTextPage = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();

  const classes = useStyles();

  // EDITOR

  const compositeDecorator = new CompositeDecorator(decorators);

  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(compositeDecorator)
  );

  const [text, setText] = useState('');

  const [loading, setLoading] = useState(false);

  const [configs, setConfigs] = useState<any>({
    activeCategory: 'deviations',
    activeCategoryType: 'all',
    corrections: {
      deviations: [],
      spellingAndGrammar: [],
      doubts: []
    }
  });

  const [loaded, setLoaded] = useState(false);

  const { editor, documents, user } = useReduxState();

  const [suggestion, setSuggestion] = useState<any>({ open: false });

  const [synonyms, setSynonyms] = useState<any>({ open: false });

  const [selector, setSelector] = useState<any>({ open: false });

  const [showSuggestions, setShowSuggestions] = useState(false);

  const suggestionsRef: any = useRef(null);

  const synonymsRef: any = useRef(null);

  const selectorRef: any = useRef(null);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const isMobileSmall = useMediaQuery((theme: any) =>
    theme.breakpoints.down('850')
  );

  const dispatch = useAppDispatch();

  const location = useLocation();

  const [textProps, setTextsProps] = useState<models.Editor>({
    textId: editor.text?.textId as string,
    title: '',
    text: '',
    rawEditorState: {}
  });

  const navigate = useNavigate();

  useEffect(() => {
    const cb = {
      success: () => {},
      error: () => {}
    };

    const pathLocation: any = location.pathname.split('/');

    if (pathLocation[2]) {
      dispatch(getDocumentById(pathLocation[2], navigate, cb));
    } else {
      navigate('/documents');
    }

    return () => {
      setTextsProps({
        textId: '',
        title: '',
        text: '',
        rawEditorState: {}
      });

      setText('');

      setLoaded(false);

      setEditorState(EditorState.createEmpty());

      dispatch(clearTemplatesDetails());
    };
  }, [location]);

  useEffect(() => {
    if (!loaded) {
      setTimeout(() => {
        if (documents.detail?.contentState?.blocks) {
          const contentState = editorState?.blocks
            ? editorState
            : createEditorStateFromRaw({
                blocks: documents.detail?.contentState?.blocks,
                entityMap: documents.detail?.contentState?.entityMap || {}
              });

          const corrections = documents.detail?.corrections
            ? {
                deviations: filteredCorrections(
                  documents.detail?.corrections.deviations,
                  'deviations',
                  editor.activeCategoryType
                ),
                spellingAndGrammar: filteredCorrections(
                  documents.detail?.corrections.spellingAndGrammar,
                  'spellingAndGrammar',
                  editor.activeCategoryType
                ),
                doubts: filteredCorrections(
                  documents.detail?.corrections.doubts,
                  'doubts',
                  editor.activeCategoryType
                )
              }
            : {
                deviations: [],
                spellingAndGrammar: [],
                doubts: []
              };

          if (corrections) {
            const state = createCorrectionEntities(
              removeCorrectionEntities(contentState),
              editor.activeCategory || 'deviations',
              corrections
            );

            if (!loaded) {
              const newText = state.getCurrentContent().getPlainText();

              if (
                newText !== '' &&
                corrections?.deviations?.length === 0 &&
                corrections?.spellingAndGrammar?.length === 0 &&
                corrections?.doubts?.length === 0
              ) {
                onChange(state);
              } else {
                throttledSetState(state, newText);
              }
            }
          }

          setTimeout(() => {
            setLoaded(true);
          }, 2000);
        } else {
          if (editor.text?.text === '') {
            setTimeout(() => {
              setLoaded(true);
            }, 2000);
          }
        }

        if (!loaded && !textProps.textId) {
          setTextsProps({
            textId: editor.text?.textId as string,
            title: documents.detail?.title as string,
            text: editor.text?.text as string,
            rawEditorState: documents.detail?.contentState
          });
        } else {
          setTextsProps({
            ...textProps,
            title:
              textProps.title === ''
                ? documents?.detail?.title || ''
                : textProps.title
          });
        }
      }, 1000);
    }
  }, [loaded, documents.detail]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (loaded && documents.detail?.contentState?.blocks) {
  //       const contentState = editorState?.blocks
  //         ? editorState
  //         : createEditorStateFromRaw({
  //             blocks: documents.detail?.contentState?.blocks,
  //             entityMap: documents.detail?.contentState?.entityMap || {}
  //           });
  //
  //       const corrections = documents.detail?.corrections
  //         ? {
  //             deviations: filteredCorrections(
  //               documents.detail?.corrections.deviations,
  //               'deviations',
  //               editor.activeCategoryType
  //             ),
  //             spellingAndGrammar: filteredCorrections(
  //               documents.detail?.corrections.spellingAndGrammar,
  //               'spellingAndGrammar',
  //               editor.activeCategoryType
  //             ),
  //             doubts: filteredCorrections(
  //               documents.detail?.corrections.doubts,
  //               'doubts',
  //               editor.activeCategoryType
  //             )
  //           }
  //         : {
  //             deviations: [],
  //             spellingAndGrammar: [],
  //             doubts: []
  //           };
  //
  //       if (corrections) {
  //         const state = createCorrectionEntities(
  //           removeCorrectionEntities(contentState),
  //           editor.activeCategory || 'deviations',
  //           corrections
  //         );
  //
  //         const newText = state.getCurrentContent().getPlainText();
  //
  //         throttledSetState(state, newText);
  //       }
  //     }
  //   }, 0);
  // }, [editor.activeCategory, editor.activeCategoryType]);

  useEffect(() => {
    const newCorrections = documents?.detail?.corrections || {
      deviations: [],
      spellingAndGrammar: [],
      doubts: []
    };

    const activeCategory = editor.activeCategory;
    const activeCategoryType = editor.activeCategoryType;

    if (loaded) {
      // VERIFICAR DE CORRECTIONS MUDOU
      if (
        JSON.stringify(newCorrections) ===
          JSON.stringify(configs.corrections) &&
        activeCategory === configs.activeCategory &&
        activeCategoryType === configs.activeCategoryType
      ) {
        return;
      }

      setConfigs({
        ...configs,
        corrections: newCorrections,
        activeCategory,
        activeCategoryType
      });

      const formattedCorrections = newCorrections
        ? {
            deviations: filteredCorrections(
              newCorrections.deviations,
              'deviations',
              activeCategoryType
            ),
            spellingAndGrammar: filteredCorrections(
              newCorrections.spellingAndGrammar,
              'spellingAndGrammar',
              activeCategoryType
            ),
            doubts: filteredCorrections(
              newCorrections.doubts,
              'doubts',
              activeCategoryType
            )
          }
        : {
            deviations: [],
            spellingAndGrammar: [],
            doubts: []
          };

      const state = createCorrectionEntities(
        removeCorrectionEntities(editorState),
        editor.activeCategory || 'deviations',
        formattedCorrections
      );

      const newText = state.getCurrentContent().getPlainText();

      throttledSetState(state, newText);
    }
  }, [
    editorState,
    documents?.detail?.corrections,
    editor.activeCategory,
    editor.activeCategoryType
  ]);

  // const updateCorrections = corrections => {
  //   if (corrections) {
  //     const state = createCorrectionEntities(
  //       removeCorrectionEntities(editorState),
  //       editor.activeCategory || 'deviations',
  //       corrections
  //     );
  //
  //     const newText = state.getCurrentContent().getPlainText();
  //
  //     // throttledSetState(state, newText);
  //
  //     setEditorState(state);
  //
  //     // setText(newText);
  //   }
  // };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    setTextsProps({ ...textProps, [type]: e.target.value });
  };

  const handleBlur = () => {
    if (documents?.detail?._id) {
      dispatch(setTitle(documents.detail._id, textProps.title));
    }
  };

  const onChange = (newEditorState, type?: any) => {
    // DOUBLE CLICK | SYNONYMS
    if (loaded && !isMobile) {
      handleChangeSelection(newEditorState);
    }
    // DOUBLE CLICK | SYNONYMS

    const newText = newEditorState.getCurrentContent().getPlainText();

    const newDocument = JSON.stringify(
      convertToRaw(newEditorState.getCurrentContent())
    );

    const oldDocument = JSON.stringify(
      convertToRaw(editorState.getCurrentContent())
    );

    // CHECK TEXT
    if (text !== newText && newDocument !== oldDocument) {
      onCheck(
        documents.detail?._id,
        documents.detail?.title,
        newEditorState,
        newText,
        editor.activeCategory,
        editor.activeCategoryType
      );
    }

    // SAVE ONLY EDITOR
    if (text === newText && newText !== '' && newDocument !== oldDocument) {
      onSave(
        documents.detail?._id,
        documents.detail?.title,
        newEditorState,
        newText
      );
    }

    const state = newEditorState;

    throttledSetState(state, newText);

    // SUGGESTION
    setTimeout(() => {
      if (text !== newText && loaded && !isMobile) {
        setSuggestion(updateSuggestionsState());
      }
    }, 200);
  };

  const onCheck = React.useCallback(
    debounce(
      (
        textId,
        title,
        newEditorState,
        newText,
        activeCategory,
        activeCategoryType
      ) => {
        const size = newText.trim().split(/\s+/).length;

        const WORD_LIMIT = {
          FREE: {
            STRING: '1000',
            NUMBER: 1000
          },
          PAID: {
            TIPS: {
              MULTI: '6x',
              QUANTITY: '120'
            },
            MORE: '20x',
            STRING: '20 mil',
            NUMBER: 20000
          }
        };

        const limit =
          user && user.isSubscriber
            ? WORD_LIMIT.PAID.NUMBER
            : WORD_LIMIT.FREE.NUMBER;

        if (size <= limit) {
          if (!loading) {
            setLoading(true);

            console.log('TEXT: ', title);

            throttledCheck(
              textId,
              title,
              newEditorState,
              newText,
              activeCategory,
              activeCategoryType
            );
          }
        }
      },
      isMobile ? 3000 : 2000
    ),
    []
  );

  const throttledCheck = React.useCallback(
    throttle(
      (
        textId,
        title,
        newEditorState,
        newText,
        activeCategory,
        activeCategoryType
      ) => {
        if (!loading) {
          setLoading(true);

          const state = removeCorrectionEntities(newEditorState);

          const rawEditorState = convertToRaw(state.getCurrentContent());

          throttledSetState(state, newText);

          const cb = {
            success: newCorrections => {
              setLoading(false);

              const formattedCorrections = newCorrections
                ? {
                    deviations: filteredCorrections(
                      newCorrections.deviations,
                      'deviations',
                      activeCategoryType
                    ),
                    spellingAndGrammar: filteredCorrections(
                      newCorrections.spellingAndGrammar,
                      'spellingAndGrammar',
                      activeCategoryType
                    ),
                    doubts: filteredCorrections(
                      newCorrections.doubts,
                      'doubts',
                      activeCategoryType
                    )
                  }
                : {
                    deviations: [],
                    spellingAndGrammar: [],
                    doubts: []
                  };

              if (formattedCorrections) {
                // setCorrections(formattedCorrections);
                // updateCorrections(formattedCorrections);
                // const state = createCorrectionEntities(
                //   removeCorrectionEntities(newEditorState),
                //   activeCategory || 'deviations',
                //   corrections
                // );
                // const newText = state.getCurrentContent().getPlainText();
                // throttledSetState(state, newText);
                // setTextsProps({
                //   ...textProps,
                //   text: newText,
                //   rawEditorState: state
                // });
              }

              // setLoaded(false);
            },
            error: () => {
              setLoading(false);
            }
          };

          dispatch(
            processText(
              {
                textId: textId,
                text: newText,
                title: title,
                rawEditorState
              } as models.Editor,
              cb
            )
          );
        }
      },
      isMobile ? 3000 : 1000
    ),
    []
  );

  const throttledSetState = React.useCallback(
    throttle((state, newText) => {
      setEditorState(state);

      setText(newText);

      // setTextsProps({ ...textProps, text: newText, rawEditorState: state });
    }, 0),
    []
  );

  const onSave = React.useCallback(
    debounce(
      (textId, title, newEditorState, newText) => {
        throttledSave(textId, title, newEditorState, newText);
      },
      isMobile ? 3000 : 1000
    ),
    []
  );

  const throttledSave = React.useCallback(
    throttle(
      (textId, title, newEditorState, newText) => {
        setLoading(true);

        const rawContentState = serialiseEditorStateToRaw(newEditorState);

        const cb = {
          success: () => {
            setLoading(false);
          },
          error: () => {
            setLoading(false);
          }
        };

        dispatch(
          saveTextPreCorrection(
            {
              textId: textId,
              text: newText,
              title: title,
              rawEditorState: rawContentState
            } as models.Editor,
            cb
          )
        );
      },
      isMobile ? 3000 : 1000
    ),
    []
  );

  // EDITOR

  const onProcess = (e?: any) => {
    if (e) e.preventDefault();

    if (!loading) {
      setLoading(true);

      const state = removeCorrectionEntities(editorState);

      throttledSetState(state, text);

      const cb = {
        success: newCorrections => {
          const corrections = newCorrections
            ? {
                deviations: filteredCorrections(
                  newCorrections.deviations,
                  'deviations',
                  editor.activeCategoryType
                ),
                spellingAndGrammar: filteredCorrections(
                  newCorrections.spellingAndGrammar,
                  'spellingAndGrammar',
                  editor.activeCategoryType
                ),
                doubts: filteredCorrections(
                  newCorrections.doubts,
                  'doubts',
                  editor.activeCategoryType
                )
              }
            : {
                deviations: [],
                spellingAndGrammar: [],
                doubts: []
              };

          if (corrections) {
            const state = createCorrectionEntities(
              removeCorrectionEntities(editorState),
              editor.activeCategory || 'deviations',
              corrections
            );

            const newText = state.getCurrentContent().getPlainText();

            throttledSetState(state, newText);
          }

          setLoading(false);
        },
        error: () => {
          setLoading(false);
        }
      };

      dispatch(
        processText(
          {
            textId: documents.detail?._id,
            text: text,
            title: textProps.title,
            rawEditorState: convertToRaw(state.getCurrentContent())
          } as models.Editor,
          cb
        )
      );
    }
  };

  const onCopyToClipboard = e => {
    if (e) e.preventDefault();

    try {
      const range = document.createRange();
      const textToCopy = document.createElement('div');
      const selection: any = window.getSelection();
      textToCopy.innerHTML = convertToHTML(HTMLExporterConfig)(
        editorState.getCurrentContent()
      );

      textToCopy.id = 'copy-to-clipboard';
      document.body.append(textToCopy);
      range.selectNodeContents(textToCopy);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');

      // console.log(textToCopy);
      textToCopy.remove();

      notify.success('Texto copiado! :D');
    } catch (e) {
      const textToCopy = document.querySelector('#copy-to-clipboard');
      if (textToCopy) {
        textToCopy.remove();
      }
    }
  };

  const ctx = useContext(GlobalContext);

  const onFixAll = async (
    textId: string,
    type: string,
    category: string,
    corrections: any,
    selected?: any
  ) => {
    const state = editorState;

    let contentState = state.getCurrentContent();
    let offsetAdjustment = 0;

    const rawContent = convertToRaw(contentState);
    const textBlocks = rawContent.blocks.map(block => block.text);
    let fullText = textBlocks.join('\n');

    corrections.forEach(correction => {
      const span = correction.spans[0];
      const suggestion = selected ? selected : correction.suggestions[0];

      const startOffset = span.offset + offsetAdjustment;
      const endOffset = startOffset + span.length;

      fullText =
        fullText.slice(0, startOffset) + suggestion + fullText.slice(endOffset);

      const lengthDifference = suggestion.length - span.length;
      offsetAdjustment += lengthDifference;
    });

    const newContentState = ContentState.createFromText(fullText);

    await onChange(
      EditorState.push(state, newContentState, 'fix-all'),
      'fix-all'
    );
  };

  const onReplaceWord = async (
    textId,
    correction,
    type,
    category,
    suggestion
  ) => {
    const contentState = editorState;

    const item = correction;

    if (!item) return;

    item.offset = item.spans[0].offset;
    item.length = item.spans[0].length;
    item.content = item.spans[0].content;

    // REMOVE
    if (suggestion === '') {
      const state = {
        text: text,
        editorState: contentState
      };

      let concat = '';
      let replacement = '';
      let run = true;
      let offset = 0;

      const paragraphs = state.text.split('\n');

      let found = false;

      paragraphs.map(paragraph => {
        if (!found) {
          const characters = paragraph.split('');

          characters.map(character => {
            if (offset >= item.offset && offset < item.offset + item.length) {
              concat = concat + character;

              found = true;
            }

            if (offset >= item.offset + item.length && run) {
              if (['.', ',', ';', ' '].includes(character)) {
                concat = concat + character;
              } else {
                const alphabet = [
                  'a',
                  'b',
                  'c',
                  'd',
                  'e',
                  'f',
                  'g',
                  'h',
                  'i',
                  'j',
                  'k',
                  'l',
                  'm',
                  'n',
                  'o',
                  'p',
                  'q',
                  'r',
                  's',
                  'u',
                  'v',
                  'w',
                  'x',
                  'y',
                  'z'
                ];

                if (
                  character !== ' ' &&
                  alphabet.includes(character.toLowerCase())
                ) {
                  const expression = item.content;

                  const letter = item.content.substring(0, 1);

                  // PRIMEIRA LETRA MAIÚSCULA
                  if (letter === letter.toUpperCase()) {
                    concat = concat + character.toUpperCase();

                    replacement = character.toUpperCase();
                  }

                  // PRIMEIRA LETRA MINÚSCULA
                  if (letter === letter.toLowerCase()) {
                    concat = concat + character;

                    replacement = character.toLowerCase();
                  }
                }

                run = false;
              }

              found = true;
            }

            offset++;

            return character;
          });

          offset++;
        }

        return paragraph;
      });

      if (item.content !== concat) {
        item.length = concat.length;
        suggestion = replacement;
      }
    }

    let fix = 0;
    const block = editorState
      .getCurrentContent()
      .getBlocksAsArray()
      .find(el => {
        const length = el.text.length;

        if (item.offset >= fix && item.offset <= fix + length) return true;
        fix += length + 1;
        // fix = el.getCurrentContent();
        return false;
      });
    const start = item.offset - fix;

    const blockSelection = SelectionState.createEmpty(block.key).merge({
      anchorOffset: start,
      focusOffset: start + item.length
    });

    await dispatch(
      sendFeedback(
        {
          textId,
          correctionId: correction._id,
          message: '',
          type,
          correctionType: category
        },
        editorState
      )
    );

    await onChange(
      EditorState.push(
        editorState,
        Modifier.replaceText(
          editorState.getCurrentContent(),
          blockSelection,
          suggestion
        )
      ),
      'replace-word'
    );
  };

  const suggestions = [
    'Peça para a IA escrever...',
    'Continue escrevendo...',
    // 'Post para blog',
    'Criar parágrafo',
    'Gerar ideias'
  ];

  const onInsertForSuggestion = (range: any, replacement: any) => {
    const { start, end, snippet }: any = getInsertRange(
      suggestion,
      editorState
    );

    const content = String(replacement || '').replace(/^\n+/g, '');

    const contentState = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();

    const blockSelection = currentSelection.merge({
      anchorOffset: start,
      focusOffset: end
    });

    const contentStateWithEntity = contentState.createEntity(
      'SUGGESTION',
      'IMMUTABLE',
      { content }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      blockSelection,
      // `${SUGGESTION.PREFIX}${content}`
      `${content}`
      // // // ['BOLD', 'ITALIC']
    );

    onChange(
      EditorState.push(
        editorState,
        newContentState,
        state.suggestion.INSERT_ACTION_LABEL
      )
    );

    setSuggestion({ open: false });
  };

  const handleBeforeInput = chars => {
    if (chars === ' ' || chars === '\n') {
      return 'handled';
    }

    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const currentBlock = contentState.getBlockForKey(
      selectionState.getStartKey()
    );
    const blockText = currentBlock.getText();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOfsetfset();
    const inputText = blockText.slice(start, end).concat(chars);

    if (inputText.endsWith('/')) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      // Verifica se a tecla é "Enter" ou "Espaço"
      event.preventDefault(); // Impede a ação padrão da tecla
    }
  };

  const updateSuggestionsState = () => {
    const triggerRange = getTriggerRange(state.suggestion.PREFIX);

    const activeSuggestion =
      !triggerRange || triggerRange.text !== '/'
        ? { open: false }
        : {
            open: true,
            position: getCaretCoordinates(),
            searchText: triggerRange.text.slice(1, triggerRange.text.length),
            range: {
              start: triggerRange.start,
              end: triggerRange.end
            },
            selectedIndex: 0
          };

    const { start, end, snippet }: any = getInsertRange(
      suggestion,
      editorState
    );

    return activeSuggestion.open
      ? { ...activeSuggestion, snippet }
      : activeSuggestion;
  };

  const toggleSynonyms = (
    open: boolean = false,
    word: string = '',
    range: any = { start: 0, end: 0 }
  ) => {
    if (open !== synonyms.open) {
      setSynonyms(
        open
          ? { open, word, range, position: getCaretCoordinates() }
          : { open: false }
      );
    }
  };

  const toggleSelector = (
    open: boolean = false,
    word: string = '',
    range: any = { start: 0, end: 0 }
  ) => {
    if (open !== selector.open) {
      setSelector(
        open
          ? { open, word, range, position: getCaretCoordinates() }
          : { open: false }
      );
    }
  };

  const getContentForAutoCorrection = (
    startOffset: any,
    endOffset: any,
    type = 'paragraph'
  ) => {
    let fix = 0;
    let start = -1;
    let end = -1;
    let sentences = [];

    const block = editorState
      .getCurrentContent()
      .getBlocksAsArray()
      .find(el => {
        const length = el.text.length;

        if (startOffset >= fix && startOffset <= fix + length) {
          start = 0;
        }

        if (endOffset >= fix && endOffset <= fix + length) {
          end = endOffset + fix;
        }

        fix += length + 1;

        if (start !== -1 && end !== -1) return true;

        return false;
      });

    end = start + block.text.length;

    if (block) {
      if (start === -1) start = 0;
      if (end === -1) end = block.text.length;

      const paragraph = {
        content: block.getText(),
        range: {
          start: start,
          end: end,
          blockKey: block.getKey()
        }
      };

      if (type === 'sentence') {
        const contentState = editorState.getCurrentContent();

        const blockText = block.getText();

        let startOffsetPar = 0;

        contentState.getBlocksAsArray().some(currentBlock => {
          if (currentBlock.getKey() === paragraph.range.blockKey) {
            return true; // interrompe a iteração ao encontrar o bloco desejado
          }

          startOffsetPar += currentBlock.getLength() + 1; // +1 para contar a quebra de linha entre blocos
          return false;
        });

        const endOffsetPar = startOffsetPar + blockText.length;

        // console.log({
        //   blockText,
        //   startOffsetPar,
        //   endOffsetPar
        // });

        let sentences: any = [];

        const sentencesArray = paragraph.content.split(/[!?.]\s/);

        // console.log('PARAGRAFO: ', paragraph);

        let startSentence = 0;

        const fullText = editorState.getCurrentContent().getPlainText();

        // const offset = fullText.substring(0, startOffset).length;

        sentencesArray.map(sentence => {
          sentences.push({
            content: paragraph.content.substring(
              startSentence,
              startSentence + sentence.length + 1
            ),
            range: {
              start: startSentence,
              end: startSentence + sentence.length + 1,
              blockKey: block.getKey()
            }
          });

          startSentence = startSentence + sentence.length + 2;
        });

        const sentence = {
          content: fullText.substring(startOffset, endOffset),
          range: {
            start: startOffset - startOffsetPar,
            end: endOffset - startOffsetPar,
            blockKey: block.getKey()
          }
        };

        // console.log({ sentences, sentence });

        const selected = sentences.find(
          sentence =>
            sentence.range.start <= startOffset - startOffsetPar &&
            sentence.range.end >= endOffset - startOffsetPar
        );

        // console.log('SELECTED: ', {
        //   ...paragraph,
        //   sentence: selected
        // });

        if (selected) {
          return {
            ...paragraph,
            sentence: selected
          };
        } else {
          // const selected = sentences.find(
          //     sentence =>
          //         sentence.range.start <= startOffset &&
          //         sentence.range.end >= endOffset
          // );
          //
          // if (selected) {
          //   return {
          //     ...paragraph,
          //     sentence: selected
          //   };
          // }

          const selected = sentences.filter(sentence => {
            return (
              (startOffset - startOffsetPar >= sentence.range.start &&
                startOffset - startOffsetPar <= sentence.range.end) ||
              (endOffset - startOffsetPar >= sentence.range.start &&
                endOffset - startOffsetPar <= sentence.range.end)
            );
          });

          if (selected && selected.length > 0) {
            return {
              ...paragraph,
              sentence: {
                content: fullText.substring(
                  selected[0].range.start,
                  selected[selected.length - 1].range.end
                ),
                range: {
                  start: selected[0].range.start,
                  end: selected[selected.length - 1].range.end,
                  blockKey: selected[0].range.blockKey
                }
              }
            };
          }

          return {
            ...paragraph,
            sentence: paragraph
          };
        }
      }

      return paragraph;
    }

    return null;
  };

  const onReplaceForSynonyms = (word, range, replacement) => {
    const { start: startOffset, end: endOffset } = range;

    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const selectedBlockKey = selectionState.getStartKey();
    const selectedBlock = contentState.getBlockForKey(selectedBlockKey);
    const selectedText = selectedBlock.getText().slice(startOffset, endOffset);

    const replacementText = replacement;

    if (selectedText) {
      // Crie uma nova ContentState com o texto substituído
      const replacedContent = Modifier.replaceText(
        contentState,
        SelectionState.createEmpty(selectedBlockKey).merge({
          anchorOffset: startOffset,
          focusOffset: endOffset
        }),
        replacementText
      );

      // Atualize o estado do editor com a nova ContentState
      const newEditorState = EditorState.push(
        editorState,
        replacedContent,
        'replace-text'
      );

      onChange(newEditorState);
    }

    // setSynonyms({ open: false });
  };

  const onReplaceForSelector = (word, range, replacement) => {
    const { start: startOffset, end: endOffset } = range;

    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    const selectedBlockKey = selectionState.getStartKey();
    const selectedBlock = contentState.getBlockForKey(selectedBlockKey);
    const selectedText = selectedBlock.getText().slice(startOffset, endOffset);

    const replacementText = replacement;

    if (selectedText) {
      // Crie uma nova ContentState com o texto substituído
      const replacedContent = Modifier.replaceText(
        contentState,
        SelectionState.createEmpty(selectedBlockKey).merge({
          anchorOffset: startOffset,
          focusOffset: endOffset
        }),
        replacementText
      );

      // Atualize o estado do editor com a nova ContentState
      const newEditorState = EditorState.push(
        editorState,
        replacedContent,
        'replace-text'
      );

      onChange(newEditorState);
    }

    setSelector({ open: false });
  };

  const onReplaceAutoCorrection = (word, range, replacement) => {
    const blockSelection = SelectionState.createEmpty(range.blockKey).merge({
      anchorOffset: range.start,
      focusOffset: range.end
    });

    onChange(
      EditorState.push(
        editorState,
        Modifier.replaceText(
          editorState.getCurrentContent(),
          blockSelection,
          replacement
        )
      )
    );
  };

  const getSelectedTextFromBlocks = (
    editorState,
    startBlock,
    endBlock,
    startOffset,
    endOffset
  ) => {
    const contentState = editorState.getCurrentContent();
    let selectedText = '';

    contentState.getBlockMap().forEach(block => {
      const blockKey = block.getKey();
      const blockText = block.getText();

      if (blockKey === startBlock.getKey() && blockKey === endBlock.getKey()) {
        selectedText += blockText.slice(startOffset, endOffset);
      } else if (blockKey === startBlock.getKey()) {
        selectedText += blockText.slice(startOffset) + '\n';
      } else if (blockKey === endBlock.getKey()) {
        selectedText += blockText.slice(0, endOffset);
      } else if (
        blockKey > startBlock.getKey() &&
        blockKey < endBlock.getKey()
      ) {
        selectedText += blockText + '\n';
      }
    });

    return selectedText;
  };

  const handleChangeSelection = (editorState: any) => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();

    const startBlockKey = selectionState.getStartKey();
    const endBlockKey = selectionState.getEndKey();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();

    const startBlock = contentState.getBlockForKey(startBlockKey);
    const endBlock = contentState.getBlockForKey(endBlockKey);

    const selected = getSelectedTextFromBlocks(
      editorState,
      startBlock,
      endBlock,
      start,
      end
    );

    if (selected !== '') {
      const response = selected.split(' ');

      if (response.length === 1) {
        const isOnlyWord = /^[\p{L}0-9]+$/u.test(selected);

        // VERIFICAR SE CARACTERE ANTERIOR E POSTERIOR É UM ESPAÇO VAZIO.
        const before = text.slice(start - 1, start);
        const after = text.slice(end, end + 1);

        // console.log({ before, after });

        toggleSelector(false, '', { start: 0, end: 0 });

        if (isOnlyWord) {
          toggleSynonyms(true, selected || '', {
            start: start,
            end: end
          });
        } else if (
          before !== ' ' &&
          before !== '' &&
          before !== ',' &&
          before !== '.' &&
          before !== ',' &&
          before !== '\n'
        ) {
          toggleSynonyms(false, '', { start: 0, end: 0 });

          return;
        } else if (
          after !== ' ' &&
          after !== '' &&
          after !== ',' &&
          after !== '.' &&
          after !== ',' &&
          after !== '\n'
        ) {
          toggleSynonyms(false, '', { start: 0, end: 0 });

          return;
        } else if (/^[a-záàâãéèêíïóôõöúçñ ]+$/i.test(selected)) {
          toggleSynonyms(true, selected || '', {
            start: start,
            end: end
          });

          // editorState.setSelection(start, start);
        }
      } else {
        toggleSelector(true, selected || '', {
          start: start,
          end: end
        });
      }
    } else {
      toggleSynonyms(false, '', { start: 0, end: 0 });

      toggleSelector(false, '', { start: 0, end: 0 });
    }
  };

  const setActiveCategory = (category: string) => {
    console.log('TESTE!');

    if (
      category === 'spellingAndGrammar' &&
      editor.activeCategory !== 'spellingAndGrammar'
    )
      return dispatch(getActiveCategory('spellingAndGrammar'));
    if (category === 'deviations' && editor.activeCategory !== 'deviations')
      return dispatch(getActiveCategory('deviations'));
    if (category === 'doubts' && editor.activeCategory !== 'doubts')
      return dispatch(getActiveCategory('doubts'));
  };

  const [scoresModalOpen, setScoresModalOpen] = useState(false);

  const [summaryModalOpen, setSummaryModalOpen] = useState(false);

  const openScoresModal = () => {
    setScoresModalOpen(!scoresModalOpen);
  };

  const openSummaryModal = () => {
    setSummaryModalOpen(!summaryModalOpen);
  };

  const { score } = getScore(documents.detail, user);

  const corrections = useMemo(() => {
    return {
      deviations: filteredCorrections(
        documents.detail?.corrections.deviations,
        'deviations',
        editor.activeCategoryType
      ),
      spellingAndGrammar: filteredCorrections(
        documents.detail?.corrections.spellingAndGrammar,
        'spellingAndGrammar',
        editor.activeCategoryType
      ),
      doubts: filteredCorrections(
        documents.detail?.corrections.doubts,
        'doubts',
        editor.activeCategoryType
      )
    };
  }, [editor, documents]);

  return (
    <div className={classes.root}>
      <Sidebar sidebarExpanded={drawerOpen} temporary toggle={setDrawerOpen} />
      <ActionsBar
        toggleDrawer={() => setDrawerOpen(!drawerOpen)}
        loading={loading}
        onProcess={onProcess}
        text={text}
      />
      <EditorContainer>
        {isMobile && (
          <CorrectionsOverviewMobile
            score={score}
            scoresModalOpen={scoresModalOpen}
            openScoresModal={openScoresModal}
            summaryModalOpen={summaryModalOpen}
            openSummaryModal={openSummaryModal}
            setActiveCategory={setActiveCategory}
          />
        )}

        <Editor
          editorState={editorState}
          onChange={onChange}
          textProps={textProps}
          handleChange={handleChange}
          handleBlur={handleBlur}
          onCopyToClipboard={onCopyToClipboard}
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
          suggestionsRef={suggestionsRef}
          handleKeyDown={handleKeyDown}
          handleBeforeInput={handleBeforeInput}
          options={state.suggestion.OPTIONS}
          onInsertForSuggestion={onInsertForSuggestion}
          suggestion={suggestion}
          setSuggestion={setSuggestion}
          synonyms={synonyms}
          setSynonyms={setSynonyms}
          toggleSynonyms={toggleSynonyms}
          onReplaceForSynonyms={onReplaceForSynonyms}
          synonymsRef={synonymsRef}
          onReplaceWord={onReplaceWord}
          onFixAll={onFixAll}
          corrections={corrections}
          editor={editor}
          selector={selector}
          setSelector={setSelector}
          selectorRef={selectorRef}
          toggleSelector={toggleSelector}
          onReplaceForSelector={onReplaceForSelector}
          onReplaceAutoCorrection={onReplaceAutoCorrection}
          getContentForAutoCorrection={getContentForAutoCorrection}
        />

        {!isMobileSmall && (
          <Corrections
            corrections={corrections}
            editor={editor}
            activeCategory={editor.activeCategory}
            onReplaceWord={onReplaceWord}
            onFixAll={onFixAll}
            onReplaceAutoCorrection={onReplaceAutoCorrection}
            getContentForAutoCorrection={getContentForAutoCorrection}
            loading={!loaded && editor.text?.text !== ''}
          />
        )}

        <LoadingContainer loading={!loaded && editor.text?.text !== ''} />
      </EditorContainer>
      {!isMobile && <CorrectionsOverview></CorrectionsOverview>}

      <IntercomButton />
    </div>
  );
};

export default EditTextPage;
