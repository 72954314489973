/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import EditorResquests from '../repositories/editor';
import mapping from '../../utils/function/mapping';

import {
  DOCUMENTS_DETAIL,
  DOCUMENTS_UPDATE_CORRECTION,
  EDITOR_ADD_WORD_FROM_DICTIONARY,
  FEEDBACK,
  TEXT_ACTIVE_CATEGORY,
  TEXT_ACTIVE_CATEGORY_TYPE,
  TEXT_EDIT,
  TEXT_PROCESS_READABILITY,
  SET_ACTIVE_CORRECTION_ITEM,
  SET_TITLE,
  DOCUMENTS_REPLACE_WORD,
  TEXT_UPDATE_GENRE
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import { doScrolling } from '../../utils/function/doScrolling';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import notify from '../../utils/notify';
import analytics from '../../utils/function/analytics';
import waiting from '../../utils/function/waiting';

export const saveTextPreCorrection =
  (text: models.Editor, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('editor', 'save-text-pre-correction');

      const payloadDocument: models.Document =
        await EditorResquests.postSaveText(text);

      dispatch({
        payload: payloadDocument,
        type: DOCUMENTS_DETAIL
      });
      dispatch({
        payload: text,
        type: TEXT_EDIT
      });

      if (cb && cb.success) {
        cb.success();
      }

      // notify.success('Texto salvo com sucesso!');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        notify.error('Ocorreu um erro ao salvar seu texto');
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const processText =
  (text: models.Editor, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('editor', 'process-text');

      await mapping.track('Process Text');

      const payloadDocument: models.Document =
        await EditorResquests.postProcessText(text);

      dispatch({
        payload: payloadDocument,
        type: DOCUMENTS_DETAIL
      });

      dispatch({
        payload: text,
        type: TEXT_EDIT
      });

      if (cb && cb.success) {
        cb.success(payloadDocument.corrections);
      }

      // notify.success('Texto processado com sucesso!');
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);

        // notify.error('Ocorreu um erro ao processar seu texto');

        // await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const setTitle =
  (textId: string, title: string, cb?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('editor', 'set-title');

      await mapping.track('Set Title Text');

      const payload: any = await EditorResquests.setTitle(textId, title);

      dispatch({
        payload: { _id: textId, title },
        type: SET_TITLE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e) {
      if (e instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }

        notify.error('Ocorreu um erro ao tentar salvar o título');

        // await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getProcessReadability =
  (text: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ProcessReadability>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('editor', 'readability');

      await mapping.track('Process Readability');

      const payload: models.ProcessReadability =
        await EditorResquests.postProcessReadability(text);

      dispatch({
        payload,
        type: TEXT_PROCESS_READABILITY
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);

        if (cb && cb.error) {
          cb.error();
        }
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const updateTextualGenre =
  (text: any, genre: any, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ProcessReadability>>) => {
    dispatch(increaseLoading());
    try {
      const payload: any = await EditorResquests.updateTextualGenre(
        text,
        genre
      );

      dispatch({
        payload,
        type: TEXT_UPDATE_GENRE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        if (cb && cb.error) {
          cb.error();
        }
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getActiveCategory =
  (activeCategory: string) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('editor', 'active-category');

      await mapping.track('Active Category');

      dispatch({
        payload: activeCategory,
        type: TEXT_ACTIVE_CATEGORY
      });

      const containerDOM = document.querySelector('#item-card-container');

      if (containerDOM) {
        // @ts-ignore
        containerDOM.style.marginTop = `${window.scrollY}px`;
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getActiveCategoryType =
  (activeCategoryType: string) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('editor', 'active-category-type');

      await mapping.track('Active Category Type');

      const containerDOM = document.querySelector('#item-card-container');
      // @ts-ignore
      containerDOM.style.marginTop = `${window.scrollY}px`;

      dispatch({
        payload: activeCategoryType,
        type: TEXT_ACTIVE_CATEGORY_TYPE
      });
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };
export const addToDictionary = (word: string) => async (dispatch: any) => {
  dispatch(increaseLoading());
  try {
    await analytics.logEvent('correction', 'add-to-dictionary');

    await mapping.track('Add To Dictionary');

    const payload: any = await EditorResquests.patchAddToDictionary(word);

    dispatch({
      payload: word,
      type: EDITOR_ADD_WORD_FROM_DICTIONARY
    });

    // notify.success('Sucesso ao adicionar a ao dicionário!')
  } catch (err) {
    if (err instanceof Error) {
      notify.error('Erro ao adicionar a palavra ao dicionário');
    }
  } finally {
    dispatch(decreaseLoading());
  }
};

export const sendFeedback =
  (feedback: models.Feedback, editorState?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent(
        'correction',
        `${feedback.type}-${feedback.correctionType}`
      );

      await mapping.track('Send Feedback');

      const payload: models.Feedback = await EditorResquests.putSendFeedback(
        feedback
      );

      feedback.correctionType = 'deviations';

      dispatch({ payload: feedback, type: FEEDBACK });

      if (!editorState) {
        dispatch({ payload: feedback, type: DOCUMENTS_UPDATE_CORRECTION });
      } else {
        dispatch({
          payload: { feedback, editorState },
          type: DOCUMENTS_REPLACE_WORD
        });
      }

      notify.success('Obrigada pelo feedback!');
    } catch (err) {
      if (err instanceof Error) {
        notify.error('Ocorreu um erro ao enviar o feedback.');
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const sendFeedbackFixAll =
  (feedback: any, editorState?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent(
        'correction',
        `${feedback.type}-${feedback.correctionType}`
      );

      await mapping.track('Send Feedback');

      const { corrections } = feedback;

      await EditorResquests.putSendFeedbackFixAll(feedback);

      corrections.map(async (correction: any) => {
        feedback.correctionId = correction._id;

        delete feedback.corrections;

        feedback.correctionType = 'deviations';

        dispatch({ payload: feedback, type: FEEDBACK });

        if (!editorState) {
          dispatch({ payload: feedback, type: DOCUMENTS_UPDATE_CORRECTION });
        } else {
          dispatch({
            payload: { feedback, editorState },
            type: DOCUMENTS_REPLACE_WORD
          });
        }
      });

      notify.success('Obrigada pelo feedback!');
    } catch (err) {
      if (err instanceof Error) {
        notify.error('Ocorreu um erro ao enviar o feedback.');
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const setActiveCorrectionItem =
  (item: any, premium?: any) => async (dispatch: any) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('editor', 'active-correction-item');

      await mapping.track('Active Correction Item');

      const cardPremium = document.querySelector(`.ITEM_PREMIUM_CTA`);

      const hoverDOM = document.querySelector(`.TEXT_${item}`);
      const cardDOM = document.querySelector(`.ITEM_${item}`);
      const activeCardDOM = document.querySelector('.active-item-card');
      const containerDOM = document.querySelector('#item-card-container');
      if (cardDOM && hoverDOM) {
        const bodyY = document.body.getBoundingClientRect().y;
        const cardY = cardDOM.getBoundingClientRect().y - bodyY;
        const hoverY = hoverDOM.getBoundingClientRect().y - bodyY;
        let newMargin = hoverY - cardY - 50;
        // @ts-ignore
        if (containerDOM.style.marginTop !== '') {
          // @ts-ignore
          newMargin += parseInt(containerDOM.style.marginTop);
        }

        if (cardPremium && premium) {
          newMargin = 0;
        }

        if (activeCardDOM) {
          if (activeCardDOM.getBoundingClientRect().y < cardY + bodyY) {
            newMargin +=
              activeCardDOM.getBoundingClientRect().height -
              cardDOM.getBoundingClientRect().height;

            // if (cardPremium) {
            //   newMargin += 280;
            // }
          }
        }
        // @ts-ignore
        containerDOM.style.marginTop = newMargin + 'px';
        doScrolling(hoverY - window.innerHeight / 2, 250);
      }

      dispatch({ payload: { item }, type: SET_ACTIVE_CORRECTION_ITEM });
    } catch (err) {
      if (err instanceof Error) {
        notify.error('Erro ao enviar o feedback');
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const clearTemplatesDetails =
  () => (dispatch: Dispatch<BasicDispatchParam<undefined>>) => {
    dispatch({
      payload: undefined,
      type: TEXT_EDIT
    });
  };
