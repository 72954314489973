export const isBetaUser = (user: any, functionality: any = null): boolean => {
  console.log('IS BETA USER');
  // console.log('IS BETA USER');
  console.log('IS BETA USER');

  const team =
    user?.email &&
    [
      'felipeiszlaji@gmail.com',
      'eurafaelcamillo@gmail.com',
      'croft.nathalia@gmail.com',
      'murilozs@live.ie',
      // SEM PARAR
      'marina.maschi@corpay.com.br',
      'eliabe.santana@corpay.com.br',
      'michelle.castro@corpay.com.br',
      'jefferson.moraes@squadra.com.br',
      'mrenata.silva@corpay.com.br',
      'fabiana.moreira@corpay.com.br',
      // GLOBO
      'luiza.baptista@edglobo.com.br',
      'juliana.causin@sp.oglobo.com.br',
      'bernardo.yoneshigue@oglobo.com.br',
      'carolina.nalin@infoglobo.com.br',
      'vitor.seta@extra.inf.br',
      // NOVA CONCURSOS
      'marinara.faria@novaconcursos.com.br',
      'nataly.rafaele@novaconcursos.com.br',
      'camila.custodio@novaconcursos.com.br',
      // OLIVER AGENCY
      'IA-Latam@oliver.agency',
      'felipecamargo@oliver.agency',
      'vanessafofano@oliver.agency',
      'alinebarreto@oliver.agency',
      // 'beatrizprocopio@oliver.agency',
      'nathaliaoliveira@oliver.agency',
      'eduardosilva@oliver.agency',
      'giovannapini@oliver.agency',
      'isabelamartyres@oliver.agency',
      'mariaribeiro@oliver.agency',
      'julianalustosa@oliver.agency',
      'lucianaassis@oliver.agency',
      'mallenasales@oliver.agency',
      'renatababoni@oliver.agency',
      'ruygalvao@oliver.agency',
      'thiagoevaristo@oliver.agency',
      'felipegoncalves@oliver.agency',
      'wellingtonCesar@oliver.agency',
      'guilhermemuto@oliver.agency',
      // FAMÍLIA DO SÍTIO
      'socialmedia@familiadositio.com.br',
      'analista.marketing@familiadositio.com.br',
      'rodrigo@familiadositio.com.br',
      // YDUQS
      'fernanda.cristina@yduqs.com.br',
      'jessica.fsilva@yduqs.com.br',
      // OTHERS
      'conrado@novi.cc',
      'raissa.mendonca@editorasolucao.com.br'
    ].includes(user.email);

  if (functionality === 'brand') {
    const domain: any = user?.email && user.email.match(/@(.+)$/);

    const domains = ['@clarice.ai'];

    return team || (domain && domains.includes(domain[0]));
  }

  const domain: any = user?.email && user.email.match(/@(.+)$/);

  const domains = ['@globo.com', '@ge.globo', '@clarice.ai'];

  return team || (domain && domains.includes(domain[0]));
};
