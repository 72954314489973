import React, { useContext } from 'react';
import { ThemeContext } from '../../utils/context/ThemeContext';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import GlobalStyle from './components/GlobalStyle';

const AuthLayout = () => {
  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex', background: '#ffffff' }}>
      <GlobalStyle />
      <Outlet />
    </Box>
  );
};

export default AuthLayout;
