/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import TemplatesRequests from '../repositories/templates';
import {
  TEMPLATE_COMPLETION,
  TEMPLATES_COMPLETION_CLEAR,
  TEMPLATES_DETAIL,
  TEMPLATES_GET_ALL_COMPLETIONS,
  TEMPLATES_COMPLETION_UPDATE_STATUS,
  DOCUMENTS_UPDATE_LIST,
  TEXT_EDIT,
  TEMPLATE_TOGGLE_FAVORITE
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import { toast } from 'react-toastify';
import notify from '../../utils/notify';
import analytics from '../../utils/function/analytics';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import mapping from '../../utils/function/mapping';

export const completionByTemplate =
  (fields: models.Templates, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'completion-by-template');

      await mapping.track('Completion By Template');

      const payload: models.CompletionByTemplateResponse =
        await TemplatesRequests.completionByTemplate(fields);

      dispatch({
        payload,
        type: TEMPLATE_COMPLETION
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const completionByEditorSelector =
  (form: any, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());

    const fields: any = {
      template: form.template,
      quantity: form.quantity || 1,
      snippet: form.snippet,
      command: form.command
    };

    try {
      await analytics.logEvent('template', 'completion-by-editor');

      await mapping.track('Completion By Editor');

      const payload: models.CompletionByTemplateResponse =
        await TemplatesRequests.completionByEditor(fields);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const completionByAutoCorrection =
  (form: any, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());

    const fields: any = {
      template: form.template,
      quantity: form.quantity || 1,
      snippet: form.snippet,
      description: form.description,
      command: form.command
    };

    try {
      await analytics.logEvent('template', 'completion-by-auto-correction');

      await mapping.track('Completion By Auto Correction');

      const payload: models.CompletionByTemplateResponse =
        await TemplatesRequests.completionByEditor(fields);

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const completionByEditor =
  (form: any, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());

    const fields: any = {
      template: form.template,
      quantity: form.quantity || 1,
      snippet: form.snippet,
      command: form.command
    };

    try {
      await analytics.logEvent('template', 'completion-by-editor');

      await mapping.track('Completion By Editor');

      const payload: models.CompletionByTemplateResponse =
        await TemplatesRequests.completionByEditor(fields);

      dispatch({
        payload,
        type: TEMPLATE_COMPLETION
      });

      if (cb && cb.success) {
        cb.success(payload.output);
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getAllCompletions =
  (template: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'completions-all');

      await mapping.track('Get All Completions');

      const payload: models.ChatResponse =
        await TemplatesRequests.getAllCompletions(template);

      dispatch({
        payload,
        type: TEMPLATES_GET_ALL_COMPLETIONS
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const completionClear =
  (template: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'completion-clear');

      await mapping.track('Completion Clear');

      const payload: models.ChatResponse =
        await TemplatesRequests.completionClear(template);

      dispatch({
        payload: null,
        type: TEMPLATES_COMPLETION_CLEAR
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const completionUpdateStatus =
  (_id: string, type: string, action: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.TemplatesResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'completion-update-status');

      await mapping.track('Completion Update Status');

      const payload: models.TemplatesResponse =
        await TemplatesRequests.completionUpdateStatus(_id, type, action);

      dispatch({
        payload,
        type: TEMPLATES_COMPLETION_UPDATE_STATUS
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const openInDocument =
  (text: any, rawEditorState: any, navigate: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.TemplatesResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'open-in-document');

      await mapping.track('Completion Open In Document');

      const payload: any = await TemplatesRequests.openInDocument(
        text,
        rawEditorState
      );

      const editorPayload: any = {
        textId: payload._id,
        title: payload.title,
        text: payload.fullText,
        rawEditorState: payload.contentState
      };

      dispatch({
        payload: editorPayload,
        type: TEXT_EDIT
      });

      dispatch({
        payload,
        type: DOCUMENTS_UPDATE_LIST
      });

      navigate(`/edit-text/${payload._id}`);

      notify.success('Seu texto foi criado!');
    } catch (e: any) {
      if (e) {
        // if (cb && cb.error) {
        //   cb.error();
        // }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getTemplateResponse =
  (filds: models.Templates) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.TemplatesResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'completion');

      const payload: models.TemplatesResponse =
        await TemplatesRequests.getTemplateResponse(filds);
      dispatch({
        payload,
        type: TEMPLATE_COMPLETION
      });
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getTemplateHistoric =
  (typeTemplate: string, idUser: string) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.TemplatesResponse[]>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'completion-historic');

      await mapping.track('Template Get Historic');

      const payload: models.TemplatesResponse[] =
        await TemplatesRequests.getTemplateHistoric(typeTemplate, idUser);
      dispatch({
        payload,
        type: TEMPLATES_DETAIL
      });
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const toggleFavoriteTemplate =
  (id: string, cb?: any) =>
  async (
    dispatch: Dispatch<BasicDispatchParam<models.CompletionByTemplateResponse>>
  ) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('template', 'toggle-favorite');

      await mapping.track('Template Toggle Favorite');

      const payload: models.CompletionByTemplateResponse =
        await TemplatesRequests.toggleFavoriteTemplate(id);

      dispatch({
        payload,
        type: TEMPLATE_TOGGLE_FAVORITE
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error();
        }

        await RequestErrorHelper(e);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const clearTemplatesDetails =
  () => (dispatch: Dispatch<BasicDispatchParam<undefined>>) => {
    dispatch({
      payload: undefined,
      type: TEMPLATES_DETAIL
    });
  };
