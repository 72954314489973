import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  container: {
    paddingTop: '50px!important',
    paddingBottom: '50px!important'
  }
}));

export default Styles;
