import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  citation: {
    marginTop: '10px',
    padding: '10px',
    backgroundColor:
      theme.palette.mode === 'light' ? 'rgba(235, 236, 237, 0.5)' : '#303030',
    borderRadius: '3px'
  },
  icon: {
    color: theme.palette.text.secondary,
    width: '1.1em',
    height: '1.1em'
  },
  text: {
    marginLeft: '5px!important',
    font: 'normal normal 400 16.5px/19px Poppins!important',
    color: theme.palette.text.secondary,
    letterSpacing: '0.12px',
    fontStyle: 'italic!important'
  },
  author: {
    marginTop: '3px!important',
    textAlign: 'right',
    font: 'normal normal 400 16.5px/19px Poppins!important',
    color: theme.palette.text.secondary,
    letterSpacing: '0.12px'
  }
}));

export default Styles;
