import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    width: `calc(100% - 18rem)!important`,
    ml: '18rem!important',
    boxShadow: 'unset!important',
    backgroundColor:
      theme.palette.mode === 'light'
        ? '#ffffff!important'
        : '#252525!important',
    color:
      theme.palette.mode === 'light'
        ? '#3a3541!important'
        : '#ffffff!important',
    /*     borderBottom:
      theme.palette.mode === 'light'
        ? '1px solid #E7EBF0!important'
        : '1px solid #3a3541!important', */
    backgroundImage:
      'linear-gradient(rgb(255 255 255 / 0%), rgb(255 255 255 / 0%))!important'
  },
  appBarAlternative: {
    width: `calc(100% - 0rem)!important`,
    ml: '0rem!important'
  }
}));

export default useStyles;
