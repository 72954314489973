import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    marginRight: '20px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alingItems: 'center'
    }
  },
  container: {
    height: 'max-content',
    width: '100%',
    maxWidth: '287px',
    borderRadius: '13px',
    border:
      theme.palette.mode === 'light'
        ? '1.5px solid #E7EBF0!important'
        : '1px solid #3a3541',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    },
    paddingBottom: '2.7rem',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#303030',
    boxShadow:
      theme.palette.mode === 'light'
        ? '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
        : '0px 18px 56px -12px #303030'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '28px 22px'
  },
  avatar: {
    background: theme.palette.primary.main,
    width: '115px !important',
    height: '115px !important',
    fontSize: '40px'
  },
  name: {
    color: theme.palette.text.primary,
    marginTop: '17px'
  },
  info: {
    display: 'flez',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 1.5rem',
    fontFamily: 'Inter'
  },
  infoLabel: {
    fontFamily: 'Inter',
    color: theme.palette.text.primary,
    marginTop: '21px!important'
  },
  infoValue: {
    fontFamily: 'Inter',
    fontSize: '17px',
    fontWeigth: '300',
    marginTop: '4px!important',
    color: theme.palette.text.primary,
    opacity: 0.76
  },
  divider: {
    width: '100%',
    backgroundColor:
      theme.palette.mode === 'light'
        ? '1px solid rgb(0 0 0 / 20%)'
        : '1px solid #3a3541'
  },
  button: {
    width: '15rem',
    marginTop: '10px!important'
  },

  title: {
    fontSize: '25px',
    lineHeight: '1.75rem',
    fontWeight: '700',
    marginBottom: '1rem'
  },
  description: {
    fontSize: '16px',
    lineHeight: '1.25rem',
    color: 'rgb(100 116 139)',
    marginTop: '0.5rem'
  },
  /* Stats Container */
  statsContainer: {
    height: 'max-content',
    width: '100%',
    paddingLeft: '25px!important',
    maxWidth: '800px',
    borderRadius: '13px',
    border:
      theme.palette.mode === 'light'
        ? '1.5px solid #E7EBF0!important'
        : '1px solid #3a3541',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#303030',
    boxShadow:
      theme.palette.mode === 'light'
        ? '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
        : '0px 18px 56px -12px #303030',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      maxWidth: '100%'
    },
    padding: '1rem'
  },
  freeAccount: {
    fontSize: '13.9px',
    width: 'max-content',
    padding: '0.1rem 0.5rem',
    borderRadius: '4px',
    color: `${theme.palette.primary.main}`,
    backgroundColor: `#e3d0fb`,
    marginLeft: '1rem'
  },
  premiumAccount: {
    fontSize: '13.9px',
    width: 'max-content',
    padding: '0.1rem 0.5rem',
    borderRadius: '4px',
    color: `#39274B`,
    backgroundColor: `rgb(255, 172, 75)`,
    marginLeft: '1rem'
  },
  statsTitle: {
    fontSize: '25px',
    lineHeight: '1.75rem',
    fontWeight: '700'
  },
  statsInfo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '8rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '1rem'
    }
  },
  statsSubtitle: {
    fontSize: '18px',
    lineHeight: '1.75rem',
    fontWeight: '700'
    // margin: '1rem 0'
  },
  statsCards: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // marginBottom: '1rem',
    gap: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  statsCardsItem: {
    display: 'flex',
    // justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
    marginRight: '20px',
    gap: '0.5rem'
  },
  statsLabel: {
    marginBlockStart: '0px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    color: theme.palette.text.primary,
    marginBottom: '0px',
    marginLeft: '2px'
  },
  statsValue: {
    margin: '0.3rem 0px 0.2rem 0px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '15px',
    color: theme.palette.text.primary,
    opacity: 0.76,
    marginLeft: '2px'
  }
}));

export default Styles;
