import React, { useContext } from 'react';
import { PropsInterface } from './interface';
import useStyles from './styles';
import clsx from 'clsx';
import {
  ACTIVE_COUPON,
  COUPONS,
  IsBlackFriday
} from '../../../../../../../../../utils/coupon';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { ThemeContext } from '../../../../../../../../../utils/context/ThemeContext';

const PlanItem = ({
  name,
  monthPrice,
  yearPrice,
  automaticRenovation,
  discount,
  bestPlan,
  first,
  last,
  selectPlan
}: PropsInterface) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  return (
    <Box
      className={classes.root}
      sx={{
        flexDirection: isMobile ? 'column' : 'row'
      }}
    >
      <Box
        display={'flex'}
        width="100%"
        alignItems="center"
        justifyContent="center"
        sx={{
          background: bestPlan ? '#ae6eff' : 'transparent',
          width: isMobile ? '100%!important' : '40px!important',
          height: isMobile ? '40px!important' : 'inherit',
          flexDirection: isMobile ? 'column' : 'row',

          borderBottomLeftRadius: isMobile ? '0px!important' : '8px',
          borderTopLeftRadius: isMobile ? '12px!important' : '8px',
          borderTopRightRadius: isMobile ? '12px!important' : '0px',
          marginTop: isMobile ? '10px!important' : '0px'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter!important',
            fontStyle: 'normal!important',
            fontWeight: '600!important',
            fontSize: '10px!important',
            lineHeight: '100%',
            width: '110px!important',
            textAlign: 'center',
            letterSpacing: '0.19em',
            textTransform: 'uppercase',
            color: '#fff',
            // color: themeMode.mode === 'light' ? '#fff' : '#252525',
            position: isMobile ? 'relative' : 'absolute',
            transform: isMobile ? 'rotate(0)!important' : 'rotate(-90deg)'
          }}
        >
          {bestPlan ? 'Melhor plano' : ''}
        </Typography>
      </Box>
      <Box
        display={'flex'}
        width="100%"
        /* className={clsx(classes.item, {
          [classes.first]: first,
          [classes.last]: last,
          [classes.borderBestPlan]: bestPlan
        })} */
        sx={{
          padding: '35px 30px',
          background: '#fff',
          // background: themeMode.mode === 'light' ? '#fff' : '#252525',
          borderBottom: '1px solid rgba(164, 163, 255, 0.3)',
          minHeight: '157px',
          flexDirection: isMobile ? 'column' : 'row',
          borderTopLeftRadius: last ? '0' : '12px',
          borderTopRightRadius: last ? '0' : '12px',
          borderBottomLeftRadius: isMobile ? '12px' : '0px',
          borderBottomRightRadius: isMobile || last ? '12px' : '0px'
        }}
      >
        <Box
          sx={{
            width: isMobile ? '100%' : '70%'
          }}
        >
          <Typography
            className={
              IsBlackFriday() && name === 'ANUAL'
                ? classes.nameBlackFriday
                : classes.name
            }
          >
            <strong>{name}</strong>

            {name === 'ANUAL' && (
              <>
                <span
                  className={
                    IsBlackFriday() ? classes.offBlackFriday : classes.off
                  }
                >
                  {IsBlackFriday() ? '60% OFF 🔥' : '50% OFF'}
                </span>
              </>
            )}
          </Typography>

          {ACTIVE_COUPON === COUPONS.NO_DISCOUNT && (
            <Typography className={classes.price}>
              {'R$ ' + monthPrice} <span className={classes.type}>/mês</span>
            </Typography>
          )}

          {ACTIVE_COUPON === COUPONS.ANUAL50 && (
            <Typography className={classes.price}>
              {'R$ ' + monthPrice} <span className={classes.type}>/mês</span>
            </Typography>
          )}

          {ACTIVE_COUPON === COUPONS.PEGN25 && (
            <Typography className={classes.price}>
              {'R$ ' + monthPrice} <span className={classes.type}>/mês</span>
            </Typography>
          )}

          {ACTIVE_COUPON === COUPONS.NIVERCLARICE && (
            <Typography className={classes.price}>
              {'R$ ' + monthPrice} <span className={classes.type}>/mês</span>
            </Typography>
          )}

          {ACTIVE_COUPON === COUPONS.LINGUAPT50 && (
            <>
              {name === 'MENSAL' && (
                <Typography className={classes.price}>
                  {'R$ 14,95'} <span className={classes.type}>/mês</span>
                </Typography>
              )}

              {name === 'ANUAL' && (
                <Typography className={classes.price}>
                  {'R$ ' + monthPrice}{' '}
                  <span className={classes.type}>/mês</span>
                </Typography>
              )}
            </>
          )}

          {ACTIVE_COUPON === COUPONS.BLACKFRIDAY && (
            <Typography className={classes.price}>
              {'R$ ' + monthPrice} <span className={classes.type}>/mês</span>
            </Typography>
          )}

          {automaticRenovation && (
            <Typography className={classes.automaticRenovation}>
              Renovado automaticamente
            </Typography>
          )}

          {yearPrice && (
            <>
              {ACTIVE_COUPON === COUPONS.NO_DISCOUNT && (
                <>
                  <Typography className={classes.yearPriceDescription}>
                    <span className={classes.yearPrice}>R$ 179,00</span> cobrado
                    anualmente
                  </Typography>
                </>
              )}

              {ACTIVE_COUPON === COUPONS.ANUAL50 && (
                <>
                  <Typography className={classes.yearPriceDescription}>
                    <span className={classes.yearPrice}>R$ 179,00</span> cobrado
                    anualmente
                  </Typography>
                </>
              )}

              {ACTIVE_COUPON === COUPONS.PEGN25 && (
                <Typography className={classes.yearPriceDescription}>
                  De R$ 197,00 por{' '}
                  <span className={classes.yearPrice}>R$ 148,00</span> cobrado
                  anualmente
                </Typography>
              )}

              {ACTIVE_COUPON === COUPONS.NIVERCLARICE && (
                <Typography className={classes.yearPriceDescription}>
                  De R$ 197,00 por{' '}
                  <span className={classes.yearPrice}>R$ 148,00</span> cobrado
                  anualmente
                </Typography>
              )}

              {ACTIVE_COUPON === COUPONS.LINGUAPT50 && (
                <Typography className={classes.yearPriceDescription}>
                  De R$ 197,34 por{' '}
                  <span className={classes.yearPrice}>R$ 98,67</span> cobrado
                  anualmente
                </Typography>
              )}

              {ACTIVE_COUPON === COUPONS.BLACKFRIDAY && (
                <Typography className={classes.yearPriceDescription}>
                  <span className={classes.yearPrice}>R$ 143,52</span> cobrado
                  anualmente
                </Typography>
              )}
            </>
          )}

          {name === 'MENSAL' && ACTIVE_COUPON === COUPONS.LINGUAPT50 && (
            <Typography className={classes.descriptionCoupon}>
              * 50% de desconto por 3 meses *
            </Typography>
          )}

          {name === 'ANUAL' && ACTIVE_COUPON === COUPONS.LINGUAPT50 && (
            <Typography className={classes.descriptionCoupon}>
              * 50% de desconto por 1 ano *
            </Typography>
          )}
        </Box>

        <Box
          alignSelf={'flex-end'}
          sx={{
            width: isMobile ? '100%!important' : 'max-content',
            marginTop: isMobile ? '10px!important' : '0px',
            alignSelf: 'flex-end'
          }}
        >
          {((discount &&
            ACTIVE_COUPON !== COUPONS.NO_DISCOUNT &&
            ACTIVE_COUPON !== COUPONS.ANUAL50) ||
            ACTIVE_COUPON === COUPONS.LINGUAPT50) && (
            <div
              className={
                IsBlackFriday()
                  ? classes.discountContainerBlackFriday
                  : ACTIVE_COUPON === COUPONS.ANUAL50
                  ? classes.discountContainerYear50
                  : classes.discountContainer
              }
            >
              {ACTIVE_COUPON === COUPONS.ANUAL50 && (
                <>
                  <Typography className={classes.discountYear50}>
                    CUPOM: ANUAL50
                  </Typography>
                </>
              )}

              {ACTIVE_COUPON === COUPONS.PEGN25 && (
                <Typography className={classes.discount}>PEGN25</Typography>
              )}

              {ACTIVE_COUPON === COUPONS.NIVERCLARICE && (
                <Typography className={classes.discount}>
                  NIVERCLARICE
                </Typography>
              )}

              {ACTIVE_COUPON === COUPONS.LINGUAPT50 && (
                <Typography className={classes.discount}>LINGUAPT50</Typography>
              )}

              {ACTIVE_COUPON === COUPONS.BLACKFRIDAY && (
                <Typography className={classes.discountBlackFriday}>
                  BLACKFRIDAY
                </Typography>
              )}
            </div>
          )}

          <Button
            className={
              IsBlackFriday() && name === 'ANUAL'
                ? classes.buttonBlackFriday
                : classes.button
            }
            variant={'contained'}
            disableElevation
            onClick={selectPlan}
            sx={{
              textTransform: 'none!important',
              backgroundColor: '#09B386',
              '&:hover': {
                backgroundColor: '#09B386'
              }
            }}
          >
            Quero este
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PlanItem;
