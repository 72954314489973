/* eslint-disable no-undef */
import { Dispatch } from 'react';
import { BasicDispatchParam } from '../../models/dispatchTypes';
import ChatRequests from '../repositories/chat';

import {
  CHAT_CONVERSATION,
  CHAT_GET_ALL,
  CHAT_UPDATE_STATUS,
  CHAT_CLEAR
} from './actionTypes';
import { decreaseLoading, increaseLoading } from './loading';
import analytics from '../../utils/function/analytics';
import RequestErrorHelper from '../../utils/helper/RequestErrorHelper';
import mapping from '../../utils/function/mapping';

export const chatConversation =
  (message: string, language: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('chat', 'conversation');

      await mapping.track('Chat Conversation');

      const payload: models.ChatResponse = await ChatRequests.chatConversation(
        message,
        language
      );

      dispatch({
        payload,
        type: CHAT_CONVERSATION
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (e: any) {
      if (e) {
        if (cb && cb.error) {
          cb.error(e);
        }

        await RequestErrorHelper(e);

        // throw new Error(e.response.data.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const chatUpdateStatus =
  (_id: string, type: string, action: string, cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('chat', 'update-status');

      await mapping.track('Chat Update Status');

      const payload: models.ChatResponse = await ChatRequests.chatUpdateStatus(
        _id,
        type,
        action
      );

      dispatch({
        payload,
        type: CHAT_UPDATE_STATUS
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const getChatConversationAll =
  (cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('chat', 'conversation-all');

      await mapping.track('Get All Conversation');

      const payload: models.ChatResponse =
        await ChatRequests.getChatConversationAll();

      dispatch({
        payload,
        type: CHAT_GET_ALL
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };

export const chatClear =
  (cb?: any) =>
  async (dispatch: Dispatch<BasicDispatchParam<models.ChatResponse>>) => {
    dispatch(increaseLoading());
    try {
      await analytics.logEvent('chat', 'clear');

      await mapping.track('Chat Clear');

      const payload: models.ChatResponse = await ChatRequests.chatClear();

      dispatch({
        payload: null,
        type: CHAT_CLEAR
      });

      if (cb && cb.success) {
        cb.success();
      }
    } catch (err) {
      if (err instanceof Error) {
        // console.log(err.message);
      }
    } finally {
      dispatch(decreaseLoading());
    }
  };
