import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    position: 'relative',
    cursor: 'pointer'
  },
  bottom: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.lightPurple
        : theme.palette.secondary,
    opacity: 0.32
  },
  top: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.primary
        : theme.palette.secondary,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  },
  circle: {
    strokeLinecap: 'round'
  },
  optionalClick: {
    cursor: 'pointer'
  },
  menuButton: {
    borderRadius: '30px',
    height: '48px'
  }
}));

export default Styles;
