import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    paddingLeft: '0px!important',
    fontSize: '32px',
    lineHeight: '32px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    letterSpacing: 0,
    maxWidth: 'calc(100% - 10px)',
    color: theme.palette.text.primary,
    marginBottom: '16px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '25px',
      lineHeight: '25px'
    },
    '& .MuiInputBase-input': {
      whiteSpace: 'nowrap',
      overflow: 'hidden!important',
      textOverflow: 'ellipsis!important'
    }
  },
  modalContainer: {
    width: '90vw',
    maxWidth: '700px',
    maxHeight: '90vh',
    overflowY: 'auto'
  },
  textName: {
    fontSize: '32px',
    lineHeight: '39px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '500',
    letterSpacing: 0,
    color: theme.palette.text.primary
  },
  container: {
    font: 'normal normal 300 18px/27px Inter !important',
    paddingRight: '10px',
    letterSpacing: '-0.31px',
    color: theme.palette.text.primary,
    marginBottom: '10vh',
    border: 'none',
    [theme.breakpoints.down(1300)]: {
      paddingRight: '0px'
    }
  },
  handle: {
    borderRadius: 2,
    padding: '0 0 0px', // opacity: 0.3,
    direction: 'ltr',
    unicodeBidi: 'bidi-override',
    color: theme.palette.text.primary
  },
  hovered: {
    borderBottom: 'none !important',
    color: theme.palette.text.black
  },
  handleLength: {
    color: 'black',
    borderRadius: 2,
    borderBottom: '2px solid rgba(255,0,0,0.2) !important',
    padding: '0 0 0px', // opacity: 0.3,
    direction: 'ltr',
    unicodeBidi: 'bidi-override'
  },
  hoveredLength: {
    borderBottom: 'none !important',
    backgroundColor: 'rgba(255,0,0,0.2)  !important'
  },
  handle_correcao: {
    borderBottom: '2px solid rgba(228, 76, 94, 0.8) !important'
  },
  handle_clareza: {
    borderBottom: '2px solid rgba(68, 137, 255, 0.8) !important'
  },
  handle_concisao: {
    borderBottom: '2px solid rgba(70, 189, 159, 0.8) !important'
  },
  handle_forca: {
    borderBottom: '2px solid rgba(136, 90, 189, 0.8) !important'
  },
  handle_originalidade: {
    borderBottom: '2px solid rgba(255, 191, 73, 0.8) !important'
  },
  handle_formatacao: {
    borderBottom: '2px solid rgba(160, 160, 160, 0.8) !important'
  },
  hover_correcao: {
    backgroundColor: 'rgba(228, 76, 94,0.3)  !important'
  },
  hover_clareza: {
    backgroundColor: 'rgba(68, 137, 255,0.3)  !important'
  },
  hover_concisao: {
    backgroundColor: 'rgba(70, 189, 159,0.3)  !important'
  },
  hover_forca: {
    backgroundColor: 'rgba(136, 90, 189,0.3)  !important'
  },
  hover_originalidade: {
    backgroundColor: 'rgba(255, 191, 73,0.3)  !important'
  },
  hover_formatacao: {
    backgroundColor: 'rgba(160, 160, 160,0.3)  !important'
  },
  handle_ortografia: {
    borderBottom: '2px solid rgba(244, 67, 54, 0.8) !important'
  },
  hover_ortografia: {
    backgroundColor: 'rgba(244, 67, 54,0.3)  !important'
  },
  handle_gramatica: {
    borderBottom: '2px solid rgba(255, 153, 0, 0.8) !important'
  },
  hover_gramatica: {
    backgroundColor: 'rgba(255, 153, 0, 0.3)  !important'
  },
  handle_pontuacao: {
    borderBottom: '2px solid rgba(255, 89, 163, 0.8) !important'
  },
  hover_pontuacao: {
    backgroundColor: 'rgba(255, 89, 163, 0.4)  !important'
  },
  btnCopyRoot: {
    color: '#4A4A4A',
    border: '1px solid #4A4A4A !important'
  },
  btnCopy: {
    textTransform: 'unset',
    font: 'normal normal 400 13.5px/15px Inter',
    letterSpacing: '0.12px',
    padding: '5px 12px',
    margin: '0 3px !important',
    borderRadius: '7px',
    position: 'fixed',
    bottom: '16px',
    right: '220px',
    zIndex: 1001,
    [theme.breakpoints.down('sm')]: {
      right: '95px',
      bottom: '60px'
    },
    [theme.breakpoints.down(1155)]: {
      right: '220px'
    },
    [theme.breakpoints.down(1055)]: {
      right: '210px'
    },
    [theme.breakpoints.down(1060)]: {
      display: 'none'
    }
  },
  btnCopyHover: {
    '&:hover': {
      color: theme.palette.common.tPurple + ' !important',
      border: '1px solid ' + theme.palette.common.tPurple + ' !important'
    }
  },
  btnCopyActive: {
    color: theme.palette.common.tPurple + ' !important',
    border: '1px solid ' + theme.palette.common.tPurple + ' !important'
  },
  btnCopyFloat: {
    color: '#4A4A4A',
    textTransform: 'unset',
    font: 'normal normal 400 13.5px/15px Inter',
    letterSpacing: '0.12px',
    padding: '5px 5px',
    margin: '0 3px !important',
    borderRadius: '7px',
    position: 'fixed',
    bottom: '16px',
    right: '195px',
    zIndex: 1001,
    [theme.breakpoints.down('sm')]: {
      right: '95px'
    },
    [theme.breakpoints.up(1060)]: {
      display: 'none'
    },
    [theme.breakpoints.down(1060)]: {
      right: '250px'
    },
    [theme.breakpoints.down(1017)]: {
      right: '150px'
    },
    [theme.breakpoints.down(956)]: {
      right: '150px'
    },
    [theme.breakpoints.down(902)]: {
      right: '120px'
    },
    [theme.breakpoints.down(870)]: {
      display: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  modalCorrection: {
    paddingLeft: '60px!important',
    paddingRight: '60px!important'
  }
}));

export default Styles;
