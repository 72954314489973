import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    position: 'absolute',
    padding: '8px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    // zIndex: '1',
    borderRadius: '4px',
    backgroundColor:
      theme.palette.mode === 'light' ? '#FFF' : '#3a3541!important',
    boxShadow:
      'rgb(255 251 251 / 5%) 0px 0px 0px 1px, rgb(255 251 251 / 5%) 0px 1px 0px, rgb(15 15 15 / 20%) 0px 1px 14px'
    // maxWidth: 'calc(100vw - 24px)'
  },
  containerInput: {
    position: 'absolute',
    zIndex: '1',
    backgroundColor:
      theme.palette.mode === 'light' ? 'white' : '#3a3541!important',
    // border: '1px solid gray',
    // boxShadow:
    //   'rgb(15 15 15 / 5%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 3px 6px, rgb(15 15 15 / 20%) 0px 9px 24px',
    // padding: '5px',
    maxWidth: 'calc(100vw - 24px)',
    borderRadius: '5px'
  },
  list: {
    display: 'flex',
    padding: '6px',
    marginBottom: '3px',
    alignItems: 'center!important',
    justifyContent: 'start',
    gap: '8px',
    color: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.85)' : 'white',
    fill: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.45)' : 'white',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '120%',
    userSelect: 'none',
    transition: 'color 200ms, background-color 200ms, border-color 200ms',
    '&:hover': {
      backgroundColor: `${theme.palette.background.lighterGrey}`
    },
    cursor: 'pointer',
    borderRadius: '2px'
  },
  boxInput: {
    padding: '8px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '8px',
    // zIndex: '1',
    borderRadius: '4px',
    backgroundColor:
      theme.palette.mode === 'light' ? '#FFF' : '#3a3541!important',
    boxShadow:
      'rgb(255 251 251 / 5%) 0px 0px 0px 1px, rgb(255 251 251 / 5%) 0px 1px 0px, rgb(15 15 15 / 20%) 0px 1px 14px',
    // maxWidth: 'calc(100vw - 24px)'

    display: 'flex',
    // paddingLeft: '10px',
    // paddingRight: '10px',
    // marginTop: '2px',
    // marginBottom: '2px',
    color: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.85)' : 'white',
    fill: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.45)' : 'white',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '120%',
    userSelect: 'none',
    transition: 'color 200ms, background-color 200ms, border-color 200ms',
    cursor: 'pointer'
  },
  icon: {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0'
  },
  iconInput: {
    display: 'flex',
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: '0'
    // color: 'rgb(167, 130, 195)'
  },
  description: {
    color: theme.palette.mode === 'light' ? '#636363' : 'white',
    fontFamily: 'Inter',
    fontSize: '13.5px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%',
    paddingRight: '2px'
  },
  descriptionLoading: {
    color: theme.palette.mode === 'light' ? '#636363' : 'white',
    fontFamily: 'Inter',
    fontSize: '13.5px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '148%'
  },
  resultsContainerWait: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgb(255 255 255 / 69%)'
        : 'rgba(195,185,222,0.2)',
    border:
      theme.palette.mode === 'light'
        ? '1.5px solid #E7EBF0!important'
        : '1.5px solid #3a3541!important',
    borderRadius: '.375rem',
    minHeight: '80px',
    paddingTop: '0.2rem',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.background.lighterGrey}`
    }
  },
  messageContainer: {
    width: '100%',
    // '&:hover': {
    //   backgroundColor: `${theme.palette.background.lighterGrey}`
    // },
    // paddingTop: '1rem',
    // paddingBottom: '1rem',
    paddingLeft: '1.0rem',
    paddingRight: '1.0rem'
    // marginBottom: '20px'
  },
  titleMessage: {
    fontSize: '.875rem',
    lineHeight: '1.25rem',
    fontWeight: '500',
    marginBottom: '5px'
  },
  descriptionMessage: {
    fontSize: '0.775rem',
    lineHeight: '1.25rem',
    color:
      theme.palette.mode === 'light' ? 'rgb(100 116 139)' : 'rgb(171,171,171)'
  },
  descriptionResult: {
    color: theme.palette.mode === 'light' ? '#636363' : '#E7EBF0',
    fontFamily: 'Inter',
    fontSize: '13.5px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '188%',
    textAlign: 'justify'
  },
  output: {
    display: 'flex',
    paddingLeft: '10px',
    paddingRight: '10px',
    marginTop: '1px',
    marginBottom: '1px',
    maxWidth: '450px!important',
    color: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.85)' : 'white',
    fill: theme.palette.mode === 'light' ? 'rgba(55, 53, 47, 0.45)' : 'white',
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '120%',
    userSelect: 'none',
    transition: 'color 200ms, background-color 200ms, border-color 200ms',
    padding: '0.8em 1em 0.8em 1em'
    // cursor: 'pointer'
  }
}));

export default useStyles;
