import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import CorrectionItem from './components/CorrectionItem';
import CorrectionNotFound from './components/CorrectionNotFound';
import filteredCorrections from '../../../../../../utils/function/filteredCorrections';
import PremiumCorrectionCTA from './components/PremiumCorrectionCTA';

function Corrections({
  onReplaceWord,
  onFixAll,
  loading,
  corrections,
  editor,
  activeCategory,
  onReplaceAutoCorrection,
  getContentForAutoCorrection
}) {
  // Utilize useMemo para memoizar o valor do componente
  const memoizedComponent = useMemo(() => {
    if (loading) {
      return (
        <Box
          flex={'0 0 460px'}
          mr={'20px'}
          pt={'70px'}
          id={'item-card-container'}
        />
      );
    }

    return (
      <Box
        flex={'0 0 460px'}
        mr={'20px'}
        pt={'70px'}
        id={'item-card-container'}
        sx={{
          minWidth: '480px'
        }}
      >
        {activeCategory === 'deviations' ? (
          !corrections.deviations.filter(
            deviation => !deviation?.score?.premium
          )[0] ? (
            <>
              <Box
                style={{
                  padding: '40px',
                  paddingTop: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  gap: '0.5rem',
                  marginLeft: '1.5rem'
                }}
                width={'100%'}
              >
                {(editor.activeCategoryType === 'all' ||
                  editor.activeCategoryType === 'premium') && (
                  <PremiumCorrectionCTA />
                )}
              </Box>

              <CorrectionNotFound />
            </>
          ) : (
            <Box
              style={{
                padding: '40px',
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '0.5rem',
                marginLeft: '1.5rem'
              }}
              width={'100%'}
            >
              {(editor.activeCategoryType === 'all' ||
                editor.activeCategoryType === 'premium') && (
                <PremiumCorrectionCTA />
              )}

              {corrections.deviations
                .filter(deviation => !deviation?.score?.premium)
                .map((item: any) => {
                  if (item.id && item.custom) {
                    return (
                      <CorrectionItem
                        _id={item._id}
                        correction={item}
                        key={item._id}
                        category={
                          item.type ? item.type : Object.keys(item.score)[0]
                        }
                        title={item.custom.title}
                        message={item.custom.feedback}
                        more={item.custom.more}
                        issue={item.replace}
                        sugestion={item.replacement}
                        citation={item.custom?.citation}
                        onReplaceWord={onReplaceWord}
                        onFixAll={onFixAll}
                        onReplaceAutoCorrection={onReplaceAutoCorrection}
                        getContentForAutoCorrection={
                          getContentForAutoCorrection
                        }
                        corrections={corrections.deviations.filter(
                          deviation => !deviation?.score?.premium
                        )}
                      ></CorrectionItem>
                    );
                  }

                  return (
                    <CorrectionItem
                      _id={item._id}
                      correction={item}
                      key={item._id}
                      category={
                        item.type ? item.type : Object.keys(item.score)[0]
                      }
                      title={item.shortMessage}
                      message={item.message}
                      issue={item.spans[0].content}
                      sugestion={item.suggestions}
                      citation={item.custom?.citation}
                      onReplaceWord={onReplaceWord}
                      onFixAll={onFixAll}
                      onReplaceAutoCorrection={onReplaceAutoCorrection}
                      getContentForAutoCorrection={getContentForAutoCorrection}
                      corrections={corrections.deviations.filter(
                        deviation => !deviation?.score?.premium
                      )}
                    ></CorrectionItem>
                  );
                })}
            </Box>
          )
        ) : null}

        {activeCategory === 'spellingAndGrammar' ? (
          !corrections.spellingAndGrammar[0] ? (
            <>
              <CorrectionNotFound />
            </>
          ) : (
            <Box
              style={{
                padding: '40px',
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '0.5rem',
                marginLeft: '1.5rem'
              }}
              width={'100%'}
            >
              {corrections.spellingAndGrammar.map((item: any) => {
                if (item.id && item.custom) {
                  return (
                    <CorrectionItem
                      _id={item._id}
                      correction={item}
                      key={item._id}
                      category={
                        item.type ? item.type : Object.keys(item.score)[0]
                      }
                      title={item.custom.title}
                      message={item.custom.feedback}
                      more={item.custom.more}
                      issue={item.replace}
                      sugestion={item.replacement}
                      citation={item.custom?.citation}
                      onReplaceWord={onReplaceWord}
                      onFixAll={onFixAll}
                      onReplaceAutoCorrection={onReplaceAutoCorrection}
                      getContentForAutoCorrection={getContentForAutoCorrection}
                      corrections={corrections.spellingAndGrammar}
                    ></CorrectionItem>
                  );
                }

                return (
                  <CorrectionItem
                    _id={item._id}
                    correction={item}
                    key={item._id}
                    category={
                      item.type ? item.type : Object.keys(item.score)[0]
                    }
                    title={item.shortMessage}
                    message={item.message}
                    issue={item.spans[0].content}
                    sugestion={item.suggestions}
                    citation={item.custom?.citation}
                    onReplaceWord={onReplaceWord}
                    onFixAll={onFixAll}
                    onReplaceAutoCorrection={onReplaceAutoCorrection}
                    getContentForAutoCorrection={getContentForAutoCorrection}
                  ></CorrectionItem>
                );
              })}
            </Box>
          )
        ) : null}

        {activeCategory === 'doubts' ? (
          corrections.doubts.length === 0 ? (
            <CorrectionNotFound />
          ) : (
            <Box
              style={{
                padding: '40px',
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '0.5rem',
                marginLeft: '1.5rem'
              }}
              width={'100%'}
            >
              {corrections.doubts.map((item: any) => {
                return (
                  <CorrectionItem
                    _id={item._id}
                    correction={item}
                    key={item._id}
                    category={
                      item.type ? item.type : Object.keys(item.score)[0]
                    }
                    title={item.custom.title}
                    message={item.custom.feedback}
                    more={item.custom.more}
                    issue={item.replace}
                    sugestion={item.replacement}
                    citation={item.custom?.citation}
                    onReplaceWord={onReplaceWord}
                    onFixAll={onFixAll}
                    onReplaceAutoCorrection={onReplaceAutoCorrection}
                    getContentForAutoCorrection={getContentForAutoCorrection}
                    corrections={corrections.doubts}
                  ></CorrectionItem>
                );
              })}
            </Box>
          )
        ) : null}
      </Box>
    );
  }, [loading, corrections, activeCategory]); // Array de dependências

  return memoizedComponent;
}

export default Corrections;
