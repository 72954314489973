import React from 'react';
import { Typography, useTheme } from '@mui/material';
import ProtectedIcon from '../../../../../../../../../assets/images/protected.svg';
import useStyles from './styles';
import styled from 'styled-components';

const RootWrapper = styled.div`
  text-transform: unset;
  white-space: nowrap;
  font: normal normal 400 14px 'Inter';
  letter-spacing: 0;
  color: #4f3d66;
  margin-right: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  @media (max-width: 600px) {
    display: none;
  }
`;
const SecurityDescriptionNavBar = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <RootWrapper className={classes.root}>
      <img src={ProtectedIcon} alt="Protected" className={classes.icon} />

      <Typography className={classes.text}>
        Pagamento garantido e seguro
      </Typography>
    </RootWrapper>
  );
};

export default SecurityDescriptionNavBar;
