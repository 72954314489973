import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    container: {
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      // gap: '2rem',
      textTransform: 'none',
      textAlign: 'center',
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '1rem'
      }
    },
    description: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // width: '100%',
      textAlign: 'center',
      font: 'normal normal 400 13.5px/15px Inter',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 8,
      paddingBottom: 8,
      color: theme.palette.mode === 'light' ? '#410A85' : '#0fedb2',
      backgroundColor:
        theme.palette.mode === 'light' ? `rgb(236 221 255)` : `rgb(72 80 78)`,
      borderRadius: '7px',
      fontWeight: '500',
      fontSize: '14px',
      padding: '7px',
      paddingRight: '9px'
    }
  };
});

export default useStyles;
