import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      // paddingLeft: "2.4rem",
      cursor: 'pointer',
      userSelect: 'none',
      fontWeight: '500',
      fontSize: '1.3rem',
      lineHeight: '120%',
      color: '#4f3d66',
      '& > a': {
        fontWeight: 'bold',
        fontSize: '1.3rem',
        lineHeight: '120%',
        color: '#410a85',
        textDecoration: 'none',
        marginLeft: '3px',
        transition: 'color 0.3s'
      },
      '& > a:hover': {
        color: '#09b386'
      }
    },
    input: {
      position: 'absolute',
      opacity: '0',
      cursor: 'pointer',
      height: '0',
      width: '0'
    },
    checkmark: {
      marginRight: '0.4rem!important',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '16px',
      width: '16px',
      fontSize: '28px!important',
      borderRadius: '4px',
      backgroundColor: 'transparent',
      border: '1px solid #7c24fb',
      '&:after': {
        content: '',
        position: 'absolute',
        display: 'none'
      }
    }
  };
});

export default useStyles;
