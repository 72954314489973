import React, { useContext } from 'react';
import { RootInterface } from './interface';
import { Box, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';

const Root = ({ children }: RootInterface) => {
  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  const ctx = useContext(GlobalContext);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '12px',
        border:
          themeMode.mode === 'dark' ? `2px solid #0fedb2` : `2px solid #0fedb2`,
        // background: '#410A85',
        width: '949px',
        height: '148px',
        padding: '34px',
        paddingLeft: '18px',
        gap: '48px',
        [theme.breakpoints.down(1250)]: {
          width: '700px',
          gap: '0.5rem'
        },
        [theme.breakpoints.down(1000)]: {
          height: 'max-content',
          width: '100%',
          flexDirection: 'column',
          gap: '0.5rem'
        }
      }}
    >
      {children}
    </Box>
  );
};

export default Root;
