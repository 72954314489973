import React from 'react';
import { useTheme } from '@mui/material';

const FullLogo = ({ color, ...props }) => {
  const theme = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="245.379"
      height="75.112"
      viewBox="0 0 245.379 75.112"
      {...props}
    >
      <g id="Group_2711" data-name="Group 2711" transform="translate(0 0)">
        <path
          id="Path_7447"
          data-name="Path 7447"
          d="M-30.634-41.649q-2.312-.75-3.4-1a11.485,11.485,0,0,0-2.53-.249,7.4,7.4,0,0,0-5.4,1.811,7.213,7.213,0,0,0-1.843,5.372,7.971,7.971,0,0,0,1.968,5.592,6.8,6.8,0,0,0,5.279,2.156,11.421,11.421,0,0,0,2.53-.25q1.092-.249,3.4-1v4.747a18.806,18.806,0,0,1-6.935,1.25A12.137,12.137,0,0,1-46.6-26.655,12.305,12.305,0,0,1-50-35.714q0-5.622,3.31-8.746t9.121-3.123A17.868,17.868,0,0,1-30.634-46.4Z"
          transform="translate(124.295 78.067)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7448"
          data-name="Path 7448"
          d="M-13.637,18.138a9.038,9.038,0,0,0,2.874-.437v3.936a11.616,11.616,0,0,1-5.373,1.187q-6.123,0-6.122-6.748V-12.1h5.872V14.328a5.251,5.251,0,0,0,.624,2.873,2.362,2.362,0,0,0,2.125.937"
          transform="translate(118.419 32.587)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7449"
          data-name="Path 7449"
          d="M-23.619-14.894l-4.249.312a7.654,7.654,0,0,0-3.561.937,2.463,2.463,0,0,0-1.125,2.186,2.67,2.67,0,0,0,1.063,2.25,4.729,4.729,0,0,0,2.936.812,10.1,10.1,0,0,0,2.467-.25,10.353,10.353,0,0,0,2.468-1.125ZM-36.677-27.138a21.965,21.965,0,0,1,4.093-.906,30.745,30.745,0,0,1,4.092-.344q5.811,0,8.34,2.156t2.53,7.028V-4.023h-5.372l-.062-2.124a10.848,10.848,0,0,1-7,2.248A9.221,9.221,0,0,1-36.24-5.866a6.441,6.441,0,0,1-2.311-5.154,6.476,6.476,0,0,1,2.592-5.466q2.592-1.968,7.4-2.218l4.686-.25a7.958,7.958,0,0,0-.437-3.186,2.463,2.463,0,0,0-1.562-1.375,12.234,12.234,0,0,0-3.561-.375,26.863,26.863,0,0,0-3.78.312,16.883,16.883,0,0,0-3.467.812Z"
          transform="translate(148.704 58.871)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7450"
          data-name="Path 7450"
          d="M0,23.99H5.933V5.748A12.462,12.462,0,0,1,9.869,5.06a10.022,10.022,0,0,1,4.872,1.125V.313A14.056,14.056,0,0,0,12.055,0h-.562A20.7,20.7,0,0,0,3.434,1.469Q-.066,2.938,0,6.186Z"
          transform="translate(133.584 30.858)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7451"
          data-name="Path 7451"
          d="M630.04,1003.859h6v-23.99h-6Zm.665-29.527a3.31,3.31,0,0,1,0-4.7,3.321,3.321,0,0,1,4.7,4.7,3.31,3.31,0,0,1-4.7,0"
          transform="translate(-479.217 -949.011)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7452"
          data-name="Path 7452"
          d="M-30.634-41.649q-2.312-.75-3.4-1a11.485,11.485,0,0,0-2.53-.249,7.4,7.4,0,0,0-5.4,1.811,7.213,7.213,0,0,0-1.843,5.372,7.971,7.971,0,0,0,1.968,5.592,6.8,6.8,0,0,0,5.279,2.156,11.421,11.421,0,0,0,2.53-.25q1.092-.249,3.4-1v4.747a18.806,18.806,0,0,1-6.935,1.25A12.137,12.137,0,0,1-46.6-26.655,12.305,12.305,0,0,1-50-35.714q0-5.622,3.31-8.746t9.121-3.123A17.868,17.868,0,0,1-30.634-46.4Z"
          transform="translate(209.32 78.067)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7453"
          data-name="Path 7453"
          d="M-17.788-47.734a4.467,4.467,0,0,0-3.5,1.655,6.805,6.805,0,0,0-1.563,4.28H-12.6a6.408,6.408,0,0,0-1.375-4.373,4.814,4.814,0,0,0-3.811-1.562M-7.729-28.368a30.086,30.086,0,0,1-8,.937q-6.123,0-9.714-3.4a11.93,11.93,0,0,1-3.593-9.09,13.642,13.642,0,0,1,1.562-6.622,11.524,11.524,0,0,1,4.061-4.343,10.331,10.331,0,0,1,5.5-1.531q5.373,0,8.309,3.311t2.936,9.184v2.312H-22.973q.5,5.435,8.059,5.435A30.942,30.942,0,0,0-7.729-32.8Z"
          transform="translate(210.218 82.903)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7454"
          data-name="Path 7454"
          d="M-1.68,3.319A3.417,3.417,0,0,1-2.741.82,3.419,3.419,0,0,1-1.68-1.68a3.42,3.42,0,0,1,2.5-1.062,3.424,3.424,0,0,1,2.5,1.062A3.423,3.423,0,0,1,4.381.82a3.421,3.421,0,0,1-1.062,2.5,3.424,3.424,0,0,1-2.5,1.062,3.42,3.42,0,0,1-2.5-1.062"
          transform="translate(208.791 51.155)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7455"
          data-name="Path 7455"
          d="M-23.619-14.894l-4.249.312a7.656,7.656,0,0,0-3.561.937,2.463,2.463,0,0,0-1.125,2.186,2.671,2.671,0,0,0,1.062,2.25,4.729,4.729,0,0,0,2.936.812,10.106,10.106,0,0,0,2.468-.25,10.36,10.36,0,0,0,2.468-1.125ZM-36.677-27.138a21.972,21.972,0,0,1,4.093-.906,30.731,30.731,0,0,1,4.092-.344q5.81,0,8.34,2.156t2.53,7.028V-4.023h-5.372l-.062-2.124a10.848,10.848,0,0,1-7,2.248A9.221,9.221,0,0,1-36.24-5.866a6.443,6.443,0,0,1-2.311-5.154,6.477,6.477,0,0,1,2.592-5.466q2.591-1.968,7.4-2.218l4.686-.25a7.958,7.958,0,0,0-.437-3.186,2.462,2.462,0,0,0-1.562-1.375,12.234,12.234,0,0,0-3.561-.375,26.851,26.851,0,0,0-3.78.312,16.881,16.881,0,0,0-3.468.812Z"
          transform="translate(254.094 58.871)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7456"
          data-name="Path 7456"
          d="M857.472,1003.956h6v-23.99h-6Zm.6-29.459a3.405,3.405,0,0,1,0-4.833A3.418,3.418,0,0,1,862.9,974.5a3.405,3.405,0,0,1-4.833,0"
          transform="translate(-618.5 -949.108)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
        <path
          id="Path_7457"
          data-name="Path 7457"
          d="M-47.555-30.464A11.475,11.475,0,0,1-59.029-41.938H-36.08A11.475,11.475,0,0,1-47.555-30.464m30.06-16.013A30.066,30.066,0,0,0-47.555-76.1,30.063,30.063,0,0,0-77.617-46.039,30.062,30.062,0,0,0-47.555-15.976V-.989A48.78,48.78,0,0,0-17.495-45.6c0-.145,0-.291,0-.438s0-.294,0-.438"
          transform="translate(77.617 76.101)"
          fill={color ? theme.palette[color].main : theme.palette.primary.main}
        />
      </g>
    </svg>
  );
};

export default FullLogo;
