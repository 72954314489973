import React, { useContext } from 'react';
import { ActionInterface } from './interface';
import { Box, Button, useTheme } from '@mui/material';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import analytics from '../../../../../../utils/function/analytics';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import mapping from '../../../../../../utils/function/mapping';

const Action = ({}: ActionInterface) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();

  const theme = useTheme();

  const themeMode = useContext(ThemeContext);

  // @ts-ignore
  const { open, close, toggle, refresh } = window.tf.createPopup('O7gcHAmh');

  const { user }: any = useReduxState();

  return (
    <>
      <Button
        variant="contained"
        sx={{
          cursor: 'pointer!important',
          fontFamily: 'Inter',
          backgroundColor: '#09B286',
          textTransform: 'none',
          padding: '8px 24px 8px 24px',
          gap: '10px',
          borderRadius: '6px',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: `${theme.palette.secondary.dark}`
          },
          [theme.breakpoints.down('sm')]: {
            // width: '100%'
          },
          fontWeight: '600',
          color: '#fff'
        }}
        onClick={() => {
          // if (user.isSubscriber) {
          //   return toggle();
          // }

          analytics.logEvent('CTA', 'redirect-google-docs-plugin');

          mapping.track('CTA | redirect-google-docs-plugin');

          window.open(
            'https://workspace.google.com/u/0/marketplace/app/clariceai/530062106392',
            '_blank'
          );
        }}
      >
        Instalar Grátis
        {/*Obter cupom*/}
      </Button>
    </>
  );
};

export default Action;
