import { SearchRounded } from '@mui/icons-material';
import { Button, Grid, InputBase, Paper, Box, useTheme } from '@mui/material';
import { useContext, useState } from 'react';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import { createNewDocument } from '../../../../../../stories/actions/documents';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import useStyles from './style';
import { useNavigate } from 'react-router-dom';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import TooltipContainer from '../../../../components/TooltipContainer';
import LoadingButton from '@mui/lab/LoadingButton';
import analytics from '../../../../../../utils/function/analytics';
import mapping from '../../../../../../utils/function/mapping';

const SearchBar = ({ value, setValue }) => {
  const classes = useStyles();
  const themeMode = useContext(ThemeContext);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { documents, user } = useReduxState();

  const [loadingNewText, setLoadingNewText] = useState(false);

  const tooltip = () => {
    const limit = {
      free: 3,
      pro: 800
    };

    const textsLimit = user.isSubscriber ? limit.pro : limit.free;

    const response: any = {
      title: '',
      disabled: false
    };

    if (documents.all.length === textsLimit) {
      if (user.isSubscriber) {
        response.title = `Você atingiu o limite de textos salvos. Por enquanto só consigo salvar ${limit.pro} textos na versão PRO.`;
        response.disabled = true;
      } else {
        response.title = (
          <div
            style={{
              padding: '8px',
              gap: '8px',
              lineHeight: '124%'
            }}
          >
            Você atingiu o limite de textos salvos. Para adicionar novos textos{' '}
            <span
              onClick={() => {
                analytics.logEvent('CTA', 'limit-text-upgrade-plan');

                mapping.track('CTA | limit-text-upgrade-plan');

                navigate('/plans');
              }}
              style={{
                cursor: 'pointer',
                color: '#FFC560',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '124%',
                textDecorationLine: 'underline'
              }}
            >
              torne-se PRO
            </span>
            .
          </div>
        );
        response.disabled = true;
      }
    }

    if (documents.all.length > textsLimit) {
      if (user.isSubscriber) {
        response.title = (
          <div
            style={{
              padding: '8px',
              gap: '8px',
              lineHeight: '124%'
            }}
          >
            Você já atingiu o limite de textos salvos. Para adicionar novos
            textos,{' '}
            <span
              onClick={() => {
                analytics.logEvent('CTA', 'limit-text-upgrade-plan');

                mapping.track('CTA | limit-text-upgrade-plan');

                navigate('/plans');
              }}
              style={{
                cursor: 'pointer',
                color: '#FFC560',
                fontFamily: 'Inter',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '124%',
                textDecorationLine: 'underline'
              }}
            >
              faça um upgrade
            </span>
            .
          </div>
        );

        // response.title =
        //   'Você excedeu o limite de textos salvos na versão FREE. Para adicionar novos textos, remova ' +
        //   (documents.all.length - (limit.free - 1)) +
        //   ' texto(s) ou assine a versão PRO.';
        response.disabled = true;
      } else {
        response.title = '';
        response.disabled = false;
      }
    }

    // console.log(response);

    return response;
  };

  const { title, disabled } = tooltip();

  return (
    <Box className={classes.container}>
      <TooltipContainer title={title}>
        <span>
          <LoadingButton
            loading={loadingNewText}
            variant={'contained'}
            disableElevation
            disabled={disabled}
            sx={{
              width: '150px',
              cursor: 'pointer!important',
              fontFamily: 'Inter',
              backgroundColor: '#09B286',
              textTransform: 'none',
              padding: '8px 24px 8px 24px',
              fontWeight: '600',
              fontSize: '16px',
              '&:hover': {
                backgroundColor: `${theme.palette.secondary.dark}`
              },
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            }}
            onClick={() => {
              setLoadingNewText(true);

              const cb = {
                success: (_id: any) => {
                  // setTimeout(() => {
                  //   // navigate(`/edit-text/${_id}`);
                  //
                  //   setLoadingNewText(false);
                  // }, 1000 * 2);

                  setLoadingNewText(false);

                  navigate(`/edit-text/${_id}`);
                },
                error: () => {
                  setLoadingNewText(false);
                }
              };

              dispatch(createNewDocument(cb));
            }}
          >
            {'Novo texto'}
          </LoadingButton>
        </span>
      </TooltipContainer>
      <div className={classes.root}>
        <Paper
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: themeMode.mode === 'light' ? '#efefef' : '#303030',
            textTransform: 'none',
            padding: '7px 12px 7px 12px',
            borderRadius: '10px',
            boxShadow:
              themeMode.mode === 'light'
                ? '0px 18px 56px -12px rgba(227, 227, 238, 0.8)'
                : '0px 18px 56px -12px rgba(38, 38, 39, 0.8)',
            [theme.breakpoints.down('sm')]: {
              padding: '7px 20px 7px 20px'
            }
          }}
        >
          <InputBase
            sx={{
              flexGrow: 1,
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: '500',
              color: theme.palette.text.primary,
              [theme.breakpoints.down('sm')]: {
                width: '100%'
              }
            }}
            placeholder={'Pesquise por um título...'}
            value={value}
            onChange={ev => setValue(ev.target.value)}
            spellCheck={false}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#09B286',
              borderRadius: '5px',
              padding: '0.3rem'
            }}
          >
            <SearchRounded
              sx={{
                height: '1rem',
                width: '1rem',
                color: '#fff'
              }}
            />
          </div>
        </Paper>
      </div>
    </Box>
  );
};

export default SearchBar;
