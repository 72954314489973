import React from 'react';
import { PropsInterface } from './interface';
import useStyles from './styles';
import MoreTipsIcon from '../../../../../../../../../assets/images/payment/more-tips.svg';
import MoreWordsIcon from '../../../../../../../../../assets/images/payment/more-words.svg';
import MoreTextsIcon from '../../../../../../../../../assets/images/payment/more-texts.svg';
import { Box, Typography } from '@mui/material';

const BenefitItem = ({ title, description, icon }: PropsInterface) => {
  const classes = useStyles();

  const imageIcon = (icon: any) => {
    if (icon === 'more-tips') {
      return MoreTipsIcon;
    } else if (icon === 'more-words') {
      return MoreWordsIcon;
    } else if (icon === 'more-texts') {
      return MoreTextsIcon;
    }
  };

  return (
    <Box display={'flex'} flexDirection={'row'} className={classes.item}>
      <Box className={classes.iconContainer}>
        <img
          src={imageIcon(icon)}
          alt={icon}
          width={'40px'}
          style={{ padding: 0, margin: 0 }}
        />
      </Box>
      <Box>
        <Typography className={classes.title}>
          <strong>{title}</strong>
        </Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
    </Box>
  );
};

export default BenefitItem;
