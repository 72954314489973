import React from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import TestimonialItem from '../TestimonialItem';

const TestimonialSlider = ({ testimonials }: any) => {
  const classes = useStyles();

  const [index, setIndex] = React.useState(0);
  const timeoutRef: any = React.useRef(null);

  const delay = 8000;

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex(prevIndex =>
          prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className={classes.slideshow}>
      <div
        className={classes.slideshowSlider}
        // style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        <TestimonialItem
          testimonialPersonIcon={testimonials[index].icon}
          person={testimonials[index].name}
          role={testimonials[index].role}
          profileImage="Profile Image"
          description={testimonials[index].description}
        />
      </div>

      <div className={classes.slideshowDots}>
        {testimonials.map((_, idx) => (
          <div
            key={idx}
            className={clsx(classes.slideshowDot, {
              [classes.slideshowDotActive]: index === idx
            })}
            onClick={() => {
              setIndex(idx);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;
