import { makeStyles } from '@mui/styles';

// @ts-ignore
const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      background: '#F2F2F2!important',
      height: '51px!important',
      borderRadius: '6px!important',
      textTransform: 'unset!important',
      font: 'normal normal 400 12px/15px Inter!important',
      color: '#4A4A4A!important',
      letterSpacing: '0.12px!important',
      marginBottom: '9px!important',
      '&:hover': {
        background: '#e3e2e2!important'
      }
    },
    icon: {
      width: '17.45px',
      marginRight: '14.65px'
    }
  };
});

export default useStyles;
