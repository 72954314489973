const development = false;

export const STATE = {
  app: {
    // beta: false,
    baseURL: (type: any) => {
      if (development) {
        return 'http://localhost:8000/';
      }

      if (type === 'staging') {
        return 'https://api-stg.clarice.ai/';
      }

      return 'https://api.clarice.ai/';
    }
  }
};
