import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {},
  card: {
    boxShadow:
      'inset 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)!important',
    borderRadius: '15px!important',
    transition: '500ms easy-in'
  },
  content: {
    minHeight: '180px!important',
    cursor: 'default!important',
    padding: '24px 16px 24px 16px'
  }
}));

export default useStyles;
