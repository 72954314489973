/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import useStyles from './style';
import {
  AppBar,
  Button,
  CircularProgress,
  debounce,
  Toolbar,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { MenuRounded, ArrowForwardRounded } from '@mui/icons-material';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import TooltipContainer from '../../../../components/TooltipContainer';
import Logo from './component/Logo';
import { Link, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../../../../../utils/context/GlobalContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import analytics from '../../../../../../utils/function/analytics';
import mapping from '../../../../../../utils/function/mapping';

const ActionsBar = ({ toggleDrawer, loading, onProcess, text }) => {
  const ctx = useContext(GlobalContext);

  const classes = useStyles();
  const theme = useTheme();
  const themeMode = useContext(ThemeContext);
  const [hideTitle, setHideTitle] = useState(true);

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const { documents, user } = useReduxState();

  const handleScroll = () => {
    setHideTitle(window.scrollY < 100);
  };

  const debouncedHandleScroll = debounce(handleScroll, 300);

  useEffect(() => {
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleScroll]);

  const WORD_LIMIT = {
    FREE: {
      STRING: '1000',
      NUMBER: 1000
    },
    PAID: {
      TIPS: {
        MULTI: '6x',
        QUANTITY: '120'
      },
      MORE: '5x',
      STRING: '5 mil',
      NUMBER: 5000
    }
  };

  const wordLimit = user.isSubscriber
    ? WORD_LIMIT.PAID.NUMBER
    : WORD_LIMIT.FREE.NUMBER;

  const wordCounter = text.trim().split(/\s+/).length;

  const tooltipInfo =
    wordCounter > wordLimit ? (
      !user.isSubscriber ? (
        <>
          Seu texto excede {WORD_LIMIT.FREE.STRING} palavras ({wordCounter}).
          Para revisar mais palavras simultâneas,{' '}
          <span
            style={{ color: 'rgb(15, 237, 178)', cursor: 'pointer' }}
            onClick={() => {
              // navigate('/premium');
              navigate('/plans');
            }}
          >
            {' '}
            assine o PRO
          </span>
          .
        </>
      ) : (
        `Eu só consigo revisar ${WORD_LIMIT.PAID.STRING} palavras simultâneas. Seu texto possui ${wordCounter} palavras.`
      )
    ) : (
      ''
    );

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        style={{
          paddingRight: isMobile ? 0 : 200,
          boxShadow: `none`,
          backgroundImage: 'none'
        }}
        sx={{
          backgroundColor:
            themeMode.mode === 'light'
              ? `#ffffff!important`
              : `${theme.palette.background.default}!important`
        }}
      >
        <Toolbar
          className={classes.toolbar}
          /* style={props.ready ? { marginRight: '20px' } : { marginRight: 42 }} */
        >
          <TooltipContainer title="Menu" small={true}>
            <Button
              className={classes.menuButton}
              onClick={() => toggleDrawer()}
            >
              <MenuRounded
                color={themeMode.mode === 'light' ? 'primary' : 'secondary'}
                className={classes.icon}
              />
              <Logo />
            </Button>

            {/*<MenuRounded*/}
            {/*  style={{ cursor: 'pointer' }}*/}
            {/*  onClick={() => toggleDrawer()}*/}
            {/*  color={themeMode.mode === 'light' ? 'primary' : 'secondary'}*/}
            {/*  className={classes.icon}*/}
            {/*/>*/}
          </TooltipContainer>
          {!hideTitle && !isMobile && (
            <h2
              style={{ marginLeft: '2rem', color: theme.palette.text.primary }}
            >
              {documents.detail?.title}
            </h2>
          )}
          <div className={classes.space} />

          <Button
            variant={'contained'}
            style={{
              color:
                themeMode.mode === 'light'
                  ? `${theme.palette.text.white}`
                  : `${theme.palette.text.white}`,
              background:
                themeMode.mode === 'light'
                  ? `${theme.palette.primary.main}`
                  : `${theme.palette.secondary.dark}`
            }}
            disableElevation
            className={classes.buttonOnboarding}
            onClick={() => {
              analytics.logEvent('CTA', 'open-modal-onboarding-ia-editora');

              mapping.track('CTA | open-modal-onboarding-ia-editora');

              ctx.modal.onboarding.toggle();
            }}
          >
            <InfoOutlinedIcon
              style={{
                color:
                  themeMode.mode === 'light'
                    ? `${theme.palette.secondary.main}`
                    : `${theme.palette.text.white}`,
                display: 'flex',
                width: '24px',
                height: '24px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
          </Button>

          <TooltipContainer
            open={wordCounter > wordLimit && tooltipInfo !== ''}
            title={
              wordCounter > wordLimit && tooltipInfo !== ''
                ? tooltipInfo
                : 'Processar texto'
            }
            small={true}
          >
            <span>
              <Button
                variant={'contained'}
                style={{
                  color:
                    themeMode.mode === 'light'
                      ? `${theme.palette.text.white}`
                      : `${theme.palette.text.white}`,
                  background:
                    themeMode.mode === 'light'
                      ? `${theme.palette.primary.main}`
                      : `${theme.palette.secondary.dark}`,
                  width: isMobile ? '10rem' : '15rem',
                  padding: '0 1.5rem',
                  borderRadius: '30px'
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  textTransform: 'none!important',
                  fontFamily: 'Inter'
                }}
                disableElevation
                className={classes.button}
                onClick={onProcess}
              >
                {loading ? (
                  <>
                    Carregando{' '}
                    <CircularProgress
                      size={20}
                      style={{
                        color:
                          themeMode.mode === 'light'
                            ? `${theme.palette.secondary.main}`
                            : `${theme.palette.text.white}`,
                        marginLeft: '10px'
                      }}
                    />
                  </>
                ) : (
                  <>
                    Vamos lá{' '}
                    <ArrowForwardRounded
                      style={{
                        color:
                          themeMode.mode === 'light'
                            ? `${theme.palette.secondary.main}`
                            : `${theme.palette.text.white}`
                      }}
                    />
                  </>
                )}
              </Button>
            </span>
          </TooltipContainer>
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default ActionsBar;
