/* eslint-disable no-undef */
import instance from '../../utils/axios/instance';
import withAuth from '../../utils/axios/withAuth';
import { sendFeedbackCorrections } from '../actions/corrections';

const CorrectionsApi = {
  sendFeedbackCorrections: async (fields: any) => {
    const { data } = await withAuth().post(`/corrections`, {
      incorrect: fields.incorrect,
      suggestions: fields.suggestions,
      type: fields.type,
      textId: fields.textId
      // textId: fields.textId
    });

    return data;
  }
};

export default CorrectionsApi;
