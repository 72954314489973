import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  container: {
    marginTop: '5px',
    marginBottom: '5px',
    padding: '9px',
    borderRadius: '3px',
    // backgroundColor: '#f0f2fc'
    '&:hover': {
      cursor: 'pointer',
      backgroundColor:
        theme.palette.mode === 'light' ? 'rgba(232, 232, 232, 0.5)' : '#3f3f3f'
    }
  },
  active: {
    cursor: 'unset!important'
    // backgroundColor:
    //   theme.palette.mode === 'light' ? 'rgba(232, 232, 232, 0.5)' : '#3f3f3f'
  },
  name: {},
  number: {
    // marginTop: 2
  },
  clareza: {
    color: 'rgba(68, 137, 255, 0.8)'
  },
  concisao: {
    color: 'rgba(70, 189, 159, 0.8)'
  },
  forca: {
    color: 'rgba(136, 90, 189, 0.8)'
  },
  originalidade: {
    color: 'rgba(255, 191, 73, 0.8)'
  },
  ortografia: {
    color: 'rgba(244, 67, 54, 0.8)'
  },
  gramatica: {
    color: 'rgba(255, 153, 0, 0.8)'
  },
  pontuacao: {
    color: 'rgba(255, 89, 163, 0.8)'
  },
  tooltip: {
    backgroundColor: `${theme.palette.common.tooltip}!important`,
    display: 'flex',
    padding: '12px 5px!important',
    justifyContent: 'center',
    alignItems: 'center',
    // gap: '8px',
    flex: '1 0 0',
    borderRadius: '8px'
  },
  arrow: {
    color: theme.palette.common.tooltip + ' !important'
  },
  info: {
    textAlign: 'center',
    font: 'normal normal 400 14px Inter !important',
    lineHeight: '140%!important'
  },
  tooltipCorrecao: {
    marginRight: '86px!important'
  },
  tooltipClareza: {
    marginRight: '77px!important'
  },
  tooltipConcisao: {
    marginRight: '87px!important'
  },
  tooltipForca: {
    marginRight: '65px!important'
  },
  tooltipOriginalidade: {
    marginRight: '109px!important'
  },
  tooltipPremium: {
    marginRight: '58px!important'
  }
}));

export default useStyles;
