import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
  root: {
    marginRight: '20px',
    marginBottom: '40px'
  },
  card: {
    boxShadow:
      'inset 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)!important',
    borderRadius: '15px!important',
    transition: '500ms easy-in',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      boxShadow:
        theme.palette.mode === 'light'
          ? 'inset 0px 0px 1px 1px #410A85, 0px 1px 1px 0px #410A85, 0px 1px 3px 0px #410A85!important'
          : 'inset 0px 0px 1px 1px #0fedb2, 0px 1px 1px 0px #0fedb2, 0px 1px 3px 0px #0fedb2!important'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px'
    }
  },
  cardNotReady: {
    // backgroundColor:
    //   theme.palette.mode === 'light'
    //     ? 'rgb(15 237 178 / 11%)!important'
    //     : 'rgb(124 36 250 / 11%)!important'
    // : 'rgb(15 237 178 / 16%)!important'
  },
  cardFavorite: {
    backgroundColor:
      theme.palette.mode === 'light'
        ? 'rgb(255 231 113 / 18%)!important'
        : 'rgb(255 231 113 / 15%)!important',
    boxShadow:
      theme.palette.mode === 'light'
        ? 'inset 0px 0px 1px 1px rgb(255, 200, 55), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)!important'
        : 'inset 0px 0px 1px 1px rgb(0 0 0 / 14%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)!important'
  },
  content: {
    minHeight: '170px!important',
    paddingLeft: '1.5rem!important',
    paddingBottom: '0rem!important'
    // paddingRight: '1.0rem!important'
    // minWidth: '345px!important'
  }
}));

export default useStyles;
