import CorrectionStrategy from './Correction/function/CorrectionStrategy';
import CorrectionComponent from './Correction/components/CorrectionComponent';
import SuggestionStrategy from './Suggestion/function/SuggestionStrategy';
import SuggestionComponent from './Suggestion/components/SuggestionComponent';

const decorators = [
  { strategy: CorrectionStrategy, component: CorrectionComponent },
  { strategy: SuggestionStrategy, component: SuggestionComponent }
];

export default decorators;
