import React, { useContext, useEffect, useMemo, useState } from 'react';
import useStyles from './style';
import { Card, useTheme } from '@mui/material';
import { UnfoldMoreRounded } from '@mui/icons-material';
import ModalProjects from './components/modalProjects';
import { ThemeContext } from '../../../../../../utils/context/ThemeContext';
import { useReduxState } from '../../../../../../hooks/useReduxState';
import { useAppDispatch } from '../../../../../../hooks/useAppDispatch';
import {
  updateProjectActive,
  updateTheme
} from '../../../../../../stories/actions/user';

const Projects = ({ toggle }: any) => {
  const themeMode = useContext(ThemeContext);
  const classes = useStyles(themeMode);

  const theme = useTheme();

  const [modalVisible, setModalVisible] = useState(false);

  const [activeProject, setActiveProject] = useState<any>('');

  const { projects, user } = useReduxState();

  const dispatch = useAppDispatch();

  const { options }: any = user;

  const { allProjects } = projects;

  const allProjectsActive = useMemo(() => {
    return allProjects.filter(project => !project?.status?.inactive);
  }, [projects]);

  useEffect(() => {
    const active = allProjectsActive.find(
      el => String(el._id) === String(options?.project?._id)
    );

    if (!active) {
      const newActive = allProjectsActive[0];

      if (newActive) {
        setActiveProject(newActive);

        dispatch(updateProjectActive(newActive._id));
      } else {
        setActiveProject(active || '');
      }
    } else {
      setActiveProject(active || '');
    }
  }, [projects, user]);

  return (
    <div style={{ position: 'relative' }}>
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '0.5rem 1rem',
          width: '210px',
          height: '60px',
          marginTop: '10px',
          borderRadius: '10px!important',
          transition: '500ms easy-in',
          backgroundColor: theme.palette.mode === 'light' ? `#fff` : '#303030',
          border:
            theme.palette.mode === 'light'
              ? ' 1.5px solid #E7EBF0!important'
              : 'none',
          backgroundImage: 'none',
          boxShadow: 'none',
          '&:hover': {
            cursor: 'pointer'
          },
          marginBottom: '10px'
        }}
        onClick={() => setModalVisible(!modalVisible)}
      >
        <div>
          <h5
            className={classes.title}
            style={{
              fontSize: '14px!important',
              marginBottom: '2px!important',
              fontWeight: '400',
              color: `${theme.palette.text.lightInfo}`
            }}
          >
            Projetos
          </h5>

          <h2
            style={{
              fontSize: '16px',
              marginBottom: '2px!important',
              fontWeight: '600',
              color: themeMode.mode === 'light' ? '#303030' : '#fff'
            }}
          >
            {activeProject && activeProject.title}
          </h2>
        </div>
        <UnfoldMoreRounded
          sx={{ color: themeMode.mode === 'light' ? '#303030' : '#fff' }}
        />
      </Card>
      {modalVisible && (
        <ModalProjects
          activeProject={activeProject?._id}
          setActiveProject={(_id: string) => {
            const active = allProjectsActive.find(
              el => String(el._id) === String(_id)
            );

            if (_id !== activeProject._id) {
              setActiveProject(active);

              dispatch(updateProjectActive(_id));
            }

            setModalVisible(false);
          }}
          setModalVisible={setModalVisible}
          modalVisible={modalVisible}
          toggle={toggle}
        ></ModalProjects>
      )}
    </div>
  );
};

export default Projects;
