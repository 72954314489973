import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  slideshow: {
    // margin: '0 auto',
    // overflow: 'hidden'
    // maxWidth: '500px'
  },
  slideshowSlider: {
    // whiteSpace: 'nowrap',
    transition: 'ease 1000ms'
  },
  slide: {
    // minHeight: '500px'
    // display: 'inline-block',
    // height: '400px',
    // width: '100%'
    // borderRadius: '40px'
  },

  /* Buttons */

  slideshowDots: {
    textAlign: 'center'
  },

  slideshowDot: {
    display: 'inline-block',
    height: '10px',
    width: '10px',
    borderRadius: '50%',
    cursor: 'pointer',
    margin: '5px 7px 0px',
    backgroundColor: '#c4c4c4'
  },
  slideshowDotActive: {
    backgroundColor: '#410A85'
    // backgroundColor:
    //   theme.palette.mode === 'light' ? '#410A85' : theme.palette.secondary.main
  }
}));

export default Styles;
