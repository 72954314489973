import React, { useState } from 'react';
import { InputInterface } from './interface';
import useStyles from './style';
import clsx from 'clsx';
import EyeDash from '../../../../assets/images/auth/icon-dash.svg';
import EyeDefault from '../../../../assets/images/auth/eye-normal.svg';

const Input = ({
  id,
  label,
  placeholder,
  type,
  value,
  setValue,
  ...props
}: InputInterface) => {
  const classes: any = useStyles();

  const [focus, setFocus] = useState(false);
  const input: any = React.useRef();
  const iconEye: any = React.useRef();

  const handleChange = ({ target }: any) => {
    setValue(target.value);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  const handleChangeTypeInput = () => {
    const typeInput: any = input.current.getAttribute('type');

    if (typeInput === 'password') {
      input.current.setAttribute('type', 'text');
      iconEye.current.setAttribute('src', EyeDefault);
    } else {
      input.current.setAttribute('type', 'password');
      iconEye.current.setAttribute('src', EyeDash);
    }
  };

  const handleBlur = () => {
    const { value }: any = input.current;
    value === '' && setFocus(false);
  };

  return (
    <div className={classes.root} onFocus={handleFocus} onBlur={handleBlur}>
      <label
        htmlFor={id}
        className={clsx(classes.label, {
          [classes.labelFocus]: focus
        })}
      >
        {label}
      </label>

      {type === 'password' && (
        <div className={classes.password}>
          <input
            className={clsx(classes.input, {
              [classes.inputFocus]: focus
            })}
            id={id}
            name={id}
            onChange={handleChange}
            ref={input}
            placeholder={placeholder}
            value={value}
            type={type}
            {...props}
          />
          <button type="button" onClick={handleChangeTypeInput}>
            <img src={EyeDash} alt="" ref={iconEye} />
          </button>
        </div>
      )}

      {type !== 'password' && (
        <input
          className={clsx(classes.input, {
            [classes.inputFocus]: focus
          })}
          id={id}
          name={id}
          onChange={handleChange}
          ref={input}
          placeholder={placeholder}
          value={value}
          type={type}
          {...props}
        />
      )}
    </div>
  );
};

export default Input;
