import React, { useState, useEffect } from 'react';
import useStyles from './style';
import ContainerAuth from '../../components/ContainerAuth';
import LeftContainerFlow from '../../components/LeftContainerFlow';
import HeadAuth from '../../components/HeadAuth';
import PasswordRecoveryForm from './components/PasswordRecoveryForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useReduxState } from '../../../../hooks/useReduxState';
import {
  mailConfirmation,
  passwordRecovery
} from '../../../../stories/actions/auth';

type Props = {};

const PasswordRecovery = (props: Props) => {
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [recoveryCode, setRecoveryCode] = useState('');

  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { auth } = useReduxState();

  useEffect(() => {
    const code: any = params['*'] || '';

    if (code.length !== 90) {
      navigate(`/`);
    } else {
      setRecoveryCode(code);
    }
  }, []);

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);

      const cb = {
        success: () => {
          navigate(`/sign-in`);
          setPassword('');
          setLoading(false);
        },
        error: () => {
          // setPassword('');
          setLoading(false);
        }
      };

      dispatch(passwordRecovery({ password, recoveryCode }, cb));
    }
  };

  return (
    <>
      <div className={classes.root}>
        <HeadAuth
          title="Clarice.ai - Nova Senha"
          description="Seus dados estão seguros com a gente, sob a proteção da LGPD. Acesse os termos de uso e as políticas de privacidade."
        />
        <ContainerAuth>
          <LeftContainerFlow />

          <PasswordRecoveryForm
            password={password}
            setPassword={setPassword}
            loading={loading}
            onSubmit={onSubmit}
          />
        </ContainerAuth>
      </div>
    </>
  );
};

export default PasswordRecovery;
