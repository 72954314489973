import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '12rem',
      [theme.breakpoints.down(480)]: {
        marginTop: '4rem'
      }
    },
    description: {
      fontWeight: '500',
      fontSize: '1.4rem',
      lineHeight: '120%',
      color: '#4f3d66',
      marginLeft: '1.2rem',
      '& > a': {
        textDecoration: 'none',
        fontWeight: 'bold',
        fontSize: '1.4rem',
        color: '#410a85',
        transition: 'color 0.3s',
        '&:hover': {
          color: '#09b386'
        }
      }
    }
  };
});

export default useStyles;
