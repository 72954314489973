import React from 'react';
import { Box } from '@mui/material';
import SecurityAndPrivacyItem from '../SecurityAndPrivacyItem';
import useStyles from './styles';

const SecurityAndPrivacy = () => {
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      className={classes.container}
    >
      <SecurityAndPrivacyItem
        title="Security Checkout"
        subtitle="SSL Encryption"
        type="security"
      />

      <SecurityAndPrivacyItem
        title="Privacy"
        subtitle="protected"
        type="privacy"
      />
    </Box>
  );
};

export default SecurityAndPrivacy;
