import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => {
  const { palette, spacing } = theme;

  const radius = spacing(2.5);

  return {
    root: {
      width: '100%'
    },
    avatar: {
      width: '50px!important',
      height: '50px!important'
    },
    leftRow: {
      textAlign: 'left'
    },
    rightRow: {
      textAlign: 'right'
    },
    msgContainer: {
      maxWidth: '49%',
      padding: spacing(1, 2),
      // borderRadius: '10px!important',
      // marginBottom: '15px!important',
      display: 'inline-block',
      wordBreak: 'break-word',
      // fontFamily:
      //   // eslint-disable-next-line max-len
      //   '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      // fontSize: '14px',
      fontFamily: 'Inter',
      fontSize: '14px',
      // backgroundColor: `#ffffff!important`,
      border: '1px solid #d7d7d7!important',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
        marginBottom: '15px'
      }
    },
    msgContainerChatBot: {
      marginBottom: '15px',
      maxWidth: '90%',
      padding: spacing(1, 2),
      // borderRadius: '10px!important',
      // marginBottom: '15px!important',
      display: 'inline-block',
      wordBreak: 'break-word',
      // fontFamily:
      //   // eslint-disable-next-line max-len
      //   '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      // fontSize: '14px',
      fontFamily: 'Inter',
      fontSize: '14px',
      // backgroundColor: `#ffffff!important`,
      border: '1px solid #d7d7d7!important'
    },
    msgContainerDark: {
      // backgroundColor: `#ffffff!important`,
      border: '0px solid #000000!important'
    },
    msg: {
      fontFamily:
        // eslint-disable-next-line max-len
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      fontSize: '14px',
      lineHeight: '1.75rem!important',
      paddingLeft: '5px'
    },
    hide: {
      opacity: '0.6'
    },
    left: {
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      backgroundColor:
        theme.palette.mode === 'light'
          ? `#ffffff!important`
          : '#3a3541!important'
    },
    right: {
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
      backgroundColor:
        theme.palette.mode === 'light'
          ? `${palette.primary.main}`
          : `${palette.secondary.main}`,
      color:
        theme.palette.mode === 'light'
          ? palette.common.white
          : palette.common.black
    },
    leftFirst: {
      borderTopLeftRadius: radius
    },
    leftLast: {
      borderBottomLeftRadius: radius
    },
    rightFirst: {
      // marginRight: '-14px!important',
      borderTopRightRadius: radius
    },
    rightLast: {
      // marginRight: '-14px!important',
      borderBottomRightRadius: radius
    }
  };
});

export default useStyles;
