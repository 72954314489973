import React, { useState } from 'react';
import { PasswordRecoveryFormInterface } from './interface';
import useStyles from './style';
import HeadForm from '../../../../components/HeadForm';
import TitleForm from '../../../../components/TitleForm';
import ButtonSubmit from '../../../../components/ButtonSubmit';
import Input from '../../../../components/Input';
import ChannelsContainer from '../../../../components/ChannelsContainer';

const PasswordRecoveryForm = ({
  password,
  setPassword,
  loading,
  onSubmit
}: PasswordRecoveryFormInterface) => {
  const classes: any = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <HeadForm hasButtonBack />

        <TitleForm
          title="Falta só mais um passo!"
          description="Insira a nova senha para o seu acesso."
        />

        <form action="" onSubmit={onSubmit} className={classes.form}>
          <Input
            id="email"
            label="Nova senha"
            placeholder="Insira sua nova senha"
            type="password"
            value={password}
            setValue={setPassword}
          />

          <ButtonSubmit
            disabled={loading}
            loading={loading}
            // style={{ marginTop: 0 }}
          >
            Atualizar minha senha
          </ButtonSubmit>

          <ChannelsContainer />
        </form>
      </div>
    </div>
  );
};

export default PasswordRecoveryForm;
