import React from 'react';
import { PropsInterface } from './interface';
import useStyles from './styles';
import Teste from '../../../../../../../../../assets/images/payment/person.svg';
import { Box, Typography } from '@mui/material';

const TitlePlanDescription = ({ title, subtitle }: PropsInterface) => {
  const classes = useStyles();

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems="center"
      width={'100%'}
      ml={-2}
      className={classes.root}
    >
      <Box className={classes.iconContainer}>
        <img src={Teste} alt="Person" />
      </Box>
      <Box>
        <Typography className={classes.title}>
          <strong>{title}</strong>
        </Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </Box>
    </Box>
  );
};

export default TitlePlanDescription;
