import { makeStyles } from '@mui/styles';

const Styles = makeStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '50px'
    }
  },
  iconContainer: {
    marginRight: '20px',
    minWidth: '80px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px'
    }
  },
  title: {
    font: ' normal normal 400 24px Inter!important',
    color: '#410A85',
    // color:
    //   theme.palette.mode === 'light' ? '#410A85' : theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '22px !important'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px !important'
    }
  },
  subtitle: {
    marginTop: '8px!important',
    font: ' normal normal 400 16px Inter!important',
    color: '#4F3D66'
    // color:
    //   theme.palette.mode === 'light' ? '#4F3D66' : theme.palette.secondary.dark
  }
}));

export default Styles;
